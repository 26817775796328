/* global $ */
import EventBus from '@/eventBus.js';
import { fetchCartDetails } from '@/router/index.js'; // Import the fetchCartDetails function

export default {
  name: 'HomeNavbarView',

  props: {
    search_input: {
      required: false,
      type: String
    },
    currentRole: {
      type: String,
      default: 'influencer', // Default role
    },
  },

  data() {
    return {
      selectedRole: 'influencer',
      departments: [],
      sidebarOpen: false,
      subSidebarOpen: [], 
      selectedDepartmentIndex: null,
      cartQuantity: 0,
      cartTotal: 0, 
      isCartOpen: false,
      cartItems: JSON.parse(localStorage.getItem('cart')) || [],
      isRightSidebarOpen: false,
      cartItemsByInfluencer: [],
      checkoutInfluencer: null,



    };
  },

  

  created() {
    this.fetchCategories();
    this.fetchCartDetailsData();
    
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    this.selectRole('influencer');

    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });

    EventBus.on('toggle-right-sidebar', () => {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = true;
    });

    EventBus.on('update-cart', () => {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = true;

    });

  },

  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },

  methods: {
      
    async fetchCartDetailsData() {
      const data = await fetchCartDetails();
      this.cartItems = data.cartItems;
      this.cartQuantity = data.cartQuantity;
      this.cartTotal = parseFloat(data.cartTotal).toFixed(2);

      
      this.cartItemsByInfluencer = this.cartItems.reduce((acc, item) => {
        const existingGroup = acc.find(group => group.seller_id === item.seller_id);
        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          acc.push({ seller_id: item.seller_id, items: [item] });
        }
        return acc;
      }, []);

    },
    

    formatPrice(price) {
      const numericPrice = parseFloat(price);
      if (isNaN(numericPrice)) {
        return 'R 0.00';
      }
      return `${numericPrice.toLocaleString('en-ZA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },

    
    handleClickOutside(event) {
      const dropdown = this.$refs.cartDropdown;
      const iconContainer = this.$refs.iconContainer;
      

      // Check if the click is outside the dropdown and icon container
      if (dropdown && !dropdown.contains(event.target) && !iconContainer.contains(event.target)) {
        this.closeCart();
      }
    },

    viewInfluencerCart(sellerId) {
      // Set checkoutInfluencer to seller_id (which is the influencer ID)
      this.checkoutInfluencer = sellerId;
      this.isRightSidebarOpen = true;
      localStorage.setItem('sellerId', sellerId);  // Store the influencer ID in localStorage
    },

    handleBackButtonClick() {
      // Reset checkoutInfluencer when going back
      this.checkoutInfluencer = null;
      this.isRightSidebarOpen = true;
    },

    calculateTotal(items) {
    
      const total = items.reduce((total, item) => total + item.price * item.quantity, 0);
      return this.formatPrice(total);
    },
  
  
      

    deleteFromCart(id, product_id) {
      // Get the cart from local storage
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      
      const updatedCart = cart.filter(item => item.id !== id || item.product_id !== product_id);
    
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    
      // Update the cartItems and fetch updated cart details
      this.cartItems = updatedCart;

      this.fetchCartDetailsData();
    
      // Emit the cart updated event
      EventBus.emit('cart-updated', updatedCart.length);
    },
    increaseQuantity(id, product_id) {
      // Find the cart item by its id and product_id
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item) {
        item.quantity++;
        this.updateCartInLocalStorage(id, product_id);
      }
    },
    
    decreaseQuantity(id, product_id) {
      const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
      if (item && item.quantity > 1) {
        item.quantity--;
        this.updateCartInLocalStorage(id, product_id); 
      }
    },
    updateCartInLocalStorage(id, product_id) {
      // Retrieve the cart from local storage
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
    
      // Find and update the specific item by id and product_id
      const existingItem = cart.find(item => item.id === id && item.product_id === product_id);
      if (existingItem) {
        const updatedItem = this.cartItems.find(item => item.id === id && item.product_id === product_id);
        if (updatedItem) {
          existingItem.quantity = updatedItem.quantity;
        }
      }
    
      // Save the updated cart back to local storage
      localStorage.setItem('cart', JSON.stringify(cart));
      this.fetchCartDetailsData();
    },    
    
    goToLandingPage() {
      if (this.$route.path === '/') {
        location.reload();
      } else {
        this.$router.push('/');
      }
    },
    selectRole(role) {
      EventBus.emit('roleChanged', role);
      this.$emit('role-selected', role)
    },
    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        // Initialize subSidebarOpen with false values for each department
      } catch (error) {
        console.error(error);
      }
    },

    update_search(event) {
      this.$emit('update:search_input', event.target.value);
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
  },

     toggleRightSidebar() {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = !this.isRightSidebarOpen;
    },
    toggleSubSidebar(departmentIndex) {
      this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
    },
 
    setCheckoutInfluencer(sellerId) {
      // Set checkoutInfluencer to seller_id (which is the influencer ID)
      this.checkoutInfluencer = sellerId;
      localStorage.setItem('sellerId', sellerId);  // Store the influencer ID in localStorage
      this.proceedToCheckout();

    },

    proceedToCheckout() {
      const token = localStorage.getItem('token');
      if (!token) {
        const currentUrl = '/shipping_address'; // Set the intended URL
        localStorage.setItem('intendedUrl', currentUrl);
        this.$router.push('/login');
      } else {
        this.$router.push('/shipping_address');
      }
    },
  }
};
