<div>
  <div class="home-navbar">
    <Admin_sidebar />
  </div>
  <div v-if="loading" class="loader"></div>
  <div class="app-content">
    <Admin_navbar />
    <div class="container mt-4">
      <!-- Tabs Navigation -->
      <ul class="nav fee-tabs" id="feeTabs">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab" href="#influencer">INFLUENCER & KHIOSK</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#brand">BRAND & KHIOSK</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#influencerBrand">ALL THREE</a>
        </li>
      </ul>

      <!-- Tabs Content -->
      <div class="tab-content">
        <!-- Influencer & Kiosk -->
        <div id="influencer" class="container tab-pane active ">
          <div class="fee-section">
            <div class="text-start">
              <div class="mb-3">
                <label class="form-label">Khiosk Fee (%):</label>
                <input type="number" class="form-control" v-model="kioskFeeInfluencer" />
              </div>
              <div class="mb-3">
                <label class="form-label">Influencer Fee (%):</label>
                <input type="number" class="form-control" v-model="influencerFee" />
              </div>
            </div>
          </div>
        </div>

        <!-- Brand & Kiosk -->
        <div id="brand" class="container tab-pane fade">
          <div class="fee-section">
            <div class="text-start">
              <div class="mb-3">
                <label class="form-label">Khiosk Fee (%):</label>
                <input type="number" class="form-control" v-model="kioskFeeBrand" />
              </div>
              <div class="mb-3">
                <label class="form-label">Brand Fee (%):</label>
                <input type="number" class="form-control" v-model="brandFee" />
              </div>
            </div>
          </div>
        </div>

        <!-- Influencer & Brand & Kiosk -->
        <div id="influencerBrand" class="container tab-pane fade">
          <div class="fee-section">
            <div class="text-start">
              <div class="mb-3">
                <label class="form-label">Khiosk Fee (%):</label>
                <input type="number" class="form-control" disabled v-model="kioskFeeBrandInfluencer" />
              </div>
              <div class="mb-3">
                <label class="form-label">Influencer Fee (%):</label>
                <input type="number" class="form-control" disabled v-model="influencerFeeBrandInfluencer" />
              </div>
              <div class="mb-3">
                <label class="form-label">Brand Fee (%):</label>
                <input type="number" class="form-control" disabled v-model="brandFeeBrandInfluencer" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <button @click="distributeFunds" class="action-btn mt-3">Save</button>
    </div>
  </div>
</div>
