import Swal from 'sweetalert2'; // Import SweetAlert2

export default {
  name: 'ForgotPasswordView',
  data: () => ({
    email: '', // To store the email entered by the user
  }),
  methods: {
    async handleForgotPassword() {
        if (!this.email) {
          await Swal.fire({
            title: 'Error',
            text: 'Please enter your email address.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
          return;
        }
      
        const RESET_PASSWORD_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/forgot_password/`;
      
        try {
          const response = await fetch(RESET_PASSWORD_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: this.email }),
          });
      
          const data = await response.json();
      
          if (response.ok) {
            await Swal.fire({
              title: 'Password Reset Request',
              text: 'A password reset link has been sent to your email.',
              icon: 'success',
              iconColor: '#F99E1B',
              confirmButtonColor: '#000',
              confirmButtonText: 'OK',
            });
          } else {
            await Swal.fire({
              title: 'Info',
              text: data.message || 'Something went wrong.',
              icon: 'warning',
              confirmButtonColor: '#000',
              iconColor: '#F99E1B',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error sending password reset request:', error);
          await Swal.fire({
            title: 'Error',
            text: 'There was an error sending the password reset request. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
            iconColor: '#F99E1B',
            confirmButtonColor: '#000',
          });
        }
      }      
  },
};
