<div class="home-navbar ">
  <Sidebar />
</div>
 
<div class="app-content mt-1"> 
  <Navbar />
       
      <div class="content">
        <div class="upload-search-row">
          <button class="action-btn" v-if="!impersonating" data-toggle="modal" data-target="#uploadModal">
            <span class="plus-sign">+</span> Upload Content
          </button>
        </div>
        
       
      <div class="card table-card">
      <div class="card-body">
        <table id="content_table" class="table align-item table-flush-item content-table table-container">
          <thead class="thead-light">
            <tr>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Video</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Description</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Images</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">No of images</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="video in videos" :key="video.id">
              <td class="align-item"> <a :href="video.video_url" target="_blank">
                <i class="fa-solid fa-film text-black fs-3"></i> 
              </a></td>
              <td class="align-item">
                <div>
                <strong>{{ video.description }}</strong>
                <br>
                <small class="text-secondary">{{ video.sub_description }}</small>
                </div>
              </td>
              <td class="align-item">
                <span class="text-xxs font-weight-light mb-0">
                  <i v-if="video.number_of_images > 0" class="fa-regular fa-images fs-4 "></i>
                  <span v-else>No images available</span>
                </span>
              </td>
              
              <td class="align-item"><span class="text-xxs font-weight-light mb-0">{{ video.number_of_images ? video.number_of_images : '0'}}</span></td>
              <td class="align-item">
                <span class="badge badge-pill badge-warning text-warning">
                  {{ video.approvalstatus__approval_status }}
                </span>
              </td>
              <td class="align-item d-flex justify-content-center">
                <router-link :to="{ name: 'manage_content', params: { video_id: video.id } }">
                  <button class="btn-sm action-btn font-weight-light text-xxs manage-btn">Manage</button>
                </router-link>
                <button class="btn-sm btn-danger font-weight-light text-xxs delete-btn" v-if="!impersonating" @click="deleteVideo(video.id)">Delete</button>

              </td>
            </tr>
          </tbody>
        </table>
      </div> 
    </div>
  </div>
  </div>


<!-- Upload Video Modal -->
<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="uploadModalLabel">Video Upload</h5>
        <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <!-- Left column: Video Upload -->
          <div class="row justify-content-center">
            <div class="col-md-5  col-sm-5 mr-4">
              <div class="  mb-4">
                <label for="videoUpload" class="upload-label" id="uploadLabel">
                  <video id="videoPreview" controls class="video-preview"></video>
                </label>
                  <input type="file" id="video_url" accept="video/*" class="file-inputs" @change="previewVideo">
              </div>
            </div>

            <!-- Right column: Description fields -->
            <div class="col-md-5 col-sm-5 ">
              <div class="form-group">
                <label for="description" class="text-label">Description:</label>
                <input type="text" id="description" name="description" placeholder="eg. The River" class="form-control">
              </div>
              
              <div class="form-group">
                <label for="sub_description" class="text-label">Sub Description:</label>
                <input type="text" id="sub_description" name="sub_description" placeholder="eg. S01E01" class="form-control">
              </div>
              
              <div class="form-group">
                <label for="contentType" class="text-label">Content Type:</label>
                <select id="content_type_id" name="contentType" class="form-control">
                  <option value="" disabled selected hidden>Select Content Type</option>
                  <option value="1">Series</option>
                  <option value="2">Movies</option>
                  <option value="3">Music Video</option>
                  <option value="contentPiece">Content Piece</option>
                </select>
              </div>

            <div class="form-group">
              <p id="uploadText" class=" text-label">Cover Image:</p>

              <div class="image-container-cover">
              <div class="upload-box-image">
                <label for="cover_image" class="upload-label">
                  <div class="upload-icon icon-container" id="uploadIcon">📤</div>
                  <img id="imagePreview" ref="imagePreview" class="image-preview">
                </label>
                <input type="file" id="cover_image" ref="coverImage" accept="image/*" class="form-control-file d-none" >
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="submit-btn">Upload</button>
      </div>
    </div>
  </div>
</div>


  
  