import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import Search_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue'

import EventBus from '@/eventBus.js';
import $ from 'jquery';

export default {
  name: 'ProductsView',
  props: ['id', 'video_id'],
  components: {
    Home_navbar,
    Influencer_navbar,
    Search_product,
    Filter_product
  },
  data() {
    return {
      product: null,
      quantity: 1,
      selectedSize: null,
      selectedcolor: null,
      sizeProductId: null,
      sizes: [0],
      cartQuantity: 0,
      cartTotal: 0,
      cart: JSON.parse(localStorage.getItem('cart')) || [],
      LoggedIn: false,
      showLoginAlert: false, 
      showSizelert: false,
      addedToWishlist: false,
      search_query: ''
    };
  },

  created() {
    // Example: Check user authentication status
    this.LoggedIn = !!localStorage.getItem('token'); // Adjust according to your logic
    this.fetchCartDetails();
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

  },
  beforeDestroy() {
    // Cleanup event listener
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
  },

  mounted() {
    const image_id = this.$route.params.id;
    if (image_id) {
      this.fetchProducts(image_id);
    }
    this.fetchCartDetails();
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newImageId) {
        if (newImageId) {
          this.fetchProducts(newImageId);
        }
      }
    },
    sizes: {
      immediate: true,
      handler(all_sizes) {
        if (all_sizes.length === 1) {
          this.selectedSize = all_sizes[0].size;
        } else {
          this.selectedSize = null;
        }
      }
    }
  },
  methods: {
    update_search_query(new_value) {
      this.search_query = new_value;
    },

    formatPrice(price) {
      const numericPrice = parseFloat(price);
      if (isNaN(numericPrice)) {
        return 'R 0.00';
      }
      return `${numericPrice.toLocaleString('en-ZA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },

    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },

    // Helper function to get a value from local storage
    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },

    async addToCart(productId) {

      if (!this.selectedSize) {
        if (this.product.sizes.length === 1) {
          this.selectedSize = this.product.sizes[0].size;
          this.sizeProductId = this.product.sizes[0].product_id;
        } else {
          this.showSizelert = true;
          setTimeout(() => {
            this.showSizelert = false;
          }, 2000);
          return;
        }
      }

      this.sizeProductId = this.product.sizes.find(size => size.size === this.selectedSize).product_id;

      const quantity = this.quantity;
      productId = this.sizeProductId;
    
      if (this.isLoggedIn()) { 
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });
    
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        const cart = this.getLocalStorage('cart') || [];

        const existingProduct = cart.find(item => item.product_id === this.sizeProductId && item.size === this.selectedSize);
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          const productData = {
            id: this.product.product_id,
            product_name: this.product.product.product_name,
            quantity: quantity,
            price: this.product.price,
            size: this.selectedSize,
            brand: this.product.product.brand__brand_name,
            image: this.product.product.image_url,
            product_id: this.sizeProductId,
            seller_id: this.product.product.influencer_id
          };
    
          if (this.selectedcolor) {
            productData.color = this.selectedcolor;
          }
    
          cart.push(productData);
        }
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            this.isRightSidebarOpen = true;
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        })
      }
    },

    async fetchCartDetails() {
      const token = localStorage.getItem('token');
  
      if (token) {
        // User is logged in, fetch the cart from the server
        const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
        try {
          const response = await $.ajax({
            url: CART_API_URL,
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (response) {
            if (response.cart_products.length > 0) {
            this.cartItems = response.cart_products;
                
            this.cartQuantity = response.total_quantity;
            this.cartTotal = parseFloat(response.total_price);
          } else {
              this.cartProducts = [];
              this.closeCart();
          }
          }
          EventBus.emit('cart-updated', this.cartQuantity);

        } catch (error) {
          console.error('Failed to fetch cart from the server:', error);
    
          const cart = this.getLocalStorage('cart') || [];
    
          this.cartItems = cart;
          this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
          this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        }
      } else {
        const cart = this.getLocalStorage('cart') || [];
        this.cartItems = cart;
        
        this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      }
    },

    // Function to check if the user is logged in
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },


    async fetchProducts(image_id) {
      const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_product/${image_id}`;
      try {
        const response = await fetch(FETCH_PRODUCT_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // Ensure the response is properly handled
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        this.product = data;
        // Check if sizes are available in the first product and assign them
        this.sizes = data.sizes;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },

    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 0) {
        this.quantity--;
      }
    },
    selectSizeProduct(product) {
      this.selectedSize = product.size;
      this.selectedcolor = product.color;
      this.sizeProductId = product.product_id;
    },

    async addToWishlist(product_id) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.showLoginAlert = true;
        setTimeout(() => {
          this.showLoginAlert = false;
        }, 5000);
        return;
      }
    
      // Prepare the request data
      const formData = new FormData();
      formData.append('productdetail_ids', product_id);
    
      try {
        const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
        const response = await fetch(ADD_TO_WISHLIST_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });
    
        const result = await response.json();
        if (response.ok && response.status === 201) {
          // If the response is successful
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else if (response.status === 200) {
          // Handle case when the product is already in the wishlist (409 Conflict)
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else {
          // If there's an error in the API call (e.g., validation error)
          alert(result.error || 'Failed to add product to the wishlist.');
        }
      } catch (error) {
        // Handle unexpected errors
        alert('Something went wrong. Please try again.');
      }
    }
  }    

};
