
<div class="app register-page">
  <div class="container">
    <div class="sign-card">
        <img 
          :src="require('@/assets/images/new_logo_organge_black.png')" 
          alt="Kyosk Logo" 
          class="kyosk-logo mb-3"
        >

        <form @submit.prevent="onSubmit" class="login-form">
          
          <div class="form-group mb-3">
            <input 
              type="email" 
              placeholder="Email address" 
              id="email" 
              v-model="form.email" 
              class="form-control"
              required
            >
          </div>

          <div class="form-group">
            <div class="input-group">
              <input 
                :type="passwordType" 
                placeholder="Password" 
                class="form-control password-input" 
                v-model="form.password"  
                required
              >
              <i 
                @click="togglePassword" 
                :class="passwordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" 
                class="toggle-password-icon"
              ></i>
            </div>
          </div>

          <div class="text-left mb-3">
            <a href="/forgot_password" class="forgot-password-link">Forgot Password?</a>
          </div>

          <button type="submit" class="register-button fw-bold">Login</button>

        </form>

        <div class="row">
          <div class="col text-center text-black mt-3">
            Don't Have An Account?
            <a href="/register" class="text-blue-500"> Sign Up</a>
          </div>
        </div>
    </div>
  </div>
</div>
