import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue'
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'
/* eslint-disable-next-line no-unused-vars */
import { get_user_details, get_token } from '@/router'; // Assuming index.js is the default export
import Chart from 'chart.js/auto'

export default {
  components: {
    Admin_sidebar,
    Admin_navbar
  },

  data: () => ({
    token_exists: null,
    user_details: null,
    chart: null,
    revenueData: {
      labels: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      datasets: [
        {
          label: 'Sales',
          data: [
            80000, 120000, 140000, 50000, 0, 0,
            0, 0, 0, 0, 0, 0
          ],
          backgroundColor: '#F99E1B',
          yAxisID: 'ySales'
        },
        {
          label: 'Orders',
          data: [
            90, 120, 100, 150, 0, 0,
            0, 0, 0, 0, 0, 0
          ],
          backgroundColor: '#000',
          yAxisID: 'yOrders'
        }
      ]
    },
    videoInsightData: {
    labels: ['Likes', 'Products', 'Views'],
    datasets: [{
      data: [40, 55, 55],
      backgroundColor: [ '#F99E1B','#000','#BEC2CD']
    }]

  }
  }),

  mounted() {
    this.token_exists = get_token()
    this.user_details = get_user_details()

    if (!this.token_exists || (this.user_details.user_role !== 'ADMIN')) {
      localStorage.removeItem('token')
      this.$router.push('/login')
    }

    this.renderChart()
    this.renderVideoInsightChart()
  },

  methods: {
    renderChart() {
      const ctx = this.$refs.salesChart
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.revenueData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              align: 'end',
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',

              }
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            }
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              grid: {
                display: false 
              }
            },
            ySales: {
              type: 'linear',
              position: 'left',
              title: {
                display: true,
                text: 'Sales (R)'
              },
              ticks: {
                callback: function(value) {
                  return 'R' + value / 1000 + 'k';
                }
              },
              grid: {
                display: false
              },
              beginAtZero: true
            },

            yOrders: {
              type: 'linear',
              position: 'right',
              title: {
                display: true,
                text: 'Orders'
              },
              grid: {
                drawOnChartArea: false
              },
              beginAtZero: true
            }
          }
        }
      })
    },
    renderVideoInsightChart() {
      const ctx = this.$refs.videoPieChart
      new Chart(ctx, {
        type: 'pie',
        data: this.videoInsightData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true,
                pointStyle: 'circle'
              }
            }
          }
        }
      })
    }
  
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}