<div class="home-navbar">
    <Influencer_sidebar />
  </div>
  <div class="app-content delivery-category">
    <Influencer_navbar />
    
    <!-- Main Content -->
    <div class="address-content mt-4 col-6">
      <h5 class="fw-bold">Banking Details</h5>
  
      <!-- Edit Existing Details Form -->
      <form v-if="banking_details" @submit.prevent="update_banking_details">
        <input type="hidden" v-model="view_form.id">
        <div class="mb-4">
          <label>Account Holder</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="view_form.account_holder"
            :readonly="userRole === 'ADMIN'"
            :disabled="userRole === 'ADMIN'"
            required
          />
        </div>
        <!-- Bank Dropdown: Add @change event -->
        <div class="mb-4">
          <label>Bank Name</label>
          <select
            v-model.number="view_form.bank_id"
            @change="view_form.branch_code = banks.find(b => b.id === view_form.bank_id)?.branch_code || ''"
            class="form-control form-control-sm"
            :readonly="userRole === 'ADMIN'"
            :disabled="userRole === 'ADMIN'"
            required
          >
            <option value="" disabled>Select Bank</option>
            <option 
              v-for="bank in banks" 
              :key="bank.id" 
              :value="bank.id"
            >
              {{ bank.name }}
            </option>
          </select>
        </div>
      
        <!-- Branch Code Input: Change to v-model -->
        <div class="mb-4">
          <label>Branch Code</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="view_form.branch_code"
            :readonly="userRole === 'ADMIN'"
            :disabled="userRole === 'ADMIN'"
            required
          />
        </div>
      
        <div class="mb-4">
          <label>Account Number</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="view_form.account_number"
            :readonly="userRole === 'ADMIN'"
            :disabled="userRole === 'ADMIN'"
            required
            @keypress="onlyNumbers($event)"

          />
        </div>
      
        <div class="mb-4">
          <label>Account Type</label>
          <select
            class="form-control form-control-sm"
            v-model="view_form.account_type_id"
            :readonly="userRole === 'ADMIN'"
            :disabled="userRole === 'ADMIN'"
            required
          >
            <option value="" disabled>Select Account Type</option>
            <option 
              v-for="account_type in account_types" 
              :key="account_type.id" 
              :value="account_type.id"
            >
              {{ account_type.account_type }}
            </option>
          </select>
        </div>
      
        <button 
        type="submit" 
        class="action-btn fw-bold" 
        id="save_btn"
        v-if="userRole !== 'ADMIN'"
      > Save
      </button>
      </form>
      <!-- Add New Details -->
      <!-- Add New Form -->
<form v-else @submit.prevent="save_banking_details">
    <div class="mb-4">
      <label>Account Holder</label>
      <input
        type="text"
        v-model="form.account_holder"
        class="form-control form-control-sm"
        required
      />
    </div>
  
    <div class="mb-4">
      <label>Bank Name</label>
      <select
        v-model.number="form.bank_name"
        @change="form.branch_code = banks.find(b => b.id === form.bank_name)?.branch_code || ''"
        class="form-control form-control-sm"
        required
      >
        <option value="" disabled>Select Bank</option>
        <option 
          v-for="bank in banks" 
          :key="bank.id" 
          :value="bank.id"
        >
          {{ bank.name }}
        </option>
      </select>
    </div>
  
    <div class="mb-4">
      <label>Branch Code</label>
      <input
        type="text"
        v-model.number="form.branch_code"
        class="form-control form-control-sm"
        readonly
        required
      />
    </div>
  
    <div class="mb-4">
      <label>Account Number</label>
      <input
        type="text"
        v-model.number="form.account_number"
        class="form-control form-control-sm"
        required
      />
    </div>
  
    <div class="mb-4">
      <label>Account Type</label>
      <select
        v-model.number="form.account_type"
        class="form-control form-control-sm"
        required
      >
        <option value="" disabled>Select Account Type</option>
        <option 
          v-for="account_type in account_types" 
          :key="account_type.id" 
          :value="account_type.id"
        >
          {{ account_type.account_type }}
        </option>
      </select>
    </div>
  
    <button v-if="!isImpersonatingAdmin" type="submit" class="action-btn fw-bold">Save
    </button>
  </form>
  </div>
  </div>