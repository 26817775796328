import Influencer_sidebar from "@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue";
import Influencer_navbar from "@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue";
import Swal from 'sweetalert2';

export default {
    name: "OrderReturnsView",
    components: {
        Influencer_sidebar,
        Influencer_navbar,
    },
    data: () => ({

            orderId: '', // Access orderId from route params
            itemId: '', // Add this property
            productImage: '', // Initialize with an empty string
            selectedItem: '', // Define selectedItem here
            order_number: '',
            currentStep: 1, // Track the current step
            total: 0,
            pargoIframeSrc: process.env.VUE_APP_PARGO_DROPOFF_IFRAME_SRC,
            isMobile: false,
            selectedPoint: null,
            reason: '',
            deliveryFee: 0,
            errorMessage: '',
            orderDetails: {},
            showIframe: false,

        }),

    mounted() {
      this.orderId = this.$route.params.orderId;
      this.itemId = this.$route.params.itemId, // Add this property
      this.getOrderDetails();


        window.addEventListener('message', (event) => {
            if (event.data && event.data.addressSms) {
              this.selectedPoint = event.data;
              this.deliveryFee = 65; // Update delivery fee when point is selected
              // Now you can use the selectedPoint object in your JavaScript code
            }
          });
          this.checkScreenSize();
          window.addEventListener('resize', this.checkScreenSize);


    },
  methods: {
    
    nextStep() {
        if (this.currentStep === 1) {
          if (!this.reason) {
            this.errorMessage = 'Please select a reason for returning the product.';
            return;
          }
        }
    
        if (this.currentStep === 2) {
          if (!this.selectedPoint) {
            this.errorMessage = 'Please select a pickup point.';
            return;
          }
        }
    
        if (this.currentStep < 3) {
          this.currentStep++;
          this.errorMessage = ''; // Clear the error message
        } else {
          // Proceed with payment
          this.proceedToCheckout();
        }
      },
    previousStep() {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      },
      selectReason(reason) {
        if (this.reason !== reason) {
          this.reason = reason;
          // Uncheck other reasons
          document.querySelectorAll('.return-reason input[type="checkbox"]').forEach((checkbox) => {
            if (checkbox !== event.target) {
              checkbox.checked = false;
            }
          });
        } else {
          this.reason = null;
          event.target.checked = false;
        }
      },
    changePoint() {
        this.selectedPoint = null;
        this.deliveryFee = 0;
        this.showIframe = true;

      },
    checkScreenSize() {
        if (window.innerWidth < 768) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      },
    
    totalDelivery() {
        if (this.selectedPoint) {
          return (this.selectedPoint.delivery_price || 0).toFixed(2);
        } else {
          return (0).toFixed(2);
        }
      },

    uncheckOthers(value) {
        if (this.reason === value) return;
        this.reason = value;
    
      },
    
    
    async senderInfo() {
      
      const token = localStorage.getItem('token');
      const GET_USER_INFO = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`;
      try {
        const response = await fetch(GET_USER_INFO, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch buyer phone number');
        }
    
        const data = await response.json();
        if (!data.phone_number) {
          Swal.fire({
            title: 'Error!',
            text: 'Please add a phone number to your profile.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        } else {
          this.buyerPhoneNumber = data.phone_number;
        }
      } catch (error) {
        console.error('Error fetching buyer phone number:', error);
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred while fetching buyer phone number.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
      
    async proceedToCheckout() {
          await this.senderInfo();

          if (!this.buyerPhoneNumber) {
            // Don't proceed with checkout if buyer's phone number doesn't exist
            return;
          }
      
          
          
          if (!this.selectedPoint) {

            Swal.fire({
              title: 'Error!',
              text: 'Please select a pickup point.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
            
          } else {

            const sellerPhoneNumber = this.selectedItem.product_details.seller_phone_number;
            if (!sellerPhoneNumber) {
              Swal.fire({
                title: 'Error!',
                text: 'The seller\'s phone number does not exist. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
              return;
            }
        
            // Show Swal loading animation
            Swal.fire({
              title: 'Processing...',
              text: 'Please wait while we process your payment.',
              allowOutsideClick: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
        
            // Get seller ID from cart items
            const sellerId = this.selectedItem.product_details.seller_id;

        
            // Get seller's drop-off point
            const token = localStorage.getItem('token');
            const SELLER_DROP_OFF_POINT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/seller_drop_off_point/${sellerId}/`;
        
            fetch(SELLER_DROP_OFF_POINT_API, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
              }
            })
            .then(response => response.json())
            .then(data => {
              // Proceed to checkout logic here
              
              const CHECKOUT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/initiate_return_payment_api/`;
        
              // Format cart items to match backend requirements
              
            
              const checkoutData = {
                drop_off_point: data.pargo_point_code, // Add seller's drop-off point
                pickup_point_code: this.selectedPoint.pargoPointCode,
                pickup_details: this.selectedPoint,
                return_reason: this.reason,
                order_item: this.selectedItem.id,
                order: this.orderId,
                seller_id: sellerId ,
                delivery_fee: this.deliveryFee,
                phone_number: this.buyerPhoneNumber,
                seller_phone_number: sellerPhoneNumber,
              };
              
              fetch(CHECKOUT_API, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                },
                body: JSON.stringify(checkoutData),
              })
              .then(response => response.json())
              .then(data => {
                if (data.payment_url) {
                  // Redirect the user to the payment URL
                  window.location.href = data.payment_url;
                } else {
                  Swal.fire({
                    title: 'Error!',
                    text: 'Failed to initiate payment. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  });
                }
              })
              .catch(error => {
                console.error('Error during checkout:', error);
                Swal.fire({
                  title: 'Error!',
                  text: 'An error occurred during checkout. Please try again.',
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              });
            })
            .catch(error => {
                console.error('Error getting seller drop-off point:', error);
                Swal.fire({
                  title: 'Error!',
                  text: 'Failed to get seller drop-off point. Please try again.',
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              });
            }
          },
      
    async getOrderDetails() {
      const token = localStorage.getItem('token');
      const VIEW_ORDER_DETAILS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_order_details_api/${this.orderId}`;
    
      try {
        // Perform the fetch request
        const response = await fetch(VIEW_ORDER_DETAILS_API, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
    
        // Check if the response is successful (status code 200-299)
        if (response.ok) {
          const orderDetails = await response.json();  // Wait for the JSON to be parsed
          this.order_number = orderDetails.data.order_number;
          // Find the selected item
          const selectedItem = orderDetails.data.order_items.find(item => item.product_details.id === parseInt(this.itemId,10));
          if (selectedItem) {
              this.selectedItem = selectedItem; // Update selectedItem here
              this.productImage = selectedItem.product_details.image_url;
            }
        } else {
          console.error('Failed to fetch order details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    },
}
  
};