<div class="home-navbar ">
  <Influencer_sidebar />
</div>

<div v-if="popupVisible" class="centered-popup alert-success">
  {{ notUpdatedMessage }}
</div>

<div class=" app-content influencer-profile-sm ">
  <Influencer_navbar/>
  <div v-if="!search_query">
    <!-- Your content here -->
    <div class="card border-0 bg-transparent">
      

      <div class="profile-header rounded-12 position-relative">
        <div class="top-right-buttons">
          <button class="btn btn-dark btn-sm me-2" @click="showEditProfileModal = true" data-toggle="tooltip" data-placement="bottom" title="Edit Profile">
            <i class='bx bx-pencil '></i> Profile
          </button>
          <button class="btn btn-outline-dark text-black btn-sm" 
        data-toggle="tooltip" 
        data-placement="bottom" 
        title="Share Profile" 
        @click="toggleShareMenu">
    <i class='bx bx-share-alt'></i>
</button>


<div class="share-button-container position-relative text-primary text-xxs text-black">
  <div v-if="loading" class="loader-overlay">
    <div class="loader"></div>
  </div>
  <div v-if="showShareMenu" class="mb-3 me-2 menu mt-1 share-menu position-absolute p-2 bg-light" style="max-height: 200px; overflow-y: auto;">
    <div class="d-flex flex-column align-items-center">
      <a @click="copyProfileLinkToClipboard" class="menu-item mb-2 text-dark" data-bs-toggle="tooltip" title="Copy Link">
        <span @click="closeMenu">
          <i class="bi bi-link fs-5"></i>
        </span>
      </a>
      
      <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(profileUrl) + '&t=' + new Date().getTime()" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Facebook">
        <i class="bi bi-facebook fs-5"></i>
      </a>
      
      <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Twitter">
        <i class="bi bi-twitter fs-5"></i>
      </a>
      
      <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share via Email">
        <i class="bi bi-google fs-5"></i>
      </a>
      
      <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on LinkedIn">
        <i class="bi bi-linkedin fs-5"></i>
      </a>
      
      <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on WhatsApp">
        <i class="bi bi-whatsapp fs-5"></i>
      </a>
      
      <a :href="'https://t.me/share/url?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Telegram">
        <i class="bi bi-telegram fs-5"></i>
      </a>
    </div>
      <!-- Toast Notification for Copy -->
      <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
        <div id="copyToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body">
              Link copied to clipboard!
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
        </div>
        <div class="profile-influencer d-flex justify-content-center align-items-center">
          <img :src="profileImage || require('@/assets/images/default-profile.png')" alt="Profile Picture" class="profile-picture-img-modal text-start" id="profileImage">
          <div class="influencer_info ">
            <p class="text-start profile-name fw-bolder">{{ form.first_name }} {{ form.last_name }}</p>
            <p class="text-start">
              <strong class="fw-bolder fs-6">{{ purchasesCount }}+ </strong> 
              <span class="text-muted text-xs"> Sold</span>
            </p>
            <p class=" text-start bio">{{ form.bio }}</p>
          </div>
        </div>

      </div> 
      <ul class="nav nav-pills justify-content-center mt-1" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-drafts-tab" data-bs-toggle="pill" data-bs-target="#pills-drafts" type="button" role="tab" aria-controls="pills-drafts" aria-selected="true" ><i class='bx bx-edit'></i> <span>Drafts</span></button>      
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="false"><i class='bx bx-video'></i> <span>Content</span></button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="false"><i class='bx bx-wallet'></i> <span>Wallet</span></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-liked-videos-tab" data-bs-toggle="pill" data-bs-target="#pills-liked-videos" type="button" role="tab" aria-controls="pills-liked-videos" aria-selected="false" @click="changeTab('content')"><i class='bx bx-heart'></i> <span>Liked</span></button>
      </li>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" id="pills-drafts" role="tabpanel" aria-labelledby="pills-drafts-tab">
          <div v-if="videosWithoutProducts.length === 0" class="text-center mt-4 ">
            No videos available. Upload your first video
            <i class="fa-regular fa-square-plus text-black fs-3 d-block mt-2 d-none"></i>
          </div>

          <div v-else class="row">
            <div class="view-video-lists">
              <div v-for="(video, index ) in videosWithoutProducts" :key="index" class="video-card position-relative">
                <div ref="videoPlayer_{{ index }}"  @click="openFullScreen(video.id, index,videosWithoutProducts )">                
                <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />
                                        
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                  <!-- Delete Icon Below Like -->
              <!-- Three-dot menu -->
              <div class="position-absolute top-0 end-0 m-2">
                <div class="dropdown">
                  <button class="btn btn-sm text-muted" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                    <i class="fa-solid fa-ellipsis-vertical fs-6 text-white"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                      <button class="dropdown-item delete-btn btn btn-sm text-black border-0 text-xxs"  @click="deleteVideo(video.id)" type="button">
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
                <!-- Display associated product images on top of the video -->
                <div class="circle-images d-flex justify-content-center pb-85">
               
                  <div class="add-product-btn" @click="openProductUploadModal(video.id)" style="cursor: pointer;">
                    <i class="fa fa-plus-circle text-white fs-3"></i>
                  </div>
                </div>
              </div>
              </div>

            </div>
          </div>
        </div>
        <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
          <div v-if="videosWithProducts.length === 0" class="text-center mt-4 ">
            No videos available. Upload your first video
            <i class="fa-regular fa-square-plus text-black fs-3 d-block mt-2 d-none"></i>
          </div>

          <div v-else class="row">
            <div class="view-video-lists">
              <div v-for="(video, index ) in videosWithProducts" :key="index" class="video-card position-relative">
                <div ref="videoPlayer_{{ index }}"  @click="openFullScreen(video.id, index, videosWithProducts)">                
                <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />
                
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div class="position-absolute top-0 end-0 m-2">
                  <div class="dropdown">
                    <button class="btn btn-sm text-muted" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                      <i class="fa-solid fa-ellipsis-vertical fs-6 text-white"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <button class="dropdown-item delete-btn btn btn-sm text-black border-0 text-xxs" @click="deleteVideo(video.id)" type="button">
                          Delete
                        </button>
                      </li>
                    
                    </ul>
                  </div>
                </div>
                                <!-- Display associated product images on top of the video -->
                <div class="circle-images d-flex justify-content-centre pb-85">
                  <div v-for="(image, index) in video.products" :key="index" class="text-center position-relative"> 
                    <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                      <input type="hidden" ref="image_id" :value="image.product.id">
                      <img
                        v-if="image.product && image.product.image_url"
                        :src="image.product.image_url"
                        class="circle-image"
                        @error="(event) => event.target.src = require('@/assets/images/img-error.png')"
                        alt="Product Image"
                      >
                    </router-link>
                    <span @click.stop="deleteProduct(image.product.id)" class="remove-product-btn position-absolute">
                      &#x2716;
                    </span>
                  </div>
                  <div class="add-product-btn" @click="openProductUploadModal(video.id)" style="cursor: pointer;">
                    <i class="fa fa-plus-circle text-white fs-3"></i>
                  </div>
                  
                  
                </div>
                
              </div>
              <div class="view-count text-white">
                <i class="fa fa-eye "></i> {{ video.number_of_views }}

              </div>
              </div>
              

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">
          <div class="container pt-4">
           <!-- Wallet Card -->
          <div class="wallet-card-influencer">
            <p class="fw-bold mb-1 text-start">Available Balance</p>
            <h2 class="fw-bold text-start">R {{ total_balance }}</h2>
            <div class="pending-balance-influencer mt-3 text-start">
              <span>Pending Balance</span>
              <span class="float-end">R {{ pending_balance }}</span>
            </div>
          </div>

          <!-- Withdraw Button -->
          <div class="text-end mt-3">
          <div v-if="!isImpersonating" class="text-end mt-3">
            <button class="withdraw-btn border-0" @click="openModal">
              Withdraw <i class="fas fa-arrow-up"></i>
            </button>
          </div>
        </div>
            <div class="mt-4">
              <!-- Align Heading to the Start -->
              <h5 class="text-start">Payment History</h5>
              <!-- Center Icon and Text -->
            </div>            
            
            
            <!-- Tabs for All, Money In, and Money Out -->
<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
      <button class="nav-link active text-black" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All</button>
  </li>
  <li class="nav-item" role="presentation">
      <button class="nav-link text-black" id="money-in-tab" data-bs-toggle="tab" data-bs-target="#money-in" type="button" role="tab" aria-controls="money-in" aria-selected="false">Money In</button>
  </li>
  <li class="nav-item" role="presentation">
      <button class="nav-link text-black" id="money-out-tab" data-bs-toggle="tab" data-bs-target="#money-out" type="button" role="tab" aria-controls="money-out" aria-selected="false">Money Out</button>
  </li>
</ul>

<!-- Tab content -->
<div class="tab-content" id="myTabContent">
  <!-- All Tab content -->
  <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
      <div class="card table-card">
          <div class="card-body">
              <table id="content_table_all" class="table align-item table-flush-item content-table">
                  <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Date</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                      </tr>
                  </thead>
                  <tbody v-if="paymentHistory && paymentHistory.length > 0">
                      <tr v-for="(payment, index) in paymentHistory" :key="payment.id">
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                          </td>
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ payment.date_created }}</span>
                          </td>
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">R {{ payment.balance }}</span>
                          </td>
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ payment.balance_status.status }}</span>
                          </td>
                          <td class="text-start">
                              <button class="btn-sm action-btn font-weight-light text-xxs">
                                  View
                              </button>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td colspan="10" class="text-center">
                              <div class="mt-4">
                                  <div class="text-center text-muted">
                                      <i class="bx bx-wallet fs-1 mb-1"></i>
                                      <p>No payment history available</p>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>

    <!-- Money In Tab content -->
    <div class="tab-pane fade" id="money-in" role="tabpanel" aria-labelledby="money-in-tab">
      <div class="card table-card">
          <div class="card-body">
              <table id="content_table_money_in" class="table align-item table-flush-item content-table">
                  <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Date</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                      </tr>
                  </thead>
                  <tbody v-if="moneyInHistory && moneyInHistory.length > 0">
                      <tr v-for="(payment, index) in moneyInHistory" :key="payment.id">
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                          </td>
    
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ payment.date_created }}</span>
                          </td>
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">R {{ payment.balance }}</span>
                          </td>
                          <td class="text-start">
                              <span class="text-xxs font-weight-light mb-0">{{ payment.balance_status.status }}</span>
                          </td>
                          <td class="text-start">
                              <button class="btn-sm action-btn font-weight-light text-xxs">
                                  View
                              </button>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td colspan="10" class="text-center">
                              <div class="mt-4">
                                  <div class="text-center text-muted">
                                      <i class="bx bx-wallet fs-1 mb-1"></i>
                                      <p>No money in history available</p>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>

  <!-- Money Out Tab content -->
  <div class="tab-pane fade" id="money-out" role="tabpanel" aria-labelledby="money-out-tab">
      <div class="card table-card">
          <div class="card-body">
              <table id="content_table_money_out" class="table align-item table-flush-item content-table">
                  <thead class="thead-light">
                      <tr>
                          <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                          <th class="text-uppercase text-xxs fw-bold opacity-9">Date</th>
                          <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
                          <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                          <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="moneyOutHistory && moneyInHistory.length > 0">
                        <tr v-for="(payment, index) in moneyOutHistory" :key="payment.id">
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ payment.product_name }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">R {{ payment.amount }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ payment.influencer_name }}</span>
                            </td>
                            <td class="text-start">
                                <button class="btn-sm action-btn font-weight-light text-xxs">
                                    View
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="10" class="text-center">
                                <div class="mt-4">
                                    <div class="text-center text-muted">
                                        <i class="bx bx-wallet fs-1 mb-1"></i>
                                        <p>No money out history available</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</div>
          </div>
        </div>
        <div class="tab-pane fade " id="pills-liked-videos" role="tabpanel" aria-labelledby="pills-liked-videos-tab">
          <div v-if="likedVideos.length === 0" class="text-center mt-4 ">
            No videos available. like your first video
            <i class="fa-regular fa-square-plus text-black fs-3 d-block mt-2 d-none"></i>
          </div>

          <div v-else class="row">
            <div class="view-video-lists">
              <div v-for="(video, index ) in likedVideos" :key="index" class="video-card position-relative">
                <div ref="videoPlayer_{{ index }}"  @click="openFullScreen(video.id, index, likedVideos)">                
                <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />
                                        
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <!-- Display associated product images on top of the video -->
                <div class="circle-images d-flex justify-content-center pb-85">
                  <div v-for="(image, index) in video.products" :key="index" class="text-center position-relative"> 
                    <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                      <input type="hidden" ref="image_id" :value="image.product.id">
                      <img
                        v-if="image.product && image.product.image_url"
                        :src="image.product.image_url"
                        class="circle-image"
                        @error="(event) => event.target.src = require('@/assets/images/img-error.png')"
                        alt="Product Image"
                      >
                    </router-link>

                  </div>

                </div>
              </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div v-else>
    <div class="container">
      <div class="row">
          <div class="col-md-3">
              <Filter_product
              @filter-products="filter_product_list"
              />
          </div>

          <div class="col-md-9">
              <div class="items-found">{{ filteredProducts.length }} items found</div>

              <!-- Dropdown to show combined results -->
              <div v-if="showDropdown" class="dropdown-menu">
                  <div
                      v-for="result in filteredProducts"
                      :key="result.id"
                      @click="selectResult(result)"
                      class="dropdown-item"
                  >
                  <span v-if="result.type === 'product'">{{ result.product_name }}</span>
                  <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                  <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                  <span v-else>Description: {{ result.description }}</span>
                  </div>
              </div>
          
              <div class="product-grid">
                  <router-link :to="{name: 'product', params: {id: product.id }}" class="product-card" v-for="product in filteredProducts" :key="product.id">
                      <input type="hidden" ref="image_id" :value="product.id">
                      <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
                      </div>
                      <div class="product-info text-start">
                          <p class="product-title text-black">{{ product.product.product_name }}</p>
                          <p class="product-brand">{{ product.product.brand__brand_name }}</p>
                          <div class="d-flex justify-content-between align-items-center">
                                  <p class="product-price text-black">R{{ product.price }}</p>
                                  <div class="d-flex justify-content-between">
                                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="addToCart(product)"></i>
                                  </div>
                                </div>
                      </div>
                  </router-link>
              </div>
          </div>
      </div>
  </div>
</div>
</div>

<div v-if="showEditProfileModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h5 class="modal-title">Profile</h5>
        <button type="button" class="close" @click="showEditProfileModal = false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center mb-3">           
          <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img-modal"  id="modalProfileImage">
          <i class="bx bxs-camera imge-edit-modal" 
              data-toggle="tooltip" 
              data-placement="bottom" 
              title="Change Profile Picture" 
              @click="triggerFileInput" 
              id="uploadIcon">
          </i> 
          <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
        </div>
          <form @submit.prevent="updateUser">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="first_name" class="text-label">First name:</label>
                <input type="text" class="form-control" v-model="form.first_name" id="first_name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last_name" class="text-label">Last name:</label>
                <input type="text" class="form-control" v-model="form.last_name" id="last_name">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="text-label">Email address:</label>
                <input type="email" class="form-control" v-model="form.email" id="email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone_number" class="text-label">Phone number:</label>
                <input type="text" class="form-control" v-model="form.phone_number" id="phone_number">
              </div>
            </div>
            <div class="form-group">
              <label for="bio" class="text-label">Bio:</label>
              <textarea class="form-control" v-model="form.bio" @input="updateBio($event.target.value)" id="bio"></textarea>
              <p class="text-muted">Characters left: {{ 150 - form.bio.length }}</p>
            </div>
            
          </div>
        </form>
      </div>
      <div  v-if="!isImpersonating" class="modal-footer">
          <button type="button" class="action-btn" :disabled="form.bio.length > 150" @click="updateUser">Save changes</button>
      </div>
    </div>
  </div>
</div>

<div v-if="isFullScreenMode" class="full-screen-container">
  <Fullscreen_navbar/>
  <!-- Close Button -->
  <button class="close-btn" @click="exitFullScreen">
      <i class="bx bx-x"></i>
  </button>
  <!-- Influencer Info -->
 
  <div v-if="isFetching" class="spinner-container">
      <div class="spinner-border text-light" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>

  <div class="reel-container">
  <div v-for="(reel, index) in fullScreenReels" :key="index" class="reel" :ref="'videoPlayer_' + index">

      <video :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin" playsinline autoplay  controls preload="auto" @play="playVideoAtIndex(index)">
          <source :src="reel.video_url" type="video/mp4" />
        </video>

      <div class="influencer-info d-flex align-items-center">
          <router-link
          :to="{ name: 'view_Influencer_Profile', params: { influencerId: reel.influencer.id } }"
          class="d-flex align-items-center text-decoration-none"  style="cursor: pointer;"
        >
          <img :src="reel.influencer.image" class="circle-image rounded-circle me-2"  @error="(event) => event.target.src = require('@/assets/images/default-profile.png')"/>
          
          <span class="fw-bold text-light">{{ reel.influencer.first_name }} {{reel.influencer.last_name}}</span>
      </router-link>
      </div>
      <div class="action-icons">
        <!-- Like Icon -->
        <div class="d-flex flex-column align-items-center text-white" style="cursor: pointer;" @click="toggleLike(reel)">
          <!-- Heart icon -->
          <i :class="reel.is_liked ? 'bx bxs-heart text-danger fs-3' : 'bx bxs-heart fs-3'"></i>
          <!-- Count below the icon, with a small margin-top -->
          <span class="text-white" >{{ reel.like_count }}</span>
        </div>
        <div class="share-icon" @click="toggleShareMenu(index)">
          <i class="fa-solid fa-share text-white fs-5"></i>
          <div v-if="showShareMenu === index" class="share-modal-overlay" @click="closeMenu">
              <div class="share-modal-content" @click.stop>
                  <div class="share-modal-header d-flex justify-content-between align-items-center">
                      <h4 class="share-modal-title fw-bold fs-5">Share Video</h4>
                      <button type="button" class="btn-close share-btn-close" @click="closeMenu" aria-label="Close"></button>
                  </div>
                  <div class="share-modal-body">
                  <!-- Share icons here -->
                  <a @click="copyVideoLinkToClipboard(reel.video_url)" class="share-menu-item mb-2 text-dark" data-bs-toggle="tooltip" title="Copy Link">
                      <span @click="closeMenu">
                          <i class="bi bi-link fs-3 clipboard-share"></i>
                      </span>
                  </a>
                  <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(reel.video_url)" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on WhatsApp">
                      <i class="fa-brands fa-square-whatsapp whatsapp-share fs-3"></i> 
                  </a>
                  <a :href="'https://www.instagram.com/?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Instagram">
                      <i class="fa-brands fa-square-instagram fs-3 instagram-share"></i>
                  </a>
                  <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(reel.video_url) + '&t=' + new Date().getTime()" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Facebook">
                      <i class="fa-brands fa-facebook fs-3 facebook-share"></i></a>

                  <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Twitter">
                      <i class="fa-brands fa-x-twitter fs-5 twitter-share"></i>                            </a>
                  <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(reel.video_url)" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share via Email">
                      <i class="fa-solid fa-envelope-open-text fs-3 email-share"></i>                            </a>
                  <a :href="'https://t.me/share/url?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="share-menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Telegram">
                      <i class="bi bi-telegram fs-3 telegram-share"></i>
                  </a>
              </div>
          </div>
    </div>
  </div>
      
      </div>
  <div class="circle-images pb-85">
      <router-link v-for="(product, imgIndex) in reel.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }" @click.native="enterPictureInPicture(reel, index)">
          <img :src="product.product.image_url" class="modal-circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
      </router-link>
  </div>
  </div>
 
  <div ref="scrollSentinel" class="scroll-sentinel"></div>
  
  </div>
</div>

<div class="modal fade" id="productUploadModal" tabindex="-1" role="dialog" aria-labelledby="productUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fw-bolder">Upload Products</h5>
        <!-- Upload Button in Modal Header -->
        <button type="button" class="action-btn" @click="uploadProducts(selectedVideoId)">Upload</button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="uploadImageForm">
          <div class="row">
            <!-- Upload Section (Left) -->
            <div class="col-6 d-flex flex-column align-items-center">
              <div class="upload-image-container text-center p-3 border rounded w-100">
                <div class="upload-box-image mb-3">
                <label for="product_url" class="upload-label">
                  <!-- Show upload icon when no image is uploaded -->
                  <div v-if="!productUploaded" class="mt-5">
                    <i class="fa fa-cloud-upload fs-4"></i>
                    Upload Image
                  </div>
                  <!-- Show uploaded image preview -->
                  <img
                    v-if="productUploaded"
                    id="productPreview"
                    class="image-preview mt-5"
                    :src="productPreviewUrl || require('@/assets/images/no-image.png')"
                    alt="Image Preview"
                  />
                </label>
                </div>
                <input type="file" id="product_url" accept="image/*" class="d-none" @change="previewProductImage" multiple>
              </div>

              <div v-if="uploadedProducts.length > 0">                              
                <div class="form-group mt-2">
                  <label for="products" class="text-label fw-bold">Uploaded Products:</label>
                  <ul class="list-unstyled d-flex flex-row">
                    <li v-for="(product, index) in uploadedProducts" :key="index" class="d-flex flex-row align-items-centre mx-2">
                      <img :src="product.productPreviewUrl" alt="Image" class="product-image-thumbnail" @input="showImageError = false">
                      <span @click="removeUploadedProduct(product)" class="text-black mt-1" style="cursor: pointer;">&#x2716;</span>
                    </li>
                  </ul>
                </div>
              </div>
              <small v-if="showImageError" class="text-danger text-xxs">image is required.</small>
            </div>

            <!-- Product Details (Right) -->
            <div class="col-6">
              <div class="form-group">
                <input type="text" id="productname" class="form-control form-control-sm" placeholder="Product name" required @input="showProductNameError = false">
                <small v-if="showProductNameError" class="text-danger text-xxs">Product name is required.</small>
              </div>

              <div class="form-group mt-2">
                <textarea id="condition" class="form-control form-control-sm" placeholder="Add description/condition" required @input="showDescriptionError = false"></textarea>
                <small v-if="showDescriptionError" class="text-danger text-xxs">Description is required.</small>
              </div>

              <div class="input-group input-group-sm mt-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">R</span>
                </div>
                <input type="text" id="amount" class="form-control form-control-sm" placeholder="Price" name = "color_price" required @input="showPriceError = false">
              </div>
              <small v-if="showPriceError" class="text-danger text-xxs">Enter a valid price.</small>

              <div class="form-group mt-2">
                <div class="d-flex align-items-center">
                  <label class="size-label mr-2 text-sm">Size:</label>
                  <select
                    v-model="selectedSize"
                    @change="onSizeChange"
                    class="form-select form-select-sm size-dropdown"
                    required
                  >
                    <option v-for="size in sizes" :key="size.id" :value="size.id">
                      {{ size.size }}
                    </option>
                  </select>
                </div>
              </div>
              <small v-if="showSizeError" class="text-danger text-xxs">Please select a size.</small>

              <div class="d-flex justify-content-end mt-4">
                <button v-if="productUploaded"  type="button" class="action-btn" @click="uploadImage">Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Withdraw Modal -->
<div v-if="showWithdrawModal" class="modal-overlay d-flex justify-content-center align-items-center">
  <div class="bg-white p-4 rounded shadow-lg position-relative w-100" style="max-width: 400px;">
    <button class="btn-close position-absolute top-0 end-0 m-2" @click="closeModal"></button>
    <h3 class="fw-bold text-center">Withdraw Funds</h3>
    <div class="account-box d-flex justify-content-between align-items-center bg-light p-3 rounded mt-3">
      <div class="account-info d-flex align-items-flex-start flex-column">
        <span class="fw-bold d-block">{{ view_form.account_holder }}</span>
        <small class="text-start text-muted d-block">
          ****{{  view_form.masked_account_number }} 
          ({{ view_form.account_type_name }})
        </small>
      </div>
      <button class="btn btn-light border" @click="openEditBankingModal">
        <i class="fas fa-edit"></i>
      </button>
    </div>
    <div class="fw-bold text-start mt-3">
      <label class="text-muted">Available Balance</label>
      <p class="fs-5 fw-bold ">R {{ total_balance}}</p>
    </div>
    <label class="mt-3 d-block text-start text-muted">Amount to Withdraw</label>
    <div class="input-group mt-1">
      <span class="input-group-text">R</span>
      <input type="number" v-model="withdrawAmount" class="form-control" placeholder="0.00" @input="showWithdrawError = false" />
    </div>
    <small v-if="showWithdrawError" class="text-danger text-xxs">Please enter an amount within your available balance</small>
    <button class="btn btn-dark w-100 mt-3 py-2" @click="processWithdraw" :disabled="!view_form.account_holder"
    >Withdraw Money</button>
  </div>
</div>

<!-- Edit Banking Details Modal -->
<div v-if="showEditBankingModal" class="modal-overlay">
  <input type="hidden" v-model="view_form.id">

  <div class="modal-withdraw">
    <button class="btn-close position-absolute top-0 end-0 m-2" @click="closeEditBankingModal">
    </button>
    <h5 class="fw-bold">Edit Banking Details</h5>
      <div class="form-group">
        <label>Account Holder</label>
        <input type="text" v-model="view_form.account_holder" class="form-control">
      </div>
      <div class="form-group">
        <label>Bank Name</label>
        <select
        v-model.number="view_form.bank_id"
        @change="view_form.branch_code = banks.find(b => b.id === view_form.bank_id)?.branch_code || ''"
  
        class="form-control"
        required
      >
        <option value="" disabled>Select Bank</option>
        <option 
          v-for="bank in banks" 
          :key="bank.id" 
          :value="bank.id"
        >
          {{ bank.name }}
        </option>
      </select>
      </div>
      <div class="form-group">
        <label>Account Number</label>
        <input type="text" v-model="view_form.account_number" class="form-control">
      </div>
      <div class="form-group">
        <label>Account Type</label>
        <select
        class="form-control"
        v-model="view_form.account_type_id"
        required
      >
        <option value="" disabled>Select Account Type</option>
        <option 
          v-for="account_type in account_types" 
          :key="account_type.id" 
          :value="account_type.id"
        >
          {{ account_type.account_type }}
        </option>
      </select>
      </div>
      <div class="form-group">
        <label>Branch Code</label>
        <input type="text" v-model="view_form.branch_code" class="form-control" disabled>
      </div>
      <button type="submit" class="action-btn" @click="saveOrUpdateBankingDetails">
        {{ view_form.id ? 'Update' : 'Save' }}
      </button>
    </div>
</div>
