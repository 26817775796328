import $ from 'jquery';
import Swal from 'sweetalert2';

export default {
  name: 'RegisterView',
  data() {
    return {
      termsAccepted: false,
      selectedRole: 'influencer',
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        phone_number: '',
        brand_name: '',
        termsChecked: false,
        role: ''
      },
      errorMessage: '',
      successMessage: '',
      emailErrorMessage: false,
      passwordType: 'password',
    confirmPasswordType: 'password',
    showPhoneNumberError: false,
    showConfirmPasswordError: false,
    showPasswordError: false,
    emailExistsError: false,
   
    


    };
  },
  mounted() {
    const names_regex = /^[a-zA-Z]+$/;

    // Validate first and last name to only allow letters
    $('#first_name, #last_name').on('keydown', function (event) {
      const keyPressed = String.fromCharCode(event.which || event.keyCode);

      if (
        event.keyCode === 8 ||
        event.keyCode === 46 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 9 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey
      ) {
        return;
      }
      if (!names_regex.test(keyPressed)) {
        event.preventDefault();
      }
    });

    $('#password').on('input', this.updatePasswordRequirements);
  },
  created() {
    const selectedRole = localStorage.getItem('selectedRole');
    if (selectedRole) {
      this.selectedRole = selectedRole;
      this.form.role = selectedRole;
    }
  },
  methods: {
    validateForm() {
      const password_pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#()^><])[A-Za-z\d@$!%*?&#()^><]{8,}$/;
      const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phone_pattern = /^(?:\+27|0)[6-8][0-9]{8}$/;
    
      if (!phone_pattern.test(this.form.phone_number)) {
        this.showPhoneNumberError = true;
        return false;
      } else {
        this.showPhoneNumberError = false;
      }
    
      if (!email_pattern.test(this.form.email)) {
        this.emailErrorMessage = true;  
        return false;
      } else {
        this.emailErrorMessage = false;
      }
      
    
      if (!password_pattern.test(this.form.password)) {
        this.showPasswordError = true;
        return false;
      } else {
        this.showPasswordError = false;
      }
    
      if (this.form.password !== this.form.confirm_password) {
        this.showConfirmPasswordError = true;
        return false;
      } else {
        this.showConfirmPasswordError = false;
      }
    
      // Clear general error messages if validations pass
      this.errorMessage = '';
      return true;
    },
    
    updatePasswordRequirements() {
      const password = $('#password').val();
      const meets_length_requirement = password.length >= 8;
      const meets_uppercase_requirement = /[A-Z]/.test(password);
      const meets_lowercase_requirement = /[a-z]/.test(password);
      const meets_digit_requirement = /\d/.test(password);
      const meets_special_char_requirement = /[@$!%*?&#()^><]/.test(password);

      $('#length-requirement').toggleClass('text-success', meets_length_requirement);
      $('#uppercase-requirement').toggleClass('text-success', meets_uppercase_requirement);
      $('#lowercase-requirement').toggleClass('text-success', meets_lowercase_requirement);
      $('#digit-requirement').toggleClass('text-success', meets_digit_requirement);
      $('#specialchar-requirement').toggleClass('text-success', meets_special_char_requirement);
    },
    
    async onSubmit() {
      if (!this.termsChecked) {
        this.errorMessage = "You must accept the Terms of Service to register";
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'Info',
          confirmButtonColor: '#000',
          text: this.errorMessage,
        });
        return;
      }
    
      if (!this.validateForm()) {
        return;
      }
    
      Swal.fire({
        title: 'Registering...',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
      });
    
      try {
        const REGISTER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/register/`;
        const response = await $.ajax({
          url: REGISTER_URL,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(this.form),
          dataType: 'json'
        });
    
        const responseData = typeof response === 'string' ? JSON.parse(response) : response;
    
        if (responseData.status === 'success') {
          Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            title: 'Successfully registered',
            text: 'You profile created successfully!',
            confirmButtonColor: '#000',
          }).then(() => {
            window.location.href = '/login';
          });
        } else {
          this.errorMessage = responseData.message;
          this.successMessage = '';
        }
      } catch (error) {
        if (error.status === 400 && error.responseJSON && error.responseJSON.message) {
          const errorMessage = error.responseJSON.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMessage,
          });
    
          if (errorMessage.includes('email already exists')) {
            this.emailExistsError = true;  // Show "email exists" message
            this.emailErrorMessage = false; // Clear invalid format error
            this.errorMessage = ''; // Clear general error message
          } else {
            this.errorMessage = errorMessage;
            this.emailExistsError = false;
          }
        } else {
          this.errorMessage = 'Network error. Please try again.';
        }
      }
    },
    
    togglePassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPassword() {
      this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
    }
  }
};
