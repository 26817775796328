/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue';
import Swal from 'sweetalert2';
import EventBus from '@/eventBus.js';


export default {
  name: 'AdminViewUsersView',
  components: {
    Admin_sidebar,
    Admin_navbar
  },

  data: () => ({
    users: [],
    showAddUserModal: false,
    showEditUserModal: false,
    loggedInUserId:'',
    loading: false,
    form: {
      id:'',
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    },
    view_form: {
      id:'',
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    },

    roles: [],
    admins: [],
    influencers: [],
    brands: [],
    customers: [],
    activeTab: 'All',
  }),

  methods: {
    async fetchUsers() { 
      const FETCH_ALL_USERS = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/admin_get_all_users_api/`;
      try {
        const token = localStorage.getItem('token'); 
        this.loading = true;
        
        const response = await fetch(FETCH_ALL_USERS, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
    
        // Fix: Assign the response correctly
        this.users = data.data; // Since `data.data` is an array of users
        this.admins = this.users.filter(user => user.role === 'ADMIN'); 
        this.influencers = this.users.filter(user => user.role === 'INFLUENCER'); 
    
        this.$nextTick(() => {
          this.initializeDataTable();
          this.loading = false;
        });
    
        await new Promise(resolve => setTimeout(resolve, 5000)); 
    
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    

    async addUser() {
      Swal.fire({
        title: 'Adding User...',
        text: 'Please wait...',
        icon: 'info',
        iconColor:'#f99e1B',
        showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      }); 
      const ADD_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/add_users/`;
      try {
        const token = localStorage.getItem('token');

        const response = await fetch(ADD_USER_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(this.form)
        });
            
        if (!response.ok) {
            if (response.status === 400) {
                const data = await response.json();
                if (data.message === 'User with this email already exists.') {
                  Swal.fire({
                    icon: 'error',
                    title: 'Email Already Exists',
                    text: 'The email address is already in use. Please try a different email address.',
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while adding the user.',
                  });
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while adding the user.',
              });
            }
            return;
          }
          const data = await response.json();
          this.users.push(data); // Add the new user to the users array
          this.showAddUserModal = false; // Close the modal
          this.form = { first_name: '', last_name: '', email: '', role: '' };
          this.$nextTick(() => {
            this.initializeDataTable();
          });
      
          // Show success alert
          Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            title: 'User Added',
            text: 'The user has been added successfully!',
            confirmButtonColor: '#000', // Change the color to black
            showConfirmButton:true
        }).then(()=>{
          window.location.reload();
          });
        } catch (error) {
          console.error('Error adding user:', error);
        }
      },
      async fetchRoles() {
        const ROLES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_roles_api/`;
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(ROLES_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            const data = await response.json();
            this.roles = data;  // Store fetched roles in the roles array
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    },
    
    async editUser() {
      const EDIT_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_user_api/${this.view_form.id}/`;
      const token = localStorage.getItem('token');
           
      try {
        const response = await fetch(EDIT_USER_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(this.view_form)
        });
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
    
        const index = this.users.findIndex(user => user.id === this.view_form.id);
        if (index !== -1) {
          this.users.splice(index, 1, this.view_form);
        }
    
        const updatedUser = this.form;
        this.users = this.users.map(user => user.id === updatedUser.id ? updatedUser : user);
        this.admins = this.users.filter(user => user.role.name === 'ADMIN');
        this.influencers = this.users.filter(user => user.role.name === 'INFLUENCER');

        Swal.fire({
          icon: 'success',
          iconColor: '#F99E1B',
          title: 'User Updated',
          text: 'The user has been updated successfully!',
          confirmButtonText:'OK',
          confirmButtonColor: '#000',
          showConfirmButton:true
        }).then(()=>{
          window.location.reload();
        });
      } catch (error) {
        console.error('Error updating user:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error Updating User',
          text: error.message,
        });
      }
    },
  
    
    openEditUserModal(user) {

  this.view_form = {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    role: user.role || ''
  };
      this.showEditUserModal = true;
    },
      async deactivateUser(user) {
        const action = user.is_active ? 'deactivated' : 'activated';

        Swal.fire({
          title: 'Confirm Deactivation',
          text: `Are you sure you want user ${user.first_name} ${user.last_name} to be ${action} ?`,
          icon: 'warning',
          iconColor:'#F99E1B',
          showCancelButton: true,
          confirmButtonText: 'Yes, deactivate',
          cancelButtonText: 'No, cancel',
          confirmButtonColor: '#000'

        }).then((result) => {
          if (result.isConfirmed) {
            if (this.loggedInUserId && user.id === this.loggedInUserId) {
              Swal.fire({
                title: 'Error',
                text: 'You cannot deactivate yourself.',
                icon: 'error',
                iconColor:'#F99E1B',
                confirmButtonText: 'OK',
                confirmButtonColor: '#000'
              });
            } else {
              this.deactivateUserConfirm(user);
            }
          }
        });
      },
      
      deactivateUserConfirm(user) {
        const token = localStorage.getItem('token');
        const CHANGE_USER_STATUS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/change_user_status_api/`;
        const newStatus = user.is_active ? 'f' : 't';
        const action = user.is_active ? 'deactivated' : 'activated';
      
        $.ajax({
          url: CHANGE_USER_STATUS_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            user_id: user.id,
            is_active: newStatus
          }),
          dataType: 'json',
          success: () => {
            if ($.fn.DataTable.isDataTable('#content_table')) {
              $('#content_table').DataTable().destroy();
            }
            this.fetchUsers();
            this.initializeDataTable();
            Swal.fire({
              title: 'Success',
              text: `User ${user.first_name} ${user.last_name} ${action} successfully.`,
              icon: 'success',
              iconColor:'#F99E1B',
              confirmButtonText: 'OK',
              confirmButtonColor: '#000',
              showConfirmButton:true,
        }).then(()=>{
          window.location.reload();
        });
          },
          error: (error) => {
            console.error('Error:', error);
            let errorMessage;
            if (error.responseJSON) {
              errorMessage = error.responseJSON.message;
            } else if (error.message) {
              errorMessage = error.message;
            } else {
              errorMessage = 'An unexpected error occurred';
            }
            Swal.fire({
              title: 'Error',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#000'
            });
          }
        });
      },

    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#content_table')) {
          $('#content_table').DataTable().destroy();
        }
        this.dataTable = $('#content_table').DataTable({
          pageLength: 10,
          lengthMenu: [10, 25, 50, 100],
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
        });
      });
    },
  },
  
    mounted() {
      this.fetchUsers();
      const self = this;
      EventBus.on('userLoggedIn', (userId) => {
        self.loggedInUserId = userId;
      });
      this.fetchRoles();
},
computed: {

    filteredUsers() {
      switch (this.activeTab) {
        case 'Admins':
          return this.users.filter(user => user.role === 'ADMIN');
        case 'Influencers':
          return this.users.filter(user => user.role === 'INFLUENCER');
        default:
          return this.users;
      }
    }
  }
}