<!DOCTYPE html>
<html lang="">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <link rel="icon" href="<%= BASE_URL %>favicon.ico">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap">
    <title>checkout</title>
  </head>
  <body>
<div class="content">
    <checkout_navbar />

    <div class="col-md-8 mt-3">
        <div class="d-flex mb-4">
          <h3 class="fw-bolder text-black">Select pick up point</h3>
        </div>
      </div>

    <div class="row mt-4">
        <div class="col-md-8">
            <div v-if="selectedTab === 'DELIVERY'">
                <div v-if="!showForm && addresses.length === 0" class="d-flex flex-column align-items-center justify-content-center mt-5">
                <img src="@/assets/images/travel-map-pin-sign-location-icon-vector.jpg" alt="Location" class="location-image" />
                <router-link to="/delivery">
                    <button class="create-btn fw-bolder mb-5" @click.prevent="showForm = true">Add Delivery Address</button>
                </router-link>
            </div>

            <!-- Existing Addresses -->
            <div v-else-if="!showForm">
                <div>
                    <a href="#" class="d-flex text-black justify-content-end fw-bolder text-muted mb-2" @click.prevent="showForm = !showForm">
                        <u>Add Delivery Address</u>
                    </a>
                </div>
                <div v-for="address in addresses" :key="address.id" class="mb-4">
                    <div class="card">
                        <div class="cart-cont">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="address-type fw-bolder">{{ address.address_type__type_name }}</span>
                            <div>
                                <button class="btn-sm create-btn fw-bold" @click="deleteAddress(address.id)">Delete</button>
                                <router-link :to="`/delivery?address_id=${address.id}`">
                                    <button class="btn-sm create-btn fw-bold">Edit</button>
                                </router-link>
                            </div>
                        </div>
                        <!-- Address Details -->
                        <label class="select-address-label d-flex align-items-start">
                            <div class="d-flex align-items-start mt-3">
                                <input type="radio" name="selectedAddress" class="select-address" v-model="selectedAddress" :value="address.id" />
                              <div class="text-start ms-3">
                                <p class="mb-2 fw-bolder">{{ address.complex_building }}</p>
                                <p class="mb-2 fw-bolder">{{ address.street_address }}</p>
                                <p class="mb-2 fw-bolder">{{ address.suburb }}</p>
                                <p class="mb-2 fw-bolder">{{ address.city_town }}</p>
                                <p class="mb-2 fw-bolder">{{ address.postal_code }}</p>
                                <p class="mb-2 text-muted">{{ address.recipient_name }} - {{ address.recipient_mobile }}</p>
                              </div>
                            </div>
                        </label>
                        </div>
                    </div>
                </div>
            </div>
            

            <!-- Address Form -->
            <div v-else>
                <div class="col-md-6 offset-md-3 mt-2">
                    <div>
                        <a href="#" class="d-flex text-black justify-content-end fw-bolder text-muted" @click.prevent="showForm = !showForm">
                            <u>Back</u>
                        </a>
                    </div>
                    <div class="mb-3 fw-bold">
                        <label v-for="address_type in address_types" :key="address_type.id" class="me-3">
                            <input 
                                type="radio" 
                                name="address_type" 
                                v-model="form.address_type" 
                                :value="address_type.id"
                            /> 
                            {{ address_type.type_name }}
                        </label>
                    </div>

                    <!-- Address Form Content -->
                    <form @submit.prevent="onSubmit()">
                        <!-- Business Name (if applicable) -->
                        <div v-if="isBusinessType" class="mb-4">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="business_name"
                                v-model="form.business_name"
                                placeholder="Business Name"
                                required
                            />
                            <div v-if="errors.business_name" class="text-danger">{{ errors.business_name }}</div>
                        </div>

                        <!-- Recipient Name -->
                        <div class="mb-4">
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="recipient_name"
                                v-model="form.recipient_name"
                                placeholder="Recipient Name"
                                required
                            />
                            <div v-if="errors.recipient_name" class="text-danger">{{ errors.recipient_name }}</div>
                        </div>
                    <div class="mb-4">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="recipient_mobile"
                            v-model="form.recipient_mobile"
                            placeholder="Recipient Mobile Number"
                            required
                        />
                        <div v-if="errors.recipient_mobile" class="text-danger">{{ errors.recipient_mobile }}</div>
                    </div>
                    <div class="autocomplete-container position-relative mb-4">
                        <input 
                          type="text" 
                          class="form-control form-control-sm" 
                          id="street_address" 
                          v-model="form.street_address" 
                          placeholder="Street Address" 
                          @input="onAddressInput" 
                          @focus="onAddressInput"
                          required
                        />
                        <div v-if="errors.street_address" class="text-danger">{{ errors.street_address }}</div>
                        
                        <ul v-if="form.street_address && suggestions.length > 2" class="dropdown-menu show">
                            <li v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectAddress(suggestion)">
                            {{ suggestion.description }}
                          </li>
                        </ul>
                      </div>
                    <div class="mb-4">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="complex_building"
                            v-model="form.complex_building"
                            placeholder="Complex/Building"
                        />
                    </div>
                    <div class="mb-4">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="suburb"
                            v-model="form.suburb"
                            placeholder="Suburb"
                            required
                        />
                        <div v-if="errors.suburb" class="text-danger">{{ errors.suburb }}</div>
                    </div>
                    <div class="mb-4">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="city_town"
                            v-model="form.city_town"
                            placeholder="City/Town"
                            required
                        />
                        <div v-if="errors.city_town" class="text-danger">{{ errors.city_town }}</div>
                    </div>
                    <div class="mb-4">
                        <select id="province" class="form-control form-control-sm" v-model="form.province" required>
                            <option value="" disabled>Select a province</option>
                            <option v-for="province in provinces" :key="province.id" :value="province.id">
                                {{ province.province_name }}
                            </option>
                        </select>
                        <div v-if="errors.province" class="text-danger">{{ errors.province }}</div>
                    </div>
                    <div class="mb-4">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="postal_code"
                            v-model="form.postal_code"
                            placeholder="Postal Code"
                            required
                        />
                        <div v-if="errors.postal_code" class="text-danger">{{ errors.postal_code }}</div>
                    </div>
                    <button type="submit" class="submit-btn mb-4">Save</button>
                </form>
            </div>
            </div>
        </div>

        <div v-if="selectedTab === 'COLLECT'">
            <!-- Hide iframe when point is selected -->
            <iframe
              id="pargo-iframe"
              :src="pargoIframeSrc"
              frameborder="0"
              width="100%"
              height="550"
              allow="geolocation"
              v-show="!isMobile || !selectedPoint"
              ></iframe>
          
            <!-- Display selected point's details -->
            <div v-if="selectedPoint" class="fw-bold justify-content-between selected-point-details">
              <p class="text-start fw-bolder text-black fs-4">
                Selected Point : {{ selectedPoint.storeName }}
              </p>
              <button class="action-btn" @click="changePoint">Change Point</button>
            </div>
          </div>
        </div>


        <!-- Cart Summary Section -->
        <div class="col-md-4">
            <div class="cart-cont">
              <h5 class="fw-bolder fs-5 text-uppercase text-black">Cart Summary</h5>
              <div class="cart-item-container">
              <div v-for="item in cartItems" :key="item.id" class="cart-item">
                <img :src="item.image" alt="Product image" class="cart-item-image" />
                <div class="cart-item-details d-flex text-black">
                  <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
                  
                  <p class="product-brand mb-1"> {{ item.brand }}</p>
                  <p class="mb-0">size: {{ item.size }}</p>
                  <p class="mb-0">color: {{ item.color }}</p>
                  <p class="mb-2">Qty: {{ item.quantity }}</p> 
                  <p class="fw-bold">Price: R {{ (item.price * item.quantity) }}</p>
                </div>
              </div>
              </div>
              <div class="d-flex justify-content-between fw-bolder mt-3">
                <span class="text-black">Sub Total</span>
                <span class="text-black">R {{ cartTotal }}</span>
              </div>
              <div class="d-flex justify-content-between fw-bolder mt-3">
                <span class="text-black">Delivery Fee</span>
                <span class="text-black" v-if="selectedPoint">R 65</span>
                <span class="text-black" v-else>R 0</span>
              </div>
              <div class="d-flex justify-content-between fw-bolder mt-3 fs-5">
                <span class="text-black">Total</span>
                <span class="text-black">R {{ totalWithServiceFeeAndDelivery  }}</span>
              </div>
              <div class="d-flex justify-content-center">
                <button class="add-to-cart fw-bolder mb-4 mt-2" @click="proceedToCheckout">Checkout</button>
              </div>
            </div>
    </div>
    </div>
</div>
</body>
</html>