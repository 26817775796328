/* global google */
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import checkout_navbar from '@/components/Influencer/checkout_navbar/CheckoutNavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';
import EventBus from '@/eventBus.js';
import { get_user_details, get_token } from '@/router/index.js';

export default {
    components: {
        Influencer_navbar,
        checkout_navbar
 
    },
    data: () => ({
      addresses: [],
      addressTypes: [],
      cartQuantity: 0,
      cartTotal: 0,
      totalFee: 0,
      cartItems: JSON.parse(localStorage.getItem('cart')) || [],
      showForm: false,
      selectedAddress: null,
      form: {
          recipient_name: '',
          recipient_mobile: '',
          business_name: '',
          street_address: '',
          complex_building: '',
          suburb: '',
          city_town: '',
          province: '',
          postal_code: '',
      },
      suggestions: [],
      provinces: [],
      address_types: [],
      GOOGLE_API: '',
      errors: {},
      selectedTab: 'COLLECT', // Default to the delivery tab
      selectedPoint: null,
      token_exists: null,
      user_details: null,
      buyerPhoneNumber: '',
      showIframe: false,
      pargoIframeSrc: process.env.VUE_APP_PARGO_PICKUP_IFRAME_SRC,
      isMobile: false,
 
     }),
 
     computed: {
      isBusinessType() {
        const selected_type = this.address_types.find(type => type.id === this.form.address_type);
        return selected_type && selected_type.type_name === 'Business';
      },
     
      totalWithServiceFeeAndDelivery() {
        return (this.cartTotal + (this.selectedPoint ? 65 : 0)).toFixed(2);
      },
    },  
 
      mounted() {

        this.token_exists = get_token();
        this.user_details = get_user_details();
    
        if (!this.token_exists) {
          localStorage.removeItem('token'); // Clear the token
          this.$router.push('/login'); // Redirect to home page
        } else {
        
       // Listen for the event
       const influencerId = localStorage.getItem('sellerId');
      if (influencerId) {
        this.fetchCartDetails(influencerId);
      }
 
      window.addEventListener('message', (event) => {
        if (event.data && event.data.addressSms) {
          this.selectedPoint = event.data;
          // Now you can use the selectedPoint object in your JavaScript code
        }
      });
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);

    }
     
      },
 
      methods: {

        changePoint() {
          this.showIframe = true;
          this.selectedPoint = null;


        },

        checkScreenSize() {
          if (window.innerWidth < 768) {
            this.isMobile = true;
          } else {
            this.isMobile = false;
          }
        },
 
        selectTab(tab) {
          this.selectedTab = tab;
        },
       
        async fetchApiKey() {
          const API_KEY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/google_maps_key/`;
          try {
            const response = await fetch(API_KEY_URL);
            if (!response.ok) throw new Error('Failed to fetch API key');
            const data = await response.json();
            this.GOOGLE_API = data.api_key;
            return Promise.resolve();  // Resolve the promise once the API key is fetched    
          } catch (error) {
            console.error('Error fetching API key:', error);
          }
        },
        async fetchAddressTypes() {
          const ADDRESS_TYPE_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/address_types/`;
          try {
            const response = await fetch(ADDRESS_TYPE_API);
            if (!response.ok){
              throw new Error('Failed to fetch address types');
            }
            this.address_types = await response.json();
           
            const residential_type = this.address_types.find(type => type.type_name === 'Residential');
            if (residential_type) {
              this.form.address_type = residential_type.id;
            }
          } catch (error) {
            console.error('Error fetching address types:', error);
          }
        },
       
 
        async deleteAddress(address_id) {
          const token = localStorage.getItem('token');
          const DELETE_ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/delete_address/${address_id}/`;
       
          try {
            const response = await fetch(DELETE_ADDRESS_API, {
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${token}`
              }
            });
       
            if (response.ok) {
              Swal.fire(
                'Deleted!',
                'The address has been deleted.',
                'success'
              ).then(() => {
                window.location.reload();  // Reload the page after deletion
              });
            }
          } catch (error) {
            console.error(error);
          }
        },
 
        async fetchProvinces() {
          const PROVINCES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/provinces/`;
          try {
            const response = await fetch(PROVINCES_API);
            if (!response.ok)
              throw new Error('Failed to fetch provinces');
            this.provinces = await response.json();
          } catch (error) {
            console.error('Error fetching provinces:', error);
          }
        },
 
        async fetchAddresses() {
            const token = localStorage.getItem('token');
            const GET_ADDRESSES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_addresses/`;
 
            try {
              const response = await fetch(GET_ADDRESSES_API, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${token}`
                }
              });
     
              if (!response.ok) {
                throw new Error('Failed to fetch addresses.');
              }
     
              const data = await response.json();
              if (data.status === 'success') {
                this.addresses = data.addresses;
              } else {
                this.error = 'No addresses found for this user.';
              }
            } catch (error) {
              this.error = 'An error occurred while fetching addresses.';
            }
        },
 
        async fetchCartDetails(influencerId) {
          const token = localStorage.getItem('token');
        
          // User is logged in, fetch the cart from the server
          const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_seller_cart/${influencerId}/`;          
          try {
            const response = await $.ajax({
              url: CART_API_URL,
              method: 'GET',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
              },
            });
        
            if (response) {
              if (response.cart_products.length > 0) {
                this.cartItems = response.cart_products;
                this.cartQuantity = response.total_quantity;
                this.cartTotal = parseFloat(response.total_price);
                this.totalFee = parseFloat(this.cartTotal) + (this.selectedPoint ? 65 : 0);
              } else {
                this.cartProducts = [];
              }
            }
            EventBus.emit('cart-updated', this.cartQuantity);

        
          } catch (error) {
            console.error('Failed to fetch cart from the server:', error);
          }
        },
       
 
     
    validateForm() {
      this.errors = {};
 
      if (!this.form.recipient_name || !/^[a-zA-Z ]+$/.test(this.form.recipient_name)) {
          this.errors.recipient_name = 'name should contain only letters';
      }
 
      if (!this.form.recipient_mobile || !/^\d{10}$/.test(this.form.recipient_mobile)) {
          this.errors.recipient_mobile = 'mobile number should be 10 digits';
      }
 
      if (this.isBusinessType && !this.form.business_name) {
          this.errors.business_name = 'Business name is required for business addresses';
      }
 
      if (!this.form.street_address) {
          this.errors.street_address = 'Street address is required';
      }
 
      if (!this.form.suburb || !/^[a-zA-Z ]+$/.test(this.form.suburb)) {
          this.errors.suburb = 'Suburb should contain only letters';
      }
 
      if (!this.form.city_town || !/^[a-zA-Z ]+$/.test(this.form.city_town)) {
          this.errors.city_town = 'City/Town should contain only letters';
      }
 
      if (!this.form.province) {
          this.errors.province = 'Province is required';
      }
 
      if (!this.form.postal_code || !/^\d{4}$/.test(this.form.postal_code)) {
          this.errors.postal_code = 'Postal code should be 4 digits';
      }
 
      return Object.keys(this.errors).length === 0;
  },
 
        async onSubmit() {
          if (this.validateForm()) {
              const token = localStorage.getItem('token');
              const SAVE_ADDRESS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/save_address/`;
             
              try {
                  const response = await fetch(SAVE_ADDRESS_API, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Token ${token}`
                      },
                      body: JSON.stringify(this.form),
                  });
 
                  if (!response.ok){
                      throw new Error('Failed to save address');
                  }
 
                  Swal.fire({
                      icon: 'success',
                      title: 'Address Saved',
                      text: 'Your delivery address has been successfully saved!',
                      confirmButtonText: 'OK'
                  }).then(() => {
                    this.showForm = false;
                    this.fetchAddresses();
                      // this.$router.push('/shipping_address');
                  });
              } catch (error) {
                  console.error('Error saving address:', error);
              }
          }
      },
 
      initAutocomplete() {
        if (google && google.maps && google.maps.places && google.maps.places.Autocomplete) {
          const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('street_address'),
            { types: ['geocode'] }
          );
          autocomplete.addListener('place_changed', this.onPlaceSelected);
        } else {
          console.error('Google Maps Places library not loaded');
        }
      },
 
      onPlaceSelected() {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          console.error('No details available for the input: ' + place.name);
          return;
        }      
        // Populate the address fields with the selected place details
        this.form.street_address = place.formatted_address;
        this.form.city_town = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';
        this.form.suburb = place.address_components.find(component => component.types.includes('sublocality_level_1'))?.long_name || '';
        this.form.postal_code = place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
        this.form.province = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '';
      },
 
      onAddressInput() {
          if (this.form.street_address.length > 2) {
            if (window.google && window.google.maps) {
              const service = new google.maps.places.AutocompleteService();
              service.getPlacePredictions(
                { input: this.form.street_address,
                  componentRestrictions: { country: 'ZA' },
                 },
                (predictions, status) => {
                  if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.suggestions = predictions;
                  } else {
                    console.error('Error fetching address suggestions:', status);
                  }
                }
              );
            }
          }
        },
     
        selectAddress(suggestion) {
          this.form.street_address = suggestion.description;
          this.suggestions = [];
     
          // Use Google Geocoder to get detailed information
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: suggestion.description }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                  const result = results[0];
                  // Get the address components
                  const components = result.address_components;
     
                  let streetAddress = '';
                  for (let i = 0; i < components.length; i++) {
                      const component = components[i];
                      if (component.types.includes('street_number')) {
                          streetAddress = component.long_name; // Set street number
                      }
                      if (component.types.includes('route')) {
                          streetAddress += ' ' + component.long_name;
                      }
                  }
                  this.form.street_address = streetAddress;
     
                  // Fill out the other fields based on the address components
                  this.form.suburb = this.getComponent(components, 'sublocality_level_1');
                  this.form.city_town = this.getComponent(components, 'locality');
                  this.form.province = this.getProvinceFromComponent(components);
                  this.form.postal_code = this.getComponent(components, 'postal_code');
              }
          });
      },
 
      getProvinceFromComponent(components) {
          const provinceComponent = components.find(comp => comp.types.includes('administrative_area_level_1'));
          if (provinceComponent) {
              const provinceName = provinceComponent.long_name;
              // Now, find the corresponding province in your provinces array
              const province = this.provinces.find(p => p.province_name === provinceName);
              return province ? province.id : '';
          }
          return '';
      },
     
      // Utility function to extract components based on the type
      getComponent(components, type) {
          const component = components.find(comp => comp.types.includes(type));
          return component ? component.long_name : '';
      },
     
      loadGoogleMapsAPI(apiKey) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      },
      updateSelectedAddress(addressId) {
        const selectedAddress = this.addresses.find(address => address.id === addressId);
        this.selectedAddress = selectedAddress;
      },

      async fetchBuyerPhoneNumber() {
        
        const token = localStorage.getItem('token');
        const GET_USER_INFO = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`;
        try {
          const response = await fetch(GET_USER_INFO, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch buyer phone number');
          }
      
          const data = await response.json();
          if (!data.phone_number) {
            Swal.fire({
              title: 'Error!',
              text: 'Please add a phone number to your profile.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          } else {
            this.buyerPhoneNumber = data.phone_number;
          }
        } catch (error) {
          console.error('Error fetching buyer phone number:', error);
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred while fetching buyer phone number.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      },
 
      async proceedToCheckout() {
        await this.fetchBuyerPhoneNumber();

        if (!this.buyerPhoneNumber) {
          // Don't proceed with checkout if buyer's phone number doesn't exist
          return;
        }
    
        
        if (this.cartItems.length === 0 ) {
          // Handle empty cart error
          Swal.fire({
            title: 'Error!',
            text: 'Your cart is empty. Please add some items to your cart.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        } else if (!this.selectedPoint) {

          Swal.fire({
            title: 'Error!',
            text: 'Please select a pickup point.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
          
        } else {

          const sellerPhoneNumber = this.cartItems[0].phone_number;
          if (!sellerPhoneNumber) {
            Swal.fire({
              title: 'Error!',
              text: 'The seller\'s phone number does not exist. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
            return;
          }
     
          // Show Swal loading animation
          Swal.fire({
            title: 'Processing...',
            text: 'Please wait while we process your payment.',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
     
          // Get seller ID from cart items
          const sellerId = this.cartItems[0].seller_id;
     
          // Get seller's drop-off point
          const token = localStorage.getItem('token');
          const SELLER_DROP_OFF_POINT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/seller_drop_off_point/${sellerId}/`;
     
          fetch(SELLER_DROP_OFF_POINT_API, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          })
          .then(response => response.json())
          .then(data => {
            // Proceed to checkout logic here
            // Calculate service fee and delivery fee
            const deliveryFee = 65.00;      
            this.totalFee = this.cartTotal + deliveryFee;
     
            const CHECKOUT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/initiate_payment_api/`;
     
            // Format cart items to match backend requirements
            const formattedCartItems = this.cartItems.map(item => ({
              product_id: item.product_id,
              seller: item.brand, // Add brand name
              price: item.price,
              color: item.color,
              quantity: item.quantity,
              product_name: item.product_name

            }));
          
            const checkoutData = {
              drop_off_point: data.pargo_point_code, // Add seller's drop-off point
              pickup_point_code: this.selectedPoint.pargoPointCode,
              pickupDetails: this.selectedPoint,
              cart_items: formattedCartItems,
              seller_id: sellerId ,
              total_price: this.totalFee,
              delivery_fee: deliveryFee,
              buyer_phone_number: this.buyerPhoneNumber,
              seller_phone_number: sellerPhoneNumber,
            };
            
            fetch(CHECKOUT_API, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
              },
              body: JSON.stringify(checkoutData),
            })
            .then(response => response.json())
            .then(data => {
              if (data.payment_url) {
                // Redirect the user to the payment URL
                window.location.href = data.payment_url;
              } else {
                Swal.fire({
                  title: 'Error!',
                  text: 'Failed to initiate payment. Please try again.',
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
            })
            .catch(error => {
              console.error('Error during checkout:', error);
              Swal.fire({
                title: 'Error!',
                text: 'An error occurred during checkout. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            });
          })
          .catch(error => {
              console.error('Error getting seller drop-off point:', error);
              Swal.fire({
                title: 'Error!',
                text: 'Failed to get seller drop-off point. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            });
          }
        },
          },
 
 
      }