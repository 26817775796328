<div class="app h-100-vh">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <img 
          :src="require('@/assets/images/new_logo_organge_black.png')" 
          alt="Kyosk Logo" 
          class="kyosk-logo mb-5"
        >

        <form @submit.prevent="handleResetPassword" class="login-form">
          
          <h5>Reset Your Password</h5>
          <p>Please enter your new password below. Your password must be at least 6 characters long.</p>

          <div class="form-group mb-3 position-relative">
            <input 
              :type="passwordType" 
              v-model="password" 
              placeholder="New Password" 
              id="password" 
              class="form-control pr-5"
              required
            >
            <i 
              :class="passwordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" 
              @click="togglePassword"
              class="toggle-password-icon"
            ></i>
          </div>

          <div class="form-group mb-3 position-relative">
            <input 
              :type="confirmPasswordType" 
              v-model="confirmPassword" 
              placeholder="Confirm Password" 
              id="confirmPassword" 
              class="form-control pr-5"
              required
            >
            <i 
              :class="confirmPasswordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" 
              @click="toggleConfirmPassword"
              class="toggle-password-icon"
            ></i>
          </div>

          <button type="submit" class="register-button" login-button>Reset Password</button>

        </form>
        <div class="row">
          <div class="col text-center mt-3">
            <a href="/login" class="forgot-password-link">Back to Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>