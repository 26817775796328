import { ref, onMounted, watch, nextTick, computed } from 'vue';
import jQuery from 'jquery';
import axios from 'axios';
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import View_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Search_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue';
import Fullscreen_navbar from '@/components/Home/Fullscreen_Navbar/FullscreenNavbarView.vue';
import Swal from 'sweetalert2';


import EventBus from '@/eventBus.js';
import Hls from 'hls.js';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar,
    View_product,
    Filter_product,
    Influencer_navbar,
    Search_product,
    Fullscreen_navbar,
  },
  data(){
    return {
      selected_department_name: '',
      selected_category_name: '',
      FETCH_VIDEOS_URL: '',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
      debounceTimer: null,
      UserLoggedIn: false, // Tracks login status
      currentVideo: null,
      pipVideoIndex: null,
      showSizes: false,
      selectedProduct: null,
      selectedSize: null,
      sizeProductId: null,
      currentPipProducts: [],
      isInPiPMode: false,
      addedToWishlist: false,
      apiError: false,
      videoErrors: {},
      userInteracted: false, // Track whether the user has interacted
      scrollLock: false,     // For throttling full screen navigation
      cartQuantity: 0,       // Example cart property
      cartItems: [],
      cartTotal: 0,
      role: '',
      isRightSidebarOpen: false,
      showLoginAlert: false,
      reelMuteStates: {},
      showShareMenu: null,
      videoUrl: window.location.href,
    };
  },
  created() {
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });
  },
  beforeDestroy() {
    
    // Consolidated cleanup logic
    EventBus.off('cart-updated');
    EventBus.off('roleChanged');

    // Dispose all video players
    Object.values(this.players).forEach((player) => {
      if (player && typeof player.dispose === 'function') {
        player.dispose();
      }
    });
    this.players = {};

    this.originalReels = [];
    this.fullScreenReels = [];

    window.removeEventListener("wheel", this.handleScrollInFullScreen);
    document.removeEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  },
  computed: {
    currentPlayingVideo() {
      if (this.currentPlayingIndex !== null && this.reels.length > 0) {
        return this.reels[this.currentPlayingIndex];
      }
      return null;
    },
  },
  methods: {
    async shareVideo(videoId) {
      const token = localStorage.getItem('token');
      try {
          const SHARE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/share_influencer_video_api/${videoId}/`;
          const response = await fetch(SHARE_URL, {
              method: 'POST',
              headers: {
                  'Authorization': `Token ${token}`,
                  'Content-Type': 'application/json'
              }
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.shareCounts[videoId] = data.share_count; // Set the property directly
          return data.share_count;
      } catch (error) {
          console.error('There was an error sharing the video!', error);
          return null;
      }
  },
    toggleShareMenu(index) {
      if (this.showShareMenu === index) {
          this.showShareMenu = null; // Close the menu if it's already open
      } else {
          this.showShareMenu = index; // Open the menu for the clicked video
      }
  },
  closeMenu() {
      this.showShareMenu = null; // Close the menu
  },

    videoUrl() {
      return this.baseUrl + this.userId;
    },
    copyVideoLinkToClipboard() {
      navigator.clipboard.writeText(this.videoUrl).then(() => {
        Swal.fire({
          title: 'Link Copied!',
          text: 'Video link copied to clipboard!',
          icon: 'success',
          iconColor:'#F99E1B',
          confirmButtonText: 'OK',
          confirmButtonColor:'#000',
      });
  });
    },
    
    handleProductClick(product) {
      this.selectedProduct = product;
      this.sizes = this.selectedProduct.sizes;
      this.showSizes = !this.showSizes;
    },
    checkLoginStatus() {
      this.UserLoggedIn = !!localStorage.getItem('token');
    },
    navigateToBrands() {
      this.$router.push('/view_brands');
    },
    navigateToInfluencers() {
      this.$router.push('/View_Influencer');
    },
    navigateToCollection() {
      this.$router.push('/new_collection');
    },
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },
    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },
    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },
    async fetchCartDetails() {
      const cart = this.getLocalStorage('cart') || [];
      for (const item of cart) {
        const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
        const response = await fetch(GET_IMAGE_URL);
        if (!response.ok) {
          throw new Error('Failed to fetch image URL');
        }
        const imageData = await response.json();
        item.image = imageData;
      }
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    },
    async handleAddToCart(size) {
      this.selectedSize = size.size;
      this.sizeProductId = size.product_id;
    },
    async addToCart(product) {
      this.showSizes = false; // Hide sizes dropdown after adding to cart
      const quantity = 1;
      const productId = this.sizeProductId;
      if (this.isLoggedIn()) {
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity,
            }),
          });
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        const cart = this.getLocalStorage('cart') || [];
        const existingProduct = cart.find(
          (item) => item.product_id === this.sizeProductId && item.size === this.selectedSize
        );
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: product.product_id,
            product_name: product.product.product_name,
            quantity: quantity,
            price: product.price,
            color: product.color,
            size: this.selectedSize,
            brand: product.product.brand__brand_name,
            image: product.product.image_url,
            product_id: productId,
          });
        }
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            this.isRightSidebarOpen = true;
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        });
      }
    },
    async addToWishlist(product_id) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.showLoginAlert = true;
        setTimeout(() => {
          this.showLoginAlert = false;
        }, 5000);
        return;
      }
      const formData = new FormData();
      formData.append('productdetail_ids', product_id);
      try {
        const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
        const response = await fetch(ADD_TO_WISHLIST_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });
        const result = await response.json();
        if (response.ok && response.status === 201) {
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else if (response.status === 200) {
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else {
          alert(result.error || 'Failed to add product to the wishlist.');
        }
      } catch (error) {
        alert('Something went wrong. Please try again.');
      }
    },

    async fetchReelData() {
      // Prevent multiple fetches if we're already loading or have all data
      if (this.isFetching || this.allDataLoaded) return;
    
      try {
        this.apiError = false;
        this.isFetching = true;
    
        // Build the base URL (replace with your actual environment variable or endpoint)
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
    
        // Construct the request URL with pagination params
        const url = `${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`;
    
        // Retrieve token from localStorage (if the user is logged in)
        const token = localStorage.getItem('token');
    
        // Build the headers object. If token is not null, add the Authorization header
        const headers = {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Token ${token}` }),
        };
    
        // Make the fetch request with headers
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        if (data.length === 0) {
          // If no data, mark that we've loaded all reels
          this.allDataLoaded = true;
        } else {
          // Append new reels to our local array
          this.reels.push(...data);
          this.page++;
    
          // Wait until DOM updates, then initialize video players/observers
          this.$nextTick(() => {
            this.initializeVideoPlayers();
            this.initializeVideoObserver();
          });
        }
      } catch (error) {
        console.error('Error fetching reel data:', error);
        this.apiError = true;
      } finally {
        this.isFetching = false;
      }
    },
    

    initializeVideoPlayers() {
      this.reels.forEach((reel, idx) => {
        // find DOM container: <div ref=\"videoPlayer_idx\">
        const reelRef = this.$refs['videoPlayer_' + idx];
        const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
        if (!reelContainer || this.players[idx]) return; // already inited?

        const videoEl = reelContainer.querySelector('video');
        if (!videoEl) return;
 
        videoEl.muted = true;
        videoEl.setAttribute('muted','true');
        videoEl.setAttribute('playsinline', 'true');
        videoEl.autoplay = true;
        videoEl.addEventListener('loadedmetadata', () => {
          // start playing after loadedmetadata
          videoEl.play();
        });

        // attach HLS if .m3u8
        this.initializeHlsOrMp4(videoEl, reel, idx);

        // store reference
        this.players[idx] = videoEl;
      });
    },

    initializeHlsOrMp4(videoEl, reel, idx) {
      const hlsUrl = reel.final_processed_url;
      const mp4Url = reel.video_url;
      console.log(`HLS URL: ${hlsUrl}, MP4 URL: ${mp4Url}`);

      if (hlsUrl && hlsUrl.endsWith('.m3u8')) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(hlsUrl);
          hls.attachMedia(videoEl);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log(`[HLS] manifest loaded for reel ${idx}`);
          });
          hls.on(Hls.Events.ERROR, (evt, data) => {
            console.error("HLS Error:", data);
          });
        } else if (videoEl.canPlayType('application/vnd.apple.mpegurl')) {
          // Safari
          videoEl.src = hlsUrl;
        } else {
          // fallback
          videoEl.src = mp4Url;
        }
      } else {
        // not an .m3u8 link, fallback
        videoEl.src = mp4Url;
      }
    },

    initializeVideoObserver() {
    
      if (!('IntersectionObserver' in window)) {
        console.warn("No IntersectionObserver support");
        return;
      }
    
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const idx = parseInt(entry.target.dataset.index, 10);
    
          if (Number.isNaN(idx)) {
            console.warn('Invalid index detected');
            return;
          }
    
          const videoEl = this.players[idx];
          if (!videoEl) {
            console.warn(`No video element found for index: ${idx}`);
            return;
          }
    
          if (entry.isIntersecting) {
            this.pauseOtherVideos(idx);
    
            videoEl.setAttribute('playsinline', 'true');
            
            videoEl.play().then(() => {
            }).catch(err => {
              console.warn(`Autoplay prevented for video ${idx}:`, err);
            });
    
            this.currentPlayingIndex = idx;
          } else {
            videoEl.pause();
            videoEl.currentTime = 0;
          }
        });
      }, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Video should be 70% visible to play
      });
    
      // Observe each video
      this.reels.forEach((_, idx) => {
        const reelRef = this.$refs['videoPlayer_' + idx];
        const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
        if (!reelContainer) {
          console.warn(`No container found for video index: ${idx}`);
          return;
        }
        const videoEl = reelContainer.querySelector('video');
        if (videoEl) {
          videoEl.dataset.index = idx;
          observer.observe(videoEl);
      
          videoEl.addEventListener('play', () => {
            this.pauseOtherVideos(idx);
          });
      
          videoEl.addEventListener('click', () => {
            this.pauseOtherVideos(idx);
            videoEl.play(); // Ensure the clicked video plays
          });
        }
      });
      
    },
    
    pauseOtherVideos(index) {
      if (!Array.isArray(this.players)) {
        console.error("this.players is not an array!", this.players);
        return;
      }
    
      this.players.forEach((video, i) => {
        if (i !== index && video) {
          video.pause();
        }
      });
    },



    
  
    openFullScreen(video, index) {
      if (!this.reels?.[index]) {
        console.error("Invalid index or reels not loaded");
        return;
      }
    
      const videoId = video.id;
      // Find the index of the video with the matching ID
      index = this.reels.findIndex((reel) => reel.id === videoId);

      // Pause currently playing video
      if (this.currentPlayingIndex !== null && this.players?.[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
      Object.assign(this, {
        originalReels: [...this.reels], // Preserve reels list
        fullScreenReels: [...this.reels], // Copy reels for fullscreen mode
        isFullScreenMode: true,
        fullScreenVideoIndex: index,
        currentPlayingIndex: index,
      });
    

    
      this.$nextTick(() => {
        this.playVideoAtIndex(index);
        this.preloadVideo(index + 1); // Preload next video
      });
    },
    
    preloadVideo(index) {
      if (this.reels?.[index]) {
        let video = this.players?.[index];
        if (video && video.readyState < 4) {
          video.load(); // Preload video
        }
      }
    },
  
    navigateFullScreen(direction) {
      if (!this.isFullScreenMode) return;
      const newIndex = Math.min(
        Math.max(this.fullScreenVideoIndex + direction, 0),
        this.fullScreenReels.length - 1
      );
      if (newIndex === this.fullScreenVideoIndex) return;
      const prevIndex = this.fullScreenVideoIndex;
      this.fullScreenVideoIndex = newIndex;
      this.currentPlayingIndex = newIndex;
      if (this.players[prevIndex]) {
        this.players[prevIndex].pause();
      }
      this.$nextTick(() => {
        this.playVideoAtIndex(newIndex);
      });
    },

    handleScroll(index) {
      const video = document.querySelector(`video[data-index="${index}"]`);
      if (!video) {
        console.warn(`No video found at index ${index}`);
        return;
      }
    
      const rect = video.getBoundingClientRect();
    
      const isVisible =
        rect.top < window.innerHeight * 0.5 && rect.bottom > window.innerHeight * 0.5;
    
      if (isVisible) {
        this.pauseOtherVideos(index);
      } else {
        video.pause();
      }
    },
    
    
    playVideoAtIndex(index) {
      // Check network connectivity before attempting playback
      if (!navigator.onLine) {
        console.warn(`No network connection; video playback is disabled for index ${index}`);
        this.handleVideoError(index);
        return;
      }
    
      // Clear previous error states
      this.videoErrors = {};
    
      // Pause all other videos and reset their time
      this.reels.forEach((_, i) => {
        const videoEl = Array.isArray(this.$refs[`videoPlayer_${i}`])
          ? this.$refs[`videoPlayer_${i}`][0]
          : this.$refs[`videoPlayer_${i}`];
    
        if (videoEl && i !== index) {
          const videoElement = videoEl.querySelector("video");
          if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0;
          }
        }
      });
    
      // Get the video element for the given index
      const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`])
        ? this.$refs[`videoPlayer_${index}`][0]
        : this.$refs[`videoPlayer_${index}`];
    
      if (videoEl) {
        const videoElement = videoEl.querySelector("video");
        if (videoElement) {
          // Set optimized video attributes
          videoElement.setAttribute("playsinline", "true");
          videoElement.setAttribute("autoplay", "true");
          videoElement.setAttribute("preload", "metadata"); // Lazy load videos
    
          // Scroll video into view smoothly
          videoElement.scrollIntoView({ behavior: "instant", block: "center" });
    
          // Auto-play the video
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error("Autoplay prevented:", error);
              this.handleVideoError(index);
            });
          }
    
          videoElement.addEventListener("ended", () => {
            videoElement.currentTime = 0; // Reset video to start
            videoElement.play(); // Play the video again
          });
          
    
          // Detect swipe gestures for navigation
          let watchTime = 0;
          let viewTracked = false; // Prevent multiple requests

          videoElement.addEventListener("timeupdate", () => {
            if (!viewTracked) {
              watchTime = videoElement.currentTime;
              if (watchTime >= 5) {
                // Create a key to store the view date for this video
                const viewKey = `video_view_${this.reels[index].id}`;
                const lastViewDate = localStorage.getItem(viewKey);
                const today = new Date().toDateString();
          
                // Only record the view if it hasn't been recorded today
                if (lastViewDate !== today) {
                  this.recordView(this.reels[index].id); // Call API after 5 seconds
                  localStorage.setItem(viewKey, today);
                }
                viewTracked = true;
              }
            }
          });
          
          
        }
      }
    
      // Remove the error state for this video
      delete this.videoErrors[index];
    },

    recordView(videoId) {
      const RECORD_VIEW_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/record_video_view/${videoId}/`;
      fetch(RECORD_VIEW_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error("Error recording view:", error);
        });
    },
    

    // If a reel fails to load
    handleVideoError(idx) {
      this.videoErrors = { ...this.videoErrors, [idx]: true };
      console.error(`Video ${idx} failed to load`);
    },
  
    retryVideo(idx) {
      // reset error, re-initialize
      delete this.videoErrors[idx];
      const reel = this.reels[idx];
      if (!reel) return;
      const reelRef = this.$refs['videoPlayer_' + idx];
      const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
      if (!reelContainer) return;
      const videoEl = reelContainer.querySelector('video');
      if (!videoEl) return;
      this.initializeHlsOrMp4(videoEl, reel, idx);
    },
    

    // Infinite Scroll sentinel
    initializeInfiniteScroll() {
      this.$nextTick(() => {
        const sentinel = this.$refs.scrollSentinel;
        if (!sentinel) return;
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !this.isFetching) {
            this.fetchReelData();
          }
        }, {
          root: null,
          rootMargin: '100px',
          threshold: 0,
        });
        observer.observe(sentinel);
      });
    },

    handleScrollInFullScreen(event) {
      if (!this.isFullScreenMode) return;
      event.preventDefault();
      const direction = event.deltaY > 0 ? 1 : -1;
      if (!this.scrollLock) {
        this.scrollLock = true;
        this.navigateFullScreen(direction);
        setTimeout(() => {
          this.scrollLock = false;
        }, 500);
      }
    },

    exitFullScreen() {
      this.isFullScreenMode = false;
      this.fullScreenReels = [];
      this.fullScreenVideoIndex = 0;
      this.currentPlayingIndex = null;
      window.removeEventListener("wheel", this.handleScrollInFullScreen);
    },

    // PiP logic if needed
    async enterPictureInPicture(reel, index) {
      const videoElement = document.getElementById(`video-${reel.id}`);
      if (!videoElement) {
        console.error('Video element not found');
        return;
      }
      try {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        await videoElement.requestPictureInPicture();
        this.isInPiPMode = true;
        this.pipVideoIndex = index;
        this.currentPipProducts = reel.products.filter((p) => p.product_details);
        videoElement.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
      } catch (error) {
        console.error('Failed to enter PiP:', error);
      }
    },

    handleLeavePictureInPicture() {
      if (!this.isInPiPMode) {
        // If PiP wasn't actually active, do nothing.
        return;
      }
    
      this.isInPiPMode = false;
      if (this.pipVideoIndex !== null) {
        const reel = this.reels[this.pipVideoIndex];
        if (reel) {
          // Only navigate if you're truly exiting PiP mode.
          this.$router.go(-1);
          this.$nextTick(() => {
            this.openFullScreen(reel, this.pipVideoIndex);
          });
        }
      }
    },

    toggleLike(reel) {
      const token = localStorage.getItem('token');
      if (!token) {
        // If not logged in, redirect to login
        this.$router.push({ name: 'login' }); 
        // or window.location.href = '/login'; if you’re not using Vue Router
        return;
      }
      reel.isLiked = !reel.isLiked;
      this.likeOrUnlikeVideo(reel)
        .then(response => {
          reel.like_count = response.data.like_count;
          reel.is_liked = (response.data.status === 'liked');
        })
        .catch(error => {
          console.error('Toggle error:', error);
          // revert if needed
          reel.isLiked = !reel.isLiked;
        });
    },
    likeOrUnlikeVideo(reel) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const LIKED_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/like_video/${reel.id}/`;
      return axios.post(LIKED_URL, {}, {
        headers: {  Authorization: `Token ${token}` }
      });
    }
  },
  
    

  mounted() {
    this.checkLoginStatus();
    this.fetchReelData().then(() => {
      this.initializeVideoPlayers();
      this.initializeVideoObserver();
    });
    this.initializeInfiniteScroll();
  },

  setup() {
    const selectedRole = ref(localStorage.getItem('selectedRole') || 'influencer');
    const search_query = ref('');
    const products = ref([]);
    const filteredProducts = ref([]);
    const allProducts = ref([]);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const influencer_videos = ref([]);
    const random_influencer_videos = ref([]);
    const showDropdown = ref(false);
    const showDetails = ref([]);
    const currentPosition = ref(0);
    const visibleCards = ref(6);
    const hoveredVideo = ref(null);
    const videoRefs = ref([]);
    const images = ref([]);
    const currentVideoId = ref('');
    const images_data = ref([]);
    const video_products = ref([]);
    const heroVideo = ref(null);
    const isFullScreenMode = ref(false);
    const selectedDepartments = ref([]);
    const filter_product_list = (data) => {
      allProducts.value = data;
      filteredProducts.value = data;
    };
    const currentIndex = ref(0);
    const isFullScreen = ref(false);
    const selectedVideo = ref(null);
    const currentCollection = ref([]);
    const selected_collection_type = ref('');
    const updateRole = (role) => {
      selectedRole.value = role;
      localStorage.setItem('selectedRole', role);
    };
    const onImageClick = () => {};
    const initializeShowDetails = () => {
      showDetails.value = new Array(brand_videos.value.length).fill(false);
    };
    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };
    const toggleOverlay = (index, isHovering) => {
      const overlay = jQuery('.overlay').eq(index);
      if (!showDetails.value[index] && isHovering) {
        overlay.addClass('bg-dark');
      } else {
        overlay.removeClass('bg-dark');
      }
    };
    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };
    const fetchBrandVideos = async () => {
      try {
        const GET_ALL_BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
        const response = await fetch(GET_ALL_BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        brand_videos.value = data.results || data;
        loadImages();
        if (brand_videos.value.length > 0) {
          loadImagesForVideos();
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };
    const loadImages = () => {
      if (brand_videos.value && brand_videos.value.length > 0) {
        const video_id = brand_videos.value[0].id;
        const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;
        axios
          .get(VIEW_IMAGE_URL, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            image_files.value = response.data;
          })
          .catch((error) => {
            console.error('Error loading images:', error);
          });
      } else {
        console.error('No brand videos available or brand_videos is empty.');
      }
    };
    const setCurrentVideo = async (videoId) => {
      currentVideoId.value = videoId;
      await loadVideoImages(videoId);
    };
    const loadVideoImages = async (videoId) => {
      const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;
      try {
        const response = await fetch(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        images.value = data;
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };
    const fetchInfluencersVideos = async () => {
      try {
        const GET_ALL_INFLUENCER_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
        const response = await fetch(GET_ALL_INFLUENCER_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
    
        // Calculate the middle index
        const middleIndex = Math.ceil(data.length / 2);
    
        // Divide the data into two halves
        const firstHalf = data.slice(0, middleIndex);
        const secondHalf = data.slice(middleIndex);
    
        // Assign the first half to influencer_videos
        influencer_videos.value = firstHalf;
    
        // Randomize the second half and assign to random_influencer_videos
        const randomizedSecondHalf = [...secondHalf].sort(() => Math.random() - 0.5);
        random_influencer_videos.value = randomizedSecondHalf;
    
      } catch (error) {
        console.error('Error fetching influencer videos:', error);
      }
    };


    const scrollCards = (direction) => {
      const maxScroll = brand_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const scrollerCards = (direction) => {
      const maxScroll = influencer_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const showPopUp = (index) => {
      this.hoveredVideo = index;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.play().catch((error) => console.error("Error playing video:", error));
      }
    };
    const hidePopUp = (index) => {
      this.hoveredVideo = null;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
    const pauseVideo = (index) => {
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
    const loadImagesForVideos = () => {
      if (!brand_videos.value || brand_videos.value.length === 0) {
        console.error('No videos available to load images for.');
        return;
      }
      for (let video of brand_videos.value) {
        try {
          const VIEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video.id}`;
          axios
            .get(VIEW_PRODUCTS_URL, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              video.video_products = response.data;
            });
        } catch (error) {
          console.error(`Error fetching images for video ${video.id}:`, error);
        }
      }
    };
    function getProductImages(videoId) {
      return images.value[videoId] || [];
    }
    const displayedVideos = computed(() => {
      const totalVideos = currentCollection.value.length;
      if (totalVideos === 0) return [];
      const previousIndex = currentPosition.value > 0 ? currentPosition.value - 1 : null;
      const nextIndex = currentPosition.value < totalVideos - 1 ? currentPosition.value + 1 : null;
      const videosWithImages = [
        previousIndex !== null
          ? {
              ...currentCollection.value[previousIndex],
              product_images: getProductImages(currentCollection.value[previousIndex].id),
            }
          : null,
        {
          ...currentCollection.value[currentPosition.value],
          product_images: getProductImages(currentCollection.value[currentPosition.value].id),
        },
        nextIndex !== null
          ? {
              ...currentCollection.value[nextIndex],
              product_images: getProductImages(currentCollection.value[nextIndex].id),
            }
          : null,
      ].filter(Boolean);
      return videosWithImages;
    });
    const nextVideo = async () => {
      currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length;
      selectedVideo.value = currentCollection.value[currentPosition.value];
      await loadVideoImages(selectedVideo.value.id);
    };
    const previousVideo = async () => {
      currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length;
      selectedVideo.value = currentCollection.value[currentPosition.value];
      await loadVideoImages(selectedVideo.value.id);
    };
    const scrollVideo = (event) => {
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight;
      const currentIndex = displayedVideos.value.findIndex((v) => v.id === selectedVideo.value.id);
      if (scrollTop <= 0 && currentIndex > 0) {
        currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id);
      } else if (scrollTop >= scrollHeight && currentIndex < displayedVideos.value.length - 1) {
        currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id);
      }
    };
    onMounted(() => {
      watch(
        () => influencer_videos.value[0],
        (new_video) => {
          if (new_video && new_video.video_url) {
            nextTick(() => {
              const video_element = heroVideo.value;
              if (video_element) {
                const observer = new IntersectionObserver(
                  (entries) => {
                    entries.forEach((entry) => {
                      if (entry.intersectionRatio > 0.5) {
                        video_element.play().catch((error) => console.error("Error playing video:", error));
                      } else {
                        video_element.pause();
                      }
                    });
                  },
                  {
                    threshold: [0.5],
                  }
                );
                observer.observe(video_element);
              } else {
                console.error("heroVideo ref is null. Ensure the video element is rendered correctly.");
              }
            });
          }
        },
        { immediate: true }
      );
      watch(isFullScreenMode, (newVal) => {
        if (newVal) {
          if (heroVideo.value) {
            heroVideo.value.pause();
          }
        }
      });
      updateVisibleCards();
      jQuery(window).on('resize', updateVisibleCards);
      const $firstNavbar = jQuery('.first-navbar');
      const firstNavbarOffset = $firstNavbar.offset().top;
      jQuery(window).on('scroll', function () {
        if (jQuery(window).scrollTop() >= firstNavbarOffset) {
          $firstNavbar.addClass('position-fixed');
        } else {
          $firstNavbar.removeClass('position-fixed');
        }
      });
      fetchBrandVideos();
      fetchInfluencersVideos();
      loadImages();
      pauseVideo();
      loadImagesForVideos();
    });
    watch(brand_videos, initializeShowDetails);
    return {
      selectedRole,
      search_query,
      products,
      brand_videos,
      influencer_videos,
      random_influencer_videos,
      showDropdown,
      showDetails,
      currentPosition,
      visibleCards,
      hoveredVideo,
      image_files,
      videoRefs,
      images,
      currentVideoId,
      images_data,
      video_products,
      heroVideo,
      selectedDepartments,
      currentIndex,
      selectedVideo,
      isFullScreen,
      selected_collection_type,
      displayedVideos,
      isFullScreenMode,
      updateRole,
      scrollCards,
      showPopUp,
      hidePopUp,
      toggleDetails,
      toggleOverlay,
      setCurrentVideo,
      pauseVideo,
      loadVideoImages,
      onImageClick,
      filter_product_list,
      nextVideo,
      previousVideo,
      scrollerCards,
      scrollVideo,
    };
  },
};
