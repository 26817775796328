/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'
/* eslint-disable-next-line no-unused-vars */
import { get_user_details, get_token } from '@/router/index.js';
import { mapActions } from 'vuex';

export default {
    name: 'InfluencersView',
  components: {
    Admin_sidebar,
    Admin_navbar
  },

  data: () => ({ items: [],token_exists: null, user_details: null, loading: false,
   }),


   mounted() {

      this.token_exists = get_token();
      this.user_details = get_user_details();

      if (!this.token_exists || (this.user_details.user_role !== 'ADMIN')) {
        localStorage.removeItem('token'); // Clear the token
        this.$router.push('/login'); // Redirect to home page
      } else {
        this.fetchInfluencers();
      }
  },


  methods: {

    ...mapActions('auth', ['impersonate']),
    loginAsInfluencer(user_id) {
      this.impersonate(user_id);
      this.$router.push({
        name: 'Influencer_profile',
        params: { influencerId: user_id.toString() },
      });
    },


    ViewOrders(user_id) {
      this.$router.push({
        name: 'influencer_Order',
        query: {
          impersonate: true,
          user_id: user_id.toString()
        }
      });
      // Add this to store impersonation context
      localStorage.setItem('impersonate', true);
      localStorage.setItem('influencer_id', user_id.toString());
    },

    async fetchInfluencers() {
        const FETCH_INFLUENCER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
        try {
          this.loading = true;
          const response = await fetch(FETCH_INFLUENCER_URL, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          this.items = data;  // Update component data
          this.initializeDataTable();
          this.loading = false;
          await new Promise(resolve => setTimeout(resolve, 5000));
        } catch (error) {
          console.error('Error fetching influencers:', error);
        }
      },
    async deactivateUser(user) {      
      const token = localStorage.getItem('token');
      const CHANGE_USER_STATUS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/change_user_status/`;
      const newStatus = user.is_active ? 'f' : 't';

      try {
        // Immediately update the button state
        user.is_active = !user.is_active;

        await $.ajax({
          url: CHANGE_USER_STATUS_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            user_id: user.id,
            is_active: newStatus
          }),
          dataType: 'json',
        });

        // Update the UI after the AJAX request is successful
        await this.fetchInfluencers();
      } catch (error) {
        console.error(error);
        // Revert the button state on error
        user.is_active = !user.is_active;
      }
    },

      initializeDataTable() {
        this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#influencer_table')) {
            $('#influencer_table').DataTable().destroy();
          }
          this.dataTable = $('#influencer_table').DataTable({
            pageLength: 10,
            lengthMenu: [10, 25, 50, 100],
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            autoWidth: false,
            
          });
        });
      },

  }
}