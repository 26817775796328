<div class="terms-page">

    <div class="container py-5">
        <div class="container me-3 text-start">

        <button @click="goBack" class="action-btn">Go Back</button>
        </div>

      <h2 class="fw-bolder mb-4">Terms and Conditions</h2>

      <!-- Section 1: Introduction -->
      <h5 class="mb-3">1. WHAT YOU NEED TO KNOW</h5>
      <p>
        If you don't understand these Terms and Conditions, it is your responsibility to ask a Khiosk representative for clarification before accepting them and/or continuing to use the Website.
      </p>
      <p>These Terms and Conditions govern the sale of Products and the use of the Website.</p>
      <p>
        By using the Website, you acknowledge that you have read and agree to be bound by these Terms and Conditions.
      </p>
      <p>
        These Terms and Conditions apply to users who are consumers under the Consumer Protection Act, 68 of 2008 ("the CPA").
      </p>

      <!-- Section 2: Registering & Using the Website -->
      <h5 class="mb-3">2. REGISTERING, GUEST CHECKOUT & USING THE WEBSITE</h5>
      <ul class="list-unstyled">
        <li>Only registered users and/or guests (Guest Checkout) can purchase Products on the Website.</li>
        <li>To register, you must provide a unique username, password, and personal details.</li>
        <li>You agree not to share your username and password with any third party.</li>
        <li>If you suspect unauthorized access to your account, you must notify Khiosk immediately.</li>
        <li>You confirm that you are 18 years or older, or have parental consent if under 18.</li>
        <li>You may not use the Website to distribute unlawful or harmful material.</li>
        <li>You may not copy, display, or use the Website's content without prior written consent from Khiosk.</li>
      </ul>

      <!-- Section 3: Ownership and Copyright -->
      <h5 class="mb-3">3. OWNERSHIP AND COPYRIGHT</h5>
      <p>
        The contents of the Website, including material, software, text, images, logos, and trademarks, are protected by copyright and trademark laws.
      </p>
      <p>The Website content is owned by Khiosk, its advertisers, or third parties. Any unauthorized use is prohibited.</p>
      <p>If content belongs to a third party, you agree to comply with their terms and conditions.</p>

      <!-- Section 4: Disclaimer -->
      <h5 class="mb-3">4. DISCLAIMER</h5>
      <p>
        Use of the Website is at your own risk. Khiosk is not responsible for any damage or loss resulting from using the Website.
      </p>
      <p>Khiosk makes no warranties about the accuracy, reliability, or functionality of the Website or its content.</p>
      <p>Khiosk does not guarantee that the Website is free from viruses, spyware, or other harmful elements.</p>

      <!-- Section 5: Limitation of Liability -->
      <h5 class="mb-3">5. LIMITATION OF LIABILITY</h5>
      <p>
        Khiosk is not liable for any direct, indirect, incidental, or consequential loss or damage arising from your use of the Website or linked third-party websites.
      </p>
      <p>You agree to indemnify Khiosk against any claims arising from your use of the Website.</p>

      <!-- Section 6: Privacy Policy -->
      <h5 class="mb-3">6. PRIVACY POLICY</h5>
      <p>
        Khiosk will take reasonable measures to protect your personal information, including your name, email address, physical address, and phone number.
      </p>
      <p>Khiosk will not disclose your personal information to third parties unless required by law or necessary for order processing.</p>
      <p>Khiosk may use your information to send marketing materials. You can unsubscribe at any time.</p>

      <!-- Section 7: Changes to Terms -->
      <h5 class="mb-3">7. CHANGES TO THESE TERMS AND CONDITIONS</h5>
      <p>
        Khiosk may update these Terms at any time. The updated terms will apply once posted on the Website. By continuing to use the Website, you accept the updated terms.
      </p>

      <!-- Section 8: Terms of Sale -->
      <h5 class="mb-3">8. TERMS OF SALE</h5>
      <p>Orders may be placed by registered users or guests as long as the Products are available.</p>
      <p>An agreement of sale is made once payment is received and the Products are delivered.</p>
      <p>Khiosk reserves the right to refuse or cancel a sale due to errors or omissions on the Website.</p>
      <p>Prices may fluctuate due to factors such as exchange rates or taxes, but Khiosk will strive to ensure accurate pricing.</p>
    </div>
  </div>