import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Products_navbar from '@/components/Products/ProductNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import jQuery from 'jquery';
import { ref, onMounted, watch, nextTick} from 'vue';
import { useRouter } from 'vue-router'; 
import videojs from 'video.js';

export default {
  name: 'InfluencerDashView',
  components: {
    Influencer_sidebar,
    Influencer_navbar,
    Products_navbar,
    Filter_product,
  },
  data() {
    return {
      brandError: false,
      showCaptionError: false,
      search_query: '',
      search_input: '',
      filteredProducts: [],

    };
  },

  setup() {
    const router = useRouter();
    const videos = ref([]); // Replace with your actual videos data source
    const showDetails = ref([]); // Array to keep track of which video's details are shown
    const visibleCards = ref(6);
    const currentPosition = ref(0);
    const hoveredVideo = ref(null);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const currentVideoUrl =ref('');
    const currentVideoTitle = ref('');
    const currentVideoId = ref(null);
    const images = ref([]);
    const isFullScreen = ref(false); // Fullscreen toggle
    const selectedVideo = ref(null);
    const heroVideo = ref(null);
    const influencer_videos = ref([]);


    // Dropdown related variables
    const filteredProducts = ref([]);
    const brands = ref([]);   
    const products = ref([]);
    const video_products = ref([]);

    const search_query = ref('');
    const allProducts = ref([]);
    const showDropdown = ref(false);

    const filter_product_list = (data) =>{
      allProducts.value = data;
      filteredProducts.value = data;
    };

    // Initialize showDetails array with false values
    const initializeShowDetails = () => {
      showDetails.value = new Array(videos.value.length).fill(false);
    };

    // Toggle the visibility of details for the clicked video
    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!showDetails.value[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    };
    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };
    const scrollCards = (direction) => {
      const maxScroll = influencer_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const onImageClick = (image_id) => {
      // Fetch products before navigating to the new route
      fetchProducts().then(() => {
        // Construct the URL for the product route
        const product_url = router.resolve({ name: 'product', params: { id: image_id } }).href;

        // Change the window location to the new URL and refresh the page
        window.location.href = product_url;
        window.location.reload(); // This will refresh the page
      });
    };
    const fetchProducts = async () => {
      try {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;

        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        products.value = data;
        allProducts .value = data;
        filteredProducts.value = data;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const filterProducts = () => {
      const query = search_query.value.toLowerCase();
      filteredProducts.value = [...allProducts.value];
      if (query) {
          const search = new RegExp(query, 'i');
          filteredProducts.value = filteredProducts.value.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
      
    };
    const fetchInfluencersVideos = async () => {
      try {
        const GET_ALL_INFLUENCER_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
        const response = await fetch(GET_ALL_INFLUENCER_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        influencer_videos.value = data;
        
      } catch (error) {
        console.error('Error fetching influencers videos:', error);
      }
    };


    onMounted(() => {
      watch(() => influencer_videos.value[0], (new_video) => {
        if (new_video && new_video.video_url) {
          nextTick(() => {
            const video_element = heroVideo.value;
    
            if (video_element) {
              const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    video_element.play().catch(error => console.error("Error playing video:", error));
                  } else {
                    video_element.pause(); 
                  }
                });
              }, {
                threshold: 0.25,
              });
    
              observer.observe(video_element);
            } else {
              console.error("heroVideo ref is null. Ensure the video element is rendered correctly.");
            }
          });
        }
      }, { immediate: true });
      updateVisibleCards();
      
      // jQuery event handlers
      jQuery(window).on('resize', updateVisibleCards);
     
    });

    initializeShowDetails();
    fetchInfluencersVideos();
    watch(search_query, filterProducts);
    return { 
      videos:[],
      brands,
      products,
      filteredProducts,
      selectedProducts: [],
      brand_videos,
      influencer_videos,
      image_files,
      hoveredVideo,
      currentVideoUrl,
      showDropdown,
      currentVideoTitle, 
      images,
      currentVideoId, 
      video_products,
      visibleCards,
      currentPosition,
      isPlaying: false, // Flag to track video playback state
      debounceTimer: null, // Timer for debounce
      selectedVideo,
      isFullScreen,
      heroVideo,
      toggleDetails,
      toggleOverlay,
      scrollCards,
      onImageClick,
      filterProducts,
      fetchProducts,
      filter_product_list,
      fetchInfluencersVideos,
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
  
      
    };
    
},
  methods: {
    update_search(event) {
      this.$emit('update:searchIinput', event.target.value);
      this.search_query = event.target.value;
      this.filterProducts();
        },
    async fetchBrandVideos() {
      try {
        const GET_ALL__BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
        const response = await fetch(GET_ALL__BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.brand_videos = data;
         
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

 

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
  toggleDetails(index) {
    // Your method to toggle details view
    this.$set(this.showDetails, index, !this.showDetails[index]);
  },
  showPopUp(index) {
    this.hoveredVideo = index;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.play().catch(error => console.error("Error playing video:", error));
    }
  },
  hidePopUp(index) {
    this.hoveredVideo = null;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  },




    async fetchReelData() {

      if (this.isFetching || this.allDataLoaded) return;
    
      try {
    
        this.isFetching = true;
    
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
    
        const response = await fetch(`${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`);
    
        if (!response.ok) {
    
          throw new Error(`HTTP error! status: ${response.status}`);
    
        }
    
        const data = await response.json();
    
        if (data.length === 0) {
    
          this.allDataLoaded = true;
    
        } else {
    
          this.reels.push(...data);
    
          this.page++;
    
          // Initialize Video.js and Observers after new reels are rendered
    
          this.$nextTick(() => {
    
            this.initializeVideoJS();
    
            this.initializeVideoObserver();
    
          });
    
        }
    
      } catch (error) {
    
        console.error("Error fetching reel data:", error);
    
      } finally {
    
        this.isFetching = false;
    
      }
    
    },
    
    initializeVideoJS() {
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) ? this.$refs['videoPlayer_' + index][0] : this.$refs['videoPlayer_' + index];
    
        if (videoEl instanceof HTMLElement && !this.players[index]) {
    
          const videoElement = videoEl.querySelector('video');
    
          videoElement.setAttribute('playsinline', '');
    
          const player = videojs(videoElement, {
    
            autoplay: true,
    
            muted: false,
    
            controls: false,
    
            preload: "auto",
    
            loop: true,
    
            html5: {
    
              vhs: {
    
                overrideNative: false, // Use native playback where possible
    
              },
    
              nativeVideoTracks: true,
    
              nativeAudioTracks: true,
    
              nativeTextTracks: true,
    
            },
    
          });
    
          this.players[index] = player;
    
          // Remove play button overlay once video starts playing
    
          videoElement.addEventListener('play', () => {
            const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
    
            if (playButtonOverlay) {
              playButtonOverlay.style.display = 'none';
    
            }
    
          });
    
          // Ensure play button overlay reappears if video is paused
          videoElement.addEventListener('pause', () => {
    
            if (!this.userInteracted) {
    
              const playButtonOverlay = videoEl.querySelector('.play-button-overlay');

    
              if (playButtonOverlay) {
    
                playButtonOverlay.style.display = 'block';
    
              }
    
            }
    
          });
    
          // Ensure overlay is clickable by adding a click event listener
    
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
          if (playButtonOverlay) {
            playButtonOverlay.addEventListener('click', (event) => {
    
              event.stopPropagation(); // Prevent click from propagating to the video element
              this.enableAutoplay();
            
                playButtonOverlay.style.display = 'none';
        
    
            });
    
          }
    
        }
    
      });
    
    },
    
    initializeInfiniteScroll() {
      this.$nextTick(() => {
    
        const sentinel = this.$refs.scrollSentinel;
    
        if (sentinel) {
    
          const observer = new IntersectionObserver(
    
            (entries) => {
    
              if (entries[0].isIntersecting && !this.isFetching) {
    
                this.fetchReelData();
    
              }
    
            },
    
            {
    
              root: null,
              rootMargin: "100px",
              threshold: 0,
    
            }
    
          );
    
          observer.observe(sentinel);
    
        }
    
      });
    
    },
    
    initializeVideoObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.75,
      };
    
      if (!('IntersectionObserver' in window)) {
        console.warn('IntersectionObserver is not supported in this browser. Videos may not automatically play/pause.');
        return;
      }
    
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const videoIndex = parseInt(entry.target.dataset.index, 10);
          if (videoIndex !== -1 && this.players[videoIndex]) {
            if (entry.isIntersecting) {
              // Only autoplay videos if not in fullscreen mode
              if (!this.isFullScreenMode || this.fullScreenVideoIndex === videoIndex) {
                const playPromise = this.players[videoIndex].play();
    
                if (playPromise !== undefined) {
                  playPromise.catch((error) => {
                    console.error('Autoplay prevented:', error);
                  });
                }
              }
            } else {
              this.players[videoIndex].pause();
            }
          }
        });
      }, options);
    
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) 
          ? this.$refs['videoPlayer_' + index][0] 
          : this.$refs['videoPlayer_' + index];
    
        if (videoEl) {
          const videoElement = videoEl.querySelector('video');
          videoElement.dataset.index = index;
          observer.observe(videoElement);
        }
      });
    },
    
    openFullScreen(video, index) {
      if (!this.originalReels.length) {
          this.originalReels = [...this.reels];
        }
        if (!this.originalReels || !this.originalReels[index]) {
          console.error("Invalid index or reels not loaded");
          return;
        }
        // Set fullscreen reels
        this.fullScreenReels = [this.originalReels[index]];
        this.isFullScreenMode = true;
        this.$nextTick(() => {
          // Find the video element reference
          const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`]) ? this.$refs[`videoPlayer_${index}`][0] : this.$refs[`videoPlayer_${index}`];
          if (videoEl) {
            const videoElement = videoEl.querySelector('video');
            if (videoElement) {
              // Scroll to the video element
              videoElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              videoElement.play().catch((error) => {
                console.error('Error trying to play video in fullscreen mode:', error);
              });
            }
          }
        });
    },  

    
    exitFullScreen() {
    
      // Cleanup current video player
      if (this.players[0]) {
    
        this.players[0].dispose();
    
        delete this.players[0];
    
      }
    
      // Reset states
    
      this.isFullScreenMode = false;
    
      this.currentPlayingIndex = null;
      this.fullScreenVideoIndex = null; // Reset fullscreen video index

      // Restore original reels
    
      if (this.originalReels.length) {
    
        this.reels = [...this.originalReels];
    
      }
    
      // Clear fullscreen reels
    
      this.fullScreenReels = [];

    },
    
    setCurrentPlaying(index) {
      if (this.currentPlayingIndex === index) return;
    
      // Pause current fullscreen video if exists
      if (this.fullScreenVideoIndex == null && this.players[this.fullScreenVideoIndex]) {
        this.players[this.fullScreenVideoIndex].pause();
      }
    
      // Pause current playing video if exists
      if (this.currentPlayingIndex == null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      this.currentPlayingIndex = index;
    
      const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) 
        ? this.$refs['videoPlayer_' + index][0] 
        : this.$refs['videoPlayer_' + index];
    
      if (videoEl) {
        videoEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  
    beforeDestroy() {
    
      // Cleanup all video players
    
      Object.values(this.players).forEach((player) => {
    
        if (player && typeof player.dispose === 'function') {
    
          player.dispose();
    
        }
    
      });
    
      this.players = {};
    
      this.originalReels = [];
    
      this.fullScreenReels = [];
    
  },
   
    enableAutoplay() {
    
      this.userInteracted = true;
    
      if (this.players[this.currentPlayingIndex]) {
    
        this.players[this.currentPlayingIndex].play();
    
        // Remove play button overlay
    
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + this.currentPlayingIndex]) ? this.$refs['videoPlayer_' + this.currentPlayingIndex][0] : this.$refs['videoPlayer_' + this.currentPlayingIndex];
    
        if (videoEl) {
    
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
    
          if (playButtonOverlay) {
    
            if (this.userInteracted) {
              playButtonOverlay.style.display = 'none';
            }
    
          }
    
        }

      }
    
    },
  
},
   



  mounted() {
    this.fetchBrandVideos();
    this.handleClickOutside();
    this.fetchProducts();
    this.fetchReelData();
    this.initializeInfiniteScroll();
 
  },
  beforeDestroy() {
    this.handleClickOutside();

  }
  
};



