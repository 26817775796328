import Fullscreen_navbar from '@/components/Home/Fullscreen_Navbar/FullscreenNavbarView.vue'
import jQuery from 'jquery';
import $ from 'jquery';
import Swal from 'sweetalert2';
import EventBus from '@/eventBus.js';
import { fetchCartDetails } from '@/router/index.js'; // Import the fetchCartDetails function


export default {
  components: {
    Fullscreen_navbar
  },
  props: {
    search_input: {
      required: false,
      type: String
    }
  },
  data: () => ({ 
    activeTab: 'upload',
    cartQuantity: 0, 
    cartTotal: 0, 
    isCartOpen: false, 
    cartItems: JSON.parse(localStorage.getItem('cart')) || [], 
    products: [],  // Initialize as an empty array
    search_query: '', // Initialize search_query for filter
    selectedDepartment: null,
    selectedCategory: null,
    selectedSubcategories: [],
    showDropdown: false,
    allProducts: [],
    showBrandDropdown: false,
    showProductDropdown: false,
    tagInput: '',
    searchQuery: '',
    filteredBrands: [],
    filteredProducts: [],
    filtered_tagged_products: [],
    brands: [],   
    selectedProductIds: [],
    selectedProducts: [],
    brandError: false,
    showCaptionError: false,
    departments: [],
    sidebarOpen: false,
    subSidebarOpen: [],
    isRightSidebarOpen: false,
    imageUploaded: false,
    uploadedProducts: [],
    thumbnails: [],
    selectedThumbnail: null,
    videoDuration: 0,
    videoFile: null,
    currentStep: 'upload',
    selectedCoverPhoto: '',
    imagePreviewUrl:'',
    current_image: {
      id: null,
      description: '',
      price: '',
      no_of_items: '',
      categories: [],
      selectedCategory: '',
      newCategory: '',
      size:'',
      color:'',
      quantity:'',
      product_name:'',
      image_urls: '',
      selectedSize: null,
      sizes: []
    },
    form: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      bio: '',
     },
    profileImage: '',
  }),

  created() {
    this.fetchProducts();
    this.fetchCategories();
    this.fetchCartDetailsData();
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

    EventBus.on('toggle-right-sidebar', () => {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = true;
    });
    EventBus.on('update-cart', () => {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = true;

    });
  },
  
  watch: {
    search_query: 'filterProducts'
  },
  selectedDepartment() {
    this.updateCategories(); // Update categories based on the department change
  },
  selectedCategory() {
    this.updateSubcategories(); // Update subcategories based on the category change
  },

  beforeDestroy() {
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

  },

  mounted() {
    const $searchIcon = jQuery('#searchIcon');
    const $searchInput = jQuery('#search_input');
    const $sidebar_close = jQuery('.close-sidebar');
    const $sidebar = jQuery('.sidebar');
    this.handleClickOutside();
    this.checkLoginStatus();
    this.fetchUserData();

    
    
    // Function to close sidebar on screens 767px or smaller
    function handleSidebarOnResize() {
      if (window.innerWidth <= 767) {
        $sidebar.addClass('close');   
      } else {
        $sidebar.removeClass('close');  
      }
    }

    // Check screen size on page load
    handleSidebarOnResize();  
    $searchIcon.on('click', function(event) {
      event.stopPropagation();
      $searchInput.toggleClass('d-none'); 
      $searchIcon.toggleClass('bx-search bx-x');  
    });

    $sidebar_close.on('click', function(event) {
      event.stopPropagation();
      $sidebar.toggleClass('close');   
    });

    // Add event listener for window resize to handle dynamic resizing
    jQuery(window).on('resize', function() {
      handleSidebarOnResize(); 
    });
  },

  methods: {

    formatPrice(price) {
      const numericPrice = parseFloat(price);
      if (isNaN(numericPrice)) {
        return 'R 0.00';
      }
      return `${numericPrice.toLocaleString('en-ZA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },


    switchTab(tab) {
      this.activeTab = tab;
    },
    checkLoginStatus() {
      this.UserLoggedIn = !!localStorage.getItem('token');
    },
    updateCategories() {   
      const selectedDept = this.departments.find(dept => dept.id === this.selectedDepartment);
      if (selectedDept) {
        this.categories = selectedDept.categories || [];
        this.selectedCategory = this.current_image.category_id || null;
        if (!this.categories.some(cat => cat.id === this.selectedCategory)) {
          this.selectedCategory = null; // Reset if not in the list
          this.subcategories = [];
          this.selectedSubcategories = [];
        } else {
          this.updateSubcategories(); // Populate subcategories
        }
      }
    },
    updateSubcategories() {
      const selectedCat = this.categories.find(cat => cat.id === this.selectedCategory);
      this.subcategories = selectedCat ? selectedCat.subcategories : [];
      // Retain the previously selected subcategory
      if (this.selectedSubcategories && !this.subcategories.some(sub => sub.id === this.selectedSubcategories)) {
          this.selectedSubcategories = null;
      }
    },

    uploadImage() {
      const product_name = $('#product_name').val();
      const description = $('#description').val();
      const price = $('#price').val();
      const imageFileInput = $('#image_url')[0];
      const imageFile = imageFileInput.files[0];
      const imagePreviewUrl = URL.createObjectURL(imageFile);
      const size = [$('#size').val()];

      this.uploadedProducts.push({
      product_name,
      description,
      price,
      imageFile,
      imagePreviewUrl, // Store the preview URL for display
      size,
      });

      // Reset the form and variables
      $('#uploadForm')[0].reset();
      this.imageUploaded = false;
      this.imagePreviewUrl = '';
      $('#imagePreview').attr('src', require('@/assets/images/no-image.png')); // Reset to placeholder image
    },

    goToHomePage() {
      window.location.reload();
    },
    previewVideo(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('video/')) {
      this.videoFile = file;
      const $videoPreview = $('#videoPreview');
      $videoPreview.attr('src', URL.createObjectURL(file));
      
      // Show the preview section immediately
      $('#uploadSection').addClass('d-none');
      $('#previewPage').removeClass('d-none');
      $('#nextButton').removeClass('d-none');
    
      // Generate thumbnails and auto-preview once the video is loaded
      $videoPreview.on('loadedmetadata', () => {
        this.videoDuration = $videoPreview[0].duration;
        this.generateVideoThumbnails($videoPreview[0]);
        this.generateAutoPreview($videoPreview[0], $('#autoPreview')[0]);
    
        // Initialize the slider
        this.initializeSlider($videoPreview[0]);
      });
    
      // Show the back button and update the current step
      $('#backButtonHeader').removeClass('d-none');
      this.currentStep = 'preview';
      }
    },

    handleBackClick() {
      if (this.currentStep === 'preview') {
      // If the user is on the preview section, go back to the upload section
      this.currentStep = 'upload';
      this.showSection('uploadSection');
      $('#backButtonHeader').addClass('d-none'); // Hide the back button
      } else if (this.currentStep === 'coverPhoto') {
      // If the user is on the cover photo section, go back to the preview section
      this.currentStep = 'preview';
      this.showSection('previewPage');
      } else if (this.currentStep === 'productUpload') {
      // If the user is on the product upload section, go back to the cover photo section
      this.currentStep = 'coverPhoto';
      this.showSection('coverPhotoSection');
      }
    },

    initializeSlider(video) {
      const slider = $('#frameSlider');
      if (slider.length) {
      slider.on('input', (event) => {
        const sliderValue = event.target.value;
        const time = (sliderValue / 100) * this.videoDuration;
        this.updatePreviewFromSlider(time, video);
      });
      }
    },

    previewImage(event) {
      const file = event.target.files[0];
      const imagePreview = $('#imagePreview');
      if (file) {
      const url = URL.createObjectURL(file);
      imagePreview.attr('src', url).removeClass('d-none'); // Use jQuery to update the image source and show it
      this.imageUploaded = true;
      } else {
      // If no file is selected, reset the preview
      imagePreview.attr('src', require('@/assets/images/img-error.png')).addClass('d-none'); // Use jQuery to show placeholder image and hide it
      this.imageUploaded = false; // Mark image as not uploaded
      }
        },

    // Handle "Next" button click
    handleNextClick() {
      if (this.currentStep === 'upload') {
      // Move to the preview section
      $('#backButtonHeader').removeClass('d-none'); // Show the back button
      this.currentStep = 'preview';
      this.showSection('previewPage');
      } else if (this.currentStep === 'preview') {
      // Move to the cover photo selection section   
      this.currentStep = 'coverPhoto';
      this.showSection('coverPhotoSection');
      this.generateVideoThumbnails();
      } else if (this.currentStep === 'coverPhoto') {
      if (!this.caption) {
        this.showCaptionError = true;
        // Don't move to the next step if there is no caption
        return;
      }
      // Save the cover photo and caption
      this.currentStep = 'productUpload';
      this.showSection('productUploadSection');
      const autoPreviewSrc = $('#autoPreview').attr('src');
      $('#coverPhotoPreview').attr('src', autoPreviewSrc);
      } else if (this.currentStep === 'productUpload') {
      // Handle the final upload
      this.handleUpload();
      }
    },

    showSection(sectionId) {
      const sections = ['uploadSection', 'previewPage', 'coverPhotoSection', 'productUploadSection'];
      sections.forEach((id) => {
      const section = $(`#${id}`);
      if (section.length) {
        section.toggleClass('d-none', id !== sectionId);
      }
      });
    },
    // Generate thumbnails from the video
    generateVideoThumbnails() {
      const video = $('#videoPreview');
      const thumbnailsContainer = $('#thumbnailsContainer');
      thumbnailsContainer.empty(); // Clear existing thumbnails
      if (video.length) {
      const thumbnailCount = 20; // Number of thumbnails to generate
      const interval = this.videoDuration / thumbnailCount;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      for (let i = 0; i < thumbnailCount; i++) {
        const time = i * interval;
        video[0].currentTime = time;
        video.one('seeked', () => {
        // Set canvas dimensions to a smaller size (e.g., 200x200)
        const maxWidth = 200; // Maximum width for the thumbnail
        const maxHeight = 200; // Maximum height for the thumbnail
      
        // Calculate the aspect ratio of the video
        const aspectRatio = video[0].videoWidth / video[0].videoHeight;
      
        // Set canvas dimensions based on the aspect ratio
        if (video[0].videoWidth > video[0].videoHeight) {
          canvas.width = maxWidth;
          canvas.height = maxWidth / aspectRatio;
        } else {
          canvas.height = maxHeight;
          canvas.width = maxHeight * aspectRatio;
        }
      
        // Draw the video frame onto the canvas
        context.drawImage(video[0], 0, 0, canvas.width, canvas.height);
      
        // Convert the canvas image to a Blob, then to a File
        canvas.toBlob((blob) => {
          // Generate a short unique name for the file
          const fileName = `thumbnail_${Date.now().toString(36)}.jpg`; // Example: "prev_kx2m3.jpg"
      
          // Create a File object from the Blob
          const file = new File([blob], fileName, {
          type: 'image/jpeg', // MIME type
          lastModified: Date.now(), // Timestamp
          });

          this.selectedCoverPhoto = file;
          
        }, 'image/jpeg', 1.0);
        });
      }
      }
    },

    triggerFileInput() {
      $('#coverPhotoUpload').click();
    },

    generateAutoPreview(video, previewElement) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
    
      video.addEventListener('seeked', () => {
        // Set canvas dimensions to a smaller size (e.g., 200x200)
        const maxWidth = 200; // Maximum width for the thumbnail
        const maxHeight = 200; // Maximum height for the thumbnail
    
        // Calculate the aspect ratio of the video
        const aspectRatio = video.videoWidth / video.videoHeight;
    
        // Set canvas dimensions based on the aspect ratio
        if (video.videoWidth > video.videoHeight) {
          canvas.width = maxWidth;
          canvas.height = maxWidth / aspectRatio;
        } else {
          canvas.height = maxHeight;
          canvas.width = maxHeight * aspectRatio;
        }
    
        // Draw the video frame onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
        // Convert the canvas image to a Blob, then to a File
        canvas.toBlob((blob) => {
          // Generate a short unique name for the file
          const fileName = `thumbnail_${Date.now().toString(36)}.jpg`; // Example: "prev_kx2m3.jpg"
    
          // Create a File object from the Blob
          const file = new File([blob], fileName, {
            type: 'image/jpeg', // MIME type
            lastModified: Date.now(), // Timestamp
          });
    
          // Optionally, create an object URL for the file
          const objectUrl = URL.createObjectURL(file);    
          // Set the preview image
          previewElement.src = objectUrl;
          previewElement.alt = fileName; // Use the file name as the alt text
    
          // Store the file and filename in selectedCoverPhoto
          this.selectedCoverPhoto = file;        
        }, 'image/jpeg',1.0); // 0.10 = 10% quality
      }, { once: true }); // Ensure the event listener is removed after execution
    
      video.currentTime = 0; // Capture the first frame
    },
  
  
    // Preview the uploaded cover photo
    previewCoverPhoto(event) {
      const file = event.target.files[0];
      if (file) {
      const url = URL.createObjectURL(file);
      this.selectedCoverPhoto = url;
      $('#autoPreview').attr('src', url);
      this.showCoverPhotoPreview(url);
      }
    },

    updatePreviewFromSlider(time) {
      const video = $('#videoPreview'); // Reference the video element using jQuery
      video[0].currentTime = time;
    
      video.one('seeked', () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
    
      // Set canvas dimensions to match the video's resolution
      canvas.width = video[0].videoWidth;
      canvas.height = video[0].videoHeight;
    
      // Draw the video frame onto the canvas
      context.drawImage(video[0], 0, 0, canvas.width, canvas.height);
    
      // Convert the canvas image to a Blob, then to a File
      canvas.toBlob((blob) => {
        // Generate a unique file name
        const fileName = `thumbnail_${Date.now().toString(36)}.jpg`;
    
        // Create a File object from the Blob
        const file = new File([blob], fileName, {
        type: 'image/jpeg', // MIME type
        lastModified: Date.now(), // Timestamp
        });
    
        // Optionally, create an object URL for the file
        const objectUrl = URL.createObjectURL(file);
        // Update the selected cover photo and the preview element
        $('#autoPreview').attr('src', objectUrl); // Update #autoPreview using jQuery
        this.selectedCoverPhoto = file;
      }, 'image/jpeg', 1.0); // 1.0 = 100% quality
      });
    },
    // Show the cover photo preview
    showCoverPhotoPreview(url) {
      const coverPhotoPreview = $('#autoPreview');
      const coverPhotoPreviewSection = $('#coverPhotoPreviewSection');
    
      if (coverPhotoPreview.length && coverPhotoPreviewSection.length) {
      coverPhotoPreview.attr('src', url);
      coverPhotoPreviewSection.removeClass('d-none');
      }
    },
    toggleRightSidebar() {
      this.fetchCartDetailsData();
      this.isRightSidebarOpen = !this.isRightSidebarOpen;
    },

    update_search(event) {
      this.$emit('update:search_input', event.target.value);
      this.search_query = event.target.value;
      this.filterProducts();
        },

      async fetchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`; 
        try {
          const response = await fetch(FETCH_PRODUCT_URL, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
          const data = await response.json();
          this.products = data;           // Use this.products directly
          this.allProducts = data;        // Use this.allProducts directly
          this.filteredProducts = data; // Initialize with all products
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      },

      clearCartLocalStorage() {
        this.setLocalStorage('cart', null);
      },
      
      getLocalStorage(name) {
        const value = localStorage.getItem(name);
        return value ? JSON.parse(value) : null;
      },
      
      setLocalStorage(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
      },

    increaseQuantity(item) {
      item.quantity += 1;
      this.updateCartInDatabase(item.product_id,1);
    },
  
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
        this.updateCartInDatabase(item.product_id,-1);
      }
    },
    filterProducts() {
      const query = this.search_query.toLowerCase();
      // Start with all products
      this.filteredProducts = [...this.allProducts];

      // Apply search filter
      if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts= this.filteredProducts.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
  },

    
    async updateCartInDatabase(productId, quantity) {
      const token = localStorage.getItem('token');
      const UPDATE_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
      try {
          const response = await $.ajax({
              url: UPDATE_CART_URL,
              method: 'POST',
              headers: {
                  'Authorization': `Token ${token}`,
                  'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                  product_id: productId,
                  quantity: quantity
              }),
              processData: false,
          });  
          if (response.message === 'success') {
              this.fetchCartDetailsData();
          } else {
              console.error('Failed to update cart in the database:', response.error || 'Unknown error');
          }
      } catch (error) {
          console.error('Error updating cart in the database:', error);
      }
  },

  async deleteCartItem(productId) {
    const token = localStorage.getItem('token');
    const DELETE_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/delete/${productId}/`;
  
    try {
        const response = await $.ajax({
          url: DELETE_CART_URL,
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.message === 'success') {
          this.fetchCartDetailsData();
        } else {
          console.error('Error deleting item from the cart:', response.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error deleting cart item:', error);
      }
    },  
  
    async fetchCartDetailsData() {

      const data = await fetchCartDetails(); 
      this.cartItems = data.cartItems; 
      this.cartQuantity = data.cartQuantity; 
      this.cartTotal = data.cartTotal;
    },

    
    
    async logout() {
      const token = localStorage.getItem('token');
      const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

      const response = await $.ajax({
        url: LOGOUT_URL,
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (response) {
        localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
        if (this.$route.path === '/') {
          // If on the homepage, reload the page to reflect the logged-out state
          window.location.reload();
        } else {
          // If on another page (e.g., profile), redirect to the homepage
          this.$router.push('/');
        }
      } else {
        throw new Error('Logout failed');
      }
    },

    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        // Initialize subSidebarOpen with false values for each department
        this.subSidebarOpen = Array(this.departments.length).fill(false);
      } catch (error) {
        console.error(error);
      }
    },

    handleUpload() {
      if (this.activeTab === 'upload') {
        this.uploadProductVideo();
      } else {
        this.uploadVideo();
      }
    },
    
    uploadVideo() {

      if (!this.videoFile) {
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'No Video Selected',
          text: 'Please select a video to upload.',
        });
        return;
      }
      
        this.brandError = false;
        this.showCaptionError = false;

        if (!this.selectedBrandId && !this.showBrandDropdown) {
          this.brandError = true;
        }

        if (!this.caption) {
          this.showCaptionError = true;
        }
        if (this.brandError || this.showCaptionError) {
          return;
        }
      Swal.fire({
        title: 'Uploading Video',
        text: 'Please wait while we upload your video...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    
      const formData = new FormData();
      formData.append('caption', this.caption); // Use `this.caption` instead of `$('#caption').val()`
      formData.append("brand_id", this.selectedBrandId);

      this.selectedProductIds.forEach(id => {
        formData.append('products', id);
      });
      formData.append('video_url', this.videoFile); // Use `this.videoFile` instead of `$('#video_url')[0].files[0]`
      formData.append('thumbnail_url', this.selectedCoverPhoto); // Use `this.selectedCoverPhoto` instead of `$('#coverPhoto')[0].files[0]`

      const token = localStorage.getItem('token');
      const UPLOAD_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/upload_content/`;
      fetch(UPLOAD_CONTENT_URL, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        if (response.statusText == 'Created') {
          Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            title: 'Upload Successful',
            text: 'Your video has been uploaded successfully!',
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Upload failed');
        }
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'Upload Failed',
          text: 'Failed to upload video. Please try again.',
        });
      });
    },
    
    uploadProductVideo() {

      if (!this.videoFile) {
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'No Video Selected',
          text: 'Please select a video to upload.',
        });
        return;
      }
      if (this.uploadedProducts.length === 0) {
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'No Products Saved',
          text: 'Please add at least one product to upload.',
         
        });
        return;
      }

      this.showCaptionError = false;

      if (!this.caption) {
        this.showCaptionError = true;
      }

      Swal.fire({
        title: 'Uploading Video',
        text: 'Please wait while we upload your video...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const token = localStorage.getItem('token');
      const UPLOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/upload_influencer_content/`;

      const videoFormData = new FormData();
      videoFormData.append('video_url', this.videoFile); // Use `this.videoFile` instead of `$('#video_url')[0].files[0]`
      videoFormData.append('thumbnail_url', this.selectedCoverPhoto); // Use `this.selectedCoverPhoto` instead of `$('#coverPhoto')[0].files[0]`
      videoFormData.append('caption', this.caption); // Use `this.caption` instead of `$('#caption').val()`

      fetch(UPLOAD_VIDEO_URL, {
        method: 'POST',
        body: videoFormData,
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        const videoId = data.video_id;

        const uploadedProducts = this.uploadedProducts;
        const UPLOAD_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/influencer_products/`;

        const uploadPromises = uploadedProducts.map(product => {
          const formData = new FormData();

          formData.append('image_urls', product.imageFile);
          formData.append('description', product.description);
          formData.append('product_name', product.product_name);
          formData.append('price', product.price);
          formData.append('sizes', product.size);
          formData.append('video_id', videoId);

          return fetch(UPLOAD_IMAGE_URL, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          });
        });

        Promise.all(uploadPromises)
          .then(responses => {
            if (responses.every(response => response.ok)) {
              Swal.fire({
                icon: 'success',
                title: 'Upload Successful',
                text: 'Your video and products have been uploaded successfully!',
              }).then(() => {
                window.location.reload();
              });
            } else {
              throw new Error('Upload failed');
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Upload Failed',
              text: 'Failed to upload video or products. Please try again.',
            });
          });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Video Upload Failed',
          text: 'Failed to upload video. Please check video file and try again.',
        });
      });
    },
     
    
  filterBrands() {
    if (this.tagInput.startsWith('@')) {
      this.showBrandDropdown = true;
      const query = this.tagInput.slice(1).toLowerCase();
      this.filteredBrands = this.brands.filter(brand => 
        brand.brand_name.toLowerCase().includes(query)
      );

      this.showBrandDropdown = this.filteredBrands.length > 0;
    } else {
      this.showBrandDropdown = false;
    }
  },
  




  selectProduct(product) {

    if (!this.selectedProductIds) {
      this.selectedProductIds = [];
    }
  
    const isProductSelected = this.selectedProductIds.some(id => id === product.id);
  
    if (!isProductSelected) {
      this.selectedProductIds.push(product.product.id);
      this.selectedProducts.push(product);
    }
  
    this.searchQuery = '';
    this.showProductDropdown = false;
  },

  removeProduct(product) {
    this.selectedProducts = this.selectedProducts.filter(p => p !== product);
  },

  removeUploadedProduct(product) {
    this.uploadedProducts = this.uploadedProducts.filter(p => p !== product);
  },
  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  },

  toggleSubSidebar(departmentIndex) {
    // Toggle the open state of the clicked department's sub-sidebar
    this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
  },
  async fetchUserData() {
            const token = localStorage.getItem('token');
            const isImpersonating = this.isImpersonating; // Get from Vuex getters
            const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
            let GET_USER_INFO = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`;
            if (isImpersonating && impersonatedUserId) {
              GET_USER_INFO += `?impersonate=true&user_id=${impersonatedUserId}`;
            }
          try {
            const response = await fetch(GET_USER_INFO, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
    
            if (!response.ok) {
              throw new Error('Failed to fetch user data');
            }
    
            const data = await response.json();
    
            if (data.status === "success") {
              this.form.first_name = data.first_name;
              this.form.last_name = data.last_name;
              this.form.email = data.email;
              this.form.phone_number = data.phone_number;
              this.form.bio = data.bio || ''; 
              this.profileImage = data.image; 
            } else {
              Swal.fire({
                title: 'Error',
                text: data.error || 'Failed to fetch user data.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        },
        
    
    getInitials(first, last) {
      return `${first?.[0] || ''}${last?.[0] || ''}`.toUpperCase();
    },
  }
}
