  <Home_navbar v-if="!LoggedIn" v-model:search_input="search_query" @update:search_input="update_search_query"/>
        <Influencer_navbar v-else />
        <div v-if="showSizelert" class="centered-popup alert-success">
          You need to select size before adding to cart! 
        </div>
        <div v-if="search_query === ''">
          <div class="container-fluid  mt-2">
            <div class=" video-containers">
                <video  id="video_player" autoplay muted loop >
                  <source  id="video_source" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
             
             
            <div class="full-contents col-md-4" v-if="product">
                
                <div class="product-details font" >
                  <div class="mb-4" >
                    <img :src="product.product.image_url" alt="Product Image" class="product-image-details">
                  </div>      
                  <h1>{{ product.product.product_name }}</h1>
                  <p><span>{{ product.product.brand__brand_name }}</span></p>
                  <p>R{{ product.price }}</p>
                          
                  <div class="quantity-selector">
                    <label class="fw-bold fs-6 me-2">Quantity</label>
                    <button class="btn btn-outline-light btn-sm" @click="decreaseQuantity">-</button>
                    <span class="quantity-number">{{ quantity }}</span>
                    <button class="btn btn-outline-light btn-sm " @click="increaseQuantity">+</button>
                  </div>
             
                  <div class="color-selector">
                    <label class="fw-bold fs-6">Color {{product.color}}</label>
                 
                  </div>
                          
                  <h5 class="fw-bold">Size:</h5>
                      <div class="d-flex mb-3">
                          <div class="size-option me-2"
                            v-for="size in sizes" :key="size"
                            :class="{ active: selectedSize === size.size }"
                            @click="selectSizeProduct(size)"
                          >                
                            {{ size.size }}
                          </div>
                      </div>
                  <p class="stock-info">Only {{product.quantity}} left - don't miss out.</p>
             
                  <div class="action-buttons justify-content-center align-content-center d-flex">
                    <button class="add-to-cartt btn-light" @click="addToCart(product.id)">
                      <i class="fa-solid fa-cart-shopping wishlist-icon fa-lg"></i> Add to Cart
                    </button>
                    <button class="btn text-white btn-outline-light justify-content-center">
                      <i class="fa-sharp fa-regular fa-heart wishlist-icon"></i>
                    </button>
                  </div>
             
                  <h5 class="fw-bold">description</h5>
                  <p class="product-view-brand text-start"> {{ product.product.description }}</p>
                </div>
              </div>
            </div>
            </div>
        </div>
        
        <div v-else>
          <!-- Search results will be displayed here -->
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <Filter_product
                @filter-products="filter_product_list"
                />
              </div>
        
              <div class="col-md-9">
                <div class="items-found">{{ filteredProducts.length }} items found</div>
        
                <!-- Dropdown to show combined results -->
                <div v-if="showDropdown" class="dropdown-menu">
                  <div
                      v-for="result in filteredProducts"
                      :key="result.id"
                      @click="selectResult(result)"
                      class="dropdown-item"
                  >
                  <span class="text-start" v-if="result.type === 'product'">{{ result.product_name }}</span>
                  <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                  <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                  <span v-else>Description: {{ result.description }}</span>
                  </div>
        
                </div>
                
                <div class="product-grid">
                  <div class="product-card" v-for="product in filteredProducts" :key="product.id">
                    <div class="product-link">
                      <router-link
                      :to="{ name: 'product', params: { id: product.id}}" 
                      class="product-link-image"
>
                <!-- Product Image and Info -->
                <input type="hidden" ref="image_id" :value="product.id">
                <div class="product-image" v-if="product.product.image_url" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
              </div>
              </router-link>
              <div class=" product-info text-start">
                <p class="product-title text-black">{{ product.product.product_name }}</p>
                <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
                <div class="d-flex justify-content-between align-items-center">
                  <p class="product-price text-black">R{{ product.price }}</p>
                  <div class="d-flex justify-content-between">
                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="addToCart(product)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>      
        </div>
      </div>
    </div>
  </div>
</div>