<div class="home-navbar ">
  <Admin_sidebar />
</div>

<div class="app-content mt-1">
  <Admin_navbar />
  <div class="content">
        <!-- Main content goes here -->
        <div class="row g-2">
          <!-- Total Videos Card -->
          <div class="col-12 col-sm-6 col-md-3">
            <div class="card text-black bg-light h-100">
              <div class="card-body d-flex justify-content-between align-items-center">
                <div class="text-start">
                  <h6 class="card-title fs-6">Total Videos</h6>
                  <h4 class="font-weight-light" id="totalVideos">0</h4>
                  <small class="rounded border border-black text-black px-2 py-1">+3.5%</small>
                </div>
                <i class="bi bi-camera-video h4 bg-dark text-white p-2 custom-rounded"></i>
              </div>
            </div>
          </div>
        
          <!-- Total Orders Card -->
          <div class="col-12 col-sm-6 col-md-3">
            <div class="card text-black bg-light h-100">
              <div class="card-body d-flex justify-content-between align-items-center">
                <div class="text-start">
                  <h6 class="card-title fs-6">Total Orders</h6>
                  <h4 class="font-weight-light" id="totalOrders">0</h4>
                  <small class="rounded border border-black text-black px-2 py-1">+2.7% </small>
                </div>
                <i class="bi bi-cart h4 text-white bg-dark p-2 custom-rounded"></i>
              </div>
            </div>
          </div>
        
          <!-- Videos This Month Card -->
          <div class="col-12 col-sm-6 col-md-3">
            <div class="card text-black bg-light h-100">
              <div class="card-body d-flex justify-content-between align-items-center">
                <div class="text-start">
                  <h6 class="card-title fs-6">Videos This month</h6>
                  <h4 class="font-weight-light" id="totalUsers">0</h4>
                  <small class="rounded border border-black text-black px-2 py-1">+1.2%</small>
                </div>
                <i class="bi bi-film h4 text-white bg-dark p-2 custom-rounded"></i>
              </div>
            </div>
          </div>
        
          <!-- Total Users Card -->
          <div class="col-12 col-sm-6 col-md-3">
            <div class="card text-black bg-light h-100">
              <div class="card-body d-flex justify-content-between align-items-center">
                <div class="text-start">
                  <h6 class="card-title fs-6">Total Users</h6>
                  <h4 class="font-weight-light" id="totalUsers">0</h4>
                  <small class="rounded border border-black text-black px-2 py-1">+1.2% </small>
                </div>
                <i class="bi bi-people h4 text-white bg-dark p-2 custom-rounded"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 g-2">
          <div class="col-lg-9 text-start font-weight-bold">
            <div class="card h-100 p-3">
              <h5 class="mb-3">Sales and Orders</h5>
              <canvas ref="salesChart"></canvas>
            </div>
            
          </div>
          <div class="col-lg-3 d-flex flex-column gap-3 custom-rounded">
  
            <!--  Pie Chart at the top -->
            <div class="card p-3">
              <h6 class="mb-2">Video Insight</h6>
              <canvas ref="videoPieChart"></canvas>
            </div>
          
            <!--  Mini Cards below -->
            <div class="card p-3 text-start gap-2">
              <small class="text-black">Total Sales</small>
              <h6 class="mb-0">R700</h6>
            </div>
          
            <div class="card p-3 text-start gap-2 custom-rounded">
              <small class="text-black">Total Orders</small>
              <h5 class="mb-0">200</h5>
            </div>
          
            <div class="card p-3 text-start gap-2 custom-rounded">
              <small class="text-black">Average</small>
              <h5 class="mb-0">R640 / order</h5>
            </div>
          
          </div>
          
          </div>


    </div>
</div>
