
<div class="app register-page">
    
  <div class="container">


      <!-- Alerts for error or success messages -->
          <div class="sign-card">
              <div id="errorAlert" class="alert alert-danger d-none" role="alert">{{ errorMessage }}</div>
              <div id="successAlert" class="alert alert-success d-none" role="alert">{{ successMessage }}</div>
              
              <!-- Logo -->
              <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class="kyosk-logo mb-4" >

              <div class="form-content">

              <!-- Registration Form -->
              <form id="register_form" class="register-form" @submit.prevent="onSubmit">

                  <!-- First and Last Name -->
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" id="first_name" v-model="form.first_name" class="form-control" placeholder="First Name" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="text" id="last_name" v-model="form.last_name" class="form-control" placeholder="Last name"required>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="form-group mb-3">
                            <input type="email" id="email" v-model="form.email" 
                                   class="form-control" placeholder="Email Address" 
                                   @input="emailErrorMessage = false; emailExistsError = false" required>
                            
                            <!-- Error: Invalid Email Format -->
                            <small v-if="emailErrorMessage" class="text-danger" >
                                Invalid email format. Please enter a valid email address.
                            </small>
                    
                            <!-- Error: Email Already Exists -->
                            <small v-if="emailExistsError" class="text-danger">
                                This email is already registered. Please use a different email.
                            </small>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">

                            <input type="tel" id="phone_number" v-model="form.phone_number" class="form-control" placeholder="Phone number" @input="showPhoneNumberError = false" required>
                            <small id="phone_error" v-if="showPhoneNumberError"  class="text-danger">Invalid phone number. Please enter a valid phone number.</small>
                        </div>
                    </div>
                    
                      <div v-if="selectedRole === 'brand'" class="col-md-6">
                        <div class="form-group mb-3">
                          <input type="text" id="brand_name" v-model="form.brand_name"  class="form-control" placeholder="Brand Name" required>
                        </div>
                      </div>
                      <div v-if="selectedRole === 'influencer' || selectedRole === 'customer'" class="col-12">
                        <div class="form-group mb-3">
                        </div>
                      </div>

                  

            <!-- Password and Confirm Password -->
            <div class="col-12">
                <div class="form-group mb-3">
                    <div class="input-group">
                        <input :type="passwordType" 
                            id="password" 
                            v-model="form.password" 
                            class="form-control password-input" 
                            placeholder="Enter password" 
                            @input="showPasswordError = false" 
                            required/>
                            <small v-if="showPasswordError" 
                            class="text-danger">'Password must be at least eight characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.
                        </small>
                        <i :class="passwordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" 
                        class="toggle-password-icon" 
                        @click="togglePassword"></i>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group mb-2">
                    <div class="input-group">
                        <input :type="confirmPasswordType" 
                            id="confirm_password" 
                            v-model="form.confirm_password" 
                            class="form-control password-input" 
                            placeholder="Confirm password" 
                            @input="showConfirmPasswordError = false"
                            required/>
                            <small v-if="showConfirmPasswordError" 
                            class="text-danger">'Passwords do not match.'
                        </small>
                        <i :class="confirmPasswordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" 
                        class="toggle-password-icon" 
                        @click="toggleConfirmPassword"></i>
                    </div>
                    <small id="password-requirements" class="d-none">
                        Password must be at least 8 characters long and contain:<br>
                        <span id="length-requirement"> At least 8 characters</span><br>
                        <span id="uppercase-requirement"> At least one uppercase letter</span><br>
                        <span id="lowercase-requirement"> At least one lowercase letter</span><br>
                        <span id="digit-requirement"> At least one digit</span><br>
                        <span id="specialchar-requirement"> At least one special character</span><br>
                    </small>
                </div>
            </div>
            <div class="text-black mt-4">
                <small>
                    <div class="form-check d-inline-block">
                        <input type="checkbox" class="form-check-input" id="termsCheckbox" v-model="termsChecked">                       
                         <label class="form-check-label" for="termsCheckbox"></label>
                    </div>
                    Creating an account means you're okay with our 
                    <a href="#" data-bs-toggle="modal" data-bs-target="#terms_modal">Terms of Service</a>, 
                    Privacy Policy, and our default Notification Settings.
                </small>
            </div>
                  <!-- Submit Button -->
                  <button type="submit" id="register" class="register-button fw-bold">Sign Up</button>
              </form>

              <!-- Login Link -->
              <p class="mt-3 text-black">
                  Already a member? <a href="/login">Login</a>
              </p>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" id="terms_modal" tabindex="-1" role="dialog" aria-labelledby="TermsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title fw-bolder">Terms and Conditions</h4>
            </div>
            <div class="modal-body">
                <div class="modal-body text-left">
                    <h5 class="mb-3">1. WHAT YOU NEED TO KNOW</h5>
                    <p class="mb-2">If you don't understand these Terms and Conditions, it is your responsibility to ask a Khiosk representative for clarification before accepting them and/or continuing to use the Website.</p>
                    <p class="mb-2">These Terms and Conditions govern the sale of Products and the use of the Website.</p>
                    <p class="mb-2">By using the Website, you acknowledge that you have read and agree to be bound by these Terms and Conditions.</p>
                    <p class="mb-4">These Terms and Conditions apply to users who are consumers under the Consumer Protection Act, 68 of 2008 ("the CPA").</p>
                  
                    <h5 class="mb-3">2. REGISTERING, GUEST CHECKOUT & USING THE WEBSITE</h5>
                    <ul class="list-unstyled mb-4">
                      <li class="mb-2">Only registered users and/or guests (Guest Checkout) can purchase Products on the Website.</li>
                      <li class="mb-2">To register, you must provide a unique username, password, and personal details.</li>
                      <li class="mb-2">You agree not to share your username and password with any third party.</li>
                      <li class="mb-2">If you suspect unauthorized access to your account, you must notify Khiosk immediately.</li>
                      <li class="mb-2">You confirm that you are 18 years or older, or have parental consent if under 18.</li>
                      <li class="mb-2">You may not use the Website to distribute unlawful or harmful material.</li>
                      <li class="mb-2">You may not copy, display, or use the Website's content without prior written consent from Khiosk.</li>
                    </ul>
                  
                    <h5 class="mb-3">3. OWNERSHIP AND COPYRIGHT</h5>
                    <p class="mb-2">The contents of the Website, including material, software, text, images, logos, trademarks, etc., are protected by copyright and trademark laws.</p>
                    <p class="mb-2">The Website content is owned by Khiosk, its advertisers, or third parties. Any use of this content without prior consent is prohibited.</p>
                    <p class="mb-4">If content belongs to a third party, you agree to comply with their terms and conditions.</p>
                  
                    <h5 class="mb-3">4. DISCLAIMER</h5>
                    <p class="mb-2">Use of the Website is at your own risk. Khiosk is not responsible for any damage or loss resulting from using the Website.</p>
                    <p class="mb-2">Khiosk makes no warranties about the accuracy, reliability, or functionality of the Website or its content.</p>
                    <p class="mb-4">Khiosk does not guarantee that the Website is free from viruses, spyware, or other harmful elements.</p>
                  
                    <h5 class="mb-3">5. LIMITATION OF LIABILITY</h5>
                    <p class="mb-2">Khiosk is not liable for any direct, indirect, incidental, or consequential loss or damage arising from your use of the Website or linked third-party websites.</p>
                    <p class="mb-4">You agree to indemnify Khiosk against any claims arising from your use of the Website.</p>
                  
                    <h5 class="mb-3">6. PRIVACY POLICY</h5>
                    <p class="mb-2">Khiosk will take reasonable measures to protect your personal information. This information may include your name, email address, physical address, and phone number.</p>
                    <p class="mb-2">You agree to provide accurate and current information. Khiosk will not disclose your personal information to third parties unless required by law or necessary for order processing.</p>
                    <p class="mb-4">Khiosk may use your information to send marketing materials. You can unsubscribe at any time.</p>
                  
                    <h5 class="mb-3">7. CHANGES TO THESE TERMS AND CONDITIONS</h5>
                    <p class="mb-4">Khiosk may update these Terms at any time. The updated terms will apply once posted on the Website. By continuing to use the Website, you accept the updated terms.</p>
                  
                    <h5 class="mb-3">8. TERMS OF SALE</h5>
                    <p class="mb-2">Orders may be placed by registered users or guests as long as the Products are available.</p>
                    <p class="mb-2">An agreement of sale is made once payment is received and the Products are delivered.</p>
                    <p class="mb-2">Khiosk reserves the right to refuse or cancel a sale due to errors or omissions on the Website.</p>
                    <p class="mb-4">Prices may fluctuate due to factors such as exchange rates or taxes, but Khiosk will strive to ensure accurate pricing.</p>
                  </div>
              
              
                <div class="modal-footer d-flex justify-content-between align-items-start">
                    <div class="form-check me-3">
                    </div>
                    <button type="button" class="action-btn" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
