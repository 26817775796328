<div v-if="UserLoggedIn">
  <Influencer_navbar v-model:search_input="search_query" />
</div>
<div v-else>
  <Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
</div>
    <div v-if="search_query == ''" class="brands-grid justify-content-center">
      <div 
      v-for="(brand, index) in availableBrands" 
      :key="index" 
      class="brand-card justify-content-center align-items-center d-flex">
      <router-link :to="{ name: 'view-brands-products', params: { brandId: brand.id } }"
        class="product-link"
        @click="$emit('select-brand', brand.id)"
      >
        <div class="img-logo-container">
          <img 
            v-if="brand.logo" 
            :src="brand.logo" 
            :alt="brand.brand_name"
            class="img-logo bg-transparent"
            @load="handleImageLoad"
            @error="handleImageError"
          />
          <img 
          v-else 
          src="@/assets/images/img-error.png" 
          :alt="brand.brand_name"
          class="img-logo bg-transparent"
        />
        </div>
        <span class="brand-name text-center">
          {{ brand.brand_name }}
        </span>
      </router-link>
    </div>
    </div>

  <div v-else>
    <Search_Brand :search_query="search_query" />

</div>