import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue';
import Swal from 'sweetalert2';
import { get_user_details, get_token } from '@/router/index.js';

export default {
  name: 'Commission',
  components: {
    Admin_sidebar,
    Admin_navbar,
  },
  data: () => ({
    fundDistributionData: {},
    kioskFeeBrand: '',
    brandFee: '',
    kioskFeeInfluencer: '',
    influencerFee: '',
    kioskFeeBrandInfluencer: '',
    influencerFeeBrandInfluencer: '',
    brandFeeBrandInfluencer: '',
    hasBrand: false,  // Default to false, update based on your logic
    hasInfluencer: false,  // Default to false, update based on your logic
    token_exists: null,
    user_details: null,
  }),
  methods: {
    // Method to handle the distribution using fetch API
    async distributeFunds() {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      const DISTRIBUTE_FUNDS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_fund_distribution/`;
    
      // Ensure numeric values are sent instead of empty strings
      const data = {
        kiosk_fee_brand: this.kioskFeeBrand || '0',
        brand_fee: this.brandFee || '0',
        kiosk_fee_influencer: this.kioskFeeInfluencer || '0',
        influencer_fee: this.influencerFee || '0',
        kiosk_fee_brand_influencer: this.kioskFeeBrandInfluencer || '0',
        influencer_fee_brand_influencer: this.influencerFeeBrandInfluencer || '0',
        brand_fee_brand_influencer: this.brandFeeBrandInfluencer || '0',
      };
    
      try {
        const response = await fetch(DISTRIBUTE_FUNDS_API, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    
        const result = await response.json();
    
        if (!response.ok || !result.success) {
          throw new Error(result.message || 'Failed to distribute funds');
        }
    
        Swal.fire({
          icon: 'success',
          iconColor:'#F99E1B',
          title: 'Funds Distributed Successfully!',
          confirmButtonColor:'#000',
          text: result.message || 'Funds have been distributed successfully.',
        });
    
      } catch (error) {
        console.error('Error during funds distribution:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.message || 'An unexpected error occurred while distributing funds.',
        });
      }
    },
    

    async getFundDistribution() {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      const GET_FUND_DISTRIBUTION_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_fund_distribution_api/`;
    
      try {
        const response = await fetch(GET_FUND_DISTRIBUTION_API, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to retrieve fund distribution');
        }
    
        const responseData = await response.json();
        // Update your frontend state with the retrieved fund distribution data
        this.fundDistributionData = responseData;
        // Update form fields with retrieved data
        this.kioskFeeBrand = this.fundDistributionData.kiosk_fee_brand;
        this.brandFee = this.fundDistributionData.brand_fee;
        this.kioskFeeInfluencer = this.fundDistributionData.kiosk_fee_influencer;
        this.influencerFee = this.fundDistributionData.influencer_fee;
        this.kioskFeeBrandInfluencer = this.fundDistributionData.kiosk_fee_brand_influencer;
        this.influencerFeeBrandInfluencer = this.fundDistributionData.influencer_fee_brand_influencer;
        this.brandFeeBrandInfluencer = this.fundDistributionData.brand_fee_brand_influencer;
      } catch (error) {
        console.error('Error retrieving fund distribution:', error);
      }
    },
  },
  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();

    if (!this.token_exists || (this.user_details.user_role !== 'ADMIN')) {
      localStorage.removeItem('token'); // Clear the token
      this.$router.push('/login'); // Redirect to home page
    }else{
      this.getFundDistribution();
    }
  },
};