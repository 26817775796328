/* global $ */
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue'
import Fullscreen_navbar from '@/components/Home/Fullscreen_Navbar/FullscreenNavbarView.vue';
import Swal from 'sweetalert2';
import { get_user_details, get_token } from '@/router/index.js';
import Hls from 'hls.js';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
    Filter_product,
    Fullscreen_navbar,
  },
  data() {
    return {
      form: { first_name: '', last_name: '', email: '', phone_number: '', bio: '', profileImage:'' },
      view_form: { id:null,account_holder: '', bank__name: '', bank__branch_code: '', account_number: '', account_type__account_type: '' },
      videos: [],
      videosWithoutProducts : [],
      videosWithProducts : [],
      showEditProfileModal: false,
      profileImage: '',
      hoveredVideo: null,
      visibleCards: 6,
      currentPosition: 0,
      showDetails: [],
      search_query: "",
      allProducts: [] ,
      showOptions: false,
      token_exists: null, // Store token existence
      user_details: null, // Store user details
      selectedPupName: '',
      selectedPoint: null,
      pending_balance: 0,
      total_balance: 0,
      paymentHistory: [],
      moneyInHistory: [],
      moneyOutHistory: [],
      pargoIframeSrc: process.env.VUE_APP_PARGO_DROPOFF_IFRAME_SRC,
      currentPipProducts: [],
      isInPiPMode: false,
      reelMuteStates: {},
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
      debounceTimer: null,
      productUploaded: false,
      productPreviewUrl: null,
      product_name: '',
      description: '',
      price: '', 
      selectedSize: null,
      sizes: [],
      uploadedProducts: [],
      selectedVideoId: null,
      notUpdatedMessage: '',
      timer: null,
      popupVisible : false,
      popupShown: false,
      currentTab: 'content',
      showProductNameError: false,
      showDescriptionError: false,
      showPriceError: false,
      showSizeError: false,
      showImageError: false,
      showShareMenu: false,
      profileUrl: window.location.href,
      showWithdrawModal: false,
      showEditBankingModal: false,
      withdrawAmount: "",
      banks: [],
      account_types: [],
      showWithdrawError: false,
      purchasesCount: 0,
      likedVideos: [],
      loading:false,

    };
  },
  computed: {
    selectedBankName() {
      return this.banks.find(b => b.id === this.view_form.bank_id)?.name || '';
    },
    selectedAccountType() {
      return this.account_types.find(t => t.id === this.view_form.account_type)?.account_type || '';
    },
    profileUrl() {
      return this.baseUrl + this.userId;
    },

    ...mapGetters('auth', ['isImpersonating', 'impersonatedUserId']),
  
    filteredProducts() {
      if (!this.search_query) return this.allProducts;

      const query = this.search_query.toLowerCase();
      return this.allProducts.filter(product => {
        const { product_name = '', brand__brand_name = '', description = '', category__category_name = '' } = product.product || {};

        return [product_name, brand__brand_name, description, category__category_name]
          .some(field => field.toLowerCase().includes(query));
      });
    },
    },
  methods: {
    openModal() {
      this.showWithdrawModal = true;
      this.fetch_banking_details();
    },

    openEditBankingModal() {
      this.fetch_banking_details();
      if (!this.view_form.id) {
        this.view_form = {
          id: '',
          account_holder: '',
          bank_id: '',
          account_number: '',
          account_type_id: '',
          branch_code: ''
        };
      }
      this.showEditBankingModal = true;
    },

    closeEditBankingModal() {
      this.showEditBankingModal = false;
    },
    async fetchInfluencerStats() {
      const FETCH_INFLUENCER_STATS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/view_influencer_orders_api/`;
      try {
        const response = await fetch(FETCH_INFLUENCER_STATS_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch influencer stats');
        }
    
        const data = await response.json();
    
        if (data.status === "success") {
          const influencerId = Object.keys(data.purchases_count)[0];
          this.purchasesCount = data.purchases_count[influencerId];
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch influencer stats.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching influencer stats:', error);
      }
    },
    async fetch_banks() {
      const BANKS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banks/`;

      try {
        const response = await fetch(BANKS_API);

        if (!response.ok) {
          console.error('Failed to fetch banks, status:', response.status);
          throw new Error('Failed to fetch banks');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.banks = data;
        } else {
          console.error("Expected an array of banks, got:", data);
        }
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    },
    async save_banking_details() {
      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire('Error', 'Not authenticated', 'error');
        return;
      }
    
      try {
        const ADD_BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/add_banking_details/`;
        
        const response = await fetch(ADD_BANKING_DETAILS_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(this.view_form),
        });
    
        const data = await response.json();
    
        if (!response.ok) {
          throw new Error(data.message || 'Failed to add banking details');
        }
    
        // Refresh the banking details after saving
        await this.fetch_banking_details();
    
        Swal.fire({
          title: 'Success',
          text: 'Banking details saved successfully!',
          icon: 'success',
          iconColor: '#F99E1B',
          confirmButtonColor: '#000',
          confirmButtonText: 'OK'
        }).then(() => {
          this.closeEditBankingModal();
        });
    
      } catch (error) {
        console.error('Save error:', error);
        Swal.fire('Error', error.message, 'error');
      }
    },
    
    async saveOrUpdateBankingDetails() {
      if (this.view_form.id) {
        await this.update_banking_details();
      } else {
        await this.save_banking_details();
      }
    },
    updateSelectedBankName() {
      const selectedBank = this.banks.find(bank => bank.id === this.view_form.bank_name);
      this.selectedBankName = selectedBank ? selectedBank.name : '';
    },
  
    async update_banking_details() {
      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire('Error', 'Not authenticated', 'error');
        return;
      }
      if (!this.view_form || !this.view_form.id) {
        console.warn('No banking details selected, skipping update.');
        return;
      }
      
      try {
        const UPDATE_BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_banking_details/${this.view_form.id}/`;
    
        const response = await fetch(UPDATE_BANKING_DETAILS_URL, {
          method: 'POST', // Keeping it as POST
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(this.view_form),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.detail || errorData.message || Object.values(errorData).flat().join(', ');
          throw new Error(errorMessage);
        }
    
        // Refresh data after update
        await this.fetch_banking_details();
    
        Swal.fire({
          title: 'Success',
          text: 'Banking details updated!',
          icon: 'success',
          iconColor: '#F99E1B',
          confirmButtonColor: '#000',
          confirmButtonText: 'OK'
        }).then(() => {
          this.closeEditBankingModal(); // Close modal instead of reloading the page
        });
    
      } catch (error) {
        console.error('Update error:', error);
        Swal.fire('Error', error.message, 'error');
      } 
    },
    
    async processWithdraw() {
      if (this.withdrawAmount > this.total_balance) {
        this.showWithdrawError = true;
        return;
      }
      this.showPriceError = false;

      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire('Error', 'Not authenticated', 'error');
        return;
      }
    
      if (!this.withdrawAmount || this.withdrawAmount <= 0) {
        Swal.fire('Error', 'Invalid withdrawal amount', 'error');
        return;
      }
    
      try {
        const WITHDRAWAL_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/initiate_withdrawal_api/`;
    
        const response = await fetch(WITHDRAWAL_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({
            amount: this.withdrawAmount,
            bank_detail_id: this.view_form.id
          }),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.detail || errorData.message || Object.values(errorData).flat().join(', ');
          throw new Error(errorMessage);
        }
    
        Swal.fire({
          title: 'Success',
          text: 'Withdrawal initiated!',
          icon: 'success',
          iconColor: '#F99E1B',
          confirmButtonColor: '#000',
          confirmButtonText: 'OK'
        }).then(() => {
          this.closeWithdrawModal(); // Close modal
        });
    
      } catch (error) {
        console.error('Withdrawal error:', error);
        Swal.fire('Error', error.message, 'error');
      }
    },

    async fetch_account_types() {
      const ACCOUNT_TYPES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_account_types/`;

      try {
        const response = await fetch(ACCOUNT_TYPES_API);

        if (!response.ok) {
          console.error('Failed to fetch account types, status:', response.status);
          throw new Error('Failed to fetch account types');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.account_types = data;
        } else {
          console.error("Expected an array of types, got:", data);
        }
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    },
    async fetch_banking_details() {
      const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banking_details/`;
      const token = localStorage.getItem('token');
    
      try {
        const response = await fetch(BANKING_DETAILS_URL, {
          headers: { 'Authorization': `Token ${token}` }
        });
    
        if (response.ok) {
          const data = await response.json();
          const matchedBank = this.banks.find(b => b.id === data.bank_id);
    
          const branchCode = data.branch_code || 
                            data.bank__branch_code || 
                            matchedBank?.branch_code || '';
    
          // Update view_form with ALL fields
          this.view_form = {
            id: data.id,
            account_holder: data.account_holder,
            bank_id: data.bank_id,
            branch_code: branchCode, // Derived from bank_id
            account_number: data.account_number,
            masked_account_number: data.account_number.slice(-4), // Slice the account number
            account_type_id: data.account_type_id, // Ensure this field is included
            account_type_name: this.account_types.find(type => type.id === data.account_type_id)?.account_type,
            bank__name: data.bank_name // Ensure this field is included
          };
          this.banking_details = data;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    toggleShareMenu() {
      this.showShareMenu = !this.showShareMenu;
    },
    copyProfileLinkToClipboard() {
      navigator.clipboard.writeText(this.profileUrl).then(() => {
        alert("Profile link copied to clipboard!");
      });
    },
    update_search_query(query) {
      this.search_query = query;
    },

    updateBio(value) {
      if (value.length <= 150) {
        this.form.bio = value;
      } else {
        this.form.bio = value.substring(0, 150);
      }
    },

    filter_product_list(data) {
      this.allProducts = data;
      this.filteredProducts = data;

      },
      fetchSearchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
    
        fetch(FETCH_PRODUCT_URL)
          .then(response => response.json())
          .then(data => {
            this.allProducts = data;
          })
          .catch(error => console.error('Error fetching products:', error));
      },
    
      filterProducts() {
        const query = this.search_query.toLowerCase();
        this.filteredProducts = [...this.allProducts];
    
        if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts = this.filteredProducts.filter((product) => {
            const match = (
              search.test(product.product.product_name) ||
              search.test(product.product.brand__brand_name) ||
              search.test(product.product.description) ||
              search.test(product.product.category__category_name)
            );
            return match;
          });
        }
      },
    initializeShowDetails() {
      this.showDetails = new Array(this.videos.length).fill(false);
    },
    toggleDetails(index) {
      this.showDetails[index] = !this.showDetails[index];
    },
    toggleOverlay(index, isHovering) {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!this.showDetails[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    },
   
    scrollCards(direction) {
      const maxScroll = this.brand_videos.length - this.visibleCards;
      if (direction === 'right' && this.currentPosition < maxScroll) {
        this.currentPosition++;
      } else if (direction === 'left' && this.currentPosition > 0) {
        this.currentPosition--;
      }
    },
    async updateUser() {
      const formData = new FormData();
      
      // Append form fields to FormData
      formData.append('first_name', this.form.first_name);
      formData.append('last_name', this.form.last_name);
      formData.append('email', this.form.email);
      formData.append('phone_number', this.form.phone_number || "");
      formData.append('bio', this.form.bio);
      formData.append('user_id', localStorage.getItem('user_id'));
    
      // Check if a profile image was selected, and append it to FormData
      if (this.form.profileImage) {
        formData.append('profile_image', this.form.profileImage);  // Ensure the key matches backend expectations
      }
    
      try {
        const token = localStorage.getItem('token');
        const UPDATE_PROFILE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/update_profile/`;
    
        const response = await fetch(UPDATE_PROFILE_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            // Do not set 'Content-Type' manually for FormData; the browser will set it automatically
          },
          body: formData,  // Send FormData instead of JSON
        });
    
        if (!response.ok) {
          throw new Error('Failed to update user data');
        }
    
        const data = await response.json();
    
        if (data.status === "success") {
          Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            text: data.message,
            title: 'Success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#000',
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Failed to update user data');
        }
      } catch (error) {
        console.error('Error updating user data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to update user data.',
          icon: 'error',
          iconColor:'#F99E1B',
          confirmButtonText: 'OK',
          confirmButtonColor: '#000',
        });
      }
    },
    

    async openEditProfileModal() {
      await this.fetchUserData();
      this.showEditProfileModal = true;
    },

    async fetchUserData() {
      try {
        this.loading = true;

        const token = localStorage.getItem('token');
        const isImpersonating = this.isImpersonating; // Get from Vuex getters
        const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
        let GET_USER_INFO = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`;
        if (isImpersonating && impersonatedUserId) {
          GET_USER_INFO += `?impersonate=true&user_id=${impersonatedUserId}`;
        }
    
        const response = await fetch(GET_USER_INFO, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();

        if (data.status === "success") {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone_number = data.phone_number;
          this.form.bio = data.bio || ''; 
          this.profileImage = data.image; 
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch user data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }finally {
        this.loading = false; // Hide the loader
      }
    },
    

    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const isImpersonating = this.isImpersonating; // Get from Vuex getters
        const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
        let VIEW_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_content/`;

        if (isImpersonating && impersonatedUserId) {
          VIEW_CONTENT_URL += `?impersonate=true&user_id=${impersonatedUserId}`;
        }
        const response = await fetch(VIEW_CONTENT_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }
        const data = await response.json();
        this.videosWithProducts = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    async fetchDraftVideos() {
      try {
        const token = localStorage.getItem('token');
        const isImpersonating = this.isImpersonating; // Get from Vuex getters
        const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
        let VIEW_DRAFT_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_draft_video_content_api/`;

        if (isImpersonating && impersonatedUserId) {
          VIEW_DRAFT_CONTENT_URL += `?impersonate=true&user_id=${impersonatedUserId}`;
        }
        
        const draftResponse = await fetch(VIEW_DRAFT_CONTENT_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
    
        if (!draftResponse.ok) {
          throw new Error('Failed to fetch draft videos');
        }
        const draftData = await draftResponse.json();
        this.videosWithoutProducts = draftData;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('modalProfileImage');
          if (img) {
            img.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
        this.form.profileImage = file;
      }
    },
    

    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    },
    closeModal() {
      this.showEditProfileModal = false;
      this.showWithdrawModal = false;

    },

      async deleteVideo(video_id) {
      
        const token = localStorage.getItem('token');
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          iconColor:'#F99E1B',
          showCancelButton: true,
          confirmButtonColor: '#000', 
          cancelButtonColor: '#F99E1B',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Deleting Video',
              text: 'Please wait while the video is being deleted...',
              allowOutsideClick: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
            try {
              const isImpersonating = this.isImpersonating; // Get from Vuex getters
              const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
              let DELETE_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/delete_influencer_video/${video_id}`;

              if (isImpersonating && impersonatedUserId) {
                DELETE_VIDEO_URL += `?impersonate=true&user_id=${impersonatedUserId}`;
              }
    
              const response = await $.ajax( {
                url: DELETE_VIDEO_URL,
                method: 'POST',
                headers: {
                  'Authorization': `Token ${token}`,
                  'Content-Type': 'application/json',

                }
              });
              if (response.status === "success") {
                Swal.fire(
                  'Deleted!',
                  'The video has been deleted.',
                  'success'
                ).then(() => {
                  window.location.reload();
                });
              } else {
                throw new Error('Deletion failed');
              }
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: 'Deletion Failed',
                text: 'Failed to delete video. Please try again.',
              });
            }
          }
        });
      },
      handleIframeMessage(event) {
        if (event.data && event.data.addressSms) {
          this.selectedPoint = event.data;
        }
      },
      async fetchWalletInfo() {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_wallet_info_api/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch wallet info');
          }
      
          const data = await response.json();
          this.pending_balance = data.pending_amount.toFixed(2);
          this.total_balance = data.total_balance.toFixed(2);
        } catch (error) {
          console.error('Error fetching wallet info:', error);
        }
      },
      async AllPaymentHistory() {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_payment_history_api/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch payment history');
            }
            const data = await response.json();
            this.paymentHistory = data.payment_history;
        } catch (error) {
            console.error('Error fetching payment history:', error);
        }
    },
    async MoneyInHistory() {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_money_in_history_api/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch money in history');
        }
    
        const data = await response.json();
        this.moneyInHistory = data.payment_history;
      } catch (error) {
        console.error('Error fetching money in history:', error);
      }
    },
    
      initializeDataTable() {
        this.$nextTick(() => {
            if ($.fn.DataTable.isDataTable('#content_table_all')) {
                $('#content_table_all').DataTable().destroy(); // Destroy existing table
            }
            
            if (this.paymentHistory && this.paymentHistory.length > 0) {
                $('#content_table_all').DataTable({
                    pageLength: 10,
                    lengthMenu: [10, 25, 50, 100],
                    searching: true,
                    ordering: true,
                    paging: true,
                    info: true,
                    autoWidth: false,
                });
            }
        });
    },
    initializeMoneyInDataTable() {
        this.$nextTick(() => {
            if ($.fn.DataTable.isDataTable('#content_table_completed')) {
                $('#content_table_money_in').DataTable().destroy();
            }
            if (this.moneyInHistory && this.moneyInHistory.length > 0) {
            $('#content_table_money_in').DataTable({
                pageLength: 10,
                lengthChange: false,
                searching: true,
                ordering: true,
                paging: true,
                info: true,
                autoWidth: false,
            });
          }
        });
    },
    initializeMoneyOutDataTable() {
      this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#content_table_completed')) {
              $('#content_table_money_out').DataTable().destroy();
          }
          if (this.moneyInHistory && this.moneyInHistory.length > 0) {
          $('#content_table_money_out').DataTable({
              pageLength: 10,
              lengthChange: false,
              searching: true,
              ordering: true,
              paging: true,
              info: true,
              autoWidth: false,
          });
        }
      });
   },

   async fetchReelData() {
    // Prevent multiple fetches if we're already loading or have all data
    if (this.isFetching || this.allDataLoaded) return;
  
    try {
      this.apiError = false;
      this.isFetching = true;
  
      const token = localStorage.getItem('token');
      const impersonate = this.$route.query.impersonate;
      const impersonatedUserId = localStorage.getItem('influencer_id');
  
      // Base endpoints
      let FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_content/?page=${this.page}&limit=${this.limit}`;
      let FETCH_DRAFT_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_draft_video_content_api/`;
      let FETCH_LIKED_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_user_liked_videos/?page=${this.page}&limit=${this.limit}`;
  
      // If impersonating another user, add query params
      if (impersonate === 'true' && impersonatedUserId) {
        FETCH_VIDEOS_URL += `&impersonate=true&user_id=${impersonatedUserId}`;
        // For draft videos, add ?impersonate=...
        FETCH_DRAFT_VIDEOS_URL += `?impersonate=true&user_id=${impersonatedUserId}`;
      }
  
      // Decide which endpoint to call based on currentTab
      if (this.currentTab === 'drafts') {
        // 1. DRAFTS
        const response = await fetch(FETCH_DRAFT_VIDEOS_URL, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // If you want to show them in "drafts" tab referencing videosWithoutProducts
        // you can just store them there. If you want pagination, push them.
        if (data.length === 0) {
          this.allDataLoaded = true;
        } else {
          // Overwrite or push? Adjust as needed.
          this.videosWithoutProducts.push(...data);
          this.page++;
        }
  
        // Reinitialize players/observers after rendering
        this.$nextTick(() => {
          this.initializeVideoPlayers();
          this.initializeVideoObserver();
        });
  
      } else if (this.currentTab === 'content') {
        // 2. CONTENT
        const response = await fetch(FETCH_VIDEOS_URL, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // Suppose "content" means videos with products:
        const filteredData = data.filter(
          (video) => video.products && video.products.length > 0
        );
  
        if (filteredData.length === 0) {
          this.allDataLoaded = true;
        } else {
          this.videosWithProducts.push(...filteredData);
          this.page++;
  
          this.$nextTick(() => {
            this.initializeVideoPlayers();
            this.initializeVideoObserver();
          });
        }
  
      } else if (this.currentTab === 'liked-videos') {
        // 3. LIKED VIDEOS
        const response = await fetch(FETCH_LIKED_VIDEOS_URL, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        // Example: only show liked videos that have products
        const filteredData = data.filter(
          (video) => video.products && video.products.length > 0
        );
  
        if (filteredData.length === 0) {
          this.allDataLoaded = true;
        } else {
          // If you want them in a separate array (like "likedVideos"), do:
          this.likedVideos.push(...filteredData);
          this.page++;
  
          this.$nextTick(() => {
            this.initializeVideoPlayers();
            this.initializeVideoObserver();
          });
        }
      }
    } catch (error) {
      console.error('Error fetching reel data:', error);
      this.apiError = true;
    } finally {
      this.isFetching = false;
    }
  },

  initializeVideoPlayers() {
    this.reels.forEach((reel, idx) => {
      // find DOM container: <div ref=\"videoPlayer_idx\">
      const reelRef = this.$refs['videoPlayer_' + idx];
      const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
      if (!reelContainer || this.players[idx]) return; // already inited?

      const videoEl = reelContainer.querySelector('video');
      if (!videoEl) return;

      videoEl.setAttribute('playsinline', 'true');
      videoEl.autoplay = true;
      videoEl.addEventListener('loadedmetadata', () => {
        // start playing after loadedmetadata
        videoEl.play();
      });

      // attach HLS if .m3u8
      this.initializeHlsOrMp4(videoEl, reel, idx);

      // store reference
      this.players[idx] = videoEl;
    });
  },

  initializeHlsOrMp4(videoEl, reel, idx) {
    const hlsUrl = reel.final_processed_url;
    const mp4Url = reel.video_url;
    console.log(`HLS URL: ${hlsUrl}, MP4 URL: ${mp4Url}`);

    if (hlsUrl && hlsUrl.endsWith('.m3u8')) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(hlsUrl);
        hls.attachMedia(videoEl);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log(`[HLS] manifest loaded for reel ${idx}`);
        });
        hls.on(Hls.Events.ERROR, (evt, data) => {
          console.error("HLS Error:", data);
        });
      } else if (videoEl.canPlayType('application/vnd.apple.mpegurl')) {
        // Safari
        videoEl.src = hlsUrl;
      } else {
        // fallback
        videoEl.src = mp4Url;
      }
    } else {
      // not an .m3u8 link, fallback
      videoEl.src = mp4Url;
    }
  },

  initializeVideoObserver() {
  
    if (!('IntersectionObserver' in window)) {
      console.warn("No IntersectionObserver support");
      return;
    }
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const idx = parseInt(entry.target.dataset.index, 10);
  
        if (Number.isNaN(idx)) {
          console.warn('Invalid index detected');
          return;
        }
  
        const videoEl = this.players[idx];
        if (!videoEl) {
          console.warn(`No video element found for index: ${idx}`);
          return;
        }
  
        if (entry.isIntersecting) {
          this.pauseOtherVideos(idx);
  
          videoEl.setAttribute('playsinline', 'true');
          
          videoEl.play().then(() => {
          }).catch(err => {
            console.warn(`Autoplay prevented for video ${idx}:`, err);
          });
  
          this.currentPlayingIndex = idx;
        } else {
          videoEl.pause();
          videoEl.currentTime = 0;
        }
      });
    }, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Video should be 70% visible to play
    });
  
    // Observe each video
    this.reels.forEach((_, idx) => {
      const reelRef = this.$refs['videoPlayer_' + idx];
      const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
      if (!reelContainer) {
        console.warn(`No container found for video index: ${idx}`);
        return;
      }
      const videoEl = reelContainer.querySelector('video');
      if (videoEl) {
        videoEl.dataset.index = idx;
        observer.observe(videoEl);
    
        videoEl.addEventListener('play', () => {
          this.pauseOtherVideos(idx);
        });
    
        videoEl.addEventListener('click', () => {
          this.pauseOtherVideos(idx);
          videoEl.play(); // Ensure the clicked video plays
        });
      }
    });
    
  },
  
  pauseOtherVideos(index) {
    if (!Array.isArray(this.players)) {
      console.error("this.players is not an array!", this.players);
      return;
    }
  
    this.players.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });
  },
  openFullScreen(videoId, index, videList) {
    if (event.target.closest('.add-product-btn')) return;
    if (event.target.closest('.dropdown')) return;
  
  
    if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
      this.players[this.currentPlayingIndex].pause();
    }
  
    this.originalReels = [...videList];
  
    if (!this.originalReels || !this.originalReels[index]) {
      console.error("Invalid index or reels not loaded");
      return;
    }
  
    this.fullScreenReels = [...this.originalReels];
    this.isFullScreenMode = true;
    this.fullScreenVideoIndex = index;
    this.currentPlayingIndex = index;
  
    window.addEventListener("wheel", this.handleScrollInFullScreen, { passive: false });
  
    this.$nextTick(() => {
      this.playVideoAtIndex(index);
    });
  },

  preloadVideo(index) {
    if (this.reels?.[index]) {
      let video = this.players?.[index];
      if (video && video.readyState < 4) {
        video.load(); // Preload video
      }
    }
  },

  navigateFullScreen(direction) {
    if (!this.isFullScreenMode) return;
    const newIndex = Math.min(
      Math.max(this.fullScreenVideoIndex + direction, 0),
      this.fullScreenReels.length - 1
    );
    if (newIndex === this.fullScreenVideoIndex) return;
    const prevIndex = this.fullScreenVideoIndex;
    this.fullScreenVideoIndex = newIndex;
    this.currentPlayingIndex = newIndex;
    if (this.players[prevIndex]) {
      this.players[prevIndex].pause();
    }
    this.$nextTick(() => {
      this.playVideoAtIndex(newIndex);
    });
  },

  handleScroll(index) {
    const video = document.querySelector(`video[data-index="${index}"]`);
    if (!video) {
      console.warn(`No video found at index ${index}`);
      return;
    }
  
    const rect = video.getBoundingClientRect();
  
    const isVisible =
      rect.top < window.innerHeight * 0.5 && rect.bottom > window.innerHeight * 0.5;
  
    if (isVisible) {
      this.pauseOtherVideos(index);
    } else {
      video.pause();
    }
  },

  playVideoAtIndex(index) {
    // Check network connectivity before attempting playback
    if (!navigator.onLine) {
      console.warn(`No network connection; video playback is disabled for index ${index}`);
      this.handleVideoError(index);
      return;
    }
  
    // Clear previous error states
    this.videoErrors = {};
  
    // Pause all other videos and reset their time
    this.fullScreenReels.forEach((_, i) => {
      const videoEl = Array.isArray(this.$refs[`videoPlayer_${i}`])
        ? this.$refs[`videoPlayer_${i}`][0]
        : this.$refs[`videoPlayer_${i}`];
  
      if (videoEl && i !== index) {
        const videoElement = videoEl.querySelector("video");
        if (videoElement) {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }
    });
  
    // Get the video element for the given index
    const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`])
      ? this.$refs[`videoPlayer_${index}`][0]
      : this.$refs[`videoPlayer_${index}`];
  
    if (videoEl) {
      const videoElement = videoEl.querySelector("video");
      if (videoElement) {
        videoEl.addEventListener("scroll", () => {	        // Set optimized video attributes
          this.reels.forEach((_, index) => this.handleScroll(index));	
        });	
        videoEl.addEventListener("touchmove", () => {	
          this.reels.forEach((_, index) => this.handleScroll(index));	
        });

        // Set optimized video attributes
        videoElement.setAttribute("playsinline", "true");
        videoElement.setAttribute("autoplay", "true");
        videoElement.setAttribute("preload", "auto");
        videoElement.setAttribute("controls", "true");
        // Do not force native controls; Video.js is handling them
        videoElement.controls = true;
        videoElement.scrollIntoView({ behavior: "instant", block: "center" });
        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error("Autoplay prevented:", error);
            this.handleVideoError(index);
          });
        }
  
        videoElement.addEventListener("ended", () => {
          videoElement.currentTime = 0; // Reset video to start
          videoElement.play(); // Play the video again
        });
        
        
      }
    }
  
    // Remove the error state for this video
    delete this.videoErrors[index];
  },
  // If a reel fails to load
  handleVideoError(idx) {
    this.videoErrors = { ...this.videoErrors, [idx]: true };
    console.error(`Video ${idx} failed to load`);
  },

  retryVideo(idx) {
    // reset error, re-initialize
    delete this.videoErrors[idx];
    const reel = this.reels[idx];
    if (!reel) return;
    const reelRef = this.$refs['videoPlayer_' + idx];
    const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
    if (!reelContainer) return;
    const videoEl = reelContainer.querySelector('video');
    if (!videoEl) return;
    this.initializeHlsOrMp4(videoEl, reel, idx);
  },

  // Infinite Scroll sentinel
  initializeInfiniteScroll() {
    this.$nextTick(() => {
      const sentinel = this.$refs.scrollSentinel;
      if (!sentinel) return;
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !this.isFetching) {
          this.fetchReelData();
        }
      }, {
        root: null,
        rootMargin: '100px',
        threshold: 0,
      });
      observer.observe(sentinel);
    });
  },

  handleScrollInFullScreen(event) {
    if (!this.isFullScreenMode) return;
    event.preventDefault();
    const direction = event.deltaY > 0 ? 1 : -1;
    if (!this.scrollLock) {
      this.scrollLock = true;
      this.navigateFullScreen(direction);
      setTimeout(() => {
        this.scrollLock = false;
      }, 500);
    }
  },

  exitFullScreen() {
    this.isFullScreenMode = false;
    this.fullScreenReels = [];
    this.fullScreenVideoIndex = 0;
    this.currentPlayingIndex = null;
    window.removeEventListener("wheel", this.handleScrollInFullScreen);
  },

  // PiP logic if needed
  async enterPictureInPicture(reel, index) {
    const videoElement = document.getElementById(`video-${reel.id}`);
    if (!videoElement) {
      console.error('Video element not found');
      return;
    }
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      }
      await videoElement.requestPictureInPicture();
      this.isInPiPMode = true;
      this.pipVideoIndex = index;
      this.currentPipProducts = reel.products.filter((p) => p.product_details);
      videoElement.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
    } catch (error) {
      console.error('Failed to enter PiP:', error);
    }
  },

  handleLeavePictureInPicture() {
    this.isInPiPMode = false;
    if (this.pipVideoIndex !== null) {
      const reel = this.reels[this.pipVideoIndex];
      if (reel) {
        this.$router.go(-1);
        this.$nextTick(() => {
          this.openFullScreen(reel, this.pipVideoIndex);
        });
      }
    }
  },
    openProductUploadModal(video_id) {
      this.selectedVideoId = video_id;

      $('#productUploadModal').modal('show'); // Opens the modal
    },
    onSizeChange() {
      this.showSizeError = !this.selectedSize; // Hide error when a valid size is selected
    },
    
    uploadImage() {
      const product_name = $('#productname').val();
      const description = $('#condition').val();
      const price = $('#amount').val().trim();
      const imageFileInput = $('#product_url')[0];
      const imageFile = imageFileInput.files[0];
      const size = [{ id: this.selectedSize }];

      // Validation checks
      let isValid = true;

      if (!product_name) {
        this.showProductNameError = true;
        isValid = false;
      } else {
        this.showProductNameError = false;
      }
    
      if (!description) {
        this.showDescriptionError = true;
        isValid = false;
      } else {
        this.showDescriptionError = false;
      }
    
      if (!price || isNaN(price)) {
        this.showPriceError = true;
        isValid = false;
      } else {
        this.showPriceError = false;
      }
    
      if (!this.selectedSize) {
        this.showSizeError = true;
        isValid = false;
      } else {
        this.showSizeError = false;
      }
    
      if (!imageFile) {
        this.showImageError = true;
        isValid = false;
      } else {
        this.showImageError = false;
      }
    
      // Stop execution if validation fails
      if (!isValid) {
        return;
      }

      const productPreviewUrl = URL.createObjectURL(imageFile);

      this.uploadedProducts.push({
      product_name,
      description,
      price,
      imageFile,
      productPreviewUrl, // Store the preview URL for display
      size,
      });

      // Reset the form and variables
      $('#uploadImageForm')[0].reset();
      this.productUploaded = false;
      this.productPreviewUrl = null;
      $('#productPreview').attr('src', require('@/assets/images/no-image.png')); // Reset to placeholder image
      this.selectedSize = null; // Reset the selected size
    },
  
    previewProductImage(event) {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        this.productPreviewUrl = url;
        this.productUploaded = true;
      } else {
        // If no file is selected, reset the preview
        this.productPreviewUrl = null;
        this.productUploaded = false;
      }
    },

    async fetchSizes() {
      const API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_sizes/`;
    
      try {
        const response = await fetch(API_URL, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
    
        if (!response.ok) {
          console.error('Failed to fetch sizes, status:', response.status);
          throw new Error('Failed to fetch sizes');
        }
    
        const data = await response.json();
        if (Array.isArray(data)) {
          // Use a Set to filter out duplicates based on the 'size' property
          const uniqueSizes = Array.from(new Set(data.map(size => size.size)))
            .map(size => data.find(s => s.size === size));
          this.sizes = uniqueSizes;
        } else {
          console.error("Expected an array of sizes, got:", data);
        }
      } catch (error) {
        console.error('Error fetching sizes:', error);
      }
    },
    getSize(sizeId) {
      const size = this.sizes.find(s => s.id === sizeId);
      return size ? size.size : ''; // Replace 'size' with the appropriate field if necessary
    },
 

    removeUploadedProduct(product) {
      this.uploadedProducts = this.uploadedProducts.filter(p => p !== product);
    },
    async uploadProducts(selectedVideoId) {
      if (this.uploadedProducts.length === 0) {
        alert("No products to upload");
        return;
      }

      Swal.fire({
        title: 'Uploading...',
        allowOutsideClick: false, 
        didOpen: () => {
          Swal.showLoading();
        }
      });
    
      try {
        const token = localStorage.getItem('token');
        const UPLOAD_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/influencer_products/`;
        const uploadPromises = this.uploadedProducts.map(product => {
          const formData = new FormData();
          formData.append('image_urls', product.imageFile);
          formData.append('description', product.description);
          formData.append('product_name', product.product_name);
          formData.append('price', (parseFloat(product.price) * 1.08).toFixed(2)); // Add 8% service fee
          formData.append('sizes', product.size[0].id);
          formData.append('video_id', selectedVideoId); // Correct variable name used
    
          return fetch(UPLOAD_IMAGE_URL, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          });
        });
    
        // Wait for all the promises to complete
        const responses = await Promise.all(uploadPromises);
    
        if (responses.every(response => response.ok)) {
          Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            title: 'Upload Successful',
            text: 'Your video and products have been uploaded successfully!',
            confirmButtonColor:'#000',
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          iconColor:'#F99E1B',
          title: 'Upload Failed',
          text: 'Failed to upload video or products. Please try again.',
        });
      }
    },
    async checkUserDetails() {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      try {
        const GET_INFLUENCER_PRODUCTS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_products_api/`;
        const responseProducts = await fetch(GET_INFLUENCER_PRODUCTS_API, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
        });
        const data = await responseProducts.json();
    
        if (data.products) {
          this.products = data.products;
        }
    
        if (this.products.length > 0) {
          // Get drop-off point
          const SELLER_DROP_OFF_POINT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/seller_drop_off_point/${user_id}/`;
          const responseDropOffPoint = await fetch(SELLER_DROP_OFF_POINT_API, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            },
          });
          const dropOffPointData = await responseDropOffPoint.json();
    
          // Check if drop-off point is present
          if (dropOffPointData.error) {
            this.notUpdatedMessage = 'Please add drop off point on your profile.';
            if (!this.popupShown) {
              this.popupVisible = true;
              setTimeout(() => {
                this.popupVisible = false;
                this.popupShown = true;
              }, 2000); // hide the popup after 5 seconds
            }
          } 
        }
      } catch (error) {
        console.error(error);
      }
    },

    async changeTab(tab) {
      this.currentTab = tab;
      this.reels = []; // Reset reels array
      this.page = 1; // Reset page number
      this.allDataLoaded = false; // Reset allDataLoaded flag
      await this.fetchReelData(); // Fetch new data
    },

    
    async deleteProduct(image_id) {
      const token = localStorage.getItem('token');
      
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        iconColor:'#F99E1B',
        showCancelButton: true,
        confirmButtonColor: '#000',
        cancelButtonColor: '#000',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting Image',
            text: 'Please wait while the image is being deleted...',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
    
          try {
            // Fix the double slashes in the URL
            const DELETE_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/delete_image/${image_id}`;
    
            // Perform the DELETE request
            const response = await $.ajax({
              url: DELETE_IMAGES_URL,
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${token}`
              }
            });
    
            // Since jQuery's $.ajax doesn't return `response.ok`, check the response directly
            if (response) {
              Swal.fire(
                'Deleted!',
                'The image has been deleted.',
                'success'
              ).then(() => {
                window.location.reload();  // Reload the page after deletion
              });
            } else {
              throw new Error('Deletion failed');
            }
    
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Deletion Failed',
              text: 'Failed to delete image. Please try again.',
            });
          }
        }
      });
    },
    toggleLike(reel) {
      const token = localStorage.getItem('token');
      if (!token) {
        // If not logged in, redirect to login
        this.$router.push({ name: 'login' }); 
        // or window.location.href = '/login'; if you’re not using Vue Router
        return;
      }
      reel.isLiked = !reel.isLiked;
      this.likeOrUnlikeVideo(reel)
        .then(response => {
          reel.like_count = response.data.like_count;
          reel.is_liked = (response.data.status === 'liked');
        })
        .catch(error => {
          console.error('Toggle error:', error);
          // revert if needed
          reel.isLiked = !reel.isLiked;
        });
    },
    likeOrUnlikeVideo(reel) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const LIKED_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/like_video/${reel.id}/`;
      return axios.post(LIKED_URL, {}, {
        headers: {  Authorization: `Token ${token}` }
      });
    },
    fetchLikedVideos() {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('token');

      // Construct the endpoint URL (replace with your actual base URL/route)
      const FETCH_LIKED_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_user_liked_videos/`;

      // Make a GET request with the token in the Authorization header
      axios.get(FETCH_LIKED_VIDEOS_URL, {
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then(response => {
        // The server returns an array of liked videos
        this.likedVideos = response.data;
      })
      .catch(error => {
        console.error('Error fetching liked videos:', error);
      });
    }
  

  },

  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();
    this. fetch_banking_details();
    this.fetch_account_types();
    this.update_banking_details();
    this.fetch_banks();
    this.fetchInfluencerStats();
    this.fetchLikedVideos();
    this.fetchDraftVideos();

    if (!this.token_exists || (this.user_details.user_role !== 'INFLUENCER' && this.user_details.user_role !== 'ADMIN')) {
      localStorage.removeItem('token'); // Clear the token
      this.$router.push('/login'); // Redirect to home page
    } else {
      this.checkUserDetails();
      this.fetchUserData();
      this.fetchVideos();
      this.handleClickOutside();
      this.fetchSearchProducts();
      this.fetchWalletInfo(); 
      this.fetchSizes(); 
      // jQuery event handlers
      
      this.initializeShowDetails();
    }
    window.addEventListener('message', this.handleIframeMessage);
  },
  created() {
    this.AllPaymentHistory();
    this.MoneyInHistory();
    this.fetchReelData().then(() => {
      this.initializeVideoPlayers();
      this.initializeVideoObserver();
    });
    this.initializeInfiniteScroll();
    document.addEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  },

  watch: {
    search_query() {
      this.filterProducts();

    }
  },
  beforeDestroy() {
    this.handleClickOutside();
    this.players = {};
  
    this.originalReels = [];
  
    this.fullScreenReels = [];
    document.removeEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  }
};
