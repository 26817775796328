<div class="home-navbar">
  <Admin_sidebar />
</div>

<div class="app-content">
  <Admin_navbar />
  <div class="container">
    <h5 class="fw-bold text-start">My Wallet</h5>

    <!-- Wallet Card -->
    <div class="wallet-card">
      <p class="fw-bold mb-1 text-start">Available Balance</p>
      <h2 class="fw-bold text-start">R {{ total_balance }}</h2>
      <div class="pending-balance mt-3 text-start">
        <span>Pending Balance</span>
        <span class="float-end">R {{ pending_balance }}</span>
      </div>
    </div>

    <!-- Withdraw Button -->
    <div class="text-end mt-3">
      <button class="withdraw-btn border-0" @click="openModal">
        Withdraw <i class="fas fa-arrow-up"></i>
      </button>
    </div>

    <!-- Payment History -->
    <h5 class="fw-bold mt-4 text-start">Payment History</h5>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a 
          class="nav-link" 
          :class="{ active: activeTab === 'all' }"
          @click.prevent="setActiveTab('all')"
        >
          All
        </a>
      </li>
      <li class="nav-item">
        <a 
          class="nav-link" 
          :class="{ active: activeTab === 'money_in' }"
          @click.prevent="setActiveTab('money_in')"
        >
          Money In
        </a>
      </li>
      <li class="nav-item">
        <a 
          class="nav-link" 
          :class="{ active: activeTab === 'money_out' }"
          @click.prevent="setActiveTab('money_out')"
        >
          Money Out
        </a>
      </li>
    </ul>

    <!-- Transaction List -->
    <div class="justify-content-center mt-5" v-if="filteredTransactions.length === 0">
      <p>No transactions yet.</p>
  </div>
    <div 
      v-for="transaction in filteredTransactions" 
      :key="transaction.id" 
      class="d-flex justify-content-between align-items-center border-bottom pb-2 mt-3"
    >
      <div>
        <span>{{ transaction.description }}</span>
        <small class="d-block text-muted">{{ transaction.date }}</small>
      </div>
      <span 
        class="fw-bold" 
        :class="{
          'text-success': transaction.balance_status === 'money_in',
          'text-danger': transaction.balance_status === 'money_out',
          'text-warning': transaction.balance_status === 'pending'
        }"
      >
        {{ transaction.balance_status === 'money_in' ? '+' : transaction.balance_status === 'money_out' ? '-' : '' }} R {{ transaction.amount }}
      </span>
    </div>
  </div>
</div>

<!-- Withdraw Modal -->
<div v-if="showWithdrawModal" class="modal-overlay d-flex justify-content-center align-items-center">
  <div class="bg-white p-4 rounded shadow-lg position-relative w-100" style="max-width: 400px;">
    <button class="btn-close position-absolute top-0 end-0 m-2" @click="closeModal"></button>
    <h3 class="fw-bold text-center">Withdraw Funds</h3>
    <div class="account-box d-flex justify-content-between align-items-center bg-light p-3 rounded mt-3">
      <div class="account-info d-flex align-items-flex-start flex-column">
        <span class="fw-bold d-block">{{ view_form.account_holder }}</span>
        <small class="text-start text-muted d-block">
          ****{{  view_form.masked_account_number }} 
          ({{ view_form.account_type_name }})
        </small>
      </div>
      <button class="btn btn-light border" @click="openEditBankingModal">
        <i class="fas fa-edit"></i>
      </button>
    </div>
    <div class="fw-bold text-start mt-3">
      <label class="text-muted">Available Balance</label>
      <p class="fs-5 fw-bold ">R {{ total_balance}}</p>
    </div>
    <label class="mt-3 d-block text-start text-muted">Amount to Withdraw</label>
    <div class="input-group mt-1">
      <span class="input-group-text">R</span>
      <input type="number" v-model="withdrawAmount" class="form-control" placeholder="0.00" @input="showWithdrawError = false" />
    </div>
    <small v-if="showWithdrawError" class="text-danger text-xxs">Please enter an amount within your available balance</small>
    <button class="btn btn-dark w-100 mt-3 py-2" @click="processWithdraw" :disabled="!view_form.account_holder"
    >Withdraw Money</button>
  </div>
</div>

<!-- Edit Banking Details Modal -->
<div v-if="showEditBankingModal" class="modal-overlay">
  <input type="hidden" v-model="view_form.id">

  <div class="modal-withdraw">
    <button class="btn-close position-absolute top-0 end-0 m-2" @click="closeEditBankingModal">
    </button>
    <h5 class="fw-bold">Edit Banking Details</h5>
      <div class="form-group">
        <label>Account Holder</label>
        <input type="text" v-model="view_form.account_holder" class="form-control">
      </div>
      <div class="form-group">
        <label>Bank Name</label>
        <select
        v-model.number="view_form.bank_id"
        @change="view_form.branch_code = banks.find(b => b.id === view_form.bank_id)?.branch_code || ''"
  
        class="form-control"
        required
      >
        <option value="" disabled>Select Bank</option>
        <option 
          v-for="bank in banks" 
          :key="bank.id" 
          :value="bank.id"
        >
          {{ bank.name }}
        </option>
      </select>
      </div>
      <div class="form-group">
        <label>Account Number</label>
        <input type="text" v-model="view_form.account_number" class="form-control">
      </div>
      <div class="form-group">
        <label>Account Type</label>
        <select
        class="form-control"
        v-model="view_form.account_type_id"
        required
      >
        <option value="" disabled>Select Account Type</option>
        <option 
          v-for="account_type in account_types" 
          :key="account_type.id" 
          :value="account_type.id"
        >
          {{ account_type.account_type }}
        </option>
      </select>
      </div>
      <div class="form-group">
        <label>Branch Code</label>
        <input type="text" v-model="view_form.branch_code" class="form-control" disabled>
      </div>
      <button type="submit" class="action-btn" @click="saveOrUpdateBankingDetails">
        {{ view_form.id ? 'Update' : 'Save' }}
      </button>
    </div>
</div>