<div class="home-navbar ">
    <Admin_sidebar />
</div>

<div class="app-content">
    <Admin_navbar />
    
    <div class="content">
        <div class="upload-search-row">
        </div>
        <div v-if="loading" class="loader-overlay">
            <div class="loader"></div>
          </div>
        <div class="card table-card">
            <div class="card-body">
                <table id="influencer_table" class="table align-item table-flush-item content-table">
                    <thead class="thead-light">
                        <tr>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Profile Image</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">First Name</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Last Name</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Email</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Date created</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Videos</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Orders</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(influencer, index) in items" :key="influencer.id">
                            <td class="text-start">
                                <img :src="influencer.profile.image  || require('@/assets/images/default-profile.png')" alt="Profile Image" width="50" height="50">
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ influencer.first_name }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ influencer.last_name }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ influencer.email }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ influencer.date_joined }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ influencer.video_count }}</span>
                            </td>
                            <td class="text-start">
                                <button 
                                    class="btn-sm action-btn font-weight-light text-xxs" 
                                    @click="ViewOrders(influencer.id)">
                                    View orders
                                </button>
                            </td>
                            <td class="text-start">
                                <button 
                                    class="btn-sm action-btn font-weight-light text-xxs"
                                    @click.prevent="loginAsInfluencer(influencer.id)"
                                >
                                    View
                                </button>
                                <button 
                                    class="btn-sm action-btn font-weight-light text-xxs" 
                                    @click="deactivateUser(influencer)">{{ influencer.is_active ? 'Deactivate' : 'Activate' }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>