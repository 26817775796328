import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Fullscreen_navbar from '@/components/Home/Fullscreen_Navbar/FullscreenNavbarView.vue';
import Swal from 'sweetalert2';
import Hls from 'hls.js';

export default {
  props: {
        influencerId: {
            type: String,
            required: true
        }
    },
  components: {
    Home_navbar,
    Fullscreen_navbar,
  },
  data: () => ({

      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        bio: '',
        profileImage: ''
      },
      videos: [],
      showEditProfileModal: false,
      profileImage: '',
      hoveredVideo: null,
      visibleCards: 6,
      currentPosition: 0,
      showDetails: [],
      influencerId: null,
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
      debounceTimer: null,
      UserLoggedIn: false, // Add a property to track login status
      currentVideo:null,
      pipVideoIndex: null,
      showSizes: false,
      selectedProduct: null,
      selectedSize: null,
      sizeProductId: null,
      currentPipProducts: [],
      isInPiPMode: false,
      reelMuteStates: {},
      apiError: false,
      showShareMenu: false,
      profileUrl: window.location.href,
  }),
  created() {
    // Define influencerId here
    this.influencerId = this.$route.params.influencerId;
  },
  methods: {
    toggleShareMenu() {
      this.showShareMenu = !this.showShareMenu;
    },
    copyProfileLinkToClipboard() {
      navigator.clipboard.writeText(this.profileUrl).then(() => {
        alert("Profile link copied to clipboard!");
      });
    },
    goBack() {
        this.$router.go(-1);
      },
    initializeShowDetails() {
      this.showDetails = new Array(this.videos.length).fill(false);
    },
    toggleDetails(index) {
      this.showDetails[index] = !this.showDetails[index];
    },
    toggleOverlay(index, isHovering) {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!this.showDetails[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    },
   
    scrollCards(direction) {
      const maxScroll = this.brand_videos.length - this.visibleCards;
      if (direction === 'right' && this.currentPosition < maxScroll) {
        this.currentPosition++;
      } else if (direction === 'left' && this.currentPosition > 0) {
        this.currentPosition--;
      }
    },
    async openEditProfileModal() {
      await this.fetchUserData();
      this.showEditProfileModal = true;
    },

    async fetchUserData() {
        const FETCH_USER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/public_user_profile/${this.influencerId}/`;
        try {
          const response = await fetch(FETCH_USER_URL, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          });
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();

        if (data.status === "success") {
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.email = data.email;
          this.form.phone_number = data.phone_number;
          this.form.bio = data.bio || '';  // Ensure the bio is set correctly
          this.profileImage = data.image || ''; 
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to fetch user data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    

    async fetchVideos() {
        const FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/public_influencer_video_content/${this.influencerId}/`;
      try {
        const response = await fetch(FETCH_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },

  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  
    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.getElementById('modalProfileImage');
          if (img) {
            img.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
        this.form.profileImage = file;
      }
    },
    

    saveProfile() {
      // Handle saving the profile information here
      this.showEditProfileModal = false;
    },
    closeModal() {
      this.showEditProfileModal = false;
    },

    playVideo(event) {
      const videoElement = event.target;
  
      // Clear any existing timeout to avoid overlapping calls
      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }
  
      // Set a timeout to delay the play action
      videoElement.playTimeout = setTimeout(() => {
        videoElement.play().catch((error) => {
          console.error("Autoplay error:", error);
        });
      }, 1000); // Delay by 500ms (adjust as needed)
    },
  
    pauseVideo(event) {
      const videoElement = event.target;
  
      // Clear the play timeout if the user stops hovering
      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }
  
      // Pause the video
      videoElement.pause();
      videoElement.currentTime = 0; 
    },

    async fetchReelData() {
      if (this.isFetching || this.allDataLoaded) return;
      try {
        this.apiError = false;
        this.isFetching = true;
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/public_influencer_video_content/${this.influencerId}/`;
        const response = await fetch(`${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.length === 0) {
          this.allDataLoaded = true;
        } else {
          this.reels.push(...data);
          this.page++;
          // Initialize Video.js and Observers after new reels are rendered
          this.$nextTick(() => {
            this.initializeVideoPlayers();
            this.initializeVideoObserver();
          });
        }
      } catch (error) {
        console.error("Error fetching reel data:", error);
        this.apiError = true;
      } finally {
        this.isFetching = false;
      }
    },

    initializeVideoPlayers() {
      this.reels.forEach((reel, idx) => {
        // find DOM container: <div ref=\"videoPlayer_idx\">
        const reelRef = this.$refs['videoPlayer_' + idx];
        const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
        if (!reelContainer || this.players[idx]) return; // already inited?

        const videoEl = reelContainer.querySelector('video');
        if (!videoEl) return;
 
        videoEl.setAttribute('playsinline', 'true');
        videoEl.autoplay = true;
        videoEl.addEventListener('loadedmetadata', () => {
          // start playing after loadedmetadata
          videoEl.play();
        });

        // attach HLS if .m3u8
        this.initializeHlsOrMp4(videoEl, reel, idx);

        // store reference
        this.players[idx] = videoEl;
      });
    },

    initializeHlsOrMp4(videoEl, reel, idx) {
      const hlsUrl = reel.final_processed_url;
      const mp4Url = reel.video_url;
      console.log(`HLS URL: ${hlsUrl}, MP4 URL: ${mp4Url}`);

      if (hlsUrl && hlsUrl.endsWith('.m3u8')) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(hlsUrl);
          hls.attachMedia(videoEl);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log(`[HLS] manifest loaded for reel ${idx}`);
          });
          hls.on(Hls.Events.ERROR, (evt, data) => {
            console.error("HLS Error:", data);
          });
        } else if (videoEl.canPlayType('application/vnd.apple.mpegurl')) {
          // Safari
          videoEl.src = hlsUrl;
        } else {
          // fallback
          videoEl.src = mp4Url;
        }
      } else {
        // not an .m3u8 link, fallback
        videoEl.src = mp4Url;
      }
    },

    initializeVideoObserver() {
    
      if (!('IntersectionObserver' in window)) {
        console.warn("No IntersectionObserver support");
        return;
      }
    
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const idx = parseInt(entry.target.dataset.index, 10);
    
          if (Number.isNaN(idx)) {
            console.warn('Invalid index detected');
            return;
          }
    
          const videoEl = this.players[idx];
          if (!videoEl) {
            console.warn(`No video element found for index: ${idx}`);
            return;
          }
    
          if (entry.isIntersecting) {
            this.pauseOtherVideos(idx);
    
            videoEl.setAttribute('playsinline', 'true');
            
            videoEl.play().then(() => {
            }).catch(err => {
              console.warn(`Autoplay prevented for video ${idx}:`, err);
            });
    
            this.currentPlayingIndex = idx;
          } else {
            videoEl.pause();
            videoEl.currentTime = 0;
          }
        });
      }, {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Video should be 70% visible to play
      });
    
      // Observe each video
      this.reels.forEach((_, idx) => {
        const reelRef = this.$refs['videoPlayer_' + idx];
        const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
        if (!reelContainer) {
          console.warn(`No container found for video index: ${idx}`);
          return;
        }
        const videoEl = reelContainer.querySelector('video');
        if (videoEl) {
          videoEl.dataset.index = idx;
          observer.observe(videoEl);
      
          videoEl.addEventListener('play', () => {
            this.pauseOtherVideos(idx);
          });
      
          videoEl.addEventListener('click', () => {
            this.pauseOtherVideos(idx);
            videoEl.play(); // Ensure the clicked video plays
          });
        }
      });
      
    },
    
    pauseOtherVideos(index) {
      if (!Array.isArray(this.players)) {
        console.error("this.players is not an array!", this.players);
        return;
      }
    
      this.players.forEach((video, i) => {
        if (i !== index && video) {
          video.pause();
        }
      });
    },
    
  
    openFullScreen(video, index) {
      // Pause the current playing video if it exists
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
      // Update originalReels with the latest reels data
      this.originalReels = [...this.reels];
      if (!this.originalReels || !this.originalReels[index]) {
        console.error("Invalid index or reels not loaded");
        return;
      }
      this.fullScreenReels = [...this.originalReels];
      this.isFullScreenMode = true;
      this.fullScreenVideoIndex = index;
      this.currentPlayingIndex = index;
      window.addEventListener("wheel", this.handleScrollInFullScreen, { passive: false });
      this.$nextTick(() => {
        this.playVideoAtIndex(index);
      });
    },
  
    navigateFullScreen(direction) {
      if (!this.isFullScreenMode) return;
      const newIndex = Math.min(
        Math.max(this.fullScreenVideoIndex + direction, 0),
        this.fullScreenReels.length - 1
      );
      if (newIndex === this.fullScreenVideoIndex) return;
      const prevIndex = this.fullScreenVideoIndex;
      this.fullScreenVideoIndex = newIndex;
      this.currentPlayingIndex = newIndex;
      if (this.players[prevIndex]) {
        this.players[prevIndex].pause();
      }
      this.$nextTick(() => {
        this.playVideoAtIndex(newIndex);
      });
    },
    handleScroll(index) {
      const video = document.querySelector(`video[data-index="${index}"]`);
      if (!video) {
        console.warn(`No video found at index ${index}`);
        return;
      }
    
      const rect = video.getBoundingClientRect();
    
      const isVisible =
        rect.top < window.innerHeight * 0.5 && rect.bottom > window.innerHeight * 0.5;
    
      if (isVisible) {
        this.pauseOtherVideos(index);
      } else {
        video.pause();
      }
    },
    
    
    playVideoAtIndex(index) {
      // Check network connectivity before attempting playback
      if (!navigator.onLine) {
        console.warn(`No network connection; video playback is disabled for index ${index}`);
        this.handleVideoError(index);
        return;
      }
      // Clear any previous error state for this video
      this.videoErrors = {};
      // Pause all other videos
      this.reels.forEach((_, i) => {
        const videoEl = Array.isArray(this.$refs[`videoPlayer_${i}`])
          ? this.$refs[`videoPlayer_${i}`][0]
          : this.$refs[`videoPlayer_${i}`];
        if (videoEl && i !== index) {
          const videoElement = videoEl.querySelector("video");
          if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0;
          }
        }
      });
      // Play the selected video
      const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`])
        ? this.$refs[`videoPlayer_${index}`][0]
        : this.$refs[`videoPlayer_${index}`];
      if (videoEl) {
        const videoElement = videoEl.querySelector("video");
        if (videoElement) {
          videoEl.addEventListener("scroll", () => {
            this.reels.forEach((_, index) => this.handleScroll(index));
          });
          videoEl.addEventListener("touchmove", () => {
            this.reels.forEach((_, index) => this.handleScroll(index));
          });
          videoElement.setAttribute("playsinline", "true");
          videoElement.setAttribute("autoplay", "true");
          videoElement.setAttribute("preload", "auto");
          videoElement.setAttribute("controls", "true");
          // Do not force native controls; Video.js is handling them
          videoElement.controls = true;
          videoElement.scrollIntoView({ behavior: "smooth", block: "center" });
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error("Autoplay prevented:", error);
              this.handleVideoError(index);
              // Ensure controls remain hidden on error
              videoElement.controls = false;
            });
          }
        }
      }
      delete this.videoErrors[index];
    },
    // If a reel fails to load
    handleVideoError(idx) {
      this.videoErrors = { ...this.videoErrors, [idx]: true };
      console.error(`Video ${idx} failed to load`);
    },
  
    retryVideo(idx) {
      // reset error, re-initialize
      delete this.videoErrors[idx];
      const reel = this.reels[idx];
      if (!reel) return;
      const reelRef = this.$refs['videoPlayer_' + idx];
      const reelContainer = Array.isArray(reelRef) ? reelRef[0] : reelRef;
      if (!reelContainer) return;
      const videoEl = reelContainer.querySelector('video');
      if (!videoEl) return;
      this.initializeHlsOrMp4(videoEl, reel, idx);
    },

    // Infinite Scroll sentinel
    initializeInfiniteScroll() {
      this.$nextTick(() => {
        const sentinel = this.$refs.scrollSentinel;
        if (!sentinel) return;
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !this.isFetching) {
            this.fetchReelData();
          }
        }, {
          root: null,
          rootMargin: '100px',
          threshold: 0,
        });
        observer.observe(sentinel);
      });
    },

    handleScrollInFullScreen(event) {
      if (!this.isFullScreenMode) return;
      event.preventDefault();
      const direction = event.deltaY > 0 ? 1 : -1;
      if (!this.scrollLock) {
        this.scrollLock = true;
        this.navigateFullScreen(direction);
        setTimeout(() => {
          this.scrollLock = false;
        }, 500);
      }
    },

    exitFullScreen() {
      this.isFullScreenMode = false;
      this.fullScreenReels = [];
      this.fullScreenVideoIndex = 0;
      this.currentPlayingIndex = null;
      window.removeEventListener("wheel", this.handleScrollInFullScreen);
    },

    // PiP logic if needed
    async enterPictureInPicture(reel, index) {
      const videoElement = document.getElementById(`video-${reel.id}`);
      if (!videoElement) {
        console.error('Video element not found');
        return;
      }
      try {
        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        await videoElement.requestPictureInPicture();
        this.isInPiPMode = true;
        this.pipVideoIndex = index;
        this.currentPipProducts = reel.products.filter((p) => p.product_details);
        videoElement.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
      } catch (error) {
        console.error('Failed to enter PiP:', error);
      }
    },

    handleLeavePictureInPicture() {
      this.isInPiPMode = false;
      if (this.pipVideoIndex !== null) {
        const reel = this.reels[this.pipVideoIndex];
        if (reel) {
          this.$router.go(-1);
          this.$nextTick(() => {
            this.openFullScreen(reel, this.pipVideoIndex);
          });
        }
      }
    },
  },
    

  mounted() {
    this.fetchUserData();
    this.fetchVideos();
    this.handleClickOutside();    
    this.initializeShowDetails();
    this.fetchReelData().then(() => {
      this.initializeVideoPlayers();
      this.initializeVideoObserver();
    });
    this.initializeInfiniteScroll();
    document.addEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  },

  beforeDestroy() {
    this.handleClickOutside();
    Object.values(this.players).forEach((player) => {
    
      if (player && typeof player.dispose === 'function') {
  
        player.dispose();
  
      }
  
    });
  
    this.players = {};
  
    this.originalReels = [];
  
    this.fullScreenReels = [];
    document.removeEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
  
  },
  computed:{
  profileUrl() {
    return this.baseUrl + this.userId;
  }
}
};
