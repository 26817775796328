// src/store/modules/auth.js
export default {
    namespaced: true,
    state: {
      isImpersonating: localStorage.getItem('isImpersonating') === 'true',
      impersonatedUserId: localStorage.getItem('impersonatedUserId') || null,
    },
    mutations: {
      setImpersonation(state, { isImpersonating, impersonatedUserId }) {
        state.isImpersonating = isImpersonating;
        state.impersonatedUserId = impersonatedUserId;
        localStorage.setItem('isImpersonating', isImpersonating);
        localStorage.setItem('impersonatedUserId', impersonatedUserId);
      },
      clearImpersonation(state) {
        state.isImpersonating = false;
        state.impersonatedUserId = null;
        localStorage.removeItem('isImpersonating');
        localStorage.removeItem('impersonatedUserId');
      },
    },
    actions: {
      impersonate({ commit }, userId) {
        commit('setImpersonation', { isImpersonating: true, impersonatedUserId: userId.toString() });
      },
      endImpersonation({ commit }) {
        commit('clearImpersonation');
      },
    },
    getters: {
      isImpersonating: state => state.isImpersonating,
      impersonatedUserId: state => state.impersonatedUserId,
    },
  };