import Search_Influencer from '@/views/Influencer_Management/SearchInfluencer/SearchInfluencerView.vue'
import _ from 'lodash'

export default {
  components: {
    Search_Influencer
  },
  props: {
    search_query: String,
  },
  data: () => ({
    allInfluencers: [],
    filteredInfluencers: [],
  }),

  computed: {
    searchedInfluencers() {
      return this.filteredInfluencers;
    }
  },
  mounted() {
    this.fetchInfluencers(); // Fetch brands when the component is mounted
  },
  methods: {
    async fetchInfluencers() {
        const FETCH_INFLUENCERS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
        try {
          const params = { search: this.search_query };
          const response = await fetch(FETCH_INFLUENCERS_URL + '?' + new URLSearchParams(params).toString(), {
            headers: { 'Content-Type': 'application/json' },
          });
          const data = await response.json();
          this.filteredInfluencers = data.map(influencer => ({
            ...influencer,
            videos: [influencer.videos[0]] // Only include the first video
          })); 
        } catch (error) {
          console.error('Error fetching influencers:', error);
        }
      },
},
  watch: {
    '$route.query.search': {
      handler: _.debounce(function(newValue) {
        this.$emit('update:search_query', newValue || ''); // Update the search_query prop
        this.fetchInfluencers(); // Re-fetch brands when the search query changes
      }, 500),
      immediate: true
    }
  }
};