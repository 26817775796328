<!-- <Home_navbar /> -->
<Home_navbar v-if="!LoggedIn" :search_input="search_query" 
@update:search_input="update_search_query" />
        <Influencer_navbar v-else   :search_input="search_query" 
        @update:search_input="update_search_query"
    />
        <div v-if="showLoginAlert" class="centered-popup alert-success">
          You need to log in to add products to the wishlist! 
          <a href="/login" class="alert-link"> LOGIN</a>
        </div>
        <div v-if="showSizelert" class="centered-popup alert-success">
          You need to select size before adding to cart! 
        </div>
        <div v-if="addedToWishlist" class="centered-popup alert-success">
          Successfully added to wishlist!
          <a href="/wishlist" class="alert-link"> WISHLIST</a>

        </div>

   
  <!-- Main Content -->
  <div v-if="!search_query">
  <div  class="  mt-1 product-view" v-if="product ">
    <p class="text-start text-black ">
      <router-link to="/" class="text-muted">
        Home
      </router-link> /
      <span class="fw-bold title-capitalize">{{ product.product.product_name }}</span>
    </p>
    <div class="row">
      <div class="col-md-5  "> 
        <img :src="product.product.image_url"  
          class="img-fluid h-400 object-fit-cover rounded-12" 
          @error="(event) => event.target.src = require('@/assets/images/img-error.png')"
        >
      </div>
      <div class="col-md-5 p-3">
        <div class="text-start"> 
          <h2 class="fw-bold mb-0 text-black title-capitalize">{{ product.product.product_name }}</h2>           
          <p class="product-view-brand">{{ product.product.brand__brand_name }}</p>
          <h3 class="fw-bold text-black">R {{ formatPrice(product.price) }}</h3>
          <hr> 

          <h5 class="title-capitalize fw-bold text-black">Color: {{ product.color }}</h5>

          <h5 class="fw-bold text-black">Size:</h5>
          <div class="d-flex mb-3 text-black">
              <div class="size-option me-2" 
                v-for="size in sizes" :key="size" 
                :class="{ active: selectedSize === size.size }"
                @click="selectSizeProduct(size)"
              >                
                {{ size.size }}
              </div>
          </div> 

          <p class="text-muted"><i class='bx bxs-shopping-bag'></i>  <span class="fw-bold"> Only {{product.quantity}} left  </span> - don't miss out.</p>
          <!-- Buttons -->
          <div class="action-buttons justify-content-center align-content-center d-flex">
            <button class="add-to-cart  rounded-12" @click="addToCart(product.id)"> <i class="fa-solid fa-cart-shopping wishlist-icon"></i> Add to Cart
            </button>
            <button class="add-to-wishlist rounded-12" @click="addToWishlist(product.id)"> <i class="fa-sharp fa-regular fa-heart wishlist-icon"></i>Add to Wishlist</button>
          </div>
          <hr>
          <h5 class="fw-bold text-black">Description</h5>
          <p class="product-view-brand"> {{ product.product.description }}</p>
        </div>
      </div>

    </div>
  </div>
</div>
<div v-else>
  <Search_product :search_query="search_query" :allProducts="allProducts" @update:search_query="update_search_query"/>
</div>

<footer class="footer">
  <div class="footer-content">
    <div class="footer-links">

      <!-- Delivery Information -->
      <div class="footer-column">
        <h2>Delivery Information</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-box-open box-icon"></i> Partnered with Pargo for delivery solutions</a></li>
          <li><a href="#"><i class="fa-sharp fa-solid fa-truck"></i> Nationwide delivery within 3-5 working days</a></li>
          <li><a href="#"><i class="fa-sharp fa-solid fa-rotate-left text-primary"></i> Easy returns at any Pargo point</a></li>
        </ul>
      </div>

      <!-- Our Location -->
      <div class="footer-column">
        <h2>Our Location</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-location-dot"></i> Rosebank, Johannesburg, South Africa</a></li>
        </ul>
      </div>

      <!-- About Us -->
      <div class="footer-column">
        <h2>About Us</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-circle-info"></i> About Us</a></li>
          <li><router-link to="/terms-and-conditions">
            <i class="fa-brands fa-readme"></i> Terms and Conditions
          </router-link></li>
        </ul>
      </div>

      <!-- Follow Us -->
      <div class="footer-column">
        <h2>Socials</h2>
        <ul>
          <li><a href="#"><i class="fa-brands fa-instagram instagram-icon"></i> Instagram</a></li>
          <li><a href="#"><i class="fa-brands fa-whatsapp whatsapp-icon"></i> WhatsApp</a></li>
        </ul>
      </div>

    </div>

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <p>&copy; 2025 Khiosk. All rights reserved.</p>
    </div>
  </div>
</footer>