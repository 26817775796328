<div class="home-navbar"></div>
    <Sidebar />
  
  <div class="app-content">
    <Navbar />
    <div class="content">
      <div class="upload-search-row">
      </div>
      <div class="card table-card">
        <div class="card-body"> 
          <table id="content_table" class="table align-item table-flush-item content-table">
            <thead class="thead-light">
                <tr>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Product</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Product Name</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Customer Name</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Code</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Order Date </th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Total</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                    <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                </tr>
            </thead>
  
            <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                    <td class="text-start">
                        <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                    </td>
                  <td class="text-start">
                      <img :src="order.product_image_url" alt="Profile Image" width="40" height="40">
                  </td>
                  <td class="align-item text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ order.product_name }}</span>
                    </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ order.customer_username }} {{ order.customer_lastname }}</span>
                  </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ order.order_number }}</span>
                  </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">{{ order.order_date }}</span>
                  </td>
                  <td class="text-start">
                      <span class="text-xxs font-weight-light mb-0">R {{ order.total_cost }}</span>
                  </td>
                  <td class="text-start">
                    <span class="text-xxs font-weight-light mb-0">{{ order.status }}</span>
                </td>
                    <td class="text-start">
                      <button 
                        class="btn-sm action-btn font-weight-light text-xxs">
                        View
                      </button>
                    </td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>