<div class="home-navbar">
    <Influencer_sidebar />
</div>
<div class="app-content delivery-category">
    <Influencer_navbar />

    <!-- Main Content -->
    <div class="address-content mt-4 col-6">
        <h5 class="fw-bold">Add Delivery Address</h5>
        <div class="mb-3 fw-bold">
            <label v-for="address_type in address_types" :key="address_type.id" class="me-3">
                <input 
                    type="radio" 
                    name="address_type" 
                    v-model="form.address_type" 
                    :value="address_type.id"
                /> 
                {{ address_type.type_name }}
            </label>
        </div>

        <form @submit.prevent="address_id ? onUpdate() : onSubmit()">

            <div v-if="isBusinessType" class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="business_name"
                    v-model="form.business_name"
                    placeholder="Business Name"
                    required
                />
                <div v-if="errors.business_name" class="text-danger">{{ errors.business_name }}</div>
            </div>

            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="recipient_name"
                    v-model="form.recipient_name"
                    placeholder="Recipient Name"
                    required
                />
                <div v-if="errors.recipient_name" class="text-danger">{{ errors.recipient_name }}</div>
            </div>
            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="recipient_mobile"
                    v-model="form.recipient_mobile"
                    placeholder="Recipient Mobile Number"
                    required
                />
                <div v-if="errors.recipient_mobile" class="text-danger">{{ errors.recipient_mobile }}</div>
            </div>
            <div class="autocomplete-container position-relative mb-4">
                <input 
                  type="text" 
                  class="form-control form-control-sm" 
                  id="street_address" 
                  v-model="form.street_address" 
                  placeholder="Street Address" 
                  @input="onAddressInput" 
                  @focus="onAddressInput"
                  required
                />
                <div v-if="errors.street_address" class="text-danger">{{ errors.street_address }}</div>
                
                <ul v-if="form.street_address && suggestions.length > 2" class="dropdown-menu show">
                    <li v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectAddress(suggestion)">
                    {{ suggestion.description }}
                  </li>
                </ul>
              </div>
            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="complex_building"
                    v-model="form.complex_building"
                    placeholder="Complex/Building"
                />
            </div>
            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="suburb"
                    v-model="form.suburb"
                    placeholder="Suburb"
                    required
                />
                <div v-if="errors.suburb" class="text-danger">{{ errors.suburb }}</div>
            </div>
            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="city_town"
                    v-model="form.city_town"
                    placeholder="City/Town"
                    required
                />
                <div v-if="errors.city_town" class="text-danger">{{ errors.city_town }}</div>
            </div>
            <div class="mb-4">
                <select id="province" class="form-control form-control-sm" v-model="form.province" required>
                    <option value="" disabled>Select a province</option>
                    <option v-for="province in provinces" :key="province.id" :value="province.id">
                        {{ province.province_name }}
                    </option>
                </select>
                <div v-if="errors.province" class="text-danger">{{ errors.province }}</div>
            </div>
            <div class="mb-4">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    id="postal_code"
                    v-model="form.postal_code"
                    placeholder="Postal Code"
                    required
                />
                <div v-if="errors.postal_code" class="text-danger">{{ errors.postal_code }}</div>
            </div>
            <button type="submit" class="submit-btn">{{ address_id ? 'Update' : 'Save' }}</button>
        </form>
    </div>
</div>
