/* global $ */

import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';

export default {
    name: 'Orders',
    components: {
      Sidebar,
      Navbar,
    },
    data: () => ({
      orders: [], // Initialize orders as an empty array
    }),
  
    methods: {
      initializeDataTable() {
        this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#content_table')) {
            $('#content_table').DataTable().destroy();
          }
          $('#content_table').DataTable({
            pageLength: 10,
            lengthMenu: [10, 25, 50, 100],
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            autoWidth: false,
          });
        });
      },
  
      async getBrandOrders() {
        const token = localStorage.getItem('token');
        const BRAND_ORDERS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_brand_orders_api/`;
        try {
          const response = await fetch(BRAND_ORDERS_API, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) throw new Error('Failed to fetch Orders');
          const data = await response.json();
          this.orders = data.data; // Assign the fetched data to orders
          this.$nextTick(() => {
            this.initializeDataTable();
          });
        } catch (error) {
          console.error('Error fetching Orders:', error);
        }
      },
    },
    mounted() {
      this.getBrandOrders();
    },
  }