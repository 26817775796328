import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue'
import Filter_product from '@/components/Products/FilterProductView.vue';
import _ from 'lodash';
import EventBus from '@/eventBus.js';


export default {
  name: "SearchProduct",
  components: {
    Customer_navbar,
    Home_navbar,
    Filter_product
  },
  props: {
    search_query: String,
  },
  data() {
    return {
      products: [],
      allProducts: [],
      videos: [], // Make sure this is initialized as an empty array
      selectedProduct: null,

      filteredProducts: [],
      showLoginAlert: false,
      showDropdown: false,
      addedToWishlist: false,
      sizes: [],
    };
  },
  computed: {
      searchedProducts() {
        if (!this.search_query) return this.filteredProducts;
        const query = this.search_query.toLowerCase();
        return this.filteredProducts.filter((product) =>
          product.product.product_name.toLowerCase().includes(query) ||
          product.product.brand__brand_name.toLowerCase().includes(query) ||
          product.product.description.toLowerCase().includes(query) ||
          product.product.category__category_name.toLowerCase().includes(query)
        );
      },
    
  
    availableBrands() {
      return this.brands.filter(brand => {
        const hasProducts = this.allProducts.some(product => product.product.brand__brand_name === brand.brand_name);
        return hasProducts;
      });
    },
  },

  mounted() {
      this.fetchProducts();
  },

  methods: {
    filter_product_list(data) {
      this.allProducts = data;
    },

    async fetchProducts() {
      const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
      try {
        const params = { search: this.search_query };
        const response = await fetch(FETCH_PRODUCT_URL + '?' + new URLSearchParams(params).toString(), {
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        
        this.allProducts = data;
        this.filteredProducts = [...data];
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    isSoldOut(product) {    
      // Check if product_details exists, otherwise check sizes
      const details = product.product_details || product.sizes; 
    
      if (!details || !Array.isArray(details)) {
        return false; 
      }
    
      const totalQuantity = details.reduce((total, detail) => total + (detail.quantity || 0), 0);    
      return totalQuantity === 0;
    },
    
    
    handleProductClick(product) {
      this.selectedProduct = product;
      this.sizes = this.selectedProduct.sizes;
      this.showSizes = !this.showSizes;
    },
    async fetchCartDetails() {
      const cart = this.getLocalStorage('cart') || [];
      for (const item of cart) {
        const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
        const response = await fetch(GET_IMAGE_URL);
        if (!response.ok) {
          throw new Error('Failed to fetch image URL');
        }
        const imageData = await response.json();
        item.image = imageData;
      }
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    },
    async addToWishlist(product_id) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.showLoginAlert = true;
        setTimeout(() => {
          this.showLoginAlert = false;
        }, 5000);
        return;
      }
      const formData = new FormData();
      formData.append('productdetail_ids', product_id);
      try {
        const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
        const response = await fetch(ADD_TO_WISHLIST_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });
        const result = await response.json();
        if (response.ok && response.status === 201) {
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else if (response.status === 200) {
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else {
          alert(result.error || 'Failed to add product to the wishlist.');
        }
      } catch (error) {
        alert('Something went wrong. Please try again.');
      }
    },
    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },
    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },
    
    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },
    async handleAddToCart(size) {
      this.selectedSize = size.size;
      this.sizeProductId = size.product_id;
    },
    async addToCart(product) {
      this.showSizes = false; // Hide sizes dropdown after adding to cart
      const quantity = 1;
      const productId = this.sizeProductId;
      if (this.isLoggedIn()) {
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity,
            }),
          });
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        const cart = this.getLocalStorage('cart') || [];
        const existingProduct = cart.find(
          (item) => item.product_id === this.sizeProductId && item.size === this.selectedSize
        );
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: product.product_id,
            product_name: product.product.product_name,
            quantity: quantity,
            price: product.price,
            color: product.color,
            size: this.selectedSize,
            brand: product.product.brand__brand_name,
            image: product.product.image_url,
            product_id: productId,
          });
        }
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            this.isRightSidebarOpen = true;
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        });
      }
    },
  },

  
  watch: {
    search_query: _.debounce(function(newValue) {
      if (newValue.trim() === '') {
        this.filteredProducts = this.allProducts; // Reset to all products
      } else {
        this.fetchProducts();
      }
    }, 500)
  },
};