<div class="home-navbar ">
  
    <i class='bx bx-menu fs-4 text-white ms-3 close-sidebar' @click="toggleSidebar"></i>
    
  <div class="influencer-category-navbar ">
   <nav class="second-navbar" :class="{'sidebar-visible': isSidebarVisible}">
      <div class="nav-item" data-dropdown>
          <a href="#">NEW IN</a>
          <div class="dropdown-content">
              <a href="#">Women</a>
              <a href="#">Men</a>
              <a href="#">Kids</a>
          </div>
      </div>
      <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
        
        <a href="#" class="department-name">{{ department.department_name }}</a>
        <div class="dropdown-content align-items justify-content-center">
          <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
            <a href="#" class="category-name">{{ category.category_name }}</a>
            <div v-if="category.subcategories.length" class="subcategory-container">
              <a v-for="(subcategoryName, subcategoryIndex) in category.subcategories" :key="subcategoryIndex" href="#">{{ subcategoryName.subcategory_name }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-item text-start">
        <a href="/view_brands">BRANDS</a>
    </div>
    </nav>
  </div>


</div>



