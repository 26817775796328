import Search_Brand from '@/views/Customer_Management/Search_Brand/SearchBrandView.vue'
import _ from 'lodash'

export default {
  name: "SearchBrands",
  components: {
    Search_Brand
  },
  props: {
    search_query: String,
  },
  data: () =>({
      allBrands: [],
      filteredBrands: [],
  }),

  computed: {
    searchedBrands() {
      return this.filteredBrands;
    }
  },
  mounted() {
    this.fetchBrands(); // Fetch brands when the component is mounted
  },
  methods: {
    async fetchBrands() {
        const FETCH_BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`;
        try {
            const params = { search: this.search_query };
            const response = await fetch(FETCH_BRANDS_URL + '?' + new URLSearchParams(params).toString(), {
              headers: { 'Content-Type': 'application/json' },
            });
          const data = await response.json();
          this.filteredBrands = data; 
        } catch (error) {
          console.error('Error fetching brands:', error);
        }
    },
},
watch: {
    '$route.query.search': {
      handler: _.debounce(function(newValue) {
        this.$emit('update:search_query', newValue || ''); // Update the search_query prop
        this.fetchBrands(); // Re-fetch brands when the search query changes
      }, 500),
      immediate: true
    }
  }
};