/* global $ */
import Customer_navbar from '@/components/Customer/CustomerNavView.vue';
import jQuery from 'jquery';

export default {
  components: {
    Customer_navbar
  },
  props: {
    search_input: {
      required: false,
      type: String
    }
  },
  data(){
    return {
      departments: [],
      sidebarOpen: false,
      subSidebarOpen: [], 
      selectedDepartmentIndex: null
    }
  },
  methods: {
    update_search(event) {
      this.$emit('update:search_input', event.target.value);
    },
    async logout() {
        const token = localStorage.getItem('token');
        const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

        const response = await $.ajax({
          url: LOGOUT_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response) {
          localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
          this.$router.push('/')
        } else {
          throw new Error('Logout failed');
        }
    },
    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        this.subSidebarOpen = Array(this.departments.length).fill(false);
      } catch (error) {
        console.error(error);
      }
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },

    toggleSubSidebar(departmentIndex) {
      // Toggle the open state of the clicked department's sub-sidebar
      this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
    }
  },
  
  mounted() {
    this.fetchCategories();
    const $search_icon = jQuery('#search_icon');
    const $search_input_box = jQuery('#search_input');
    const $sidebar_close = jQuery('.close-sidebar');
    const $sidebar = jQuery('.sidebar');

    // Function to close sidebar on screens 767px or smaller
    function handleSidebarOnResize() {
      if (window.innerWidth <= 767) {
        $sidebar.addClass('close');   
      } else {
        $sidebar.removeClass('close');  
      }
    }

    // Check screen size on page load
    handleSidebarOnResize();  

    // Toggle the search input and icon on click
    $search_icon.on('click', function(event) {
      event.stopPropagation();
      $search_input_box.toggleClass('d-none'); // Show/hide the search input
      $search_icon.toggleClass('bx-search bx-x'); // Toggle between search and close icon
    });

    $sidebar_close.on('click', function(event) {
      event.stopPropagation();
      $sidebar.toggleClass('close');   
    });

    // Add event listener for window resize to handle dynamic resizing
    jQuery(window).on('resize', function() {
      handleSidebarOnResize(); 
    });

    
  }
  
};
