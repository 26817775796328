<div class="home-navbar ">
    <Influencer_sidebar />
  </div>
  
  <div class=" app-content wishlist-category">
    <Influencer_navbar />
  
        <div class="card border-0 bg-transparent">
  
          <!-- Tab Content -->
          <div class="tab-content ml-3 mr-3" id="pills-tabContent">

            <div v-if="wishlistProducts.length > 0" class="product-grid">
              <div v-for="product_wish in wishlistProducts" :key="product_wish.id" class="product-card">
                <img :src="product_wish.productdetail.product.image_url" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" class="product-image" />
                
                <div class="product-info text-start">  
                  <h4 class="product-title text-black">{{ product_wish.productdetail.product.product_name }}</h4>
                  <p class="product-brand mb-1"> {{ product_wish.productdetail.product.brand__brand_name }}</p>
                  <p class="mb-1 fw-bolder">color: {{ product_wish.productdetail.color }}</p>
                  <p class="fw-bold">Price: R {{ product_wish.productdetail.price }}</p>

                  <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 fw-bold text-muted">{{ product_wish.productdetail.stock_status__status_name }}</p>
                    <div class="d-flex justify-content-between">
                      <i class="fa fa-cart-plus text-black fa-lg me-1" aria-hidden="true" @click="addToCart(product_wish.productdetail.id)"></i>
                      <i class="fa-solid fa-trash text-black fa-lg" @click="removeFromWishlist(product_wish.productdetail.id)"></i>
                 </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center mt-5">
              <i class="fas fa-trash-alt empty-bin-icon"></i>
              <p>Your wishlist is empty</p>
              <p>Add products to create your own wishlist by tapping the heart icon.</p>
            </div>
        </div>
    </div>
</div>
  