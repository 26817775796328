<div class="home-navbar mt-2">
  <div v-if="showProgressBar" class="progress">
    <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }" :aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100">
      {{ progressPercentage }}%
    </div>
  </div>
  <div v-if="showProcessingPopup" class="centered-popup alert-success">
    Processing Your Video... Please wait.
  </div>

  
  <nav class="first-navbar">
    <i v-if="showSidebar" class='bx bx-menu fs-4 text-dark ms-3 close-sidebar' @click="toggleSidebar"></i>
   
 
    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo" style="cursor: pointer;" @click="goToLandingPage" >
    <div class="search-bar search-bar-lg">
        <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
      <div>
          <a href="#" data-toggle="modal" data-target="#uploadModal" class="text-black">
            <i class="fa-solid fa-square-plus nav-icon text-black me-2 fs-4"></i>
          </a>
      </div>
      <div class="icon-container" @click="toggleRightSidebar" ref="iconContainer">
          <a href="#">
            <i class='bx bxs-shopping-bag fs-4' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
          <div class="profile">
            <img v-if="profileImage" :src="profileImage" alt="Profile Picture" class="navbar-profile-picture">
            <div v-else class="initials text-black text-xs">{{ getInitials(form.first_name, form.last_name) }}</div>
          </div>
          <div class="dropdown-content">
                <router-link to="/Influencer_profile" class="">
                  <span class="text-dark" >Profile</span>
                </router-link>
       
                  <a href="#" @click="logout">
                    <span class="text-dark">logout</span>
                  </a>
            </div>
        </div>      
    </div>
  </nav>
 
  <div class="search-bar mt-3 search-bar-sm">
    <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
    <div class="search-dropdown" id="search-dropdown">
    </div>
</div>
 <br>
 <div class="text-start fw-bold text-black" v-if="isImpersonating && isAdmin" @click="returnToAdmin" style="cursor: pointer;" > <i class='bx bx-arrow-back'></i> To Admin Dashboard
</div>

  <nav class="second-navbar d-none" :class="{'sidebar-visible': isSidebarVisible}">
    <div class="nav-item d-none" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
     
      <a href="#" class="department-name">{{ department.department_name }}</a>
      <div class="dropdown-content align-items justify-content-center">
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
          <a href="#" class="category-name">{{ category.category_name }}</a>
          <div v-if="category.subcategories.length" class="subcategory-container">
            <router-link
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories"
              :key="subcategoryIndex"
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item d-none" data-dropdown>
        <a href="/view_brands">BRANDS</a>
    </div>
  </nav>
</div>
 
<div class="influencer-category-navbar-sm">
<!-- Main Sidebar -->
<div class="category-sidebar d-none" :class="{'open': sidebarOpen}" id="sidebar" ref="sidebar">  
 
  <div class="sidebar-links d-flex justify-content-end position-relative text-black" @click="toggleSidebar">
      <img 
      :src="require('@/assets/images/Influencer_1.png')" 
      alt="Back" 
      class="category-image h-100 w-100"

    />
    <i class='bx bx-x position-absolute top-0 end-0'></i>
  </div>
  <!-- Departments Loop -->
  <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
    <a href="#" class="sidebar-item department-name-sidebar text-start fw-bold" @click.prevent="toggleSubSidebar(departmentIndex)">
      {{ department.department_name }}
    </a>
   
    <!-- Sub-Sidebar for Categories -->
    <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar">
      <div class="back-btn d-flex justify-content-center align-items-center position-relative text-black" @click="toggleSubSidebar(departmentIndex)">
        <img 
        :src="require('@/assets/images/Influencer_1.png')" 
        alt="Back" 
        class="category-image h-100 w-100"

      />
      <span class="position-absolute top-50 translate-middle text-white fw-bolder">{{ department.department_name }}</span>
        <i class='bx bx-x position-absolute top-0 end-0 text-black fs-5'></i>
      </div> 
      <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
        <a href="#"
        class="sidebar-item dropdown-toggle category-name text-start d-flex justify-content-between"
        data-bs-toggle="collapse"
           :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex"
           aria-expanded="false"
           :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
          {{ category.category_name }}
        </a>
 
        <!-- Only show subcategories when toggle button is clicked -->
        <div :id="'categoryDropdown' + departmentIndex + categoryIndex" class="collapse">
          <div v-if="category.subcategories.length" class="subcategory-container text-black text-start">
            <router-link
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories"
              :key="subcategoryIndex"
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-item text-start d-none">
    <a href="/view_brands">Brands</a>
 
</div>
</div>
</div>
 
<!-- Sidebar Container -->
<div id="right-sidebar" :class="{'open':isRightSidebarOpen}">
  <!-- Close Button -->
  <div class="view-cart-header d-flex justify-content-between fixed-top">
    <div class="back-button" v-if="checkoutInfluencer" @click="handleBackButtonClick()">
      <i class="fa-solid fa-arrow-left"></i>
    </div>
    <h5 v-else class="text-start fw-bold text-black">Cart</h5>
    <div class="close-btn-cart text-black fw-bolder" @click="isRightSidebarOpen = false">
      <i class='bx bx-x'></i>
    </div>
  </div>
  
 <!-- Display cart items by influencer if there's more than one influencer -->
<div v-if="!checkoutInfluencer && cartItemsByInfluencer.length > 1" class="sidebar-content">
  <div v-for="(items, index) in cartItemsByInfluencer" :key="index" class="cart-cont">
    <!-- Only show the first product for each influencer -->
    <div v-for="item in items.items.slice(0, 1)" :key="item.id" class="cart-item">      
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
       
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
   
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
        </div>
      </div>
    </div>
    <div class="cart-view-container">

      <!-- Display the total count of products for each influencer on the "View Cart" button -->
      <a href="" class="add-to-cart text-white fw-bold" @click.stop.prevent="viewInfluencerCart(items.items[0].seller_id)">View {{ items.items[0].brand }}'s Cart    ({{ items.items.length }})</a>    
    </div>

  </div>
</div>
  <!-- Display selected influencer's cart items -->
<div v-else-if="checkoutInfluencer" class="sidebar-content">
  <div v-for="item in cartItemsByInfluencer.find(influencer => influencer.seller_id === checkoutInfluencer).items" :key="item.id" class="cart-item">
    <img :src="item.image" alt="Product image" class="cart-item-image" />
    <div class="cart-item-details d-flex text-black">
      <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
     
      <p class="product-brand mb-1"> {{ item.brand }}</p>
      <p class="mb-0">size: {{ item.size }}</p>
      <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
      <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
      <div class="d-flex align-items-center">
        <span class="me-2">Qty:</span>
        <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
          <i class="fa-solid fa-trash"></i>
        </button>
        <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
        <span class="quantity mx-2">{{ item.quantity }}</span>
        <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
      </div>
    </div>
  </div>
  
  <div class="view-cart-btn">
    <a href="/shipping_address" class="add-to-cart text-white">Checkout <span class="mx-3">-</span> R{{ calculateTotal(cartItemsByInfluencer.find(influencer => influencer.seller_id === checkoutInfluencer).items)  }}</a>
  </div>
</div>
  <!-- Display cart items if there's only one influencer -->
  <div v-else class="sidebar-content">
  <!-- Sidebar Content -->
    <div v-if="cartItems.length === 0" class="empty-cart-message text-center">
      <i class="mt-5 fa fa-cart-plus fa-3x"></i> <!-- Shopping cart icon -->
      <p class="mt-3 fs-6 fw-bold">Your cart looks lonely!</p>
      <p class="fs-7 ">Add Some Products and Make It Happy.</p>
    </div>
    <div v-else>
    <div v-for="item in cartItems" :key="item.id" class="cart-item">
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
       
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
 
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
      </div>
    </div>
      </div>
      <div class="view-cart-btn">
        <a href="/shipping_address" class="add-to-cart text-white" @click="setCheckoutInfluencer(cartItems[0].seller_id)">Checkout <span class="mx-3">-</span> R{{ calculateTotal(cartItems) }}</a>
      </div>
    </div>
  </div>
 
</div>

<!-- Upload Modal -->
<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn text-black d-none" id="backButtonHeader" @click="handleBackClick">
          <i class="fas fa-arrow-left"></i>
        </button>
        <!-- Title (Visible in both upload and preview modes) -->
        <h5 class="modal-title fw-bolder" id="uploadModalLabel">Create Content</h5>
        <button type="button" class="action-btn fw-bold" id="nextButton" @click="handleNextClick" v-if="currentStep !== 'upload' && videoFile">
          {{ currentStep === 'productUpload' ? 'Upload' : 'Next' }}
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Upload Section -->
        <div id="uploadSection">
          <div class="text-center">
            <!-- Label acts as the file input trigger -->
            <label for="videoUpload" class="video-upload-label" id="uploadLabel">
              <i class="fas fa-cloud-arrow-up fs-1 mb-4"></i>
              <span>Tap to upload</span>
            </label>
            <!-- Hidden file input -->
            <input type="file" id="videoUpload" accept="video/*" class="d-none" @change="previewVideo">
          </div>
        </div>
       
 
        <!-- Preview Section -->
        <div id="previewPage" class="d-none">
 
          <div class="upload-box-video">
          <video id="videoPreview" controls autoplay></video>
        </div>
        </div>
 
        <!-- Cover Photo Selection Section -->
        <div id="coverPhotoSection" class="d-none">
          <div class="row">
            <!-- Left Column: Image Preview -->
            <div class="col-md-6">
              <div class="image-preview-container">
                <img id="autoPreview" src="" alt="Automatically Selected Preview">
              </div>
            </div>
 
            <!-- Right Column: Cover Photo Selection -->
            <div class="col-md-6">
              <div class="form-group mb-4 mt-4">
                <label for="caption" class="text-label fw-bold">Caption:</label>
 
                <input type="text" v-model="caption" id="caption" class="form-control form-control-sm" @input="showCaptionError = false">
                <small v-if="showCaptionError" class="text-danger text-xxs">Please provide a caption.</small>
              </div>
              <div class="mb-4">
                <div class="justify-content-between d-flex">
                  <h6 class="fw-bold">Select Cover Photo</h6>
                  <h6 class="fw-bold device-cover" @click="triggerFileInput">
                    Choose from Device
                  </h6>
                </div>
                <!-- Thumbnail Strip -->
                <div class="thumbnail-strip">
                  <div class="thumbnails" id="thumbnailsContainer"></div>
                </div>
                <!-- Slider -->
                <input
                  type="range"
                  id="frameSlider"
                  class="form-range text-black"
                  min="0"
                  max="100"
                  step="1"
                  @input="updatePreviewFromSlider($event.target.value)"
                  >
                <input
                  type="file"
                  id="coverPhotoUpload"
                  accept="image/*"
                  class="file-input"
                  style="display: none;"
                  @change="previewCoverPhoto"
                >
              </div>
            </div>
          </div>
        </div>
 
        <div id="productUploadSection" class="d-none">
          <div class="row">
 
              <div class="col-md-6">
                <div class="image-preview-container mt-2">
                  <img id="coverPhotoPreview" :src="selectedCoverPhoto" alt="Selected Cover Photo">
                </div>
              </div>
 
              <div class="col-md-6">
                <ul class="nav nav-tabs mt-2 mb-4" id="my_tab" role="tablist">
                  <li class="nav-item-upload" role="presentation">
                    <button class="nav-link" id="upload-tab" data-bs-toggle="tab" data-bs-target="#upload" type="button" role="tab" aria-controls="upload" aria-selected="false" @click="switchTab('upload')">Upload Products</button>
                  </li>
                  <li class="nav-item-upload d-none" role="presentation">
                    <button class="nav-link text-muted" id="tag-tab" data-bs-toggle="tab" data-bs-target="#tag" type="button" role="tab" aria-controls="tag" aria-selected="true" @click="switchTab('tag')" disabled>Tag Products</button>
                  </li>
                </ul>
             
              <div class="tab-content" id="my_tab_content">
 
              <div class="tab-pane fade show active" id="upload" role="tabpanel" aria-labelledby="upload-tab">
                  <!-- Upload Products tab content -->
 
                 
                <div class="modal-body">
                  <!-- Add the form tag here -->
                  <form id="uploadForm" >
                    <!-- <div id="imageUploadContainer"> -->
                      <div class="upload-group">
                        <!-- <div class="form-section"> -->
                          <div class="row">
                            <!-- Image Upload Section -->
                            <div class="upload-image-container">
                              <div class="upload-box-image mb-3">
                                <label for="image_url" class="upload-label">
                                  <!-- Show upload icon when no image is uploaded -->
                                  <div v-if="!imageUploaded" class="mt-3">
                                    <i class="fa fa-cloud-upload fs-4"></i>
                                    Upload Image
                                  </div>
                                  <!-- Show uploaded image preview -->
                                  <img
                                    v-if="imageUploaded"
                                    id="imagePreview"
                                    class="image-preview mt-3"
                                    :src="imagePreviewUrl || require('@/assets/images/no-image.png')"
                                    alt="Image Preview"
                                  />
                                </label>
                              </div>
                            </div>
                            <input type="file" id="image_url" accept="image/*" class="d-none" @change="previewImage" multiple>
                            <!-- Form Fields Section -->
                              <div class="form-group">
                                <input type="text" id="product_name" name="productName" class="form-control form-control-sm" placeholder="Product name" required @input="showProductNameError = false">
                                <small v-if="showProductNameError" class="text-danger text-xxs">Product name is required.</small>
                              </div>
         
                              <div class="form-group mt-2">
                                <textarea id="description" name="description" class="form-control form-control-sm" placeholder="Add description/condition" required @input="showDescriptionError = false"></textarea>
                                <small v-if="showDescriptionError" class="text-danger text-xxs">Description is required.</small>
                              </div>
 
                              <div class="input-group input-group-sm mt-2">
 
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">R</span>
                                </div>
                                <input type="text" class="form-control form-control-sm price" placeholder="Price " id="price" name="color_price" required @input="showPriceError = false">
                              </div>
                              <small v-if="showPriceError" class="text-danger text-xxs">Enter a valid price.</small>

                              <!-- </div> -->
 
                              <div class="form-group mt-2">
                                <div class="d-flex align-items-center">
                                  <label class="size-label mr-2 text-sm">Size:</label>
                                  <select
                                    v-model="selectedSize"
                                    @change="onSizeChange"
                                    class="form-select form-select-sm size-dropdown"
                                    required
                                  >
                                    <option v-for="size in sizes" :key="size.id" :value="size.id">
                                      {{ size.size }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <small v-if="showSizeError" class="text-danger text-xxs">Please select a size.</small>


                              <div class="show-info">
                                <p class="text-start product-info">
                                  Customize Product 
                                  <span @click="toggleInfo" class="info-icon">
                                    <i class="fas fa-info-circle "></i>
                                  </span>
                                </p>
                                <p class="text-start text-sm" v-if="showInfo">
                                  Make Your Products <span class="fw-bolder">Stand Out</span> ✨ <br />
                                  Want to attract more buyers and increase your sales? Adding detailed product information helps shoppers find exactly what they're looking for! Here's how you can enhance your listings:
                                  <ul>
                                    <li>✅ <span class="fw-bold">Add color & more sizes</span>  - Give customers options that match their preferences.</li>
                                    <li>✅ <span class="fw-bold"> Choose the right categories </span> - Ensure your products appear in relevant searches.</li>
                                    <li>✅ <span class="fw-bold"> Highlight key details </span> - Make your items more appealing with complete descriptions.</li>
                                  </ul>
                                  <strong>Steps:</strong><br />
                                  Go to your profile → Click on 'Inventory' → Select the product you'd like to update → Click 'Edit' and add all the extra details.
                                </p>
                              </div>
                             
                             
                             
                             
                              <div v-if="uploadedProducts.length > 0">                              
                                <div class="form-group mt-2">
 
 
                                <label for="products" class="text-label fw-bold">Uploaded Products:</label>
                                <ul class="list-unstyled d-flex flex-wrap">
                                  <li v-for="(product, index) in uploadedProducts" 
                                      :key="index" 
                                      class="d-flex align-items-center mx-2 mb-2 position-relative">
                                    <img :src="product.imagePreviewUrl" alt="Image" class="product-image-thumbnail">
                                    <span @click="removeUploadedProduct(product)" 
                                          class="position-absolute top-1 end-0 rounded-circle-x p-1" >
                                      &#x2716;
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              </div>
                              <div class="d-flex justify-content-end mt-4">
                                <button v-if="imageUploaded" type="button" class="action-btn" @click="uploadImage">Save</button>
                                <button v-else-if="uploadedProducts.length === 0" type="button" class="action-btn" @click="uploadVideoContent">Save as Draft</button>
                              </div>
 
                             
                          </div>
                        </div>
                  </form>
                </div>
            </div>
 
              <div class="tab-pane fade " id="tag" role="tabpanel" aria-labelledby="tag-tab">
                <!-- Tag Products tab content -->
       
                <div class="form-group position-relative">
 
                  <input type="text" v-model="tagInput" id="tagInput" class="form-control mb-4" placeholder="Type @ to tag brands" @keyup="filterBrands" @blur="validateBrand">
                  <!-- Dropdown for brands -->
                  <ul v-if="showBrandDropdown" class="dropdown-menu show">
                    <li v-for="brand in filteredBrands" :key="brand.id" @click="selectBrand(brand)" class="dropdown-item">
                      {{ brand.brand_name }}
                    </li>
                  </ul>
                  <small v-if="brandError" class="text-danger">Please select a valid brand from the list.</small>
                </div>
                <div class="form-group position-relative">
                  <input type="text" v-model="searchQuery" id="products" class="form-control" placeholder="Type product name" @keyup="filterTaggedProducts">
                  <!-- Dropdown for products -->
                  <ul v-if="showProductDropdown" class="dropdown-menu show">
                    <li v-for="product in filtered_tagged_products" :key="product.product_id" @click="selectProduct(product)" class="dropdown-item d-flex align-items-center">
                      <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                      <span>{{ product.product.product_name }}</span>
                    </li>
                  </ul>
                </div>
                <!-- Display selected products -->
 
                <div v-if="selectedProducts.length > 0">
                  <div class="form-group mt-2">
                  <label for="products" class="text-label fw-bold">Selected Products:</label>
                  <ul class="list-unstyled">
 
                    <li v-for="product in selectedProducts" :key="product.id" class="d-flex text-start">
                      <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                      <span>{{ product.product.product_name }}</span>
                        <span @click="removeProduct(product)" class="ml-2 text-danger">&#x2716;</span>
                    </li>
                  </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
     
    </div>
  </div>
</div>
 
 