import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },
  data: () => ({
    form: { account_holder: '', bank_name: '', branch_code: '', account_number: '', account_type: '' },
    view_form: { id:null,account_holder: '', bank__name: '', bank__branch_code: '', account_number: '', account_type__account_type: '' },
    banks: [],
    account_types: [],
    banking_details: null,
    isEditing: false,
    selectedBankName: '',
    showDropdown: false, // Dropdown visibility toggle
    isImpersonatingAdmin: false,
    userRole: null, // Store the user role
 

  }),

  methods: {
    onlyNumbers(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },
    async checkImpersonationStatus() {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/user_info/`, {
        headers: { 
          'Authorization': `Token ${localStorage.getItem('token')}` 
        }
      });
      try {
       
        const data = await response.json();
        this.userRole = data.role_name; // Log the user role for debugging
        if (data.role_name ===  'ADMIN') {
          this.isImpersonatingAdmin = true;
          this.userRole = data.role_name; // Store the user role
          
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    async fetch_banks() {
      const BANKS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banks/`;

      try {
        const response = await fetch(BANKS_API);

        if (!response.ok) {
          console.error('Failed to fetch banks, status:', response.status);
          throw new Error('Failed to fetch banks');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.banks = data;
        } else {
          console.error("Expected an array of banks, got:", data);
        }
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    },

    async fetch_account_types() {
      const ACCOUNT_TYPES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_account_types/`;

      try {
        const response = await fetch(ACCOUNT_TYPES_API);

        if (!response.ok) {
          console.error('Failed to fetch account types, status:', response.status);
          throw new Error('Failed to fetch account types');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.account_types = data;
        } else {
          console.error("Expected an array of types, got:", data);
        }
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    },
    update_branch_code() {
      const selected_bank = this.banks.find(bank => bank.id === this.form.bank_name);
      if (selected_bank) {
        this.form.branch_code = selected_bank.branch_code;
        this.form.bank_name = selected_bank.name;
      } else {
        this.form.branch_code = '';
        this.form.bank_name = '';
      }
    },
    async update_banking_details() {
      const token = localStorage.getItem('token');
        if (!token) {
          Swal.fire('Error', 'Not authenticated', 'error');
          return;
        }
        if (!this.view_form.id) {
          throw new Error("No banking details selected for update");
        } 

      try {
           
        // Construct the API URL
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_banking_details/${this.view_form.id}/`;
    
        // Send the request
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(this.view_form),
        });
        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.detail || errorData.message || Object.values(errorData).flat().join(', ');
          throw new Error(errorMessage);
        }
        // Refetch the data after successful update
        await this.fetch_banking_details();
        Swal.fire({
          title: 'Success',
          text: 'Banking details updated!',
          icon: 'success',
          iconColor:'#F99E1B',
          confirmButtonColor:'#000',
          confirmButtonText: 'OK'
        }).then(() => {
          // Reload the page after the user clicks "OK"
          window.location.reload();
        });
        this.isEditing = false;
    
      } catch (error) {
        console.error('Update error:', error);
        Swal.fire('Error', error.message, 'error');
      } finally {
        this.isSaving = false;
      }
    },
    async fetch_banking_details() {
      const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banking_details/`;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(BANKING_DETAILS_URL, {
          headers: { 'Authorization': `Token ${token}` }
        });

        if (response.ok) {
          const data = await response.json();
          const matchedBank = this.banks.find(b => b.id === data.bank_id);

          const branchCode = data.branch_code || 
                            data.bank__branch_code || 
                            matchedBank?.branch_code || '';

          // Update view_form with ALL fields
          this.view_form = {
            id: data.id,
            account_holder: data.account_holder,
            bank_id: data.bank_id,
            branch_code: branchCode, // Derived from bank_id
            account_number: data.account_number,
            account_type_id: data.account_type_id, // Ensure this field is included
            bank__name: data.bank_name // Ensure this field is included
          };
          this.banking_details = data; 
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire('Error', error.message, 'error');
      }
    },

    updateSelectedBankName() {
        const selectedBank = this.banks.find(bank => bank.id === this.view_form.bank_name);
        this.selectedBankName = selectedBank ? selectedBank.name : '';
      },
    async save_banking_details() {
      const token = localStorage.getItem('token');
      const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/add_banking_details/`; 
      const formData = {
        bank_id: this.form.bank_name,
        account_holder: this.form.account_holder,
        branch_code: this.form.branch_code,
        account_number: this.form.account_number,
        account_type_id: this.form.account_type
      };

      try {
        const response = await $.ajax({
          url: BANKING_DETAILS_URL,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          data: JSON.stringify(formData),
        });

        if (response.status ==='success') {
            Swal.fire ({
                icon: 'success',
                iconColor:'#F99E1B',
                confirmButtonColor:'#000',
                text: response.message,
                title: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                window.location.reload();
            })
          } else {
            throw new Error('Failed to save banking details');
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire('Error', 'An error occurred while saving your banking details.', 'error');
      }
    },

    enableEditing() {
      this.isEditing = true;
    },

    resetForm() {
      this.form = {
        account_holder: '',
        bank_name: '',
        branch_code: '',
        account_number: '',
        account_type: ''
      };
    },

    getBankName(bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return bank ? bank.name : '';
    },

    getAccountTypeName(accountTypeId) {
      const accountType = this.account_types.find(type => type.id === accountTypeId);
      return accountType ? accountType.account_type : '';
    },
  },

  mounted() {
    this.fetch_banking_details();
    this.fetch_banks();
    this.fetch_account_types();
    this.checkImpersonationStatus();
  },
  computed: {
    selectedBankName() {
      return this.banks.find(b => b.id === this.view_form.bank_id)?.name || '';
    },
    selectedAccountType() {
      return this.account_types.find(t => t.id === this.view_form.account_type)?.account_type || '';
    }
  },
};