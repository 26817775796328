import { createRouter, createWebHistory } from 'vue-router'
import EventBus from '@/eventBus.js';

import Register from '@/views/Register/RegisterView.vue'
import Login from '@/views/Login/LoginView.vue'
import Navbar from '@/components/Brand/Navbar/NavbarView.vue'
import Sidebar from '@/components/Brand/Sidebar/SideView.vue'
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Content_Upload from '@/views/Brand_Management/Content_Upload/ContentUploadView.vue'
import Manage_Content from '@/views/Brand_Management/Manage_Video/ManageView.vue'
import Inventory from '@/views/Brand_Management/Inventory/InventoryView.vue'
import Brand from '@/views/Brand_Management/Brand/BrandView.vue'
import update_brand from '@/views/Brand_Management/Update_brand/UpdateBrand.vue'
import View_brands from '@/views/Brand_Management/View_Brands/ViewBrands.vue'
import View_brands_products from '@/views/Brand_Management/View_Brand_Products/ViewBrandProducts.vue'
import update_user from '@/views/Brand_Management/update_user/UpdateUser.vue'
import Team from '@/views/Brand_Management/Team/TeamView.vue'
import Home from '@/views/Home/HomeView.vue'
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue'
import Product_navbar from '@/components/Products/ProductNavbarView.vue'
import drop_off from '@/views/Brand_Management/Drop_off/DropOffView.vue'

// Admin
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue'
import Admin_dashboard from '@/views/Admin_Management/AdminDashView.vue'
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'
import Admin_brands from '@/views/Admin_Management/Admin_Brands/AdminBrandsView.vue'

// Influencer
import Influencer from '@/views/Influencer_Management/InfluencerDashView.vue'
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue'
import Influencer_profile from '@/views/Influencer_Management/Influencer_profile/InfluencerProfileView.vue'
import View_Influencer from '@/views/Influencer_Management/View_Influencers/ViewInfluencer.vue'
import View_Influencer_Profile from '@/views/Influencer_Management/View_Influencers/View_Influencer_Profile/ViewInfluencerProfile.vue'
import ViewInfluencerBuyerOrdersView from '@/views/Influencer_Management/Influencer_Buyer_Orders/ViewInfluencerBuyerOrdersView.vue';
import DropoffView from '@/views/Influencer_Management/Delivery_address/drop_off/DropoffView.vue';

// Customer
import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Customer_sidebar from '@/components/Customer/Customer_Sidebar/CustomerSidebarView.vue'
import Customer_dashboard from '@/views/Customer_Management/CustomerDashView.vue'
import Customer_profile from '@/views/Customer_Management/Customer_Profile/CustomerProfileView.vue'
import View_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue'
import Product_detail from '@/views/Customer_Management/Customer_Search_Products/ProductDetailView.vue'

import Filter_product from '@/components/Products/FilterProductView.vue'
import Search_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue'
import Search_Brand from '@/views/Customer_Management/Search_Brand/SearchBrandView.vue'
import Search_Influencer from '@/views/Influencer_Management/SearchInfluencer/SearchInfluencerView.vue'



import Product_view from '@/views/Home/Products/ProductsView.vue'
import New_Collection from '@/views/Home/Products/New_collection/NewCollectionView.vue'
import view_cart from '@/views/Home/Products/View_Cart/CartView.vue'
import Wish_list from '@/views/Influencer_Management/Wishlist/AddWishView.vue'
import Orders from '@/views/Brand_Management/Orders/OrdersView.vue'
import Delivery  from '@/views/Influencer_Management/Delivery_address/DeliveryView.vue'
import view_address from '@/views/Influencer_Management/Delivery_address/view_address/AddressView.vue'
import shipping_address from '@/views/Influencer_Management/Checkout/AddressOptionsView.vue'
import checkout_navbar from '@/components/Influencer/checkout_navbar/CheckoutNavbarView.vue'

import SubcategoryProductsPage from '@/views/Home/Products/Categories/CategoriesView.vue'
import tagged from '@/views/Brand_Management/Tagged/TaggedView.vue'
import influencers from '@/views/Admin_Management/Influencers/InfluencersView.vue'
import influencer_Order from '@/views/Influencer_Management/Influencer_Orders/InfluencerOrderView.vue'

import Transactions from '@/views/Admin_Management/Admin_Transactions/TransactionView.vue'
import Commission from '@/views/Admin_Management/Admin_Commission/CommissionView.vue'
import Admin_Wallet from '@/views/Admin_Management/Admin_Wallets/AdminWalletView.vue'



import orders from '@/views/Admin_Management/Orders/OrdersView.vue'
import Add_banking_details from '@/views/Influencer_Management/Add_Banking_Details/AddBankingDetailsView.vue'
import Influencer_inventory from '@/views/Influencer_Management/Influencer_Inventory/InfluencerInventoryView.vue'

import Fullscreen_navbar from '@/components/Home/Fullscreen_Navbar/FullscreenNavbarView.vue'
import OrderDetailsView from '@/views/Influencer_Management/Influencer_Buyer_Orders/OrderDetailsView.vue';
import OrderReturnsView from '@/views/Influencer_Management/Returns/order_returns/OrderReturnsView.vue';
import ForgotPasswordView from '@/views/ForgotPassword/ForgotPasswordView.vue';
import AdminViewUsersView from '@/views/Admin_Management/Admin_User_Management/AdminViewUsersView.vue';
import ResetPasswordView from '@/views/Reset_Password/ResetPasswordView.vue';
import TermsView from '@/views/Home/TermsView.vue';




<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
    integrity="sha384-k6RqeWeci5ZR/Lv4MR0sA0FfDOM/NmYd8xEUh6T9xQ+Lz5FHR4D0l4lm1Jv3V7z"
    crossorigin="anonymous"
  />


const routes = [
  {
  path: '/',
    name: 'home',
    component: Home,
    meta: { showSidebarIcon: false } // Add this line

  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/navbar',
    name: 'navbar',
    component: Navbar
  },
  {
    path: '/sidebar',
    name: 'sidebar',
    component: Sidebar
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

  {
    path: '/content_upload',
    name: 'content_upload',
    component: Content_Upload
  },
  {
    path: '/brand',
    name: 'brand',
    component: Brand
  },

  {path: '/update_brand',
    name: 'update_brand',
    component: update_brand
  },

  {
    path: '/view_brands',
    name: 'view_brands',
    component: View_brands,
  },

  {
    path: '/view-brands-products/:brandId',
    name: 'view-brands-products',
    component: View_brands_products,
  },

  { 
   path: '/manage_content/:video_id',
    name: 'manage_content',
    component: Manage_Content },
    {path: '/update_user',
      name: 'update_user',
      component: update_user
    },
  
    {
      path: '/inventory',
      name: 'inventory',
      component: Inventory
    },
    {
      path: '/add_user',
      name: 'add_user',
      component: Team
    },

    {
      path: '/Home_navbar',
      name: 'Home_navbar',
      component: Home_navbar
    },
    // Admin
    {
      path: '/Admin_sidebar',
      name: 'Admin_sidebar',
      component: Admin_sidebar
    },
    {
      path: '/Transactions',
      name: 'Transactions',
      component: Transactions
    },
    {
      path: '/Admin_Wallet',
      name: 'Admin_Wallet',
      component: Admin_Wallet
    },
    {
      path: '/Commission',
      name: 'Commission',
      component: Commission
    },
    

    {
      path: '/Admin_navbar',
      name: 'Admin_navbar',
      component: Admin_navbar
    },
    {
      path: '/Admin_dashboard',
      name: 'Admin_dashboard',
      component: Admin_dashboard
    },

    // Influencer
    {
      path: '/Influencer',
      name: 'Influencer',
      component: Influencer
    },

    {
      path: '/Influencer_navbar',
      name: 'Influencer_navbar',
      component: Influencer_navbar
    },

    {
      path: '/Influencer_sidebar',
      name: 'Influencer_sidebar',
      component: Influencer_sidebar
    },

    {
      path: '/Influencer_profile',
      name: 'Influencer_profile',
      component: Influencer_profile,
      meta: { showSidebarIcon: true } // Add this line

    },

    {
      path: '/View_Influencer',
      name: 'View_Influencer',
      component: View_Influencer
    },

    {
      path: '/View_Influencer_Profile/:influencerId',
      name: 'view_Influencer_Profile',
      component: View_Influencer_Profile
    },
    {
      path: '/search_product',
      name: 'search_product',
      component: Search_product
    },
    {
      path: '/search_brand',
      name: 'Search_Brand',
      component: Search_Brand
    },
    {
      path: '/search_Influencer',
      name: 'Search_Influencer',
      component: Search_Influencer
    },
    // Customer
    {
      path: '/Customer_navbar',
      name: 'Customer_navbar',
      component: Customer_navbar
    },

    {
      path: '/Customer_sidebar',
      name: 'Customer_sidebar',
      component: Customer_sidebar
    },

    {
      path: '/Customer_dashboard',
      name: 'Customer_dashboard',
      component: Customer_dashboard
    },

    {
      path: '/Customer_profile',
      name: 'Customer_profile',
      component: Customer_profile
    },
    {
      path: '/product_navbar',
      name: 'Product_navbar',
      component: Product_navbar
    },

    {
      path: '/View_product',
      name: 'View_product',
      component: View_product,
    },
    {
      path: '/product/:id',
      name: 'product',
      component: Product_view,
    },

    {
      path: '/new_collection',
      name: 'new_collection',
      component: New_Collection,
    },


    {
      path: '/product_detail/:id/:video_id?',
      name: 'product_detail',
      component: Product_detail,
    },

    {
      path: '/filter_product',
      name: 'filter_product',
      component: Filter_product,
    },

    {
      path: '/cart',
      name: 'view_cart',
      component: view_cart,
    },

    {
      path: '/wishlist',
      name: 'wishlist',
      component: Wish_list,
      meta: { showSidebarIcon: true }
    },

    {
      path: '/delivery',
      name: 'delivery',
      component: Delivery,
    },

    {
      path: '/addresses',
      name: 'addresses',
      component: view_address,
    },

    {
      path: '/shipping_address',
      name: 'shipping_address',
      component: shipping_address,
    },

    {
      path: '/checkout_navbar',
      name: 'checkout_navbar',
      component: checkout_navbar,
    },
    
    {
      path: '/subcategories/:subcategory/products',
      name: 'subcategory-products',
      component: SubcategoryProductsPage
    },

    {
      path: '/tagged',
      name: 'tagged',
      component: tagged
    },

    {
      path: '/orders',
      name: 'orders',
      component: Orders,
      meta: { showSidebarIcon: true }
    },
    {
      path: '/influencer_Order',
      name: 'influencer_Order',
      component: influencer_Order,
      meta: { showSidebarIcon: true }

    },

    {
      path: '/all_influencers',
      name: 'al_influencers',
      component: influencers
    },

    {
      path: '/all_brands',
      name: 'all_brands',
      component: Admin_brands
    },


    {
      path: '/view_orders',
      name: 'View_orders',
      component: orders,
    },


    {
      path: '/add_banking_details', 
      name: '/add_banking_details',
      component: Add_banking_details,
      meta: { showSidebarIcon: true }

    },

    {
      path: '/influencer_inventory', 
      name: '/influencer_inventory',
      component: Influencer_inventory,
      meta: { showSidebarIcon: true }

    },

    {
      path: '/Fullscreen_navbar',
      name: 'Fullscreen_navbar',
      component: Fullscreen_navbar
    },


    {
      path: '/drop_off',
      name: 'drop_off',
      component: drop_off
    },
    {
      path: '/influencer_buyer_orders',
      name: 'influencer_buyer_orders',
      component: ViewInfluencerBuyerOrdersView,
      meta: { showSidebarIcon: true }

    },
    {
      path: '/order/:orderId',
      name: 'OrderDetails',
      component:OrderDetailsView,
      meta: { showSidebarIcon: true }

    },
    {
      path: '/forgot_password',
      name: 'forgot_password',
      component:ForgotPasswordView
    },
    {
      path: '/admin_view_users',
      name: 'admin_view_users',
      component:AdminViewUsersView
    },
    {
      path: '/reset_password/:uidb64/:token',
      name: 'ResetPassword',
      component: ResetPasswordView,  // The component that renders the form
    },
    {
      path: '/terms-and-conditions', // The URL path
      name: 'TermsPage',
      component: TermsView, // The component to display
    },
    {
      path: '/drop_off_point',
      name: 'drop_off_point',
      component: DropoffView,
      meta: { showSidebarIcon: true }

    },
   
    {
      path: '/order_return/:orderId/:itemId',
      name: 'order_return',
      component: OrderReturnsView
    },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function set_token(token) {
  localStorage.setItem('token', token);
  // Store the current timestamp
  const loginTime = new Date().getTime();
  localStorage.setItem('login_time', loginTime);

}
 
export function get_token() {
 
  var encrypted_token = localStorage.getItem('token');

  return encrypted_token;
 
}

export function set_user_details(user_details) {
 
  var user_role = user_details.role.role;
  var first_name = user_details.first_name;
  var last_name = user_details.last_name;
  var user_id = user_details.id;
  var email = user_details.email;
 
  localStorage.setItem('user_role', user_role);
  localStorage.setItem('first_name', first_name);
  localStorage.setItem('last_name', last_name);
  localStorage.setItem('user_id', user_id);
  localStorage.setItem('email', email);
 
}
 
export function get_user_details() {
 
  const user_role = localStorage.getItem('user_role');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const user_id = localStorage.getItem('user_id');
  const email = localStorage.getItem('email');
 
 
  var user_details = {
    "user_role":user_role,
    "first_name":first_name,
    "last_name":last_name,
    "user_id":user_id,
    "email":email
 
  }
 
  return user_details;
 
}
const INACTIVITY_PERIOD = 60 * 60 * 1000; // 30 minutes in milliseconds

export function check_inactivity() {
  const loginTime = localStorage.getItem('login_time');
  const currentTime = new Date().getTime();

  if (currentTime - loginTime > INACTIVITY_PERIOD) {
    // Log out the user
    logout();
  }
}

export function logout() {
  // Clear all user-related data from localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('login_time');
  localStorage.removeItem('user_role');
  localStorage.removeItem('first_name');
  localStorage.removeItem('last_name');
  localStorage.removeItem('user_id');
  localStorage.removeItem('email');

  // Force a page reload to clear any cached data
  window.location.reload();
  // Redirect to the home page or login page
  window.location.href = '/';
}

function reset_inactivity_timer() {
  localStorage.setItem('login_time', new Date().getTime()); // Reset the timer
}

// Attach event listeners to track user activity
window.addEventListener('click', reset_inactivity_timer);
window.addEventListener('keypress', reset_inactivity_timer);

// Periodically check inactivity
setInterval(check_inactivity, 60 * 1000); // Check every minute

export async function fetchCartDetails() {
  const token = localStorage.getItem('token');
  let cartItems = [];
  let cartQuantity = 0;
  let cartTotal = 0;

  try {
    if (token) {
      const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
      const response = await fetch(CART_API_URL, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cart from the server');
      }

      const data = await response.json();
      if (data.cart_products.length > 0) {
        cartItems = data.cart_products;
        cartQuantity = data.total_quantity;
        cartTotal = parseFloat(data.total_price);
      } else {
        cartItems = [];
      }
    } else {
      cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    }

    for (const item of cartItems) {
      const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
      const response = await fetch(GET_IMAGE_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch image URL');
      }
      const imageData = await response.json();
      item.image = imageData;
    }

    cartQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
    cartTotal = cartItems.reduce((total, item) => total + item.quantity * item.price, 0);

    EventBus.emit('cart-updated', cartQuantity);
  } catch (error) {
    console.error('Error fetching cart details:', error);
    cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  }
  return { cartItems, cartQuantity, cartTotal };
}



export default router
