<div>
  <div class="home-navbar">
    <Influencer_sidebar />
  </div>
  <div class="app-content delivery-category">
    <Influencer_navbar />
    <div class="content">
      <div v-if="loading" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <div class="upload-search-row"></div>
      <div class="card table-card">
        <div class="card-body">
          <table id="content_table" class="table align-item table-flush-item content-table" data-sort="true">
            <thead class="thead-light">
              <tr>
                <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Product</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Product Name</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Customer Name</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Code</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Order Date</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Total</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Status</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in orders" :key="order.order_id">
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                </td>
                <td class="text-start">
                  <img :src="order.product_image_url" alt="Profile Image" width="40" height="40">
                </td>
                <td class="align-item text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ order.product_name }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">
                    {{ order.customer?.first_name || order.customer_username }} 
                    {{ order.customer?.last_name || order.customer_lastname }}
                  </span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ order.order_number }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ formatDate(order.order_date) }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">R {{ order.total_cost }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ order.order_statuses.status }}</span>
                </td>
                <td class="text-start">
                  <button class="btn-sm action-btn font-weight-light text-xxs"  @click="viewOrderDetails(order.order_id)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal for Order Details -->
  <div v-if="showOrderDetails" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog custom-modal-width" role="document">
      <div class="modal-content modal-style">
  
        <!-- Modal Header -->
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h4 class="modal-title fw-bold">Order Confirmation</h4>
          <button type="button" class="btn-close" @click="closeOrderDetails" aria-label="Close"></button>
        </div>
  
        <div class="modal-body">
  
          <!-- Order Info Card -->
          <div class="card mb-4 p-4 shadow-sm text-start">
            <h5 class="fw-bold mb-3">Order Information</h5>
            <p class="mb-1"><strong>Order Number:</strong> #{{ selectedOrder?.order_number }}</p>
            <p class="mb-1"><strong>Order Date:</strong> {{ formatDate(selectedOrder?.order_date) }}</p>
            <p class="mb-0"><strong>Status:</strong> {{ selectedOrder?.order_statuses?.status }}</p>
          </div>
  
          <!-- Customer Info Card -->
          <div class="card mb-4 p-4 shadow-sm text-start">
            <h5 class="fw-bold mb-3">Customer Information</h5>
            <div class="d-flex align-items-start flex-wrap">
              <img :src="selectedOrder && selectedOrder.customer && selectedOrder.customer.image ? selectedOrder.customer.image : require('@/assets/images/default-profile.png')" 
              alt="Profile Image" 
              width="80" 
              height="80" 
              class="rounded me-4" />              
              <div>
                <p class="mb-1"><strong>Name:</strong> {{ selectedOrder.customer.first_name }} {{ selectedOrder.customer.last_name }}</p>
                <p class="mb-1"><strong>Email:</strong> {{ selectedOrder.customer.email }}</p>
                <p class="mb-0"><strong>Phone:</strong> {{ selectedOrder.customer.phone_number }}</p>
              </div>
            </div>
          </div>
  
          <!-- Combined Payment & Items Card -->
          <div class="card mb-4 p-4 shadow-sm text-start">
            <h5 class="fw-bold mb-3">Payment Information</h5>
            <p class="mb-1"><strong>Payment Method:</strong> Credit Card</p>
            <p class="mb-1"><strong>Transaction ID:</strong> {{ selectedOrder.payment_transaction.payment_id }}</p>
            <p class="mb-3"><strong>Amount Paid:</strong> R {{ selectedOrder.total_cost }}</p>
  
            <div v-for="item in selectedOrder.order_items" :key="item.id" class="mb-3 d-flex align-items-start">
              <img :src="item.product_details.image_url || 'placeholder.jpg'" alt="Product Image" width="80" height="80" class="rounded me-3" />
              <div>

                <p class="text-dark mb-1">Name:{{ item.product_details.product_name }}</p>
                <p class="text-dark mb-1">Size: {{ item.product_details.size || 'N/A' }}</p>
                <p class="text-dark mb-1">Colour: {{ item.product_details.color || 'N/A' }}</p>
                <p class="text-dark mb-1">Quantity: {{ selectedOrder.order_items[0].quantity_ordered}}</p>
                <p class="text-dark mb-0">Price: R {{ item.unit_price }}</p>
              </div>
            </div>
            <hr class="mt-4 mb-3">
            
            <div class="mt-4">
              <p class="mb-1"><strong>Subtotal:</strong> R{{ selectedOrder.total_cost || 'N/A' }}</p>
              <p class="mb-1"><strong>Discount:</strong> R{{ selectedOrder.discount || '0.00' }}</p>
              <p class="mb-0"><strong>Shipping:</strong> R{{ selectedOrder.deliveries?.[0]?.delivery_cost || 'N/A' }}</p>
            </div>
          </div>
  
          <!-- Delivery Info Card -->
          <div class="card mb-4 p-4 shadow-sm text-start">
            <h5 class="fw-bold mb-3">Delivery Information</h5>
            <p class="mb-1"><strong>Shipping Method:</strong> {{ selectedOrder.deliveries?.[0]?.delivery_method }}</p>
            <p class="mb-0"><strong>Tracking ID:</strong> {{ selectedOrder.deliveries?.[0]?.tracking_number }}</p>
          </div>
  
        </div>
      </div>
    </div>
  </div>
</div>