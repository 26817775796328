<div v-if="UserLoggedIn">
    <Influencer_navbar v-model:search_input="search_query" />
  </div>
  <div v-else>
    <Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
  </div>
  <div v-if="addedToWishlist" class="centered-popup alert-success">
    Successfully added to wishlist!
    <a href="/wishlist" class="alert-link"> WISHLIST</a>
 
  </div>
 
<div v-if="selectedRole === 'brand'" class="container mt-10">
    <div class="row">
        <div class="col-md-6">
            <h1 class="font-weight-bold text-start mb-5">
                Innovate Your Style. <br />
            Showcase your brand's <br />
            identity and craftsmanship.</h1>
            <p class="text-start mb-5 home-font">
            Present your clothing line to customers with a focus on <br />
            quality and unique designs that cater to diverse styles and occasions.
            <br />
            Making it easy for customers to discover and purchase your latest <br />
            collections. Elevate your presence in the market and connect with <br />
            fashion enthusiasts looking to express their personal style!
            </p>
            </div>
        <!-- Right side: Image -->
        <div class="col-md-6 d-flex align-items-center justify-content-center position-relative">
            <img :src="require('@/assets/images/brand-homepg.png')"  class="brand-homepg">
        </div>
    </div>
  </div>
 
<div v-else>
    <div v-if="search_query == ''">
        <div v-if="influencer_videos.length === 0" class="text-center    ">
            <div class="hero">
                <div class="video-container  border-0">
 
                <div class="fade-overlay"></div>
                    <video :src="require('@/assets/images/default_video.mp4')"  muted autoplay controls class="h-100">
                       
                    </video>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="hero-content text-start text-light">
                            <div class="d-flex align-content-center">
                                <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo ">
                                <h3 class="ms-2 mt-n1">Khiosk </h3>
                            </div>  
                            <p></p>
                          </div>
                    </div>
                </div>            
            </div>
        </div>
         
        <div v-else>
            <!-- Check if there are influencer videos -->
            <div v-if="influencer_videos.length > 0">
             
                <div class="hero">
                    <div class="video-container border-0">
                        <div class="fade-overlay"></div>
                        <video ref="heroVideo" playsinline webkit-playsinline loop autoplay controls>
                        <!-- If final_processed_url is .m3u8 (HLS) -> type='application/vnd.apple.mpegurl' -->
                        <source v-if="influencer_videos[0].final_processed_url" :src="influencer_videos[0].final_processed_url" type="application/vnd.apple.mpegurl" />
                        <!-- Fallback to MP4 -->
                        <source :src="influencer_videos[0].video_url" type="video/mp4" />
                      </video>                    
                       
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="hero-content text-start text-light">
                                <div class="d-flex align-content-center">
                                    <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                                    <h3 class="ms-2 mt-n1">Khiosk</h3>
                                </div>
                                <p>{{ influencer_videos[0].caption }}</p> <!-- Display the video caption -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="hero-cards hero-product">
                                <div class="bg-transparent">
                                    <div class="scroller-container">
                                        <div class="sub-scroller" ref="scroller">
                                            <div class="logo-description-container">
                                                <div class="justify-content-end">
                                                    <!-- Check if the video has associated products -->
                                                    <div v-if="influencer_videos[0].products && influencer_videos[0].products.length">
                                                        <router-link v-for="(productObj, index) in influencer_videos[0].products" :key="index" :to="{ name: 'product', params: { id: productObj.product_details.id } }" class="card h-100">
                                                            <img :src="productObj.product.image_url" class="rounded-12 p-1 h-130 mw-90 card-img-top" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                                                            <div class="card-body text-centre">
                                                                <p class="card-text description text-white">{{ productObj.product.product_name }}</p>  
                                                                <p class="card-text description text-white">by {{ productObj.product.brand__brand_name}}</p>                                                                
                                                             
                                                                <!-- Check if product details exist, and display relevant details -->
                                                                <div v-if="productObj.product_details">
                                                                    <h5 class="card-title text-warning fw-bold">R{{ productObj.product_details.price }}</h5>
                                                                </div>
                                                                <div v-else>
                                                                    <p class="text-white">Details not available</p>
                                                                </div>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                    <!-- If there are no products, display a message -->
                                                    <div v-else>
                                                        <p class="text-white">No products available for this video.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>  
             
        <!-- Influencer Colection SECTION-->
        <div class="px-3 d-flow-root subvideo-content">
        <p class="fw-bold text-start text-black">New videos</p>
          <div class="scroller-container mb-3">
            <div class="scroller">
              <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">              
                 <div v-for="(video, index) in influencer_videos" :key="index" class="card">
                   
                    <div class="product_modal">
                    <div class="view-product-list">
                        <!-- Thumbnail or Video -->
                        <div @click="openFullScreen(video, index)">
                            <!-- Show Thumbnail if thumbnail_url exists -->
                        <img  :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />                        
                        </div>
        
                        <!-- Product Images -->
                        <div class="circle-images pb-85">
                        <router-link v-for="(product, imgIndex) in video.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }">
                            <img :src="product.product.image_url" class="circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                       </router-link>
                        </div>                     
                    </div>
                    <div class="text-start ms-1 video-caption d-none">
                        <p class="fw-bold">{{ video.caption }}</p>
                        </div>
                    </div>        
                </div>          
              </div>
            </div>
          </div>
        </div>

         <!-- Influencer Colection SECTION-->
         <div class="px-3 d-flow-root subvideo-content">
            <p class="fw-bold text-start text-black">Most Watched</p>

           <div class="scroller-container mb-3">
             <div class="scroller">
               <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">              
                  <div v-for="(video, index) in random_influencer_videos" :key="index" class="card">                    
                     <div class="product_modal">
                     <div class="view-product-list">
                         <!-- Thumbnail or Video -->
                         <div @click="openFullScreen(video, index)">
                            <!-- Show Thumbnail if thumbnail_url exists -->
                         <img  :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />                        
                         </div>
         
                         <!-- Product Images -->
                         <div class="circle-images pb-85">
                         <router-link v-for="(product, imgIndex) in video.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }">
                             <img :src="product.product.image_url" class="circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                        </router-link>
                         </div>                     
                     </div>
                     <div class="text-start ms-1 video-caption d-none">
                         <p class="fw-bold">{{ video.caption }}</p>
                         </div>
                     </div>        
                 </div>          
               </div>
             </div>
           </div>
         </div>

 
        <div v-if="isFullScreenMode" class="full-screen-container">
            <Fullscreen_navbar/>
            <!-- Close Button -->
            <button class="close-btn" @click="exitFullScreen">
                <i class="bx bx-x"></i>
            </button>
            <!-- Influencer Info -->
           
            <div v-if="isFetching" class="spinner-container">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
 
            <div class="reel-container">
            <div v-for="(reel, index) in reels" :key="index" class="reel" :ref="'videoPlayer_' + index">
                <div v-if="videoErrors[index]" class="video-error-overlay" @click="retryVideo(index)">
                    <p>Video failed to load</p>
                    <span><i class="fa-solid fa-rotate-right"></i></span>
                  </div>
               
                  <video v-else :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin" playsinline webkit-playsinline autoplay controls preload="auto" @error="handleVideoError(index)" @play="playVideoAtIndex(index)">
                    <source v-if="reel.final_processed_url && reel.final_processed_url.endsWith('.m3u8')" :src="reel.final_processed_url"
                      type="application/vnd.apple.mpegurl" />
                    <!-- fallback MP4 -->
                    <source :src="reel.video_url" type="video/mp4" />
                  </video>
 
                <div class="influencer-info d-flex align-items-center">
                    <router-link
                    :to="{ name: 'view_Influencer_Profile', params: { influencerId: reel.influencer.id } }"
                    class="d-flex align-items-center text-decoration-none"  style="cursor: pointer;"
                  >
                    <img :src="reel.influencer.image" class="circle-image rounded-circle me-2"  @error="(event) => event.target.src = require('@/assets/images/default-profile.png')"/>
                   
                    <span class="fw-bold text-light">{{ reel.influencer.first_name }} {{reel.influencer.last_name}}</span>
                </router-link>
                </div>
                <div class="action-icons mt-5">
                  <div class="d-flex flex-column align-items-center text-white" style="cursor: pointer;" @click="toggleLike(reel)">
                    <!-- Heart icon -->
                    <i :class="reel.is_liked ? 'bx bxs-heart text-danger fs-3' : 'bx bxs-heart fs-3'"></i>
                    <!-- Count below the icon, with a small margin-top -->
                    <span class="text-white mt-1">{{ reel.like_count }}</span>
                  </div>
                  <div class="d-flex flex-column align-items-center text-white" style="cursor: pointer;" @click="toggleShareMenu(index)">
                    <!-- Share icon -->
                    <i class="fa-solid fa-share text-white fs-5"></i>
                    <!-- Count below the icon, with a small margin-top -->
                    <span class="text-white mt-1">{{ reel.share_count }}</span>
                  </div>
                  <div v-if="showShareMenu === index" class="modal fade show d-flex align-items-center justify-content-center" @click="closeMenu">
                    <div class="modal-dialog modal-dialog-centered" @click.stop>
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title fs-6">Share Video</h4>
                          <button type="button" class="btn-close" @click="closeMenu" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <!-- Share icons here -->
                          <a @click="copyVideoLinkToClipboard(reel.video_url); shareVideo(reel.id)" class="btn mb-2 text-dark" data-bs-toggle="tooltip" title="Copy Link">
                            <span @click="closeMenu">
                              <i class="bi bi-link fs-3 clipboard-share"></i>
                            </span>
                          </a>
                          <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(reel.video_url)" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share on WhatsApp">
                            <i class="fa-brands fa-square-whatsapp whatsapp-share fs-3"></i>
                          </a>
                          <a :href="'https://www.instagram.com/?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share on Instagram">
                            <i class="fa-brands fa-square-instagram fs-3 instagram-share"></i>
                          </a>
                          <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(reel.video_url) + '&t=' + new Date().getTime()" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share on Facebook">
                            <i class="fa-brands fa-facebook fs-3 facebook-share"></i>
                          </a>
                          <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share on Twitter">
                            <i class="fa-brands fa-x-twitter fs-5 twitter-share"></i>
                          </a>
                          <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(reel.video_url)" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share via Email">
                            <i class="fa-solid fa-envelope-open-text fs-3 email-share"></i>
                          </a>
                          <a :href="'https://t.me/share/url?url=' + encodeURIComponent(reel.video_url)" target="_blank" class="btn mb-2 text-dark" @click="shareVideo(reel.id); closeMenu" data-bs-toggle="tooltip" title="Share on Telegram">
                            <i class="bi bi-telegram fs-3 telegram-share"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            <div class="circle-images pb-85">
                <router-link v-for="(product, imgIndex) in reel.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }" @click.native="enterPictureInPicture(reel, index)">
                    <img :src="product.product.image_url" class="modal-circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                </router-link>
            </div>
            </div>
           
            <div ref="scrollSentinel" class="scroll-sentinel"></div>
           
            </div>
        </div>
       
        <div v-if="isInPiPMode" class="circle-images pb-85">
            <div v-for="(product, index) in currentPipProducts" :key="index">
              <img :src="product.product.image_url" class="modal-circle-image" />
            </div>
          </div>
           
 
    </div>
       
        </div>  
 
        <div class="container-fluid home-product mt-5">            
 
            <div class="row">
 
                    <div class="card card-small home-influencer" @click="navigateToInfluencers">
                        <span>
                            <router-link to="/View_Influencer" class="text-white us-text">Us </router-link>
                         </span>
                    </div>
                </div>
               
               
            </div>
    </div>
 
    <div v-else>
 
        <Search_product :search_query="search_query" :allProducts="allProducts" @update:search_query="update_search_query"/>
 
  </div>
</div>
 
<footer class="footer">
  <div class="footer-content">
    <div class="footer-links row">

      <!-- Delivery Information -->
      <div class="footer-column col-md-3">
        <h2>Delivery Information</h2>
        <ul class="list-unstyled ps-3">
          <li class="d-flex align-items-center">
            <i class="fa-sharp fa-solid fa-rotate-left me-2"></i> <span>Easy returns at any Pargo point</span>
          </li>
          <li class="d-flex align-items-center">
            <i class="fa-sharp fa-solid fa-truck me-2"></i> <span>Nationwide delivery within 3-5 working days</span>
          </li>
          <li class="d-flex align-items-center">
            <i class="fa-solid fa-box-open box-icon me-2"></i> <span>Partnered with Pargo for delivery solutions</span>
          </li>
        </ul>
      </div>

      <!-- Our Location -->
      <div class="footer-column col-md-3">
        <h2>Our Location</h2>
        <ul class="list-unstyled ps-3">
          <li class="d-flex align-items-center">
            <i class="fa-solid fa-location-dot me-2"></i> <span>Rosebank, Johannesburg, South Africa</span>
          </li>
        </ul>
      </div>

      <!-- About Us -->
      <div class="footer-column col-md-3">
        <h2>About Us</h2>
        <ul class="list-unstyled ps-3">
          <li class="d-flex align-items-center">
            <i class="fa-solid fa-circle-info me-2"></i> <span>About Us</span>
          </li>
          <li class="d-flex align-items-center">
            <i class="fa-brands fa-readme me-2"></i> <span>Terms and Conditions</span>
          </li>
        </ul>
      </div>

      <!-- Follow Us -->
      <div class="footer-column col-md-3">
        <h2>Socials</h2>
        <ul class="list-unstyled ps-3">
          <li class="d-flex align-items-center">
            <a href="https://www.instagram.com/khiosk_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" class="d-flex align-items-center">
              <i class="fa-brands fa-instagram instagram-icon me-2"></i> <span>Instagram</span>
            </a>
          </li>
          <li class="d-flex align-items-center">
            <i class="fa-brands fa-whatsapp whatsapp-icon me-2"></i> <span>WhatsApp</span>
          </li>
        </ul>
      </div>

    </div>

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <p>&copy; 2025 Khiosk. All rights reserved.</p>
    </div>
  </div>
</footer>