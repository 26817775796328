<button class="filter-btn d-lg-none d-flex align-item-start" @click="toggleFilterSidebar">Filter</button>
<div :class="['filter-sidebar', { active: isSidebarVisible }]">
  <button v-if="isSidebarVisible" class="close-btn" @click="toggleFilterSidebar">&times;</button>
  <div class="filter-container">
    <h6 class=" text-start  fw-bold">Filter</h6>

    <!-- Department Section -->
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('department')">Department</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'department', 'filter-content hidden': activeDropdown !== 'department' }">
        <div v-for="department in departments" :key="department.id">
          <label>
            <input 
              type="checkbox" 
              :value="department.id" 
              v-model="selectedDepartments" 
              @change="updateCategories"
            > 
            {{ department.department_name }}
          </label>
        </div>
      </div>
    </div>

    <!-- Category Section -->
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('category')">Category</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'category', 'filter-content hidden': activeDropdown !== 'category' }">
        <div v-if="categories.length === 0">No department selected.</div>
        <div v-for="category in categories" :key="category.category_name">
          <label>
            <input 
              type="checkbox" 
              :value="category.category_name" 
              v-model="selectedCategories" 
              @change="updateSubcategories"
            > 
            {{ category.category_name }}
          </label>
        </div>
      </div>
    </div>

    <!-- Subcategory Section -->
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('subcategory')">Sub Category</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'subcategory', 'filter-content hidden': activeDropdown !== 'subcategory' }">
        <div v-if="subcategories.length === 0">No category selected .</div>
        <div v-for="subcategory in subcategories" :key="subcategory">
          <label>
            <input type="checkbox" :value="subcategory" v-model="selectedSubcategories"> {{ subcategory.subcategory_name }}
          </label>
        </div>
      </div>
    </div>
  
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('brands')">Brands</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'brands', 'filter-content hidden': activeDropdown !== 'brands' }">
        <div class="search-box">
          <input type="text" placeholder="Search brands" v-model="brandSearchQuery">
        </div>
        <div v-for="brand in filteredBrands" :key="brand.id">
          <label>
            <input 
              type="checkbox" :value="brand.brand_name" v-model="selectedBrands"
            > 
            {{ brand.brand_name }}
          </label>
        </div>
      </div>
    </div>
  
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('color')">Color</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'color', 'filter-content hidden': activeDropdown !== 'color' }">
        <div v-for="color in colors" :key="color">
          <label>
            <input type="checkbox" :value="color" v-model="selectedColors">
            {{ color }}
          </label>
        </div>
      </div>
    </div>
  
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('size')">Size</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'size', 'filter-content hidden': activeDropdown !== 'size' }">
        <div v-for="size in sizes" :key="index">
          <label>
            <input type="checkbox" :value="size" v-model="selectedSizes">
            {{ size.size }}
          </label>
        </div>
      </div>
    </div>
  
    <div class="filter-section">
      <h5 class="text-xs text-start fw-bold ms-3" @click="toggleDropdown('price')">Price</h5>
      <div :class="{ 'filter-content active': activeDropdown === 'price', 'filter-content hidden': activeDropdown !== 'price' }">
        <div class="price-range">
          <input
            type="number" 
            placeholder="Min" 
            v-model.number="minPrice"
            @input="validatePriceRange"
            
          >
          <input 
            type="number" 
            placeholder="Max" 
            v-model.number="maxPrice"
            @input="validatePriceRange"
          >
          <small v-if="priceRangeError" class="text-danger text-xs">{{ errorMessage }}</small>

        </div>
      </div>
    </div>

  </div>
  </div>
  