import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';
import { get_user_details } from '@/router/index.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },

    data: () => ({
        pargoIframeSrc: process.env.VUE_APP_PARGO_DROPOFF_IFRAME_SRC,
        selectedPupName: '',
        selectedPoint: null,
    }),

     computed: {
        ...mapGetters('auth', ['isImpersonating', 'impersonatedUserId']),
    },

    methods: {
        
        handleIframeMessage(event) {
            if (event.data && event.data.addressSms) {
              this.selectedPoint = event.data;
              this.saveDropoffPoint();
            }
        },

        saveDropoffPoint() {
            const SAVE_DROP_OFF_POINT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/save_drop_off_point/`;
            const token = localStorage.getItem('token');
    
            const dropOffPointData = {
            pargo_point_code: this.selectedPoint.pargoPointCode,
            store_name: this.selectedPoint.storeName,
            address1: this.selectedPoint.address1,
            address2: this.selectedPoint.address2,
            city: this.selectedPoint.city,
            province: this.selectedPoint.province,
            postal_code: this.selectedPoint.postalcode,
            address_sms: this.selectedPoint.addressSms,
        };
        
        fetch(SAVE_DROP_OFF_POINT_URL, {
        method: 'POST',
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dropOffPointData)
        })
        .then(response => response.json())
        .then(() => {
        Swal.fire({
            icon: 'success',
            iconColor:'#F99E1B',
            text: 'Drop-off point saved successfully.',
            title: 'Success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#000',
        }).then(() => {
            window.location.reload();
          });        })
        .catch(error => {
        console.error('Error saving drop-off point:', error);
        Swal.fire({
            icon: 'error',
            iconColor:'#F99E1B',
            text: 'Failed to save drop-off point.',
            title: 'Error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#000',
        });
        });
    },

    fetchSavedDropOffPoint(userId) {
        let GET_DROP_OFF_POINT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/seller_drop_off_point/${userId}/`;
        const token = localStorage.getItem('token');
        const isImpersonating = this.isImpersonating; // Get from Vuex getters
        const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters

        try {
            if (isImpersonating && impersonatedUserId) {
                GET_DROP_OFF_POINT_URL += `?impersonate=true&user_id=${impersonatedUserId}`;
            }
            fetch(GET_DROP_OFF_POINT_URL, {
                method: 'GET',
                headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.selectedPoint = data;
            })
            .catch(error => {
                console.error(error);
            });
        } catch (error) {
        console.error(error);
            }
        },
    },

    mounted() {
        this.user_details = get_user_details();
        
        this.fetchSavedDropOffPoint(this.user_details.user_id);
        window.addEventListener('message', this.handleIframeMessage);
  

    }
}