/* global $ */
import Customer_navbar from '@/components/Customer/CustomerNavView.vue'
import Products_navbar from '@/components/Products/ProductNavbarView.vue';

import jQuery from 'jquery';
import { ref, onMounted} from 'vue';
import axios from 'axios';

export default {
  name: 'CustomerDashView',
  components: {
    Customer_navbar,
    Products_navbar
  },
  setup() {
    const videos = ref([]); // Replace with your actual videos data source
    const showDetails = ref([]); // Array to keep track of which video's details are shown
    const visibleCards = ref(6);
    const currentPosition = ref(0);
    const hoveredVideo = ref(null);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const currentVideoId = ref(null);
    const images = ref([]);


    // Initialize showDetails array with false values
    const initializeShowDetails = () => {
      showDetails.value = new Array(videos.value.length).fill(false);
    };

    // Toggle the visibility of details for the clicked video
    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = document.querySelectorAll('.overlay')[index];
      if (!showDetails.value[index] && isHovering) {
        overlay.classList.add('bg-dark');
      } else {
        overlay.classList.remove('bg-dark');
      }
    };
    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };
    const scrollCards = (direction) => {
      const maxScroll = videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    onMounted(() => {
      updateVisibleCards();
      
      // jQuery event handlers
      jQuery(window).on('resize', updateVisibleCards);
     
    });

    initializeShowDetails();
    return { 
      showBrandDropdown: false,
      tagInput: '',
      searchQuery: '',
      filteredBrands:[],
      videos,
      brands:[],
      products:[],
      filteredProducts:[],
      selectedProductIds: [],  // Initialize as an empty array
      selectedProducts: [],
      showDetails,
      currentPosition,
      visibleCards,
      image_files,
      hoveredVideo,
      brand_videos,
      images,
      currentVideoId,
      toggleDetails,
      toggleOverlay,
      scrollCards,
    };
  },
  methods: {
    scrollCards(direction) {
      if (direction === 'left') {
        this.currentPosition = Math.max(this.currentPosition - 1, 0);
      } else if (direction === 'right') {
        this.currentPosition = Math.min(this.currentPosition + 1, this.videos.length - this.visibleCards);
      }
    },
    async fetchBrandVideos() {
      try {
        const GET_ALL_BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
        const response = await fetch(GET_ALL_BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        this.brand_videos = data;
        this.loadImages();

      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
    loadImages() {
      if (this.brand_videos && this.brand_videos.length > 0) {
        const video_id = this.brand_videos[0].id; // Access the video ID
        if (video_id) {
          const token = localStorage.getItem('token');
          const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;

          axios.get(VIEW_IMAGE_URL, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
          .then(response => {
            this.image_files = response.data; // Assuming response.data is an array of image data
          })
          .catch(error => {
            console.error('Error loading images:', error);
          });
        } else {
          console.error('Video ID is undefined or null');
        }
      } else {
        console.error('No brand videos available');
      }
    },

    async fetchBrands() {
      try {
          const token = localStorage.getItem('token');
          const FETCH_BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`;
  
          // Use jQuery's ajax method
          const response = await $.ajax({
              url: FETCH_BRANDS_URL,
              method: 'GET',
              headers: {
                  'Authorization': `Token ${token}`
              }
          });
  
          // No need for response.json(), assign the response directly
          this.brands = response;  // The response is already parsed JSON
      } catch (error) {
          console.error('Error fetching brands:', error);
      }
  },
  
  filterBrands() {
    if (this.tagInput.startsWith('@')) {
      this.showBrandDropdown = true;
      const query = this.tagInput.slice(1).toLowerCase(); // Remove '@' and make it lowercase
      this.filteredBrands = this.brands.filter(brand =>
        brand.brand_name.toLowerCase().includes(query)
      );
      this.showBrandDropdown = this.filteredBrands.length > 0;
    } else {
      this.showBrandDropdown = false;
    }
  },
  selectBrand(brand) {
    this.tagInput = `@${brand.brand_name}`;
    this.selectedBrandId = brand.id;
    this.showBrandDropdown = false;
    
  },
  async fetchProducts() {
    try {
        const token = localStorage.getItem('token');
        const FETCH_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_products/`;

        // Use jQuery's ajax method
        const response = await $.ajax({
            url: FETCH_PRODUCTS_URL,
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        });

        // Since jQuery already handles parsing, directly assign the response
        this.products = response;  // The response is already parsed JSON
    } catch (error) {
        console.error('Error fetching products:', error);
    }
  },

  filterProducts() {
    const query = this.searchQuery.toLowerCase(); // Convert query to lowercase for case-insensitive search
    if (query) {
      this.filteredProducts = this.products.filter(product =>
        product.description.toLowerCase().includes(query)
      );
      this.showProductDropdown = this.filteredProducts.length > 0;
    } else {
      this.showProductDropdown = false;
    }
  },
  selectProduct(product) {
    // Initialize selectedProductIds if it's not already defined
    if (!this.selectedProductIds) {
      this.selectedProductIds = [];
    }
  
    // Check if the selected product is already in the array
    const isProductSelected = this.selectedProductIds.some(id => id === product.id);
  
    // If not selected, add to the list; otherwise, ignore
    if (!isProductSelected) {
      this.selectedProductIds.push(product.id);
      this.selectedProducts.push(product);
    }
  
    this.searchQuery = '';  // Clear input after selection
    this.showProductDropdown = false;  // Hide the dropdown after selection
  },

  removeProduct(product) {
    // Remove product from selectedProducts array
    this.selectedProducts = this.selectedProducts.filter(p => p !== product);
  },
  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },


  showPopUp(index) {
    this.hoveredVideo = index;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.play().catch(error => console.error("Error playing video:", error));
    }
  },
  hidePopUp(index) {
    this.hoveredVideo = null;
    const videoElement = document.querySelectorAll('video')[index];
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  },
  setCurrentVideo  (videoId) {
    this.currentVideoId = videoId;
    this.loadVideoImages(videoId);
  },
  loadVideoImages  (videoId)  {
    const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;

    axios.get(VIEW_IMAGE_URL, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        this.images = response.data;
    })
    .catch(error => {
        console.error('Error loading images:', error);
    });
  }

},

  mounted() {
    this.fetchBrandVideos();
    this.loadImages();
    this.fetchBrands();
    this.fetchProducts();
    this.handleClickOutside();
    this.showPopUp();
    this.hidePopUp();
    this.loadVideoImages();
  },
  beforeDestroy() {
    this.handleClickOutside();
  }
  
};