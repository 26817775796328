import Filter_product from '@/components/Products/FilterProductView.vue';

export default {
  name: 'FilterProductView',
  components: {
    Filter_product,
  },
  props: {
    search_input: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      departments: [],
      categories: [],
      selectedDepartments: [],
      subcategories: [],
      selectedSubcategories: [],
      selectedCategories: [],
      activeDropdown: null,
      search_query: '',
      products: [],
      filteredProducts: [],
      showDropdown: false,
      selectedDepartment: null,
      selectedCategory: null,
      brands: [],
      selectedBrands: [],
      brandSearchQuery: '',
      selectedSizes: [],
      selectedColors: [],
      sizes: [],
      colors: [],
      minPrice: null,
      maxPrice: null,
      priceRangeError: false,
      isSidebarVisible: false,
    };
  },
  methods: {
    toggleFilterSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    // Emit search input changes to parent component
    update_search(event) {
      this.$emit('update:search_input', event.target.value);
      this.search_query = event.target.value;
      this.filterProducts();
        },
    // Toggle active dropdown
    toggleDropdown(dropdownName) {
      this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
    },
    validatePriceRange() {
      if (this.minPrice !== null && this.maxPrice !== null && this.maxPrice < this.minPrice) {
        this.priceRangeError = true;
        this.errorMessage = 'Max price cannot be less than min price.';
      } else {
        this.priceRangeError = false;
        this.errorMessage = '';
        this.filterProducts();
      }
    },
    toggleSize(event, size) {
      if (event.target.checked) {
        if (!this.selectedSizes.includes(size)) {
          this.selectedSizes.push(size);
        }
      } else {
        this.selectedSizes = this.selectedSizes.filter(selectedSize => selectedSize.id !== size.id);
      }
      this.fetchProducts();
    },

    // Fetch departments from API on mount
    async fetchDepartments() {
      const DEPARTMENT_CATEGORY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await fetch(DEPARTMENT_CATEGORY_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.departments = data; // Assuming the API returns an array of departments
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },

    async fetchProducts() {
      const queryParams = new URLSearchParams();
      if (this.search_query) {
        queryParams.append('search', this.search_query);
      }
      
      // Add selected departments parameter
      if (this.selectedDepartments.length) {
        queryParams.append('departments', this.selectedDepartments.join(','));
      }
      // Add selected category parameter
      if (this.selectedCategories.length) {
        queryParams.append('categories', this.selectedCategories.join(','));
      }
      // Add selected subcategories parameter
      if (this.selectedSubcategories.length) {
        const subcategoryNames = this.selectedSubcategories.map(subcat => subcat.subcategory_name);
        queryParams.append('subcategories', subcategoryNames.join(','));
      }
      if (this.selectedBrands.length) {
        queryParams.append('brands', this.selectedBrands.join(','));
      }
      if (this.selectedSizes.length) {
        const sizeIds = this.selectedSizes.map(size => size.id).join(',');
        queryParams.append('sizes', sizeIds);
      }
        if (this.selectedColors.length) {
        queryParams.append('colors', this.selectedColors.join(','));
        }
        if (this.minPrice) {
          queryParams.append('min_price', this.minPrice);
        }
        if (this.maxPrice) {
          queryParams.append('max_price', this.maxPrice);
        }
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/?${queryParams.toString()}`);
        const data = await response.json();
        this.products = data;
        this.filteredProducts = data;
        this.$emit('filter-products', data)
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },

    filterProducts() {
      if (this.priceRangeError) return;

      const query = this.search_query.toLowerCase();
      this.filteredProducts = this.products.filter((product) => {
        const matchesSearchQuery = 
          !query || 
          product.product.product_name.toLowerCase().includes(query) || 
          product.product.brand__brand_name.toLowerCase().includes(query) || 
          product.product.description.toLowerCase().includes(query);
        
        const matchesDepartment = 
          !this.selectedDepartments.length ||
          this.selectedDepartments.includes(product.product.department_id);
        
        const matchesCategory =
          !this.selectedCategories.length || 
          this.selectedCategories.includes(product.product.category_name);
        
        const matchesSubcategory =
          !this.selectedSubcategories.length || 
          this.selectedSubcategories.includes(product.product.subcategory_name);

        const matchesSize =
          !this.selectedSizes.length || this.selectedSizes.includes(product.product.size__size_name);

        const matchesColor = 
          !this.selectedColors.length || this.selectedColors.includes(product.product.color);

          const matchesPrice =
          (!this.minPrice || product.price >= this.minPrice) &&
          (!this.maxPrice || product.price <= this.maxPrice);

        return matchesSearchQuery && matchesDepartment && matchesCategory && matchesSubcategory && matchesSize && matchesColor && matchesPrice;
      });
    
      this.showDropdown = !!this.filteredProducts.length; // Show dropdown if matches found
    },
    async fetchBrands() {
      const BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`;
    
      try {
        const response = await fetch(BRANDS_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.brands = data; // Assuming the API returns an array of brands
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async fetchSizes() {
      const SIZE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_sizes/`;
      try {
        const response = await fetch(SIZE_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        this.sizes = data
      } catch (error) {
        console.error('Error fetching sizes:', error);
      }
    },
  
    async fetchColors() {
      const COLOR_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_colors/`;
      try {
        const response = await fetch(COLOR_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        this.colors = data;
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    },


    // Update categories based on selected department
    updateCategories() {
      const selectedDepts = this.departments.filter(dept => this.selectedDepartments.includes(dept.id));
      const categories = selectedDepts.flatMap(dept => dept.categories);
      this.categories = categories;
      this.selectedCategory = null;
      this.subcategories = [];
      this.selectedSubcategories = [];
    },

    // Update subcategories based on selected category
    updateSubcategories() {
      const selectedCats = this.categories.filter(cat => this.selectedCategories.includes(cat.category_name));
      this.subcategories = selectedCats.flatMap(cat => cat.subcategories);
      
      this.selectedSubcategories = []; // Reset selected subcategories when category changes
      this.filterProducts(); // Filter products after category change
    },
    selectResult() {
      this.showDropdown = false; // Hide dropdown after selection
    }
  },
  computed: {
    filteredBrands() {
      return this.brands.filter(brand => 
        brand.brand_name.toLowerCase().includes(this.brandSearchQuery.toLowerCase())
      );
    }
  },
  async  mounted() {
    this.fetchDepartments();
    this.fetchBrands();
    this.fetchProducts();
    this.fetchSizes();
    this.fetchColors();
  },
  
  watch: {
    selectedDepartment() {
      this.fetchProducts();
      this.updateCategories();
    },
    selectedCategory() {
      this.fetchProducts();
      this.updateSubcategories();
    },
    selectedSubcategories() {
      this.fetchProducts();
    },
    search_query() {
      this.fetchProducts();
    },
    selectedBrands() {
      this.fetchProducts();
    },
    brandSearchQuery() {
      this.fetchProducts();
    },
    selectedSizes() {
      this.fetchProducts();    
    },
    selectedColors() {
      this.fetchProducts();
    },
    minPrice() {
      this.fetchProducts();
    },
    maxPrice() {
      this.fetchProducts();
    },
  }
  
}  
