import Swal from 'sweetalert2';

export default {
  name: 'ResetPasswordView',
  data: () => ({
    password: '',
    confirmPassword: '', 
    errorMessage: '',
    uidb64: '', 
    token: '', 
    passwordType: 'password', 
    confirmPasswordType: 'password'
  }),
  mounted() {
    // Get the `uidb64` and `token` from the URL parameters
    this.uidb64 = this.$route.params.uidb64;
    this.token = this.$route.params.token;

  },
  methods: {
    togglePassword() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      },
      toggleConfirmPassword() {
        this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
      },
    async handleResetPassword () {
      // Validate that the new password and confirm password match
      if (this.password !== this.confirmPassword) {
        Swal.fire({
          title: 'Error!',
          text: 'Passwords do not match!',
          icon: 'error',
          confirmButtonText: 'Try Again',
          iconColor:'#F99E1B',
          confirmButtonColor: '#000',
        });
        return;
      }

      try {
        const RESET_CONFIRM_PASSWORD_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/reset_password/${this.uidb64}/${this.token}/confirm/`;

        const response = await fetch(RESET_CONFIRM_PASSWORD_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: this.password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: 'Your password has been reset successfully. You can now log in with your new password.',
            icon: 'success',
            confirmButtonText: 'Go to Login',
            iconColor:'#F99E1B',
            confirmButtonColor: '#000',
          }).then(() => {

            this.$router.push('/login');
          });
        } else {

          this.errorMessage = data.error || 'There was an issue resetting your password. Please try again.';
          Swal.fire({
            title: 'Error!',
            text: this.errorMessage,
            icon: 'error',
            confirmButtonText: 'Try Again',
            iconColor:'#F99E1B',
            confirmButtonColor: '#000',
          });
        }
      } catch (error) {
        console.error('Error during password reset:', error);
        this.errorMessage = 'There was an error resetting your password. Please try again later.';
        Swal.fire({
          title: 'Error!',
          text: this.errorMessage,
          icon: 'error',
          iconColor:'#F99E1B',
          confirmButtonColor: '#000',
          confirmButtonText: 'Try Again',
        });
      }
    },
  },
};