import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue3 } from 'bootstrap-vue-3';
import Vue3TouchEvents from 'vue3-touch-events';
import store from './store'; // Import the store

import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';



const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.use(Vue3TouchEvents);
app.use(store);
app.mount('#app');
