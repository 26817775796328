<div class="home-navbar">
    <Influencer_sidebar />
</div>
<div class="app-content addresses-category">
    <Influencer_navbar />

    <div class="content">
        <!-- Display location image and button if there are no addresses -->
        <div v-if="addresses.length === 0" class="d-flex flex-column align-items-center justify-content-center min-vh-100 mt-n5">
            <img src="@/assets/images/travel-map-pin-sign-location-icon-vector.jpg" alt="Location" class="location-image" />
            <router-link to="/delivery">
                <button class="create-btn fw-bolder">Add Delivery Address</button>
            </router-link>
        </div>

        <div v-else>
            <router-link to="/delivery">
                <button class="create-btn d-flex justify-content-start fw-bolder mb-3">Add Delivery Address</button>
            </router-link>

            <div v-for="address in addresses" :key="address.id" class="mb-4">
                <div class="card">
                    <div class="cart-cont">
                        <div class="d-flex justify-content-between align-items-center"> 
                            <span class="address-type fw-bolder">
                                {{ address.address_type__type_name }}
                            </span>
                            <div>
                                <!-- <button class=" btn-sm create-btn fw-bold">Delete</button> -->
                                <button class="btn-sm create-btn fw-bold" @click="deleteAddress(address.id)">Delete</button>
                                <router-link :to="`/delivery?addressId=${address.id}`">
                                    <button class="btn-sm create-btn fw-bold">Edit</button>
                                  </router-link>
                                <!-- <button class="btn-sm action-btn fw-bold">Edit</button> -->
                            </div>
                        </div>
                        <div class="d-flex align-items-start mt-3">
                            <div class="me-3">
                                <input 
                                    type="radio" 
                                    name="selectedAddress" 
                                    class="select-address" 
                                    v-model="selectedAddress" 
                                    :value="address.id" 
                                >
                            </div>
                            <div class="text-start fw-bolder">
                                <p class="mb-2">{{ address.complex_building }}</p>
                                <p class="mb-2">{{ address.street_address }}</p>
                                <p class="mb-2">{{ address.suburb }}</p>
                                <p class="mb-2">{{ address.city_town }}</p>
                                <p class="mb-2">{{ address.postal_code }}</p>
                                <p class="mb-2">{{ address.recipient_name }} {{ address.recipient_mobile }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
