<Home_navbar />

<div class="container" v-if="Object.keys(fetchedProducts).length > 0">
    <div class="row">
    <div class="col-md-3">

        <Filter_product
        @filter-products="filter_product_list"
        />
    </div>
    <div class="col-md-9">
      <div class="items-found">{{ Object.keys(fetchedProducts).length }} items found</div>
      
      <div class="product-grid">
        <div class="product-card" v-for="product in fetchedProducts" :key="product.id">
          <router-link 
            :to="{ name: 'product', params: { id: product.id}}" 
            class="product-link"
          >
            <!-- Product Image and Info -->
            <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
            </div>
          </router-link>
            <div class="product-info text-start">
                <p class="product-title text-black">{{ product.product.product_name }}</p>
                <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
                
                <div class="d-flex justify-content-between align-items-center">
                  <p class="product-price text-black">R{{ product.price }}</p>
                  <div class="d-flex justify-content-between">
                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.prevent="addToCart(product)"></i>
                  </div>
                </div>
              </div>
        </div>      
      </div>
    </div>
  </div>
</div>

<div v-else>
    <h1 class="font-weight-bold mb-5 mt-5 text-muted ">No Results Found</h1>
</div>


<footer class="footer">
  <div class="footer-content">
    <div class="footer-links">

      <!-- Delivery Information -->
      <div class="footer-column">
        <h2>Delivery Information</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-box-open box-icon"></i> Partnered with Pargo for delivery solutions</a></li>
          <li><a href="#"><i class="fa-sharp fa-solid fa-truck"></i> Nationwide delivery within 3-5 working days</a></li>
          <li><a href="#"><i class="fa-sharp fa-solid fa-rotate-left text-primary"></i> Easy returns at any Pargo point</a></li>
        </ul>
      </div>

      <!-- Our Location -->
      <div class="footer-column">
        <h2>Our Location</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-location-dot"></i> Rosebank, Johannesburg, South Africa</a></li>
        </ul>
      </div>

      <!-- About Us -->
      <div class="footer-column">
        <h2>About Us</h2>
        <ul>
          <li><a href="#"><i class="fa-solid fa-circle-info"></i> About Us</a></li>
          <li><router-link to="/terms-and-conditions">
            <i class="fa-brands fa-readme"></i> Terms and Conditions
          </router-link></li>
        </ul>
      </div>

      <!-- Follow Us -->
      <div class="footer-column">
        <h2>Socials</h2>
        <ul>
          <li><a href="#"><i class="fa-brands fa-instagram instagram-icon"></i> Instagram</a></li>
          <li><a href="#"><i class="fa-brands fa-whatsapp whatsapp-icon"></i> WhatsApp</a></li>
        </ul>
      </div>

    </div>

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <p>&copy; 2025 Khiosk. All rights reserved.</p>
    </div>
  </div>
</footer>