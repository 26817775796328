
<nav class="influencer-top-nav navbar navbar-expand-lg navbar-dark bg-dark z-3 rounded-12 mt-2">
    <div class="container-fluid">
        <i class='bx bx-menu fs-4 text-white ms-3 close-sidebar'></i> 
        <p class="text-white mt-3 ms-2"> Brand </p>
 
        <div class="collapse navbar-collapse" id="navbarNav">
        
        </div>
        <div class="d-flex align-items-center">
            <i class="bx bx-search nav-icon text-white me-3" id="search_icon"></i>             
            <a href="#" @click="logout"> 
                <i class="bx bx-log-in nav-icon text-white me-3"></i>  
                </a>
        </div>
    </div>
</nav>
<div class="search-input bg-dark p-2 d-none" id="search_input">
    <div class="container-fluid">
        <input type="text" class="form-control" placeholder="Search...">
    </div>
</div> 
