<div class="home-navbar ">
    <Sidebar />
  </div>
   
  <div class="app-content mt-1"> 
    <Navbar />


    <div class="mt-4">
        <div class="fw-bold" v-if="selectedPoint">
            <p class="text-start fw-bolder text-black fs-4" v-if="selectedPoint.store_name">Selected Point : {{ selectedPoint.store_name }}</p>
          </div> 
        <iframe id="pargo-iframe" src="https://map.pargo.co.za/?token=Ww7H9oMJZnMjI8lfpGjDE8yChEqqf0nF2QsO4afnUfFJTyyo" frameborder="0" width="100%" height="800" allow="geolocation"></iframe>
    </div>

  </div>