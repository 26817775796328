import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  components: {
    Sidebar,
    Navbar
  },

  data() {
    return {
      logoUrl: require('@/assets/images/icon.jpg'), // Default placeholder

      form: {
        brand_name: '',
        registration_number: '',
        vat_number: '',
        email: '',
        phone_number: '',
        owner_first_name: '',
        owner_last_name: '',
        fileName: '',
        logo: null, // For file upload
        brand_id: null // Ensure this is set from the fetched data
      },
      errorMessage: '',
      emailErrorMessage: ''
    };
  },

  mounted() {
    this.fetchBrandData();
  },

  methods: {
    async fetchBrandData() {
      const token = localStorage.getItem('token');
      const FETCH_BRAND_DATA_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_brand_info/`;
      try {
        const response = await $.ajax({
          url: FETCH_BRAND_DATA_URL,
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          },
        });
    
        // Check if data contains the expected properties
        if (response.status === "success") {
          this.form.brand_name = response.brand_name || '';
          this.form.registration_number = response.registration_number || '';
          this.form.vat_number = response.vat_number || '';
          this.form.email = response.email || '';
          this.form.phone_number = response.phone_number || '';
          this.form.owner_first_name = response.owner_first_name || '';
          this.form.owner_last_name = response.owner_last_name || '';
          this.form.user_id = response.user_id;
          this.form.brand_id = response.brand_id;
    
          localStorage.setItem('user_id', response.user_id);
        } else {
        Swal.fire({
            title: 'Error',
            text: response.error || 'Failed to fetch brand data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch brand data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    validateForm() {
      const namePattern = /^[a-zA-Z]+$/;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^[0-9]+$/;

      if (!namePattern.test(this.form.owner_first_name)) {
        this.errorMessage = 'First name should not contain numbers or special characters.';
        return false;
      }

      if (!namePattern.test(this.form.owner_last_name)) {
        this.errorMessage = 'Last name should not contain numbers or special characters.';
        return false;
      }

      if (!emailPattern.test(this.form.email)) {
        this.emailErrorMessage = 'Invalid email format. Please enter a valid email address.';
        return false;
      }

      if (!phonePattern.test(this.form.phone_number)) {
        this.errorMessage = 'Phone number should only contain numbers.';
        return false;
      }

      this.errorMessage = '';
      this.emailErrorMessage = '';

      return true;
    },

    async updateBrandInfo() {
      if (!this.validateForm()) {
        Swal.fire({
          title: 'Validation Error',
          text: this.errorMessage || this.emailErrorMessage,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return;
      }

      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('brand_name', this.form.brand_name);
      formData.append('registration_number', this.form.registration_number);
      formData.append('vat_number', this.form.vat_number);
      formData.append('email', this.form.email);
      formData.append('phone_number', this.form.phone_number);
      formData.append('owner_first_name', this.form.owner_first_name);
      formData.append('owner_last_name', this.form.owner_last_name);
      formData.append('user_id', localStorage.getItem('user_id')); 
      formData.append('brand_id', this.form.brand_id); 

      if (this.form.logo) {
        formData.append('logo', this.form.logo);
      }

      const UPDATE_BRAND_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/brand_info/`;


      try {
        const response = await $.ajax({
          url: UPDATE_BRAND_URL,
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to update brand data');
        }

        const data = await response.json();

        if (data.status === "success") {
          Swal.fire({
            title: 'Success',
            text: 'Brand information updated successfully.',
            icon: 'success',
            confirmButtonText: 'OK'

          });
        } else {
          Swal.fire({
            title: 'Error',
            text: data.error || 'Failed to update brand data.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred during the submission.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    onFileChange(event) {
      this.form.logo = event.target.files[0];
      this.form.fileName = this.form.logo ? this.form.logo.name : '';
    }
  }
}
