import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue';
import Swal from 'sweetalert2';


export default {
    name: 'Admin_Wallet',
    components: {
        Admin_sidebar,
        Admin_navbar,
    },
    data: () => ({
        view_form: { id:null,account_holder: '', bank__name: '', bank__branch_code: '', account_number: '', account_type__account_type: '' },
        pending_balance: 0,
        total_balance: 0,
        paymentHistory: [],
        moneyInHistory: [],
        moneyOutHistory: [],
        activeTab: 'all',
        showWithdrawModal: false,
        showEditBankingModal: false,
        withdrawAmount: "",
        banks: [],
        account_types: [],
        showWithdrawError: false,
    }),
    computed: {
        filteredTransactions() {
            if (this.activeTab === 'all') {
                return this.paymentHistory;
            } else if (this.activeTab === 'money_in') {
                return this.moneyInHistory;
            } else if (this.activeTab === 'money_out') {
                return this.paymentHistory.filter(transaction => transaction.status === 'money_out');
            }
            return [];
        },
        selectedBankName() {
            return this.banks.find(b => b.id === this.view_form.bank_id)?.name || '';
          },
        selectedAccountType() {
            return this.account_types.find(t => t.id === this.view_form.account_type)?.account_type || '';
          },
    },
    methods: {
        openModal() {
            this.showWithdrawModal = true;
            this.fetch_banking_details();
            },
        closeModal() {
            this.showWithdrawModal = false;
        
            },
            openEditBankingModal() {
            this.fetch_banking_details();
            if (!this.view_form.id) {
                this.view_form = {
                id: '',
                account_holder: '',
                bank_id: '',
                account_number: '',
                account_type_id: '',
                branch_code: ''
                };
            }
            this.showEditBankingModal = true;
            },
        
            closeEditBankingModal() {
            this.showEditBankingModal = false;
            },
        
            async fetch_banks() {
            const BANKS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banks/`;
        
            try {
                const response = await fetch(BANKS_API);
        
                if (!response.ok) {
                console.error('Failed to fetch banks, status:', response.status);
                throw new Error('Failed to fetch banks');
                }
        
                const data = await response.json();
                if (Array.isArray(data)) {
                this.banks = data;
                } else {
                console.error("Expected an array of banks, got:", data);
                }
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
            },
            async save_banking_details() {
            const token = localStorage.getItem('token');
            if (!token) {
                Swal.fire('Error', 'Not authenticated', 'error');
                return;
            }
            
            try {
                const ADD_BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/add_banking_details/`;
                
                const response = await fetch(ADD_BANKING_DETAILS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(this.view_form),
                });
            
                const data = await response.json();
            
                if (!response.ok) {
                throw new Error(data.message || 'Failed to add banking details');
                }
            
                // Refresh the banking details after saving
                await this.fetch_banking_details();
            
                Swal.fire({
                title: 'Success',
                text: 'Banking details saved successfully!',
                icon: 'success',
                iconColor: '#F99E1B',
                confirmButtonColor: '#000',
                confirmButtonText: 'OK'
                }).then(() => {
                this.closeEditBankingModal();
                });
            
            } catch (error) {
                console.error('Save error:', error);
                Swal.fire('Error', error.message, 'error');
            }
            },
            
            async saveOrUpdateBankingDetails() {
            if (this.view_form.id) {
                await this.update_banking_details();
            } else {
                await this.save_banking_details();
            }
            },
            updateSelectedBankName() {
            const selectedBank = this.banks.find(bank => bank.id === this.view_form.bank_name);
            this.selectedBankName = selectedBank ? selectedBank.name : '';
            },
        
            async update_banking_details() {
            const token = localStorage.getItem('token');
            if (!token) {
                Swal.fire('Error', 'Not authenticated', 'error');
                return;
            }
            if (!this.view_form || !this.view_form.id) {
                console.warn('No banking details selected, skipping update.');
                return;
            }
            
            try {
                const UPDATE_BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/update_banking_details/${this.view_form.id}/`;
            
                const response = await fetch(UPDATE_BANKING_DETAILS_URL, {
                method: 'POST', // Keeping it as POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(this.view_form),
                });
            
                if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.detail || errorData.message || Object.values(errorData).flat().join(', ');
                throw new Error(errorMessage);
                }
            
                // Refresh data after update
                await this.fetch_banking_details();
            
                Swal.fire({
                title: 'Success',
                text: 'Banking details updated!',
                icon: 'success',
                iconColor: '#F99E1B',
                confirmButtonColor: '#000',
                confirmButtonText: 'OK'
                }).then(() => {
                this.closeEditBankingModal(); // Close modal instead of reloading the page
                });
            
            } catch (error) {
                console.error('Update error:', error);
                Swal.fire('Error', error.message, 'error');
            } 
            },
            
            async processWithdraw() {
            if (this.withdrawAmount > this.total_balance) {
                this.showWithdrawError = true;
                return;
            }
            this.showPriceError = false;
        
            const token = localStorage.getItem('token');
            if (!token) {
                Swal.fire('Error', 'Not authenticated', 'error');
                return;
            }
            
            if (!this.withdrawAmount || this.withdrawAmount <= 0) {
                Swal.fire('Error', 'Invalid withdrawal amount', 'error');
                return;
            }
            
            try {
                const WITHDRAWAL_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/initiate_withdrawal_api/`;
            
                const response = await fetch(WITHDRAWAL_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    amount: this.withdrawAmount,
                    bank_detail_id: this.view_form.id
                }),
                });
            
                if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.detail || errorData.message || Object.values(errorData).flat().join(', ');
                throw new Error(errorMessage);
                }
            
                Swal.fire({
                title: 'Success',
                text: 'Withdrawal initiated!',
                icon: 'success',
                iconColor: '#F99E1B',
                confirmButtonColor: '#000',
                confirmButtonText: 'OK'
                }).then(() => {
                this.closeWithdrawModal(); // Close modal
                });
            
            } catch (error) {
                console.error('Withdrawal error:', error);
                Swal.fire('Error', error.message, 'error');
            }
            },
        
            async fetch_account_types() {
            const ACCOUNT_TYPES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_account_types/`;
        
            try {
                const response = await fetch(ACCOUNT_TYPES_API);
        
                if (!response.ok) {
                console.error('Failed to fetch account types, status:', response.status);
                throw new Error('Failed to fetch account types');
                }
        
                const data = await response.json();
                if (Array.isArray(data)) {
                this.account_types = data;
                } else {
                console.error("Expected an array of types, got:", data);
                }
            } catch (error) {
                console.error('Error fetching types:', error);
            }
            },
            async fetch_banking_details() {
            const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banking_details/`;
            const token = localStorage.getItem('token');
            
            try {
                const response = await fetch(BANKING_DETAILS_URL, {
                headers: { 'Authorization': `Token ${token}` }
                });
            
                if (response.ok) {
                const data = await response.json();
                const matchedBank = this.banks.find(b => b.id === data.bank_id);
            
                const branchCode = data.branch_code || 
                                    data.bank__branch_code || 
                                    matchedBank?.branch_code || '';
            
                // Update view_form with ALL fields
                this.view_form = {
                    id: data.id,
                    account_holder: data.account_holder,
                    bank_id: data.bank_id,
                    branch_code: branchCode, // Derived from bank_id
                    account_number: data.account_number,
                    masked_account_number: data.account_number.slice(-4), // Slice the account number
                    account_type_id: data.account_type_id, // Ensure this field is included
                    account_type_name: this.account_types.find(type => type.id === data.account_type_id)?.account_type,
                    bank__name: data.bank_name // Ensure this field is included
                };
                this.banking_details = data;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchWalletInfo() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_wallet_info_api/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch wallet info');
                }
                const data = await response.json();
                this.pending_balance = data.pending_amount.toFixed(2);
                this.total_balance = data.total_balance.toFixed(2);
            } catch (error) {
                console.error('Error fetching wallet info:', error);
            }
        },

        async AllPaymentHistory() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_payment_history_api/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch payment history');
                }

                const data = await response.json();
                this.paymentHistory = data.payment_history.map(transaction => ({
                    id: transaction.id,
                    description: `#${transaction.order_number}`,
                    amount: parseFloat(transaction.balance || 0).toFixed(2),
                    balance_status: this.getTransactionStatus(transaction.balance_status?.balance_status),
                    date: transaction.date_created
                }));
            } catch (error) {
                console.error('Error fetching payment history:', error);
            }
        },

        // Helper function to determine transaction type
        getTransactionStatus(balance_status) {
            if (balance_status === 'available') return 'money_in';
            if (balance_status === 'pending') return 'pending';
        },

        async MoneyInHistory() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_money_in_history_api/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Failed to fetch money in history');
                }
        
                const data = await response.json();                
                this.moneyInHistory = data.payment_history.map(transaction => ({
                    id: transaction.id,
                    description: `#${transaction.order_number}`,
                    amount: parseFloat(transaction.balance || 0).toFixed(2),
                    balance_status: this.getTransactionStatus(transaction.balance_status?.balance_status),
                    date: transaction.date_created
                }));
            } catch (error) {
                console.error('Error fetching money in history:', error);
            }
        },

        setActiveTab(tab) {
            this.activeTab = tab;
            if (tab === 'money_in') {
                this.MoneyInHistory();
            } else if (tab === 'all') {
                this.AllPaymentHistory();
            }
        }
    },

    mounted() {
        this.fetchWalletInfo();
        this.AllPaymentHistory();
        this. fetch_banking_details();
        this.fetch_account_types();
        this.update_banking_details();
        this.fetch_banks();
    },
};
