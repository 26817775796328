import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import New_Collection from '@/views/Home/Products/New_collection/NewCollectionView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import EventBus from '@/eventBus.js';

export default {
    name: 'NewCollectionView',
  components: {
    Home_navbar,
    Filter_product,
    Influencer_navbar,
    New_Collection
},
data: () => ({ new_products: [], days: 15, search_query: "", filter_data: {},
  showSizes: false,UserLoggedIn: false,
  selectedProduct: null,selectedSize: null,sizeProductId: null,addedToWishlist: false,}),


computed: {
  filteredProducts() {
    if (!this.search_query) return this.new_products;

    const query = this.search_query.toLowerCase();
    return this.new_products.filter(product => {
      const { product_name = '', brand__brand_name = '', description = '', category__category_name = '' } = product.product || {};

      return [product_name, brand__brand_name, description, category__category_name]
        .some(field => field.toLowerCase().includes(query));
    });
  },
},
  mounted() {
    this.fetchNewProducts();
    this.checkLoginStatus();
    this.fetchSearchProducts();

  },
  methods: {
    checkLoginStatus() {
      this.UserLoggedIn = !!localStorage.getItem('token');
    },

    update_search_query(query) {
      this.search_query = query;
    },
      fetchSearchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;
    
        fetch(FETCH_PRODUCT_URL)
          .then(response => response.json())
          .then(data => {
            this.allProducts = data;
            this.filteredProducts = data;
          })
          .catch(error => console.error('Error fetching products:', error));
      },
    
      filterProducts() {
        const query = this.search_query.toLowerCase();
        this.filteredProducts = [...this.allProducts];
    
        if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts = this.filteredProducts.filter((product) => {
            const match = (
              search.test(product.product.product_name) ||
              search.test(product.product.brand__brand_name) ||
              search.test(product.product.description) ||
              search.test(product.product.category__category_name)
            );
            return match;
          });
        }
      },
    async fetchNewProducts() {
      const FETCH_NEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/new_products/`;
      try {
        const response = await fetch(FETCH_NEW_PRODUCTS_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.length > 0) {
          // If there are new products, show them
          this.new_products = data;
        } else {
          // Fallback: Fetch products last updated
          await this.fetchLastUpdatedProducts();
        }
      } catch (error) {
        console.error('Failed to fetch new products:', error);
      }
    },

    async fetchLastUpdatedProducts() {
      const FETCH_LAST_UPDATED_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/last_updated_products/`;
      try {
        const response = await fetch(FETCH_LAST_UPDATED_URL, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        this.new_products = data;
      } catch (error) {
        console.error('Failed to fetch last updated products:', error);
      }
    },
    
    filter_product_list(filter_data) {
// Update the days filter and refetch products
      this.days = filter_data.days;
      this.fetchNewProducts();
    },

    handleProductClick(product) {
      this.selectedProduct = product;
      this.sizes = this.selectedProduct.sizes;
      this.showSizes = !this.showSizes;
    },

    async handleAddToCart(size) {
      // Add size to product object
      this.selectedSize = size.size;
      this.sizeProductId = size.product_id;
    },

    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },

    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },

    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },

    async fetchCartDetails() {
      const cart = this.getLocalStorage('cart') || [];
    
      for (const item of cart) {
      
          const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
          const response = await fetch(GET_IMAGE_URL);
          
          if (!response.ok) {
            throw new Error('Failed to fetch image URL');
          }
          const imageData = await response.json();
          item.image = imageData;
      }
    
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    }, 

    toggleSizes(product) {
      if (this.selectedProduct === product) {
        this.showSizes = !this.showSizes;
      } else {
        this.selectedProduct = product;
        this.showSizes = true;
      }
    },


    async addToCart(product) {
      this.showSizes = false;
      const quantity = 1;
      const productId = this.sizeProductId;

      if (this.isLoggedIn()) { // Function to check if the user is logged in
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        // Handle the case for guest users
        const cart = this.getLocalStorage('cart') || [];

        const existingProduct = cart.find(item => item.id === productId);
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: product.product_id,
            product_name: product.product.product_name,
            quantity: quantity,
            price: product.price,
            color: product.color,
            size: this.selectedSize,
            brand: product.product.brand__brand_name,
            image: product.product.image_url,
            product_id: productId
          });
        }
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            this.isRightSidebarOpen = true;
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        })
      }
    },

    async addToWishlist(product_id) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.showLoginAlert = true;
        setTimeout(() => {
          this.showLoginAlert = false;
        }, 5000);
        return;
      }
    
      // Prepare the request data
      const formData = new FormData();
      formData.append('productdetail_ids', product_id);
    
      try {
        const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
        const response = await fetch(ADD_TO_WISHLIST_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });
    
        const result = await response.json();
        if (response.ok && response.status === 201) {
          // If the response is successful
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else if (response.status === 200) {
          // Handle case when the product is already in the wishlist (409 Conflict)
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else {
          // If there's an error in the API call (e.g., validation error)
          alert(result.error || 'Failed to add product to the wishlist.');
        }
      } catch (error) {
        // Handle unexpected errors
        alert('Something went wrong. Please try again.');
      }
    }


  },
};