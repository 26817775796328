/* global $ */

import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
  name: 'InfluencerInventoryView',
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },
  data: () => ({
    loading:false,
    selectedProduct: {
      id: null,
      image: '',
      product_name: '',
      description: '',
      price: '',
      color: '',
      sizes: [],
      stock: '',
    },
    products: [], // Initialize as an empty array
    departments: [],
    categories: [],
    subcategories: [] ,
    selectedDepartment: null,
    selectedCategory: null,
    selectedSubcategories: [],
    product_list:[]
  }),
  methods: {
    
    openEditModal(product) {
      // Set the selected department, category, and subcategory names based on their IDs
      this.selectedDepartment = this.departments.find(dept => dept.id === product.department_id)?.department_name || '';
      // Find the category name using the category_id
      const category = this.categories.find(cat => cat.id === product.category_id);
      this.selectedCategory = category ? category.category_name : '';
      // Find the subcategory name using the subcategory_id
      const subcategory = this.subcategories.find(sub => sub.id === product.subcategory_id);
      this.selectedSubcategories = subcategory ? subcategory.subcategory_name : '';
      // Copy product details to the selectedProduct
      this.selectedProduct = { 
        ...product, 
        sizes: product.product_details.map(detail => ({
          size: detail.size,
          quantity: detail.quantity,
          price: detail.price
        }))
      };
    
      // Show modal
      $('#updateModalImage').modal('show');
    },
    setSelectedSize(sizeId, index) {
      const selectedSize = this.sizes.find(s => s.id === sizeId);
      if (selectedSize) {
        // Update the size of the specific product detail based on the index
        this.selectedProduct.product_details[index].size = selectedSize.id;
      }
    },
    onSizeChange(event, index) {
      const selectedSize = this.sizes.find(s => s.id === event.target.value);
      if (selectedSize) {
        // Update the size in the correct product detail
        this.selectedProduct.product_details[index].size = selectedSize.id;
      }
    },
    async saveProduct() {
      const token = localStorage.getItem('token');
      const SAVE_PRODUCT_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/update_product_api/`;
    
      const sizeList = [];
      const sizeTable = document.getElementById('size_table');
      const sizeRows = sizeTable.getElementsByTagName('tr');
    
      for (const row of sizeRows) {
        const sizeSelect = row.querySelector('.size-dropdown');
        const quantityInput = row.querySelector('#size_quantity');
    
        if (sizeSelect && quantityInput) {
          const sizeId = this.sizes.find(s => s.size === sizeSelect.value).id;
          const quantity = quantityInput.value;
    
          sizeList.push({ size: sizeId, quantity });
        }
      } 
      const payload = {
        product_id: this.selectedProduct.id,  
        product_name: this.selectedProduct.product_name,
        description: this.selectedProduct.description,
        sizes: sizeList,
        price: parseFloat(this.selectedProduct.product_details[0].price * 1.08).toFixed(2), // Rounding to 2 decimal places
        color: this.selectedProduct.product_details[0].color,
        department_id: this.selectedDepartment,
        category_id: this.selectedCategory,
        subcategory_id: this.selectedSubcategories,
      };
    
      try {
        const response = await fetch(SAVE_PRODUCT_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify(payload),
        });
    
        const data = await response.json();
    
        if (response.ok && data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Product saved successfully!',
          }).then(() => {
            window.location.reload();
          });
          this.fetchInfluencerProducts(); 
          $('#updateModalImage').modal('hide'); 
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: data.message || 'Error saving product',
          });
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Error saving product',
        });
      }
    },
    async fetchInfluencerProductsDetails() {
      const token = localStorage.getItem('token');
      const isImpersonating = this.isImpersonating; // Get from Vuex getters
      const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
      let INFLUENCER_PRODUCTS_DETAILS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_products_api/`;
    
      if (isImpersonating && impersonatedUserId) {
        INFLUENCER_PRODUCTS_DETAILS_API += `?impersonate=true&user_id=${impersonatedUserId}`;
      }
  
      
      try {
        const response = await fetch(INFLUENCER_PRODUCTS_DETAILS_API, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
    
        const data = await response.json();
    
        if (data.products) {
          this.products = data.products;
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    async fetchInfluencerProducts() {
      this.loading = true;
      const token = localStorage.getItem('token');
      const INFLUENCER_PRODUCTS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_inventory_api/`;
    
      try {
        const response = await fetch(INFLUENCER_PRODUCTS_API, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
    
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch products: ${response.statusText} - ${errorText}`);
        }
        const data = await response.json();
        this.product_list = data
        this.$nextTick(() => {
          this.initializeDataTable();
        });
        if (!Array.isArray(data) || data.length === 0) {
          console.warn('No products found');
          return;
        }        

      } catch (error) {
        console.error('Error fetching products:', error);
      }finally {
        this.loading = false; // Hide the loader
      }
    },

    initializeDataTable() {
      this.$nextTick(() => {
        // Check if DataTable is already initialized and destroy it if so
        if ($.fn.DataTable.isDataTable('#content_table')) {
          $('#content_table').DataTable().destroy();
        }

        this.dataTable = $('#content_table').DataTable({
          pageLength: 10,
          lengthMenu: [10, 25, 50, 100],
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
        });
      });
    },

    async fetchDepartments() {
      const DEPARTMENT_CATEGORY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;
    
      try {
        const response = await fetch(DEPARTMENT_CATEGORY_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.departments = data;
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    updateCategories() {         
      const selectedDept = this.departments.find(dept => dept.id === this.selectedDepartment);
      
      if (selectedDept) {
        this.categories = selectedDept.categories || [];    
        if (!this.categories.some(cat => cat.id === this.selectedCategory)) {
          this.selectedCategory = null;
          this.subcategories = [];
          this.selectedSubcategories = [];
        } else {
          this.updateSubcategories();
        }
      } else {
        this.categories = [];
        this.selectedCategory = null;
      }
    },
    
    updateSubcategories() {    
      const selectedCat = this.categories.find(cat => cat.id === this.selectedCategory);
      
      if (selectedCat) {
        this.subcategories = selectedCat.subcategories || [];
      } else {
        this.subcategories = [];
      }  
    },
        async fetchSizes() {
      const API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_sizes/`;
    
      try {
        const response = await fetch(API_URL, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
    
        if (!response.ok) {
          console.error('Failed to fetch sizes, status:', response.status);
          throw new Error('Failed to fetch sizes');
        }
    
        const data = await response.json();
        if (Array.isArray(data)) {
          // Use a Set to filter out duplicates based on the 'size' property
          const uniqueSizes = Array.from(new Set(data.map(size => size.size)))
            .map(size => data.find(s => s.size === size));
          this.sizes = uniqueSizes;
        } else {
          console.error("Expected an array of sizes, got:", data);
        }
      } catch (error) {
        console.error('Error fetching sizes:', error);
      }
    },
    getSize(sizeId) {
      const size = this.sizes.find(s => s.id === sizeId);
      return size ? size.size : '';
    },
  },
  mounted() {
    this.fetchDepartments();
    this.fetchInfluencerProductsDetails();
    this.fetchSizes();
  },
  created() {
    this.fetchInfluencerProducts();

  },

  computed: {
    ...mapGetters('auth', ['isImpersonating', 'impersonatedUserId']),
  },
  watch: {
    
    selectedDepartment() {
      this.updateCategories();
    },
    selectedCategory() {
      this.updateSubcategories()
    },
  },
};
