import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import jQuery from 'jquery';
import $ from 'jquery';

export default {
  components: {
    Navbar
  },
  mounted() {
    const $search_icon = jQuery('#search_icon');
    const $search_input_box = jQuery('#search_input');
    const $sidebar_close = jQuery('.close-sidebar');
    const $sidebar = jQuery('.sidebar');

    // Function to close sidebar on screens 767px or smaller
    function handleSidebarOnResize() {
      if (window.innerWidth <= 767) {
        $sidebar.addClass('close');   
      } else {
        $sidebar.removeClass('close');  
      }
    }

    // Check screen size on page load
    handleSidebarOnResize();  

    // Toggle the search input and icon on click
    $search_icon.on('click', function(event) {
      event.stopPropagation();
      $search_input_box.toggleClass('d-none');  
      $search_icon.toggleClass('bx-search bx-x');  
    });

    // Toggle sidebar close/open on button click
    $sidebar_close.on('click', function(event) {
      event.stopPropagation();
      $sidebar.toggleClass('close');
    });

    // Add event listener for window resize to handle dynamic resizing
    jQuery(window).on('resize', function() {
      handleSidebarOnResize(); 
    });
  },
  methods: {
    
    async logout() {
      const token = localStorage.getItem('token');
      const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

      const response = await $.ajax({
        url: LOGOUT_URL,
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
        localStorage.removeItem('impersonate'); // Remove impersonate flag
        this.$router.push('/')
      } else {
        throw new Error('Logout failed');
      }
    },
  }
};
