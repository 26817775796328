<Home_navbar v-model:search_input="search_query" @update:search_input="update_search_query" />

<div v-if="addedToWishlist" class="centered-popup alert-success">
  Successfully added to wishlist!
  <a href="/wishlist" class="alert-link"> WISHLIST</a>

</div>
<div class="container">
  <p class="text-start">
    <router-link to="/" class="text-muted">Home</router-link> / <router-link to="/view_brands" class="text-muted">
      Brand</router-link> / 
    <span class="fw-bold">{{ brandProducts[0]?.product?.brand__brand_name }}</span>
  </p>

  <div class="row">
    <div class="col-md-3">
      <Filter_product @filter-products="filter_product_list" />
    </div>

    <div class="col-md-9">
        <div class="items-found">{{ filteredBrandSearch.length }} items found</div>  
        <div class="product-grid">
          <div 
            v-for="product in filteredBrandSearch" 
            :key="product?.product?.id" 
            class="product-card"
          >
            <router-link :to="{ name: 'product', params: { id: product.id } }"
              class="product-link">
              <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }"></div>
            </router-link>

            <div class="product-info text-start">
              <p class="product-title text-black">{{ product.product.product_name }}</p>
              <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
              
              <div class="d-flex justify-content-between align-items-center">
                <p class="product-price text-black">R{{ product.price }}</p>
                <div class="d-flex justify-content-between">
                  <div class="cart-icon position-relative">
                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addToWishlist(product.id)"></i>
                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="handleProductClick(product)"></i>
                    <div v-if="selectedProduct === product && showSizes" class="sizes show text-black">
                      <div v-for="size in sizes" :key="size.size" class="size-select" @click="handleAddToCart(size); addToCart(product)">
                        {{ size.size }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>