
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ViewInfluencerBuyerOrdersView',
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },
  data: () => ({
    orders: [],
    selectedOrderId: null, // To store the selected order ID
    selectedOrder: null, // To store the selected order details
    selectedOrderFullAddress: null, // Added to store the full address
    error: null,
    showReturnButton: false,
    loading:false,
  }),
  methods: {
    
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
      }).format(date);
    },

    isReturnWindowOpen(order) {
      const updatedAt = new Date(order.updated_at);
      const now = new Date();
      const twoDaysAgo = new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000);
    
      const status = this.steps.find(step => step.id === order.order_statuses);
      return status && status.status === 'Delivered' && updatedAt > twoDaysAgo;
    },
    async fetchOrders() {
      this.loading = true;

      this.error = null;
      const token = localStorage.getItem('token');
      const isImpersonating = this.isImpersonating; // Get from Vuex getters
      const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
      let VIEW_INFLUENCER_BUYER_ORDER = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_influencer_orders_as_buyer_api/`;
      const GET_ORDER_STATUSES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/order_statuses/`; // New API endpoint
      
      if (isImpersonating && impersonatedUserId) {
        VIEW_INFLUENCER_BUYER_ORDER += `?impersonate=true&user_id=${impersonatedUserId}`;
      }

      try {
        // Fetch orders
        const response = await fetch(VIEW_INFLUENCER_BUYER_ORDER, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch orders: ${response.statusText} - ${errorText}`);
        }
        const data = await response.json();
        if (data.status === 'success') {
          this.orders = data.data;
        } else {
          this.error = 'No orders found or unable to fetch data.';
        }
    
       
        const statusResponse = await fetch(GET_ORDER_STATUSES_API);
        if (!statusResponse.ok) {
          throw new Error('Failed to fetch order statuses');
        }
        const statusData = await statusResponse.json();

        // Sort the statuses
        this.steps = statusData
          .filter(status => status.status !== "Returned")
          .sort((a, b) => {
            if (a.status === "Shipped" && b.status === "Delivered") {
              return -1;
            } else if (a.status === "Delivered" && b.status === "Shipped") {
              return 1; 
            } else {
              return a.id - b.id;
            }
          });    
      } catch (err) {
        this.error = 'Failed to fetch data: ' + err.message;
      }finally {
        this.loading = false; // Hide the loader
      }

    },
    
    isWithinReturnWindow(lastUpdatedAt) {
      const twoDaysAgo = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      return new Date(lastUpdatedAt) >= twoDaysAgo;
    },
    


    async fetchOrderDetails(orderId) {
      this.error = null;

      const token = localStorage.getItem('token');
      const VIEW_ORDER_DETAILS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/view_order_details_api/${orderId}`;

      try {
        const response = await fetch(VIEW_ORDER_DETAILS_API, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch order details: ${response.statusText} - ${errorText}`);
        }

        const data = await response.json();
        if (data.status === 'success') {
          this.selectedOrder = data.data;
          if (this.selectedOrder && this.selectedOrder.full_address) {
            this.selectedOrderFullAddress = this.selectedOrder.full_address;
          }
          
            // Check if the return window is open
          if (this.isReturnWindowOpen(this.selectedOrder)) {
            // Return window is open, show the return button
            this.showReturnButton = true;
          } else {
            // Return window is closed, hide the return button
            this.showReturnButton = false;
          }
        } else {
          this.error = 'Unable to fetch order details.';
        }
      } catch (err) {
        this.error = 'Failed to fetch order details: ' + err.message;
      }
    },


    showOrderDetails(orderId) {
      this.selectedOrderId = orderId;
      this.fetchOrderDetails(orderId);
    },
    closeOrderDetails() {
      this.selectedOrderId = null;
      this.selectedOrder = null;
      this.selectedOrderFullAddress = null; // Clear the full address as well
    },
  },
  created() {
    this.fetchOrders();
  },

  computed: {
    currentStep() {
      if (!this.selectedOrder || !this.steps) {
        return 0;
      }
  
      const orderStatusId = this.selectedOrder.order_statuses;
  
      // Find the status object in this.steps that matches the orderStatusId
      const matchingStatus = this.steps.find(status => status.id === orderStatusId);
  
      if (!matchingStatus) {
        return 0;
      }
  
      // Find the index of the matching status's name in the steps array
      const stepIndex = this.steps
        .map(status => status.status)
        .indexOf(matchingStatus.status);
  
      return stepIndex >= 0 ? stepIndex : 0;
    },
    ...mapGetters('auth', ['isImpersonating', 'impersonatedUserId']),
    
  },
};
