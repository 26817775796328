<div>
  
  <div class="home-navbar">
    <Admin_sidebar />
  </div>
  <div class="app-content">
    <Admin_navbar />
    <div class="content">
      <div v-if="loading" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <div class="upload-search-row">
        <button type="button" class="action-btn" @click="showAddUserModal = true" data-toggle="modal"
          data-target="#uploadModal" aria-label="Open"><span class="plus-sign">+</span>
          Add User</button>
      </div>
      
      
      <div class="card table-card">
        <div class="card-body">
          <ul class="nav fee-tabs" id="feeTabs">
            <li class="nav-item" @click="activeTab = 'All'">
              <a class="nav-link" :class="{ active: activeTab === 'All' }">All</a>
            </li>
            <li class="nav-item" @click="activeTab = 'Admins'">
              <a class="nav-link" :class="{ active: activeTab === 'Admins' }">Admins</a>
            </li>
            <li class="nav-item" @click="activeTab = 'Influencers'">
              <a class="nav-link" :class="{ active: activeTab === 'Influencers' }">Influencers</a>
            </li>
          </ul>
          <table id="content_table" class="table align-items-center table-flush content-table" data-sort="true">
            <thead class="thead-light">
              <tr>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Name</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Surname</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Email</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Role</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Created</th>
                <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
              </tr>
            </thead>
            <tbody v-if="filteredUsers.length > 0">
              <tr v-for="user in filteredUsers" :key="user.id">
                <td class="text-start">
                  <small class="text-xxs font-weight-light mb-0">{{ user.first_name }}</small>
                </td>
                <td class="text-start">
                  <small class="text-xxs font-weight-light mb-0">{{ user.last_name }}</small>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ user.email }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ user.role }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ user.date_joined }}</span>
                </td>
                <td class="text-start">                  
                  <button class="btn-sm action-btn font-weight-light text-xxs manage-btn" @click="openEditUserModal(user)">Edit</button>
                  <button @click="deactivateUser(user)" 
                  class="btn-sm action-btn font-weight-light text-xxs manage-btn">
                  {{ user.is_active ? 'Deactivate' : 'Activate' }}
                  </button>                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  <!-- Add User Modal -->
  <div v-if="showAddUserModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New User</h5>
          <button type="button" class="close" @click="showAddUserModal = false" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addUser">
            <div class="form-group">
              <label for="first_name" class="text-label">First Name</label>
              <input v-model="form.first_name" type="text" class="form-control" id="first_name" required />
            </div>
            <div class="form-group">
            <label for="last_name" class="text-label">Last Name</label>
              <input v-model="form.last_name" type="text" class="form-control" id="last_name" required />
            </div>
            <div class="form-group">
              <label for="email" class="text-label">Email</label>
              <input v-model="form.email" type="email" class="form-control" id="email" required />
            </div>
            <div class="form-group">
              <label for="role" class="text-label">Role</label>
              <select v-model="form.role" class="form-control" id="role" required>
                <option value="" disabled>Select Role</option>
                <option value="1">Admin</option>
                <option value="2">Brand</option>
                <option value="4">Influencer</option>
              </select>
            </div>
            
            <div class="modal-footer">
                <button type="submit" class="action-btn me-2">Create</button>
                <button type="button" class="action-btn" @click="showAddUserModal = false">Close</button>
              </div>
          </form>
        </div>
      </div>
    </div>
    </div>
    <div v-if="showEditUserModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit User</h5>
              <button type="button" class="close" @click="showEditUserModal = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editUser">
                <div class="form-group">
                  <label for="first_name" class="text-label">First Name</label>
                  <input v-model="view_form.first_name" type="text" class="form-control" id="first_name" required />
                </div>
                <div class="form-group">
                  <label for="last_name" class="text-label">Last Name</label>
                  <input v-model="view_form.last_name" type="text" class="form-control" id="last_name" required />
                </div>
                <div class="form-group">
                  <label for="email" class="text-label">Email</label>
                  <input v-model="view_form.email" type="email" class="form-control" id="email" required />
                </div>
                <div class="form-group">
                  <label for="role" class="text-label">Role</label>

                  
                  <select v-model.number="view_form.role" class="form-control form-control-sm" required>
                    <option value="" disabled>Select Role</option>
                    <option v-for="role in roles" :key="role.id" :value="role.id">
                      {{ role.name }}
                    </option>
                  </select>
                </div>
                <div class="modal-footer d-flex justify-content-end">
                  <button type="submit" class="action-btn me-2">Update</button>
                  <button type="button" class="action-btn" @click="showEditUserModal = false">Close</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  