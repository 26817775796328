<div class="home-navbar ">
    <Admin_sidebar />
</div>

<div class="app-content">
    <Admin_navbar />
    <div v-if="loading" class="loader"></div>
    <div class="content">
        <div class="upload-search-row">
        </div>
        <!-- Tabs for Pending Payment and Payment Completed -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active text-black" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">Pending Payment</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link text-black" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" type="button" role="tab" aria-controls="completed" aria-selected="false">Payment Completed</button>
            </li>
        </ul>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                <!-- Pending Payment Tab content -->
                <div class="card table-card">
                    <div class="card-body">
                        <table id="content_table" class="table align-item table-flush-item content-table">
                    <thead class="thead-light">
                        <tr>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Product</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Product Name</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Influencer Name</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Email </th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Brand Name</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Date</th>
                            <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(payment, index) in pendingPayments" :key="payment.id">
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                            </td>
                            <td class="text-start">
                                <img :src="payment.product_image_url" alt="Product Image" width="40" height="40">
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ payment.product_details && payment.product_details.length > 0 ? payment.product_details[0].product.product_name : 'N/A' }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">R {{ payment.influencer_amount }}</span>
                            </td>
                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ payment.influencer_name }}</span>

                            </td>
                            <td class="align-item text-start">
                                <div>
                                <span class="text-xxs font-weight-light mb-0">{{ payment.influencer_email  || 'None' }}</span>
                                </div>
                            </td>
                            <td class="align-item text-start">
                                <div>
                                <span class="text-xxs font-weight-light mb-0">{{ payment.brand_name  || 'None' }}</span>
                                </div>
                            </td>

                            <td class="text-start">
                                <span class="text-xxs font-weight-light mb-0">{{ payment.settlement_date }}</span>
                            </td>

    
                            <td class="text-start">
                                <button class="btn-sm action-btn font-weight-light text-xxs">
                                    View
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<!-- Payment Completed Tab -->
<div class="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab">
    <!-- Payment Completed Tab content -->
    <div class="card table-card">
        <div class="card-body">
            <table id="content_table_completed" class="table align-item table-flush-item content-table">
                <thead class="thead-light">
                    <tr>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">No</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Product</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Product Name</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Amount</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Influencer Name</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Email </th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Brand Name</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Date</th>
                        <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr  v-for="(payment, index) in distributedPayments" :key="payment.id">
                        <td class="text-start">
                            <span class="text-xxs font-weight-light mb-0">{{ index + 1 }}</span>
                        </td>
                        <td class="text-start">
                            <img :src="payment.product_image_url" alt="Product Image" width="40" height="40">
                        </td>
                        <td class="text-start">
                            <span class="text-xxs font-weight-light mb-0">{{ payment.product_details && payment.product_details.length > 0 ? payment.product_details[0].product.product_name : 'N/A' }}</span>
                        </td>
                        <td class="text-start">
                            <span class="text-xxs font-weight-light mb-0">R {{ payment.influencer_amount }}</span>
                        </td>
                        <td class="text-start">
                            <span class="text-xxs font-weight-light mb-0">{{ payment.influencer_name }}</span>

                        </td>
                        <td class="align-item text-start">
                            <div>
                            <span class="text-xxs font-weight-light mb-0">{{ payment.influencer_email  || 'None' }}</span>
                            </div>
                        </td>
                        <td class="align-item text-start">
                            <div>
                            <span class="text-xxs font-weight-light mb-0">{{ payment.brand_name  || 'None' }}</span>
                            </div>
                        </td>

                        <td class="text-start">
                            <span class="text-xxs font-weight-light mb-0">{{ payment.settlement_date }}</span>
                        </td>
                            <td class="text-start">
                                <button class="btn-sm action-btn font-weight-light text-xxs">
                                    View
                                </button>
                            </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>
</div>
</div>