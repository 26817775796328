<div v-if="UserLoggedIn">
  <Influencer_navbar v-model:search_input="search_query" />
</div>
<div v-else>
  <Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
</div>

<div v-if="!search_query">
<div class="container mt-4">
  <div class="custom-gap">
    <div
    v-for="(item, index) in items"
    :key="index"
  >
      <div class="card border-0">
        <div class="profile-info d-flex align-items-center mb-1">
          
          <img
            v-if="item.profile && item.profile.image"
            :src="item.profile.image"
            class="rounded-circle me-2 profile-pic"
            alt="Profile"
          />
          <img
            v-else
            src="@/assets/images/default-profile.png"
            class="rounded-circle me-2 profile-pic"
            alt="Profile"
          />
          <div class="d-flex justify-content-between w-100">
            <div>
              <RouterLink
                :to="{  name: 'view_Influencer_Profile', params: { influencerId: item.id } }"
                class="text-decoration-none text-dark"
              >
                <p class="small mb-0">{{ item.first_name }} {{ item.last_name }} 
                </p>
              </RouterLink>
            </div>
          </div>
        </div>


        <!-- Video section -->
        <div class="influencers-section flex-row">
          <div v-for="(video, videoIndex) in item.videos" :key="videoIndex">
            <div ref="videoPlayer_{{ videoIndex }}">

              <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="handleThumbnailError"/>
                                        
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100" >
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
              <div class="circle-images pb-85">
                <div v-for="(image, index) in video.products" :key="index" class="text-center">

                  <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                    <input type="hidden" ref="image_id" :value="image.product.id">
              
                    <img 
                      v-if="image.product && image.product.image_url" 
                      :src="image.product.image_url" 
                      class="circle-image" 
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                      alt="Product Image"
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</div>
<div v-else>
  <Search_Influencer :search_query="search_query" />

</div>