import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';
import EventBus from '@/eventBus.js';

export default {
  name: 'CartView',
  components: {
    Home_navbar,
    Influencer_navbar

  },
  data: () => ({
    cartItems: [],
    cartQuantity: 0,
    cartTotal: 0,
    LoggedIn: false,

  }),
  
    created() {
      this.LoggedIn = !!localStorage.getItem('token');
      if (this.isLoggedIn()) {
        this.fetchCartItems();
      } else {
        this.cartItems = this.getCartItemsFromCookies();
        this.calculateCartDetails();
      }
      EventBus.on('cart-updated', (quantity) => {
        this.cartQuantity = quantity;
      });

    },

    beforeDestroy() {
      // Cleanup event listener
      EventBus.off('cart-updated', (quantity) => {
        this.cartQuantity = quantity;
      });
    },
  
    methods: {
      setCookie(name, value, days) {
        let expires = "";
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=None; Secure";
      },
  
      // Helper function to get a cookie by name
      getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      },
  
      getCartItemsFromCookies() {
        const cart = this.getCookie('cart');
        return cart ? JSON.parse(cart) : [];
      },
      async fetchCartItems() {
        const token = localStorage.getItem('token');
        const FETCH_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
  
        try {
          const response = await fetch(FETCH_CART_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            }
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          this.cartItems = data.cart_products;
          this.calculateCartDetails();
        } catch (error) {
          console.error('Error fetching cart from API:', error);
        }
      },  
  
      calculateCartDetails() {
        this.cartQuantity = this.cartItems.reduce((total, item) => total + item.quantity, 0);
        this.cartTotal = this.cartItems.reduce((total, item) => total + (item.quantity * item.price), 0);
        EventBus.emit('cart-updated', this.cartQuantity);

      },
      increaseQuantity(id, product_id) {
        const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
        if (item) {
            item.quantity += 1;
            this.updateCart(item.product_id, 1);
            this.calculateCartDetails();
        }
    },

    decreaseQuantity(id, product_id) {
        const item = this.cartItems.find(item => item.id === id && item.product_id === product_id);
        if (item && item.quantity > 1) {
            item.quantity -= 1;
            this.updateCart(item.product_id, -1);
            this.calculateCartDetails();
        }
    },
      async removeFromCart(id,product_id) {
          const token = localStorage.getItem('token');
          
          if (this.isLoggedIn()) {
            // User is logged in, remove from server
            const DELETE_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/delete/${product_id}`;
      
            const response = await $.ajax({
              url: DELETE_PRODUCT_URL,
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${token}`
              }
            });
      
            if (response) {
              Swal.fire(
                'Removed!',
                'The product has been removed from the cart.',
                'success'
              ).then(() => {
                window.location.reload(); // Optionally reload to update UI
              });
            } else {
              throw new Error('Removal failed');
            }
          } else {
            // User is not logged in, remove from cookies
            const cart = JSON.parse(this.getCookie('cart')) || [];
            const updatedCart = cart.filter(item => item.id !== id || item.product_id !== product_id);
      
            this.setCookie('cart', JSON.stringify(updatedCart), 1);
            this.cartItems = updatedCart; // Update local cart state
            this.cartQuantity = updatedCart.reduce((total, item) => total + item.quantity, 0);
      
            // Emit an event to notify other components
            EventBus.emit('cart-updated', this.cartQuantity);
      
            Swal.fire(
              'Removed!',
              'The product has been removed from your cart.',
              'success'
            );
          }
      },      

      isLoggedIn() {
        const token = localStorage.getItem('token');
        return token !== null; 
      },  
  
      async updateCart(productId, quantity) {
        const token = localStorage.getItem('token');
      
        if (token) {
          // User is logged in, update cart on the server
          const UPDATE_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          try {
            const response = await $.ajax({
              url: UPDATE_CART_URL,
              method: 'POST',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                product_id: productId,
                quantity: quantity
              }),
              processData: false,
            });
      
            if (response.message === 'success') {
              // Refresh cart details after a successful update
              this.fetchCartDetails();
            } else {
              console.error('Failed to update cart in the database:', response.error || 'Unknown error');
            }
          } catch (error) {
            console.error('Error updating cart in the database:', error);
          }
        } else {
          // User is not logged in, update cart in cookies
          this.setCookie('cart', JSON.stringify(this.cartItems), 1);
        }
      },      
  
      proceedToCheckout() {
        const token = localStorage.getItem('token');
      if (!token) {
        this.$router.push('/login');
      }else{
        this.$router.push('/shipping_address');
      }
    
      },
    },
  };
  
