<nav class="navbar navbar-dark bg-dark fixed-top">
  <div class="container-fluid">
    <button class="btn text-white" @click="goBack">
      <i class="fas fa-arrow-left"></i>
    </button>
    <div class="ms-auto">
      <div class="nav-icons me-3 d-flex align-items-center">
        <div class="icon-container me-3" @click="toggleCart" ref="iconContainer">
          <a href="#">
            <i class='bx bx-shopping-bag text-white' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
          <a href="#"><i class='bx bx-user text-white'></i></a>
          <div class="dropdown-content">
            <router-link to="/register" class="">
              <span class="text-dark" >Sign Up</span>
            </router-link>
       
            <router-link to="/login" class=" ">
              <span class="text-dark">Login</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
    <div class="view-influencer-app-content">
      <div class="card border-0 bg-transparent">
   
        <div class="profile-header-view rounded-12 position-relative">
         
          <div class="View-influencer mt-4 d-flex justify-content-center align-items-center">
            <div class="top-right-buttons">
               <div class="share-button-container">
                <button class="btn btn-outline-dark text-black btn-sm" 
        data-toggle="tooltip" 
        data-placement="bottom" 
        title="Share Profile" 
        @click="toggleShareMenu">
    <i class='bx bx-share-alt'></i>
</button>

<div class="share-button-container position-relative text-primary text-xxs text-black">
  <div v-if="showShareMenu" class="mb-3 me-2 menu mt-1 share-menu position-absolute p-2 bg-light" style="max-height: 200px; overflow-y: auto;">
    <div class="d-flex flex-column align-items-center">
      <a @click="copyProfileLinkToClipboard" class="menu-item mb-2 text-dark" data-bs-toggle="tooltip" title="Copy Link">
        <span @click="closeMenu">
          <i class="bi bi-link fs-5"></i>
        </span>
      </a>
      
      <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(profileUrl) + '&t=' + new Date().getTime()" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Facebook">
        <i class="bi bi-facebook fs-5"></i>
      </a>
      
      <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Twitter">
        <i class="bi bi-twitter fs-5"></i>
      </a>
      
      <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share via Email">
        <i class="bi bi-google fs-5"></i>
      </a>
      
      <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on LinkedIn">
        <i class="bi bi-linkedin fs-5"></i>
      </a>
      
      <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on WhatsApp">
        <i class="bi bi-whatsapp fs-5"></i>
      </a>
      
      <a :href="'https://t.me/share/url?url=' + encodeURIComponent(profileUrl)" target="_blank" class="menu-item mb-2 text-dark" @click="closeMenu" data-bs-toggle="tooltip" title="Share on Telegram">
        <i class="bi bi-telegram fs-5"></i>
      </a>
    </div>
    </div>
</div>

    <!-- Toast Notification for Copy -->
    <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
      <div id="copyToast" class="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            Link copied to clipboard!
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</div>


            <img :src="profileImage || require('@/assets/images/default-profile.png')" alt="Profile Picture" class="profile-picture-img-modal" id="profileImage">
            <div class="influencer_info">
              <p class="text-start profile-name fw-bolder">{{ form.first_name }} {{ form.last_name }}</p>
              <p class=" text-start bio">{{ form.bio }}</p>
            </div>
          </div>
        </div>
   
        <ul class="nav view_influencers_line justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link text-black" id="pills-videos-tab" data-bs-toggle="pill" data-bs-target="#pills-videos" role="tab" aria-controls="pills-videos" aria-selected="true"><i class='bx bx-video text-black'></i> Content</a>
          </li>
        </ul>
    
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
            <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
        
            <div v-else class="row">
                <div class="view-video-lists">
                  <div v-for="(video, index ) in videos" :key="index" class="video-card position-relative">
                    <div ref="videoPlayer_{{ index }}"  @click="openFullScreen(video.id, index)">
                      <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="handleThumbnailError" />

                      <video v-else :src="video.video_url"  class="rounded-12 img-fluid h-250 object-fit-cover w-100">
                        Your browser does not support the video tag.
                      </video>
                
              <div class="circle-images pb-85">
                <div v-for="(image, index) in video.products" :key="index" class="text-center">
                  <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                    <input type="hidden" ref="image_id" :value="image.product.id">
                    <img
                      v-if="image.product && image.product.image_url"
                      :src="image.product.image_url"
                      class="circle-image"
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')"
                      alt="Product Image"
                    >
                  </router-link>
                </div>
              </div>
                  <div class="text-start mt-2 ms-1 text-sm d-none">
                    <h6 class="fw-bold">{{ video?.caption || 'No Caption' }}</h6>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isFullScreenMode" class="full-screen-container">
          <Fullscreen_navbar/>
          <!-- Close Button -->
          <button class="close-btn" @click="exitFullScreen">
              <i class="bx bx-x"></i>
          </button>
          <!-- Influencer Info -->
         
          <div v-if="isFetching" class="spinner-container">
              <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
              </div>
          </div>

          <div class="reel-container">
          <div v-for="(reel, index) in reels" :key="index" class="reel" :ref="'videoPlayer_' + index">
       
              <video :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin" playsinline autoplay  controls preload="auto" @play="playVideoAtIndex(index)">
                  <source :src="reel.video_url" type="video/mp4" />
                </video>
 
              <div class="influencer-info d-flex align-items-center">
                  <router-link
                  :to="{ name: 'view_Influencer_Profile', params: { influencerId: reel.influencer.id } }"
                  class="d-flex align-items-center text-decoration-none"  style="cursor: pointer;"
                >
                  <img :src="reel.influencer.image" class="circle-image rounded-circle me-2"  @error="(event) => event.target.src = require('@/assets/images/default-profile.png')"/>
                  
                  <span class="fw-bold text-light">{{ reel.influencer.first_name }} {{reel.influencer.last_name}}</span>
              </router-link>
              </div>
              <div class="action-icons mt-5">
                <div class="d-flex flex-column align-items-center text-white" style="cursor: pointer;">
                  <!-- Heart icon -->
                  <i :class="reel.is_liked ? 'bx bxs-heart text-danger fs-3' : 'bx bxs-heart fs-3'"></i>
                  <!-- Count below the icon, with a small margin-top -->
                  <span class="text-white mt-1">{{ reel.like_count }}</span>
                </div>
                <div class="d-flex flex-column align-items-center text-white" style="cursor: pointer;">
                  <!-- Share icon -->
                  <i class="fa-solid fa-share text-white fs-5"></i>
                  <!-- Count below the icon, with a small margin-top -->
                  <span class="text-white mt-1">{{ reel.share_count }}</span>
                </div>
              </div>
          <div class="circle-images pb-85">
              <router-link v-for="(product, imgIndex) in reel.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }" @click.native="enterPictureInPicture(reel, index)">
                  <img :src="product.product.image_url" class="modal-circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
              </router-link>
          </div>
          </div>
         
          <div ref="scrollSentinel" class="scroll-sentinel"></div>
          
          </div>
      </div>

          </div>
          <div class="tab-pane fade" id="pills-pending-aproval" role="tabpanel" aria-labelledby="pills-pending-aproval-tab">...

          </div> 
        </div>
        