<div class="home-navbar ">
  <Customer_sidebar />
</div>

<div class=" app-content">
  <Customer_navbar />

      <div class="card border-0 bg-transparent">
        <div class="profile-header rounded-12 position-relative">
          <div class="top-right-buttons">
            <button class="btn btn-light btn-sm me-2" @click="showEditProfileModal = true" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Profile">
              <i class="bx bx-pencil"></i> Profile
            </button>
          </div>

          <img :src="require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img w-auto" id="profileImage" />
          <i class="bx bxs-camera imge-edit" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Change Profile Picture" @click="triggerFileInput" id="uploadIcon"></i>
          <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;" />

          <h2 class="profile-name">{{ form.first_name }} {{ form.last_name }}</h2>
          <h6 class="custom-margin-top">{{ form.bio }}</h6>
        </div>

        <!-- Tabs -->
        <ul class="nav nav-pills mb-3 justify-content-center mt-5" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-wishlist-tab" data-bs-toggle="pill" data-bs-target="#pills-wishlist" type="button" role="tab" aria-controls="pills-wishlist" aria-selected="true">
              <i class="bx bx-heart"></i> Wishlist
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-track-order-tab" data-bs-toggle="pill" data-bs-target="#pills-track-order" type="button" role="tab" aria-controls="pills-track-order" aria-selected="false">
              <i class="bx bx-package"></i> Track Order
            </button>
          </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content" id="pills-tabContent">
          <!-- Wishlist Tab -->
          <div class="tab-pane fade show active" id="pills-wishlist" role="tabpanel" aria-labelledby="pills-wishlist-tab">
            <div v-if="wishlistProducts.length === 0" class="text-center my-4">
              <i class="fas fa-trash-alt empty-bin-icon"></i>
              <p>Your wishlist is empty</p>
              <p>Add products to create your own wishlist by tapping the heart icon.</p>
            </div>
            <div v-else class="row justify-content-center">
              <div v-for="product_wish in wishlistProducts" :key="product_wish.id" class="cart-cont d-flex align-items-center mb-3 border p-2 rounded col-8">
                <img :src="product_wish.productdetail.product.image_url" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" class="cart-item-image" />
                <div class="cart-item-details flex-grow-1 mx-3">
                  <!-- Product Name -->
                  <h5>{{ product_wish.productdetail.product.product_name }}</h5>
                  <h6 class="text-muted">R{{ product_wish.productdetail.price }}</h6>
                  <h6 class="text-muted">{{ product_wish.productdetail.color }}</h6>
                  <h6 class="text-muted">{{ product_wish.productdetail.stock_status__status_name }}</h6>
                 
                </div>
                <!-- Actions Section: Add to Cart & Delete -->
                <div class="cart-item-actions d-flex align-items-center">
                  <!-- Add to Cart Button -->
                  <button class="add-to-cart bg-warning rounded-12 mr-2" @click="addToCart(product_wish.productdetail.id)">
                    <i class="fa-solid fa-cart-shopping wishlist-icon"></i> Add to Cart
                  </button>
                
                  <!-- Delete Button -->
                  <button class="btn btn-warning" @click="removeFromWishlist(product_wish.productdetail.id)">
                    <i class="fa-solid fa-trash text-white"></i>
                  </button>
                </div>
                
              </div>
            </div>
          </div>

          <!-- Track Order Tab -->
          <div class="tab-pane fade" id="pills-track-order" role="tabpanel" aria-labelledby="pills-track-order-tab">
            <!-- Track order content goes here -->
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Profile Modal -->
    <div v-if="showEditProfileModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="close-btn" @click="closeModal">&times;</span>
            <h5 class="modal-title">Profile</h5>
            <button type="button" class="close" @click="showEditProfileModal = false" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-3">
              <img :src="require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img-modal w-auto" id="profileImage" />
              <i class="bx bxs-camera imge-edit-modal" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Change Profile Picture" @click="triggerFileInput" id="uploadIcon"></i>
            </div>
            <form @submit.prevent="updateUser">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="first_name" class="text-label">First name:</label>
                    <input type="text" class="form-control" v-model="form.first_name" id="first_name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="last_name" class="text-label">Last name:</label>
                    <input type="text" class="form-control" v-model="form.last_name" id="last_name" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email" class="text-label">Email address:</label>
                    <input type="email" class="form-control" v-model="form.email" id="email" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="phone_number" class="text-label">Phone number:</label>
                    <input type="text" class="form-control" v-model="form.phone_number" id="phone_number" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="action-btn" @click="updateUser">Save changes</button>
          </div>
        </div>
      </div>
    </div>

