import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import { get_user_details, get_token } from '@/router/index.js';

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return { 
      videos: [],
      token_exists: null, // Store token existence
      user_details: null, // Store user details

    };
  },
  methods:{

    async fetchVideos() {
      try {
        const token = localStorage.getItem('token');
        const impersonate = localStorage.getItem('impersonate');
        const brand_id = localStorage.getItem('brand_id');

        let FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_brand_videos/`;

        if (impersonate === 'true') {
          FETCH_VIDEOS_URL += `?brand_id=${brand_id}&impersonate=true`;
        }else {
          FETCH_VIDEOS_URL += `?brand_id=${brand_id}`;
        }

        const response = await fetch(FETCH_VIDEOS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        const data = await response.json();
        this.videos = data;
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    },
  },
  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();

    if (!this.token_exists || (this.user_details.user_role !== 'BRAND' && this.user_details.user_role !== 'ADMIN')) {
      localStorage.removeItem('token'); // Clear the token
      this.$router.push('/login'); // Redirect to home page
    } else {
      this.fetchVideos(); // Fetch videos if the user is authorized
    }
  },
}