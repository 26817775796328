<div v-if="UserLoggedIn">
  <Influencer_navbar v-model:search_input="search_query" />
</div>
<div v-else>
  <Home_navbar v-model:search_input="search_query" @update:search_input="update_search_query" />
</div>
<div v-if="addedToWishlist" class="centered-popup alert-success">
  Successfully added to wishlist!
  <a href="/wishlist" class="alert-link"> WISHLIST</a>

</div>

<div v-if="search_query === ''">
<div class="container">
  <div class="row">
    <div class="col-md-3">
      <Filter_product @filter-products="filter_product_list" />
    </div>

    <div class="col-md-9">
      <div v-if="new_products.length">
        <div class="items-found">{{ new_products.length }} items found</div>
        <div class="product-grid">
          <div 
            v-for="product in new_products" 
            :key="product?.product?.id" 
            class="product-card">
            <router-link
            :to="{ 
              name: 'product', 
              params: { 
                id: product.id 
              } 
            }"
            class="product-link"
          >
            <div
              class="product-image"
              :style="{ backgroundImage: `url(${product.product.image_url})` }"
            ></div>
          </router-link>
            <div class="product-info text-start">
              <p class="product-title text-black">{{ product.product.product_name }}</p>
              <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
              
              <div class="d-flex justify-content-between align-items-center">
                <p class="product-price text-black">R{{ product.price }}</p>
                <div class="d-flex justify-content-between">
                  <div class="cart-icon position-relative">
                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addToWishlist(product.id)"></i>
                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="handleProductClick(product)"></i>
                    <div v-if="selectedProduct === product && showSizes" class="sizes show text-black">
                      
                      <div v-for="size in sizes" :key="size.size" class="size-select" @click="handleAddToCart(size); addToCart(product)">
                        {{ size.size }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div v-else>
  <!-- Search results will be displayed here -->
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <Filter_product
        @filter-products="filter_product_list"
        />
      </div>

      <div class="col-md-9">
        <div class="items-found">{{ filteredProducts.length }} items found</div>

        <!-- Dropdown to show combined results -->
        <div v-if="showDropdown" class="dropdown-menu">
          <div
              v-for="result in filteredProducts"
              :key="result.id"
              @click="selectResult(result)"
              class="dropdown-item"
          >
          <span class="text-start" v-if="result.type === 'product'">{{ result.product_name }}</span>
          <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
          <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
          <span v-else>Description: {{ result.description }}</span>
          </div>

        </div>
        
        <div class="product-grid">
          <div class="product-card" v-for="product in filteredProducts" :key="product.id">
            <div class="product-link">
              <router-link
              :to="{ name: 'product', params: { id: product.id, video_id: product.product.brand_video_id }}" 
              class="product-link-image"
>
        <!-- Product Image and Info -->
        <input type="hidden" ref="image_id" :value="product.id">
        <div class="product-image" v-if="product.product.image_url" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
      </div>
      </router-link>
      <div class=" product-info text-start">
        <p class="product-title text-black">{{ product.product.product_name }}</p>
        <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
        <div class="d-flex justify-content-between align-items-center">
          <p class="product-price text-black">R{{ product.price }}</p>
          <div class="d-flex justify-content-between">
            <div class="cart-icon position-relative">
              <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addToWishlist(product_d)"></i>
              <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="handleProductClick(product)"></i>
              <div v-if="selectedProduct === product && showSizes" class="sizes show text-black">
                <div v-for="size in sizes" :key="size.size" class="size-select" @click="handleAddToCart(size); addToCart(product)">
                  {{ size.size }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>      
</div>
</div>
</div>
</div>
</div>