<div>
  <div class="home-navbar">
    <Influencer_sidebar />
  </div>

  <div class="app-content delivery-category">
    <Influencer_navbar />
    <div v-if="loading" class="loader-overlay">
      <div class="loader"></div>
    </div>

    <div v-if="!selectedOrderId">
      <!-- Home Page Content -->
      <div class="order- p-2">
        <div v-if="orders.length === 0" class="empty-cart text-center">
          <p>No orders yet. Your cart is empty!</p>
          <i class="bi bi-cart4 cart-icon"></i>
        </div>

        <div
          v-for="order in orders"
          :key="order.order_number"
          class="card order-card mb-4 p-3 shadow-sm rounded-4"
          @click="showOrderDetails(order.id)"
          style="cursor: pointer;"
        >
          <div class="d-flex justify-content-between">
            <!-- <div class="d-flex"> -->
              <h5 class="text-sm fw-bolder text-black">#{{ order.order_number }}</h5>
              <div class="d-flex justify-content-between">
                <p class="text-sm text-muted me-2">{{ formatDate(order.order_date) }}</p>
                <p class="text-sm fw-bold mb-0">R{{ order.total_cost }}</p>
              </div>
          </div>     
          <div class="d-flex"> 
          <div v-for="item in order.order_items" :key="item.id" class="me-2">
            <img :src="item.product_image_url" 
                 alt="Product Image" 
                 class="rounded img-fluid" 
                 width="60" height="60" />
          </div>
        </div> 
        </div>
      </div>
    </div>

    <div v-else>
      <!-- Order Details Page Content -->
      <div class="container mt-5">
        
        <div class="d-flex align-items-start">
          <h5 @click="closeOrderDetails" class="btn mt-n1"> 
            <i class="bx bx-arrow-back fw-bold fs-5"></i>
          </h5>
          <h5 v-if="selectedOrder" class="fw-bold text-black">Order #{{ selectedOrder.order_number }}</h5>
        </div>

        <div v-if="selectedOrderFullAddress" class="card mb-4 ps-2">
          <p class="text-start fw-bold ps-2">Delivered</p>
          <p class="text-start text-muted fw-bold ps-2">{{ selectedOrderFullAddress }}</p>
        </div>
    
        <div v-if="selectedOrder" class="order-details-section">
          
          <!-- Image Section -->
    
          <!-- Order Details Section -->
          <div class="order-details-content cart-cont mb-4 text-black">
            <h3 class="order-title fw-bolder mb-3">Order Details</h3>
            <div class="delivery-progress justify-content-between position-relative">
              <div class="step-tracker justify-content-between position-relative d-flex mt-2 mb-2">
                <div
                  v-for="(step, index) in steps"
                  :key="index.id"
                  class="step-track d-flex flex-column align-items-center mx-4"
                  :class="{ active: currentStep >= index }"
                >
                
                  <div class="d-flex justify-content-center align-items-center">
                    <i :class="{ 
                      'fas fa-check fa-2x': step.status === 'Confirmed', 
                      'fas fa-box fa-2x': step.status === 'Processing', 
                      'fas fa-truck fa-2x': step.status === 'Shipped',        // Shipped comes before Delivered
                      'fas fa-check-double fa-2x': step.status === 'Delivered' 
                    }"></i> 
                  </div>
                  <div class="mt-3 fw-bold fs-6 ">{{ step.status }}</div>
                </div>
              </div>
            </div>
    
            <div class="order-items">
              <div v-for="item in selectedOrder.order_items" :key="item.id" class="cart-item mb-3">
                <!-- <div class="card-body"> -->
                  <img :src="item.product_details.image_url || 'placeholder.jpg'" 
                 alt="Product Image" 
                 class="cart-item-image" 
                   width="100" height="100" />
                   <div class="cart-item-details d-flex">
                  <h5 class="fw-bold">{{ item.product_details.product_name }}</h5>
                  <p class="mb-0">Size: {{ item.product_details.size }}</p>
                  <p class="mb-0">Colour: {{ item.product_details.color }}</p>
                  <p class="mb-0">Quantity Purchased: {{ item.quantity_ordered }}</p>
                  <p class="mb-0">Price: R {{ item.unit_price }}</p>
                  <router-link :to="`/order_return/${selectedOrderId}/${item.product_details.id}`">
                    <button 
                    v-if="!isImpersonating && selectedOrder.order_statuses === steps.find(step => step.status === 'Delivered').id 
                    && isWithinReturnWindow(selectedOrder.updated_at)" 
                    :disabled="item.has_return_been_logged || !isWithinReturnWindow(selectedOrder.updated_at)" 
                    class="action-btn btn-secondary btn-sm mt-2" 
                  >
                    <i class="fas fa-undo-alt mr-2"></i>Return Item 
                  </button>
                </router-link>
                </div>
              </div>
            </div>
    
            <!-- Invoice Section -->
            <div class="invoice-section p-3">
             
              <h3 class="order-title fw-bold">Invoice</h3>
              <div class="d-flex justify-content-between mt-3 fw-bold">
                <span>Subtotal</span>
                <span >R {{ selectedOrder.total_cost || 'N/A' }}</span>
              </div>
              <div class="d-flex justify-content-between mt-3 fw-bold">
                <span>Discount</span>
                <span>R 0.00</span>
              </div>
              <div class="d-flex justify-content-between mt-3 fw-bold">
                <span>Shipping</span>
                <span>
                  {{ selectedOrder.deliveries[0]?.delivery_cost ? 'R ' + selectedOrder.deliveries[0]?.delivery_cost : 'N/A' }}
                </span>
              </div>
              <hr>
              <div class="d-flex justify-content-between mt-3 ">
                <h5 class="fw-bolder">Total</h5>
                <h5 class="fw-bolder"> R {{ selectedOrder.total_cost || 'N/A' }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>

    