<!DOCTYPE html>
<html lang="">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <link rel="icon" href="<%= BASE_URL %>favicon.ico">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap">
    <title>Home navbar </title>
    </head>
  <body>

<div class="home-navbar " @click="handleClickOutside($event)">
   
  <div class="top-banner">
    <div class="row">
      <div class="col-md-3 col-12 d-flex justify-content-start">
        <div class="col top-banner-sm">
          <a href="#"
          class="btn btn-link"
          :class="{ active: currentRole === 'influencer' }"
          @click.prevent="selectRole('influencer')">Influencer</a>
          <a href="#"
              class="btn btn-link d-none"
              :class="{ active: currentRole === 'brand' }"
              @click.prevent="selectRole('brand')">Brand</a>              
        </div>
      </div>    
    </div>
  </div>
 
  <nav class="first-navbar d-none">
    <i class='bx bx-menu fs-4 text-dark ms-3 close-sidebar d-none' @click="toggleSidebar"></i>
 
    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo"  @click="goToLandingPage" >
    <div class="search-bar search-bar-lg">
        <input type="text" placeholder="Search for products" id="search-input" :value="search_input" @input="update_search">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
      <div>
        <router-link to="/login" class="text-black">
          <i class="fa-regular fa-square-plus nav-icon text-black me-2 fs-4"></i>
        </router-link>
      </div>
      <div id="iconContainer" class="icon-container cart-toggle" @click="toggleRightSidebar" ref="iconContainer">
        <a href="#">
            <i class='bx bx-shopping-bag fs-4' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-user fs-4'></i></a>
            <div class="dropdown-content"> 
                <router-link to="/register" class="">
                  <span class="text-dark" >Sign Up</span>
                </router-link>
       
                <router-link to="/login" class=" ">
                  <span class="text-dark">Login</span>
                </router-link>
            </div>
        </div>      
    </div>
  </nav>
 
  <div class="search-bar mt-3 search-bar-sm">
    <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
    <div class="search-dropdown" id="search-dropdown">
    </div>
</div>
 <br>
  <nav class="second-navbar d-none" :class="{'sidebar-visible': isSidebarVisible}">
    <div class="nav-item" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
     
      <a href="#" class="department-name">{{ department.department_name }}</a>
      <div class="dropdown-content align-items justify-content-center">
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
          <a href="#" class="category-name">{{ category.category_name }}</a>
          <div v-if="category.subcategories.length" class="subcategory-container">
            <router-link
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories"
              :key="subcategoryIndex"
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item d-none" data-dropdown>
        <a href="/view_brands">BRANDS</a>
    </div>
  </nav>
</div>
 
 
 
<!-- Main Sidebar -->
 
<div class="category-sidebar d-none" :class="{'open': sidebarOpen}" id="sidebar" ref="sidebar">  
  <div class="sidebar-links d-flex justify-content-end position-relative text-black" @click="toggleSidebar">
      <img 
      :src="require('@/assets/images/Influencer_1.png')" 
      alt="Back" 
      class="category-image h-100 w-100"

    />
    <i class='bx bx-x position-absolute top-0 end-0'></i>
  </div>
  <!-- Departments Loop -->
  <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
    <a href="#" class="sidebar-item department-name-sidebar text-start fw-bold" @click.prevent="toggleSubSidebar(departmentIndex)">
      {{ department.department_name }}
    </a>
   
    <!-- Sub-Sidebar for Categories -->
    <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar">
      <div class="back-btn d-flex justify-content-center align-items-center position-relative" @click="toggleSubSidebar(departmentIndex)">
        <img 
        :src="require('@/assets/images/Influencer_1.png')" 
        alt="Back" 
        class="category-image h-100 w-100"
        
      />
      <span class="position-absolute start-50 translate-middle text-white fw-bolder">{{ department.department_name }}</span>
      <i class='bx bx-x position-absolute top-0 end-0 text-black fs-5'></i>
      </div>
      <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
        <a href="#"
        class="sidebar-item dropdown-toggle category-name text-start d-flex justify-content-between"
        data-bs-toggle="collapse"
           :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex"
           aria-expanded="false"
           :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
          {{ category.category_name }}
        </a>
 
        <!-- Only show subcategories when toggle button is clicked -->
        <div :id="'categoryDropdown' + departmentIndex + categoryIndex" class="collapse">
          <div v-if="category.subcategories.length" class="subcategory-container text-black text-start">
            <router-link
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories"
              :key="subcategoryIndex"
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-item text-start fw-bold d-none">
    <a href="/view_brands">BRANDS</a>
 
</div>
</div>
 
<!-- Sidebar Container -->
<div id="right-sidebar" :class="{'open':isRightSidebarOpen}">
  <!-- Close Button -->
  <div class="view-cart-header d-flex justify-content-between fixed-top">
    <div class="back-button" v-if="checkoutInfluencer" @click="handleBackButtonClick()">
      <i class="fa-solid fa-arrow-left"></i>
    </div>
    <h5 v-else class="text-start fw-bold text-black">Cart</h5>
    <div class="close-btn-cart text-black fw-bolder" @click="isRightSidebarOpen = false">
      <i class='bx bx-x'></i>
    </div>
  </div>

 <!-- Display cart items by influencer if there's more than one influencer -->
<div v-if="!checkoutInfluencer && cartItemsByInfluencer.length > 1" class="sidebar-content">
  <div v-for="(items, index) in cartItemsByInfluencer" :key="index" class="cart-cont">
    <!-- Only show the first product for each influencer -->
    <div v-for="item in items.items.slice(0, 1)" :key="item.id" class="cart-item">      
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
       
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
   
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
        </div>
      </div>
    </div>
    
    <div class="cart-view-container">

      <!-- Display the total count of products for each influencer on the "View Cart" button -->
      <a href="" class="add-to-cart text-white fw-bold" @click.stop.prevent="viewInfluencerCart(items.items[0].seller_id)">View {{ items.items[0].brand }}'s Cart    ({{ items.items.length }})</a>    
    </div>

  </div>
  <br>
</div>
  <!-- Display selected influencer's cart items -->
<div v-else-if="checkoutInfluencer" class="sidebar-content">
  <div v-for="item in cartItemsByInfluencer.find(influencer => influencer.seller_id === checkoutInfluencer).items" :key="item.id" class="cart-item">
    <img :src="item.image" alt="Product image" class="cart-item-image" />
    <div class="cart-item-details d-flex text-black">
      <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
     
      <p class="product-brand mb-1"> {{ item.brand }}</p>
      <p class="mb-0">size: {{ item.size }}</p>
      <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
      <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
      <div class="d-flex align-items-center">
        <span class="me-2">Qty:</span>
        <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteFromCart(item.id, item.product_id)">
          <i class="fa-solid fa-trash"></i>
        </button>
        <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item.id, item.product_id)">-</button>
        <span class="quantity mx-2">{{ item.quantity }}</span>
        <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item.id, item.product_id)">+</button>
      </div>
    </div>
  </div>
  
  <div class="view-cart-btn">
    <a href="#" class="add-to-cart text-white" @click="proceedToCheckout">Checkout <span class="mx-3">-</span> R{{ calculateTotal(cartItemsByInfluencer.find(influencer => influencer.seller_id === checkoutInfluencer).items)  }}</a>
  </div>


</div>
  <!-- Display cart items if there's only one influencer -->
  <div v-else class="sidebar-content">
  <!-- Sidebar Content -->
    <div v-if="cartItems.length === 0" class="empty-cart-message text-center">
      <i class="mt-5 fa fa-cart-plus fa-3x"></i> <!-- Shopping cart icon -->
      <p class="mt-3 fs-6 fw-bold">Your cart looks lonely!</p>
      <p class="fs-7 ">Add Some Products and Make It Happy.</p>
    </div>
    <div v-else>
    <div v-for="item in cartItems" :key="item.id" class="cart-item">
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
       
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
 
        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
            <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteFromCart(item.id, item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
            <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item.id, item.product_id)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
            <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item.id, item.product_id)">+</button>
      </div>
    </div>
      </div>

      <div class="view-cart-btn">
        <a href="#" class="add-to-cart text-white"  @click="setCheckoutInfluencer(cartItems[0].seller_id)">Checkout <span class="mx-3">-</span> R{{formatPrice(cartTotal) }}</a>
      </div>
    </div>
  </div>
 
</div>

 
</body>
</html>