/* global $ */
import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import axios from 'axios';
import Swal from 'sweetalert2';


export default {
  name: 'ManageView',
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return {
      impersonating: localStorage.getItem('impersonate') === 'true',
      image_files: [],
        currentPage: 0,
        imagesPerPage: 5,
        current_image: {
          id: null,
          description: '',
          price: '',
          no_of_items: '',
          categories: [],
          selectedCategory: '',
          newCategory: '',
          size:'',
          color:'',
          quantity:'',
          product_name:'',

        },
        departments: [],
        categories: [],
        subcategories: [] ,
        selectedDepartment: null,
        selectedCategory: null,
        selectedSubcategories: [],
    };
  },
  mounted() {
    this.loadVideo();
    this.initImageUpload();
    this.loadImages();
    this.fetchDepartments();
    
  },
  watch: {
    selectedDepartment() {
      this.updateCategories(); // Update categories based on the department change
    },
    selectedCategory() {
      this.updateSubcategories(); // Update subcategories based on the category change
    },
  },

  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.current_image.image_url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    scrollLeft() {
      const container = this.$refs.imageContainer.querySelector('.row');
      container.scrollBy({
        left: -container.offsetWidth,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$refs.imageContainer.querySelector('.row');
      container.scrollBy({
        left: container.offsetWidth,
        behavior: 'smooth'
      });
    },
    async loadVideo() {
      const video_id = this.$route.params.video_id;
      
      if (video_id) {
          try {
              const token = localStorage.getItem('token');
              const LOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_video/${video_id}`;
  
              // Fetch the video data
              const response = await fetch(LOAD_VIDEO_URL, {
                  headers: {
                      'Authorization': `Token ${token}`,
                  },
              });
              const data = await response.json();
              const video_url = data.video_url;
  
              $('#video_source').attr('src', video_url);
              $('#video_player')[0].load();
  
          } catch (error) {
              console.error('Error loading video:', error);
              Swal.fire({
                  icon: 'error',
                  title: 'Error Loading Video',
                  text: 'There was an error loading the video. Please try again.',
              });
          }
      } else {
          Swal.fire({
              icon: 'warning',
              title: 'Missing Video ID',
              text: 'No video ID was provided. Please select a valid video.',
          });
      }
  },
  
    
  loadImages() {
    const video_id = this.$route.params.video_id;
    if (video_id) {
      const token = localStorage.getItem('token');
      const LOAD_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;

      axios.get(LOAD_IMAGES_URL, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.image_files = response.data; // Assuming response.data is an array of image data
      })
      .catch(error => {
        console.error('Error loading images:', error);
      });
    }
  },
  initImageUpload() {
    var initialUploadGroup = $('.upload-group').first().clone();
  
    // Add more image upload groups
    $('#addMoreImages').click(function () {
      var clonedGroup = initialUploadGroup.clone();
      clonedGroup.find('input').val('');
      clonedGroup.find('img#imagePreview').attr('src', ''); // Clear the image preview
      $('#imageUploadContainer').append(clonedGroup);
    }); 
  
    // Handle image preview on file selection
    $(document).on('change', '#image_url', function (event) {
      var reader = new FileReader();
      var preview = $('#imagePreview');
      var uploadIcon = $('#uploadIcon');
      var uploadText = $('#uploadText');
  
      reader.onload = function (e) {
        preview.attr('src', e.target.result);
        preview.show();
        uploadIcon.hide();
        uploadText.hide();
      };
  
      reader.readAsDataURL(event.target.files[0]);
    });
  
    // Warn if there are unsaved changes when clicking outside of modal
    window.addEventListener('beforeunload', function (e) {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = ''; // Modern browsers require this
      }
    });
  
    // Check if there are unsaved changes
    let unsavedChanges = false;
    $('input, textarea, select').on('input change', function() {
      unsavedChanges = true;
    });
  
    // Upload images
    $('#upload_image').on('click', async (e) => {
      e.preventDefault();
  
      // Validate form fields before uploading
      if (!validateUploadForm()) {
        return;
      }
  
      // Clear unsaved changes flag
      unsavedChanges = false;
  
      Swal.fire({
        title: 'Uploading Images',
        text: 'Please wait while we upload your image(s)...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      const video_id = this.$route ? this.$route.params.video_id : null; 
      const formData = new FormData();
      const imageGroups = $('.upload-group');
      const departmentId = this.selectedDepartment;
      const categoryId = this.selectedCategory;
      const subcategoryId = this.selectedSubcategories;

      const color = $('#color').val();
      const price = $('#price').val();
   
  
      imageGroups.each(function () {
        const image_file = $(this).find('#image_url')[0].files[0];
        if (image_file) {
          formData.append('image_urls', image_file);
        }
  
        formData.append('description', $(this).find('#description').val());
        formData.append('product_name', $(this).find('#product_name').val());
        formData.append('no_of_items', $(this).find('#no_of_items').val());
        formData.append('department', departmentId);
        formData.append('category', categoryId);
        formData.append('sub_category', subcategoryId);
  
        // Handle sizes and colors
        $(this).find('#size_table tr.size-color-row').each(function () {
          const size = $(this).find('#size').val();
          const quantity = $(this).find('#size_quantity').val();  
          formData.append('sizes[]', size);
          formData.append('quantities[]', quantity);
          });
          formData.append('color', color);
          formData.append('price', price);
      });
      if (video_id) {
        formData.append('brand_video_id', video_id);
      }
      const token = localStorage.getItem('token');
      try {
        const UPLOAD_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/upload_image/`;
  
        const response = await $.ajax({
          url: UPLOAD_IMAGE_URL,
          method: 'POST',
          data: formData, // Use 'data' instead of 'body'
          processData: false, // Important for FormData
          contentType: false, // Important for FormData
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
  
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Upload Successful',
            text: 'Your product has been uploaded successfully!',
          }).then(() => {
            window.location.reload();  // Reload page after upload
          });
        } else {
          throw new Error('Upload failed');
        }
  
      } catch (error) {
        console.error('Error uploading images:', error);
        Swal.fire({
          icon: 'error',
          title: 'Upload Failed',
          text: 'Failed to upload products. Please try again.',
        });
      }
    });
  
    function validateUploadForm() {
      let form = document.getElementById('uploadForm');
  
      // Reset previous validation state
      $(form).find('.is-invalid').removeClass('is-invalid');
  
      let isValid = true;
  
      // Check for valid fields
      if (form.checkValidity() === false) {
        $(form).find('input, select, textarea').each(function () {
          if (!this.checkValidity()) {
            $(this).addClass('is-invalid');
            isValid = false;
          } else {
            $(this).removeClass('is-invalid');
          }
        });
      }
  
      $('#imageUploadContainer').find('.upload-group').each(function () {
        const priceInput = $(this).find('#price');
        let price = priceInput.val().trim();
    
        let parsedPrice = parseFloat(price);
        if (!isNaN(parsedPrice)) {
          price = parsedPrice.toFixed(2);
          priceInput.val(price);
        } else {
          priceInput.val(''); 
        }
      });
    
      // If the form is invalid, show the validity message
      if (!isValid) {
        form.reportValidity();
      }
    
      return isValid; 
    }
  
    // Attach the form validation to the upload button click
    document.getElementById('upload_image').addEventListener('click', function (event) {
      if (!validateUploadForm()) {
        event.preventDefault();  // Stop the form submission if invalid fields are found
      }
    });  
  },
      
  async deleteImage(image_id) {
    const token = localStorage.getItem('token');
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E39356',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleting Image',
          text: 'Please wait while the image is being deleted...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        try {
          // Fix the double slashes in the URL
          const DELETE_IMAGES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/delete_image/${image_id}`;
  
          // Perform the DELETE request
          const response = await $.ajax({
            url: DELETE_IMAGES_URL,
            method: 'DELETE',
            headers: {
              'Authorization': `Token ${token}`
            }
          });
  
          // Since jQuery's $.ajax doesn't return `response.ok`, check the response directly
          if (response) {
            Swal.fire(
              'Deleted!',
              'The image has been deleted.',
              'success'
            ).then(() => {
              window.location.reload();  // Reload the page after deletion
            });
          } else {
            throw new Error('Deletion failed');
          }
  
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Deletion Failed',
            text: 'Failed to delete image. Please try again.',
          });
        }
      }
    });
  },
  
  openUpdateModal(image) {
    if (image && image.id !== undefined) {
      this.current_image = {
        id: image.id,
        image_url: image.product.image_url || '',
        description: image.product.description || '',
        price: image.price || '',
        no_of_items: image.product.no_of_items || 0,
        quantity: image.quantity || 0,
        product_name: image.product.product_name || '',
        category__category_name: image.product.category__category_name || '',
        department__Department: image.product.department__Department || '',
        sub_category__subcategory_name: image.product.sub_category__subcategory_name || '',
        code: image.product.code || '',
        size: image.size__size_name || '',
        color: image.color || '',
        product_id: image.product_id,
        category_id: image.product.category_id || null,
        department_id: image.product.department_id || null,
        sub_category_id: image.product.sub_category_id || null
      };
      // Set the selected department, category, and subcategories for dropdowns
      this.selectedDepartment = this.current_image.department_id;
      this.selectedCategory = this.current_image.category_id;
      this.updateCategories();
      this.updateSubcategories() 
      this.selectedSubcategories = this.current_image.sub_category_id;

      $('#updateModalImage').modal('show');
    } else {
      console.error('Invalid image object:', image);
    }
  },


  async updateImage() {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    const new_image_file = this.$refs.newImage.files[0];
    const departmentId = this.selectedDepartment;
    const categoryId = this.selectedCategory;
    const subcategoryId = this.selectedSubcategories;

    // Check if a new image is provided
    if (!new_image_file) {
        Swal.fire({
            icon: 'error',
            title: 'Image Required',
            text: 'Please select an image file to upload.'
        });
        return;
    }
    formData.append('image_urls', new_image_file);
    formData.append('product_name', this.current_image.product_name);
    formData.append('description', this.current_image.description);
    formData.append('no_of_items', this.current_image.no_of_items);
    formData.append('department', departmentId);
    formData.append('category', categoryId);
    formData.append('sub_category',subcategoryId);
    formData.append('product_id', this.current_image.product_id);

    // Ensure the product ID is included
    const productId = this.current_image.product_id;
    if (!productId) {
        Swal.fire({
            icon: 'error',
            title: 'Product ID Missing',
            text: 'The product ID is required to update the image.'
        });
        return;
    }
    formData.append('product_id', productId);

    // Append sizes, quantities, colors, and prices
    $('#update_size_table tr.size-color-row').each(function () {
        const size = $(this).find('.update-size-input input').val();
        const quantity = $(this).find('.update-size-quantity input').val();
        const color = $(this).find('.update-color-cell input').val();
        const color_quantity = $(this).find('.update-color-quantity-cell input').val();
        const price = $(this).find('.update-color-price input').val();

        // Append each field only if it exists
        if (size) formData.append('sizes[]', size);
        if (quantity) formData.append('quantities[]', quantity);
        if (color) formData.append('colors[]', color);
        if (color_quantity) formData.append('color_quantities[]', color_quantity);
        if (price) formData.append('prices[]', price);
    });

    try {
        const UPDATE_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/update_image/`;
        const response = await $.ajax({
          url: UPDATE_IMAGE_URL,
          method: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          headers: {
              'Authorization': `Token ${token}`
          }
      });
  
      if (response.products && response.products.length > 0) {
        Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: 'Products and variants updated successfully.',
            showConfirmButton: false,
            timer: 2000 
        }).then(() => {
          window.location.reload(); 
      });
        $('#updateModalImage').modal('hide');
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: response.error || 'An error occurred while updating the product.'
        });
    }
  
  } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred while updating the product.';
      Swal.fire({
          icon: 'error',
          title: 'Update Failed',
          text: errorMessage
      });
  }
},
async fetchDepartments() {
  const DEPARTMENT_CATEGORY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

  try {
    const response = await fetch(DEPARTMENT_CATEGORY_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    this.departments = data;
  } catch (error) {
    console.error('Error fetching departments:', error);
  }
},
updateCategories() {   
  const selectedDept = this.departments.find(dept => dept.id === this.selectedDepartment);
  if (selectedDept) {
    this.categories = selectedDept.categories || [];
    this.selectedCategory = this.current_image.category_id || null;
    if (!this.categories.some(cat => cat.id === this.selectedCategory)) {
      this.selectedCategory = null; // Reset if not in the list
      this.subcategories = [];
      this.selectedSubcategories = [];
    } else {
      this.updateSubcategories(); // Populate subcategories
    }
  }
},
updateSubcategories() {
  const selectedCat = this.categories.find(cat => cat.id === this.selectedCategory);
  this.subcategories = selectedCat ? selectedCat.subcategories : [];
  // Retain the previously selected subcategory
  if (this.selectedSubcategories && !this.subcategories.some(sub => sub.id === this.selectedSubcategories)) {
      this.selectedSubcategories = null;
  }
},
selectResult() {
  this.showDropdown = false;
},

  async addCategory() {
    const token = localStorage.getItem('token');
    const CREATE_CATEGORY_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/create_categories/`;

    try {
      const response = await $.ajax({
        url: CREATE_CATEGORY_URL,
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ category_name: this.newCategory })
      });

      this.categories.push(response);
      this.newCategory = '';

      Swal.fire({
        title: 'Success',
        text: 'Category added successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error adding category:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to add category.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  },
}
};

$(document).ready(function () {
  // Handle adding a new size row with only size and size quantity fields
  $(document).on('click', '#addMore', function () {
    // Get the last row
      var $last_row = $('#size_table tr.size-color-row').last();
      var $new_row = $last_row.clone().empty();

      // Clone only the size and quantity fields
      var size_input = $last_row.find('.size-input').clone();
      var size_quantity_input = $last_row.find('.size-quantity').clone();
 
      // Clear the input values in the new row
      size_input.find('input').val('');
      size_quantity_input.find('input').val('');
 
      // Append the new row with only size and quantity fields
      $new_row.append(size_input);
      $new_row.append(size_quantity_input);
 
      // Append the new row to the table
      $('#size_table').append($new_row);
  });
 
  // Update modal code remains the same
  $('#update_size_table').on('click', '.update-add-color-btn', function () {
    var $last_row = $('#update_size_table tr.size-color-row').last();
    var $new_row = $last_row.clone().empty(); 

    // Clone only the size and quantity fields
    var size_input = $last_row.find('.update-size-input').clone();
    var size_quantity_input = $last_row.find('.update-size-quantity').clone();

    // Clear the input values in the new row
    size_input.find('input').val(''); 
    size_quantity_input.find('input').val(''); 

    // Append the new row with only size and quantity fields
    $new_row.append(size_input);
    $new_row.append(size_quantity_input);
    $('#update_size_table').append($new_row);
  });
 
  $('#update_size_table').on('click', '.update-remove-color-btn', function () {
      var color_cell = $(this).closest('tr').find('.update-color-cell input').last();
      var color_quantity_cell = $(this).closest('tr').find('.update-color-quantity-cell input').last();
      var color_price = $(this).closest('tr').find('.update-color-price input').last();
 
      color_cell.remove();
      color_price.remove();
      color_quantity_cell.remove();
  });
});
 