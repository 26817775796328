<!DOCTYPE html>
<html lang="">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <link rel="icon" href="<%= BASE_URL %>favicon.ico">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap">
    <title>Drop Off </title>
    </head>
  <body>

<div class="home-navbar">
    <Influencer_sidebar />
</div>
<div class="app-content delivery-category">
    <Influencer_navbar />

    <div class="d-flex justify-content-between mb-3 ">
        <h5 class="fw-bolder text-black">Select Drop-off Point</h5>
        <div class="fw-bold text-black" v-if="selectedPoint">
        <p v-if="selectedPoint.store_name">Selected Point: {{ selectedPoint.store_name }}</p>

        </div>
    </div>
    <iframe id="pargo-iframe" :src="pargoIframeSrc" frameborder="0" width="100%" height="400" allow="geolocation"></iframe>

</div>

</body>
</html>