import $ from 'jquery'; // Ensure jQuery is imported if used
import Swal from 'sweetalert2'; // Import SweetAlert2
import { set_user_details, set_token } from '@/router/index.js'; // Import the new functions
import EventBus from '@/eventBus.js';

export default {
  name: 'LoginView',
  data() {
    return {
      form: { email: '', password: '' },
      errorMessage: '',
      passwordType: 'password',
    };
  },
  methods: {
    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },

    async onSubmit() {
      try {
        const LOGIN_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/login/`;
        const response = await $.ajax({
          url: LOGIN_URL,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(this.form),
          dataType: 'json',
        });

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;
        if (responseData.status === 'success') {
          set_token(responseData.token);

          const user_details = {
            role: { role: responseData.user.role__name }, // Role name
            first_name: responseData.user.first_name, // First name
            last_name: responseData.user.last_name, // Last name
            id: responseData.user.id, // User ID
            email: responseData.user.email // Email
          };
          // Set user details in localStorage
          set_user_details(user_details);
          EventBus.emit('userLoggedIn', user_details.id);
          const cart = this.getLocalStorage('cart');
          if (cart) {
            await this.saveCartToDatabase(cart);
            localStorage.removeItem('cart');
          }
          // Redirect based on user role
          if (responseData.user.role__name === 'BRAND' && responseData.first_login) {
            this.$router.push('/brand');
          } else {
            const intendedUrl = localStorage.getItem('intendedUrl');
            if (intendedUrl) {
              this.$router.push(intendedUrl);
              localStorage.removeItem('intendedUrl');
            } else {
              switch (responseData.user.role__name) {
                case 'ADMIN':
                  this.$router.push('/Admin_dashboard');
                  break;
                case 'INFLUENCER':
                  this.$router.push('/');
                  break;
                case 'BRAND':
                  this.$router.push('/dashboard');
                  break;
                case 'CUSTOMER':
                  this.$router.push('/');
                  break;
                default:
                  this.$router.push('/');
                  break;
              }
            }
          }
        } else {
          await Swal.fire({
            title: 'Error',
            text: responseData.message,
            icon: 'error',
            confirmButtonText: 'OK',
          });
          this.errorMessage = responseData.message;
        }
      } catch (error) {
        console.error('Error occurred:', error);

        let errorMessage = 'An error occurred during login.';
        // If the error response is in JSON format, extract the message
        if (error.responseJSON && error.responseJSON.message) {
          errorMessage = error.responseJSON.message;
        } else if (error.responseText) {
          // Fallback to responseText if JSON is not available
          errorMessage = 'Invalid credentials';
        }

        await Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK',
        });

        this.errorMessage = errorMessage;
      }
    },


    async saveCartToDatabase(cartItems) {
      const token = localStorage.getItem('token');
      if (!token) return;

      const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;

      try {
        for (const item of cartItems) {
          await $.ajax({
            url: ADD_TO_CART_URL,
            method: 'POST',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              product_id: item.product_id,
              quantity: item.quantity,
            }),
            dataType: 'json',
          });
        }
      } catch (error) {
        console.error('Error saving cart to the database:', error);
        Swal.fire({
          title: 'Error',
          text: 'There was an error saving your cart. Please try again later.',
          icon: 'error',
        });
      }
    },

    togglePassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
  },
}