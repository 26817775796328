<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    <router-link to="/" class="d-inline-block"> 
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>
  </div>
  <div class="close-btn text-black close-sidebar">
    <i class='bx bx-x'></i>
  </div>

  <ul class="nav-links">
    <li :class="{ active: $route.path === '/Admin_dashboard' }">
      <div class="iocn-link">
        <router-link to="/Admin_dashboard">
          <i class='bx bxs-dashboard fs-6'></i> 
          <span class="link-name">Dashboard</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/all_brands' }" class="d-none">
      <div class="iocn-link">
        <router-link to="/all_brands">
          <i class="bx bxs-user fs-6"></i>
          <span class="link-name">Brands</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/all_influencers' }">
      <div class="iocn-link">
        <router-link to="/all_influencers">
          <i class="bx bxs-user fs-6"></i>
          <span class="link-name">Influencers</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/view_orders' }">
      <div class="iocn-link">
        <router-link to="/view_orders">
          <i class="bx bxs-package fs-6"></i>
          <span class="link-name">Orders</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/admin_view_users' }">
      <div class="iocn-link">
        <router-link to="/admin_view_users">
          <i class='bx bxs-group fs-6'></i> 
          <span class="link-name">User Management</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/Transactions' }">
      <div class="iocn-link">
        <router-link to="/Transactions">
          <i class='bx bx-wallet'></i> 
          <span class="link-name">Transaction Portal</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/Commission' }">
      <div class="iocn-link">
        <router-link to="/Commission">
          <i class='bx bx-wallet'></i> 
          <span class="link-name">Commission Rate</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/Admin_Wallet' }">
      <div class="iocn-link">
        <router-link to="/Admin_Wallet">
          <i class='bx bx-wallet'></i> 
          <span class="link-name">Wallet</span>
        </router-link>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bxs-check-shield fs-6"></i>
          <span class="link-name">Analytics</span>
        </a>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#" @click="logout">
          <i class="bx bx-log-out fs-6"></i>
          <span class="link-name">Logout</span>
        </a>
      </div>
    </li>
  </ul>
</div>