/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue';
import 'datatables.net';

export default {
    name: 'Transactions',
    components: {
        Admin_sidebar,
        Admin_navbar,
    },
    data: () => ({
        pendingPayments: [],
        distributedPayments: []
       }),
    methods: {
        showCompletedPayments() {
            this.activeTab = 'completed';
            this.$nextTick(() => {
                this.initializeCompletedDataTable();
            });
        },
        showPendingPayments() {
            this.activeTab = 'pending';
        },
        async viewPendingPayments() {
            const token = localStorage.getItem('token');
            const PENDING_PAYMENTS_URL =`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/view_pending_payments_api/`
            try {
                const response = await fetch(PENDING_PAYMENTS_URL, {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch pending payments');
                }

                const data = await response.json();
                this.pendingPayments = data.pending_settlements;
                await this.$nextTick();
                this.initializeDataTable();
            } catch (error) {
                console.error('Error fetching pending payments:', error);
            }
        },
        async viewDistributedPayments() {
            const token = localStorage.getItem('token');
            const DISTRIBUTED_PAYMENTS_URL =`${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/view_distributed_payments_api/`;
            
            try {
                const response = await fetch(DISTRIBUTED_PAYMENTS_URL, {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
        
                if (!response.ok) {
                    throw new Error('Failed to fetch distributed payments');
                }
        
                const data = await response.json();
                this.distributedPayments = data.distributed_settlements;
                await this.$nextTick();
                this.initializeCompletedDataTable();
            } catch (error) {
                console.error('Error fetching distributed payments:', error);
            }
        },

        initializeDataTable() {
            this.$nextTick(() => {
                if ($.fn.DataTable.isDataTable('#content_table')) {
                    $('#content_table').DataTable().destroy();
                }
                $('#content_table').DataTable({
                    pageLength: 10,
                    lengthMenu: [10, 25, 50, 100],
                    searching: true,
                    ordering: true,
                    paging: true,
                    info: true,
                    autoWidth: false,
                });
            });
        },
        initializeCompletedDataTable() {
            this.$nextTick(() => {
                if ($.fn.DataTable.isDataTable('#content_table_completed')) {
                    $('#content_table_completed').DataTable().destroy();
                }
                $('#content_table_completed').DataTable({
                    pageLength: 10,
                    lengthChange: false,
                    searching: true,
                    ordering: true,
                    paging: true,
                    info: true,
                    autoWidth: false,
                });
            });
        },
    },
    mounted() {
        this.viewPendingPayments();
        this.viewDistributedPayments();
    },
};
