<div>
    <div class="home-navbar">
      <Influencer_sidebar />
    </div>

    <div class="app-content">
      <Influencer_navbar />

      <div class="container return-order">
        <h2 class="text-start text-black fw-bolder mt-4">Return my order(s)</h2>

        <!-- Step Tracker -->
        <div class="step-tracker justify-content-between d-flex position-relative mt-2 mb-2">
          <div :class="['step-track flex-column d-flex align-items-center', { active: currentStep === 1 }]">
            <i class="fas fa-info-circle text-black"></i>
            <span class="text-black">Reason for Return</span>
          </div>
          <div :class="['step-track flex-column d-flex align-items-center', { active: currentStep === 2 }]">
            <i class="fas fa-truck text-black"></i>
            <span class="text-black">Pickup Method</span>
          </div>
         
          <div :class="['step-track flex-column d-flex align-items-center', { active: currentStep === 3 }]">
            <i class="fas fa-clipboard-check text-black"></i>
            <span class="text-black">Review & Submit</span>
          </div>
        </div>

        <!-- Step 1: Reason for Return -->
        <div v-if="currentStep === 1">
          <p class="text-start text-black fw-bold">{{ order_number }}</p>
          <div class="cart-cont d-flex" v-if="selectedItem && selectedItem.product_details">
            <img
              :src="selectedItem.product_details.image_url || 'placeholder.jpg'"
              alt="Product Image"
              width="60"
              height="60"
            />
            <div class="cart-item-details d-flex">
              <h6 class="fw-bold text-black">{{ selectedItem.product_details.product_name }}</h6>
              <p class="mb-0">Size: {{ selectedItem.product_details.size }}</p>
              <p class="mb-0">Quantity Ordered: {{ selectedItem.quantity_ordered }}</p>
              <p class="mb-0">Unit Price: R {{ selectedItem.unit_price }}</p>
            </div>
          </div>

          <div class="mt-4">
            <div class="return-reason text-start text-black">
                <h5 class="mb-4">
                  To help us process your request quickly, please answer the following questions.
                </h5>
                <p>What is the primary reason for returning the product?</p>
                <label class="d-block">
                  <input type="checkbox" :checked="reason === 'The product quality is unsatisfactory.'" @click="selectReason('The product quality is unsatisfactory.')" />
                  The product quality is unsatisfactory.
                </label>
                <label class="d-block">
                  <input type="checkbox" :checked="reason === 'I changed my mind.'" @click="selectReason('I changed my mind.')" />
                  I changed my mind.
                </label>
                <label class="d-block">
                  <input type="checkbox" :checked="reason === 'Other'" @click="selectReason('Other')" />
                  Other
                </label>
                <textarea v-if="reason === 'Other'" v-model="reasonDescription" placeholder="Please provide more details"></textarea>
                <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
              </div>
          </div>
        </div>

        <!-- Step 2: Pickup Method -->
        <div v-if="currentStep === 2">
          <div class="col-md-8 mt-3">
            <div class="d-flex mb-4">
              <h3 class="fw-bolder text-black">Select pick-up point</h3>
            </div>
          </div>

            <div>
              <iframe
                id="pargo-iframe"
                :src="pargoIframeSrc"
                frameborder="0"
                width="100%"
                height="350"
                allow="geolocation"
                v-show="!isMobile || !selectedPoint"
              ></iframe>

             
            </div>
            <div class="">

                <div v-if="isMobile">
                    <!-- Mobile layout -->
                    <div class="d-flex flex-column align-items-start">
                      <div v-if="selectedPoint" class="fw-bold mb-3">
                        <p class="text-start fw-bolder text-black fs-4">
                          Selected Point : {{ selectedPoint.storeName }}
                        </p>
                      </div>
                      <button class="action-btn" @click="changePoint">Change Point</button>
                      <div class="fw-bolder mt-3">
                        <span class="text-black me-2">Delivery Fee</span>
                        <span class="text-black">R {{ deliveryFee }}</span>
                        <span class="separator-line mb-4"></span>
                      </div>
                    </div>
                    <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
                  </div>
                  
                  <div v-else>
                <div v-if="selectedPoint" class="d-flex justify-content-between align-items-end selected-point-details">
                  <div class="fw-bold">
                    <p class="text-start fw-bolder text-black fs-5">
                      Selected Point : {{ selectedPoint.storeName }}
                    </p>
                  </div>
                  <div class="fw-bolder">
                    <span class="text-black me-2">Delivery Fee</span>
                    <span class="text-black">R {{ deliveryFee }}</span>
                    <span class="separator-line mb-4"></span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column align-items-end">
                  <div class="fw-bolder">
                    <span class="text-black me-2">Delivery Fee</span>
                    <span class="text-black">R {{ deliveryFee }}</span>
                    <span class="separator-line mb-4"></span>
                  </div>
                </div>
                <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
              </div>
              </div>
        </div>

        <div v-if="currentStep === 3">
            <p class="text-start text-black fw-bold">Review your return request</p>
            <div class="cart-cont d-flex" v-if="selectedItem && selectedItem.product_details">
                <img
                :src="selectedItem.product_details.image_url || 'placeholder.jpg'"
                alt="Product Image"
                width="60"
                height="60"
                />
                <div class="cart-item-details d-flex">
                <h6 class="fw-bold text-black">{{ selectedItem.product_details.product_name }}</h6>
                <p class="mb-0">Size: {{ selectedItem.product_details.size }}</p>
                <p class="mb-0">Quantity Ordered: {{ selectedItem.quantity_ordered }}</p>
                <p class="mb-0">Unit Price: R {{ selectedItem.unit_price }}</p>
                </div>
            </div>

            <p class="text-black text-start fw-bold">Main reason for returning the product : {{reason}}</p>
            <p class="text-black text-start fw-bold">Drop off to returning the product : {{selectedPoint.storeName}}</p>

        </div>

        <!-- Navigation Buttons -->
    <!-- Navigation Buttons -->
<div class="buttons d-flex justify-content-between">
    <button class="btn btn-outline-secondary text-black" @click="previousStep" v-if="currentStep > 1">
      Previous
    </button>
    <div class="ml-auto"></div>
    <button class="btn btn-dark" @click="nextStep" v-if="currentStep < 3">
      Next
    </button>
    <button class="btn btn-dark" @click="nextStep" v-else>
      Pay
    </button>
  </div>
      </div>
    </div>
  </div>