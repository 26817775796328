import Sidebar from '@/components/Brand/Sidebar/SideView.vue';
import Navbar from '@/components/Brand/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_user_details,get_token } from '@/router/index.js';


export default {
  name: 'DropOffView',
  components: {
    Sidebar,
    Navbar,
  },

  data: () => ({ selectedPoint: null, savedDropOffPoint: null,token_exists: null, user_details: null, }),


  methods: {

    saveDropoffPoint() {
           
            const SAVE_DROP_OFF_POINT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/save_drop_off_point/`;
            const token = localStorage.getItem('token');
    
            const dropOffPointData = {
              pargo_point_code: this.selectedPoint.pargoPointCode,
              store_name: this.selectedPoint.storeName,
              address1: this.selectedPoint.address1,
              address2: this.selectedPoint.address2,
              city: this.selectedPoint.city,
              province: this.selectedPoint.province,
              postal_code: this.selectedPoint.postalcode,
              address_sms: this.selectedPoint.addressSms,
            };
        
            fetch(SAVE_DROP_OFF_POINT_URL, {
              method: 'POST',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dropOffPointData)
            })
            .then(response => response.json())
            
            .then(() => {
              Swal.fire({
                icon: 'success',
                text: 'Drop-off point saved successfully.',
                title: 'Success',
                confirmButtonText: 'OK'
              }).then(() => {
                window.location.reload();
              });
            })
            .catch(error => {
              console.error('Error saving drop-off point:', error);
              Swal.fire({
                icon: 'error',
                text: 'Failed to save drop-off point.',
                title: 'Error',
                confirmButtonText: 'OK'
              });
            });
          },

          handleIframeMessage(event) {
            if (event.data && event.data.addressSms) {
              this.selectedPoint = event.data;
              this.saveDropoffPoint();
            }
          },

    fetchSavedDropOffPoint(userId) {
  
      const GET_DROP_OFF_POINT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/seller_drop_off_point/${userId}/`;
      const token = localStorage.getItem('token');

      try {
      
          fetch(GET_DROP_OFF_POINT_URL, {
          method: 'GET',
          headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
          }
          })
          .then(response => response.json())
          
          .then(data => {
          this.selectedPoint = data;
          })
          .catch(error => {
          console.error(error);
          });
      } catch (error) {
          console.error(error);

      }
    },


  },


  mounted(){


    this.token_exists = get_token();
    this.user_details = get_user_details();

    if (!this.token_exists || (this.user_details.user_role !== 'BRAND' && this.user_details.user_role !== 'ADMIN')) {
      localStorage.removeItem('token'); // Clear the token
      this.$router.push('/login'); // Redirect to home page
    } else {
      this.fetchSavedDropOffPoint(this.user_details.user_id);
      window.addEventListener('message', (event) => {
          this.handleIframeMessage(event);
        });

    }
  }


}