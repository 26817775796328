<div class="app h-100-vh">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <img 
          :src="require('@/assets/images/new_logo_organge_black.png')" 
          alt="Kyosk Logo" 
          class="kyosk-logo mb-4"
        >

        <form @submit.prevent="handleForgotPassword" class="login-form">
          <p>Enter the email associated with your account and we will send you password reset instructions</p>

          <div class="form-group mb-3">
            <!-- Bind the input to email data property -->
            <input 
              type="email" 
              v-model="email" 
              placeholder="Email address" 
              id="email" 
              class="form-control"
              required
            >
          </div>

          <button type="submit" class="register-button" login-button>Forgot Password</button>

        </form>
      </div>
    </div>
  </div>
</div>
