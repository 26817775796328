<div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="items-found">{{ searchedBrands.length }} items found</div>
        <div class="brands-grid">
          <div 
            v-for="(brand, index) in searchedBrands" 
            :key="index" 
            class="brand-card justify-content-center align-items-center d-flex"
          >
            <router-link :to="{ name: 'view-brands-products', params: { brandId: brand.id } }" class="product-link"
            @click="$emit('select-brand', brand.id)">
              <div class="img-logo-container">
                <img 
                  v-if="brand.logo" 
                  :src="brand.logo" 
                  :alt="brand.brand_name"
                  class="img-logo bg-transparent"
                  @load="handleImageLoad"
                  @error="handleImageError"
                />
                <img 
                v-else 
                src="@/assets/images/img-error.png" 
                :alt="brand.brand_name"
                class="img-logo bg-transparent"
              />
              </div>
              <span class="brand-name text-center">
                {{ brand.brand_name }}
              </span>
            </router-link>
             </div>
          </div>
        </div>
      </div>
    </div>
