import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Search_Influencer from '@/views/Influencer_Management/SearchInfluencer/SearchInfluencerView.vue'

export default {
  components: {
    Home_navbar,
    Influencer_navbar,
    Search_Influencer
  },

  data: () => ({ items: [], UserLoggedIn: false, loggedInUserId: localStorage.getItem('user_id'),  search_query: "" }),

  mounted() {
    this.fetchInfluencers();
    this.checkLoginStatus();
  },

  methods: {
    checkLoginStatus() {
      this.UserLoggedIn = !!localStorage.getItem('token');
    },

    fetchInfluencers() {
      const FETCH_INFLUENCER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
    
      fetch(FETCH_INFLUENCER_URL, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => response.json())
      .then(data => {
        this.items = data.filter(influencer => 
          influencer.id !== parseInt(this.loggedInUserId, 10) && influencer.videos.length > 0
        ).map(influencer => ({
          ...influencer,
          videos: [influencer.videos[0]] // Only include the first video
        }));
      })
      .catch(error => console.error('Error fetching influencers:', error));
    },

    playVideo(event) {
      const videoElement = event.target;

      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }

      videoElement.playTimeout = setTimeout(() => {
        videoElement.play().catch((error) => {
          console.error("Autoplay error:", error);
        });
      }, 500);
    },

    pauseVideo(event) {
      const videoElement = event.target;

      if (videoElement.playTimeout) {
        clearTimeout(videoElement.playTimeout);
      }

      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }
}