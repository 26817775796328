<div class="sidebar">
  <div class="logo-details mx-3 mt-4 mb-3">
    <router-link to="/" class="d-inline-block"> 
      <img :src="require('@/assets/images/logo-text.svg')" class="img-fluid ms-1" width="150" alt="profileImg">
    </router-link>
  </div>
  <div class="close-btn text-black close-sidebar">
    <i class='bx bx-x'></i>
  </div>

  <ul class="nav-links">
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Profile</span>
    </a> 
    <li :class="{ active: $route.path === '/Influencer_profile' }">
      <div class="iocn-link">
        <router-link to="/Influencer_profile">
          <i class="bx bxs-user fs-6"></i>
          <span class="link-name">Profile details</span>
        </router-link>
      </div>
    </li>
    <li :class="{ active: $route.path === '/addresses' }">
      <div class="iocn-link d-none">
        <router-link to="/addresses">
          <i class="bx bxs-edit-location fs-6"></i>
          <span class="link-name">Delivery Address</span>
        </router-link>
      </div>
    </li>
    <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bxs-check-shield fs-6"></i>
          <span class="link-name">Security</span>
        </a>
      </div>
    </li>
    <li :class="{ active: $route.path === '/drop_off_point' }">
      <div class="iocn-link">
        <router-link to="/drop_off_point">
          <i class='bx bx-map-pin'></i>
          <span class="link-name">Drop Off Point</span>
        </router-link>
      </div>
    </li>
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">My Orders</span>
    </a> 
    <li :class="{ active: $route.path === '/influencer_buyer_orders' }">
      <div class="iocn-link">
        <router-link to="/influencer_buyer_orders">
          <i class="bx bxs-package fs-6"></i>
          <span class="link-name">Orders</span>
        </router-link>
      </div>
    </li>
    <li>
      <div class="iocn-link d-none">
        <a href="#">
          <i class="bx bx-undo fs-5"></i>
          <span class="link-name">Return</span>
        </a>
      </div>
    </li>
    <li :class="{ active: $route.path === '/wishlist' }">
      <div class="iocn-link">
        <router-link to="/wishlist">
          <i class="bx bxs-cart-alt fs-6"></i>
          <span class="link-name">Wishlist</span>
        </router-link>
      </div>
    </li>

    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">My Sales</span>
    </a> 

    <li :class="{ active: $route.path === '/influencer_Order' }">
      <div class="iocn-link">
        <router-link to="/influencer_Order">
          <i class="bx bxs-package fs-6"></i>
          <span class="link-name">Customer Orders</span>
        </router-link>
      </div>
    </li>
    <li>
    <div class="iocn-link">
      <a href="#">
        <i class="bx bx-undo fs-5"></i>
        <span class="link-name">Customer Returns</span>
      </a>
      </div>
    </li>

    <li :class="{ active: $route.path === '/influencer_inventory' }">
      <div class="iocn-link">
        <router-link to="/influencer_inventory">
          <i class="bx bxs-layer fs-6"></i>
          <span class="link-name">Inventory</span>
        </router-link>
      </div>
    </li>
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities">
        <span class="heading">Payment</span>
    </a> 
    <li :class="{ active: $route.path === '/add_banking_details' }">
      <div class="iocn-link">
        <router-link to="/add_banking_details">
          <i class="bx bxs-credit-card"></i>
          <span class="link-name">Banking Details</span>
        </router-link>
      </div>
    </li>
      <li>
      <div class="iocn-link">
        <a href="#">
          <i class="bx bx-undo fs-6"></i>
          <span class="link-name">Refunds</span>
        </a>
      </div>
    </li>
    <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapse_utilities"
        aria-expanded="true" aria-controls="collapse_utilities" href="#" @click="logout">
        <span class="heading">Logout</span>
        <i class="bx bx-log-in ms-2"></i>
    </a> 
  </ul> 
</div>