<div class="home-navbar ">
  <Admin_sidebar />
</div>

<div class="app-content">
  <Admin_navbar />
  <div class="content">
    <div class="upload-search-row">
    </div>
    <div class="card table-card">
      <div class="card-body">
        <table id="brands_table" class="table align-item table-flush-item content-table">
          <thead class="thead-light">
            <tr>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Logo</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Brand Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Owner Email</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">First Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Last Name</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Created At</th>
              <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>
            </tr>
          </thead>
          
          <tbody>
            <template v-for="(brand, index) in items" :key="brand.id">
              <tr>
                <td class="text-start">
                  <img     :src="brand.logo || require('@/assets/images/img-error.png')" alt="Brand Logo" width="50" height="50">
                </td> 
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ brand.brand_name }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ brand.user.email }}</span>
                </td>
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ brand.user.first_name }}</span>
                </td>

                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0"> {{ brand.user.last_name }}</span>
                </td>
                
                <td class="text-start">
                  <span class="text-xxs font-weight-light mb-0">{{ brand.user.date_joined }}</span>
                </td>
                <td class="align-item">
                  <button 
                    class="btn-sm action-btn font-weight-light text-xxs"
                    @click="loginAsBrand(brand.id)"
                  >
                    View
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>