<div class="home-navbar">
    <nav class="first-navbar">
        <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class="logo" @click="goToHomePage" style="cursor: pointer;">
        <div class="search-bar search-bar-m">
            <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
            <div class="search-dropdown" id="search-dropdown">
                <a href="#">Popular search 1</a>
                <a href="#">Popular search 2</a>
                <a href="#">Popular search 3</a>
                <a href="#">Popular search 4</a>
                <a href="#">Popular search 5</a>
            </div>
        </div>
        <div class="nav-icons">
            <div v-if="UserLoggedIn">
                <a href="#" data-toggle="modal" data-target="#uploadModal" class="text-black">
                    <i class="fa-solid fa-square-plus nav-icon text-black me-2 fs-5"></i>
                </a>
            </div>
            <div class="icon-container" @click="toggleRightSidebar" ref="iconContainer">
                <a href="#">
                    <i class='bx bxs-shopping-bag' id="cart-icon"></i>
                    <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
                </a>
            </div>
            <div v-if="UserLoggedIn">
              <div class="icon-container" data-dropdown>
                <div class="profile">
                  <img v-if="profileImage" :src="profileImage" alt="Profile Picture" class="navbar-profile-picture">
                  <div v-else class="initials text-black text-xs">{{ getInitials(form.first_name, form.last_name) }}</div>
                </div>
                <div class="dropdown-content">
                        <router-link to="/Influencer_profile">
                            <span class="text-dark">Profile</span>
                        </router-link>
                        <a href="#" @click="logout">
                            <span class="text-dark">Logout</span>
                        </a>
                      </div>
                    </div>
                </div>
            <div v-else>
                <div class="icon-container" data-dropdown>
                    <a href="#"><i class='bx bx-user'></i></a>
                    <div class="dropdown-content">
                        <router-link to="/register">
                            <span class="text-dark">Sign Up</span>
                        </router-link>
                        <router-link to="/login">
                            <span class="text-dark">Login</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

  
  <!-- Upload Modal -->
  <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn text-black d-none" id="backButtonHeader" @click="handleBackClick">
            <i class="fas fa-arrow-left"></i>
          </button>
          <!-- Title (Visible in both upload and preview modes) -->
          <h5 class="modal-title fw-bolder" id="uploadModalLabel">Video Upload</h5>
          <button type="button" class="action-btn fw-bold" id="nextButton" @click="handleNextClick">
            {{ currentStep === 'productUpload' ? 'Upload' : 'Next' }}
          </button>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Upload Section -->
          <div id="uploadSection">
            <div class="text-center">
              <!-- Label acts as the file input trigger -->
              <label for="videoUpload" class="video-upload-label" id="uploadLabel">
                <i class="fas fa-cloud-arrow-up fs-1 mb-4"></i>
                <span>Drag files to upload</span>
              </label>
              <!-- Hidden file input -->
              <input type="file" id="videoUpload" accept="video/*" class="d-none" @change="previewVideo">
            </div>
          </div>
          
  
          <!-- Preview Section -->
          <div id="previewPage" class="d-none">
  
            <div class="upload-box-video">
            <video id="videoPreview" controls autoplay></video>
          </div>
          </div>
  
          <!-- Cover Photo Selection Section -->
          <div id="coverPhotoSection" class="d-none">
            <div class="row">
              <!-- Left Column: Image Preview -->
              <div class="col-md-6">
                <div class="image-preview-container">
                  <img id="autoPreview" src="" alt="Automatically Selected Preview">
                </div>
              </div>
  
              <!-- Right Column: Cover Photo Selection -->
              <div class="col-md-6">
                <div class="form-group mb-4 mt-4">
                  <label for="caption" class="text-label fw-bold">Caption:</label>
  
                  <input type="text" v-model="caption" id="caption" class="form-control form-control-sm">
                  <small v-if="showCaptionError" class="text-danger">Please provide a caption.</small>
                </div>
                <div class="mb-4">
                  <div class="justify-content-between d-flex">
                    <h6 class="fw-bold">Select Cover Photo</h6>
                    <h6 class="fw-bold device-cover" @click="triggerFileInput">
                      Choose from Device
                    </h6>
                  </div>
                  <!-- Thumbnail Strip -->
                  <div class="thumbnail-strip">
                    <div class="thumbnails" id="thumbnailsContainer"></div>
                  </div>
                  <!-- Slider -->
                  <input
                    type="range"
                    id="frameSlider"
                    class="form-range text-black"
                    min="0"
                    max="100"
                    step="1"
                    @input="updatePreviewFromSlider($event.target.value)"
                    >
                  <input
                    type="file"
                    id="coverPhotoUpload"
                    accept="image/*"
                    class="file-input"
                    style="display: none;"
                    @change="previewCoverPhoto"
                  >
                </div>
              </div>
            </div>
          </div>
  
          <div id="productUploadSection" class="d-none">
            <div class="row">
  
                <div class="col-md-6">
                  <div class="image-preview-container mt-2">
                    <img id="coverPhotoPreview" :src="selectedCoverPhoto" alt="Selected Cover Photo">
                  </div>
                </div>
  
                <div class="col-md-6">
                  <ul class="nav nav-tabs mt-2 mb-4" id="my_tab" role="tablist">
                    <li class="nav-item-upload" role="presentation">
                      <button class="nav-link active" id="upload-tab" data-bs-toggle="tab" data-bs-target="#upload" type="button" role="tab" aria-controls="upload" aria-selected="false" @click="switchTab('upload')">Upload Products</button>
                    </li>
                    <li class="nav-item-upload" role="presentation">
                      <button class="nav-link" id="tag-tab" data-bs-toggle="tab" data-bs-target="#tag" type="button" role="tab" aria-controls="tag" aria-selected="true" @click="switchTab('tag')">Tag Products</button>
                    </li>
                  </ul>
                
                <div class="tab-content" id="my_tab_content">
  
                <div class="tab-pane fade show active" id="upload" role="tabpanel" aria-labelledby="upload-tab">
                    <!-- Upload Products tab content -->
    
                   
                  <div class="modal-body">
                    <!-- Add the form tag here -->
                    <form id="uploadForm" >
                        <div class="upload-group">
                          <!-- <div class="form-section"> -->
                            <div class="row">
                              <!-- Image Upload Section -->
                              <div class="upload-image-container">
                                <div class="upload-box-image mb-3">
                                  <label for="image_url">
                                    <!-- Placeholder text when no image is uploaded -->
                                    <p v-if="!imageUploaded" id="uploadText"></p>
                                    <i class="fas fa-upload" fs-1 aria-hidden="true"></i> <br>Upload Image
                                  </label>
                                </div>
                              </div>
                              <input type="file" id="image_url" accept="image/*" class="d-none" @change="previewImage" multiple>
                              <!-- Form Fields Section -->
                                <div class="form-group">
                                  <input type="text" id="product_name" name="productName" class="form-control form-control-sm" placeholder="Product name" required>
                                </div>
            
                                <div class="form-group mt-2">
                                  <textarea id="description" name="description" class="form-control form-control-sm" placeholder="Add description/condition" required></textarea>
                                </div>
  
                                <div class="form-group mt-2">
                                  <input type="text" class="form-control form-control-sm price" placeholder="Price " id="price" name="color_price" required>
                                </div>
  
                                <div class="form-group mt-2">
                                  <input type="text" class="form-control form-control-sm size" placeholder="Size " id="size" name="size" required>
                                </div>
  
                                <div v-if="uploadedProducts.length > 0">                              
                                  <div class="form-group mt-2">
  u
  
                                  <label for="products" class="text-label fw-bold">Uploaded Products:</label>
                                  <ul class="list-unstyled">
                                    <li v-for="(product, index) in uploadedProducts" :key="index" class="d-flex align-items-left">
                                      <img :src="product.imagePreviewUrl" alt="Image" class="product-image-thumbnail mr-2">
                                      <span>{{ product.product_name }}</span>
                                      <span @click="removeUploadedProduct(product)" class="ml-2 text-danger">&#x2716;</span>
                                    </li>
                                  </ul>
                                </div>
                                </div>
                                <div class="d-flex justify-content-end mt-4">
                                  <button type="button" class="action-btn" @click="uploadImage">Save</button>
                                </div>
  
                                
                            </div>
                          </div>
                    </form>
                  </div>
              </div>
  
                <div class="tab-pane fade " id="tag" role="tabpanel" aria-labelledby="tag-tab">
                  <!-- Tag Products tab content -->
          
                  <div class="form-group position-relative">
  
                    <input type="text" v-model="tagInput" id="tagInput" class="form-control mb-4" placeholder="Type @ to tag brands" @keyup="filterBrands" @blur="validateBrand">
                    <!-- Dropdown for brands -->
                    <ul v-if="showBrandDropdown" class="dropdown-menu show">
                      <li v-for="brand in filteredBrands" :key="brand.id" @click="selectBrand(brand)" class="dropdown-item">
                        {{ brand.brand_name }}
                      </li>
                    </ul>
                    <small v-if="brandError" class="text-danger">Please select a valid brand from the list.</small>
                  </div>
                  <div class="form-group position-relative">
                    <input type="text" v-model="searchQuery" id="products" class="form-control" placeholder="Type product name" @keyup="filterTaggedProducts">
                    <!-- Dropdown for products -->
                    <ul v-if="showProductDropdown" class="dropdown-menu show">
                      <li v-for="product in filtered_tagged_products" :key="product.product_id" @click="selectProduct(product)" class="dropdown-item d-flex align-items-center">
                        <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                        <span>{{ product.product.product_name }}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- Display selected products -->
  
                  <div v-if="selectedProducts.length > 0">
                    <div class="form-group mt-2">
                    <label for="products" class="text-label fw-bold">Selected Products:</label>
                    <ul class="list-unstyled">
  
                      <li v-for="product in selectedProducts" :key="product.id" class="d-flex text-start">
                        <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                        <span>{{ product.product.product_name }}</span>
                          <span @click="removeProduct(product)" class="ml-2 text-danger">&#x2716;</span>
                      </li>
                    </ul>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
  </div>


  <div id="right-sidebar" :class="{'open': cartQuantity > 0 && isRightSidebarOpen}">
    <!-- Close Button -->
    <div class="view-cart-header d-flex justify-content-between align-items-center fixed-top">
      <h4 class="text-start fw-bold text-black ml-3">Cart</h4>
      <div class="close-btn-cart text-black" @click="isRightSidebarOpen = false">
        <i class='bx bx-x'></i>
      </div>
    </div>
    <!-- Sidebar Content -->
    <div class="sidebar-content">
      <div v-for="item in cartItems" :key="item.id" class="cart-item">
        <img :src="item.image" alt="Product image" class="cart-item-image" />
        <div class="cart-item-details d-flex text-black">
          <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
         
          <p class="product-brand mb-1"> {{ item.brand }}</p>
          <p class="mb-0">size: {{ item.size }}</p>
          <p class="mb-2" v-if="item.color">color: {{ item.color }}</p>
          <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>
   
          <div class="d-flex align-items-center">
            <span class="me-2">Qty:</span>
            <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
              <i class="fa-solid fa-trash"></i>
            </button>
            <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
            <span class="quantity mx-2">{{ item.quantity }}</span>
            <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
        </div>
        </div>
      </div>
    </div>
    <div class="view-cart-btn">
      <a href="/shipping_address" class="add-to-cart text-white">Checkout <span class="mx-3">-</span> R{{ cartTotal }}</a>
    </div>
  </div>