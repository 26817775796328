/* global $ */

import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import 'datatables.net';
import { mapGetters } from 'vuex';


export default {
    name: 'influencer_Order', 
    components: {
      Influencer_sidebar,
      Influencer_navbar,
    },
    data: () => ({
      orders: [],  // Make sure your orders are populated
      selectedOrder: null,
      showOrderDetails: false,
      error: null,
      loading: false,
    }),
  
    methods: {
      initializeDataTable() {
        this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#content_table')) {
            $('#content_table').DataTable().destroy();
          }
          $('#content_table').DataTable({
            pageLength: 10,
            lengthMenu: [10, 25, 50, 100],
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            autoWidth: false,
          });
        });
      },
        formatDate(date) {
          return new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          }).format(new Date(date));
        },
        formatEmail(email) {
          return email.toLowerCase();
        },
  
        async getInfluencerOrders() {
          this.loading = true;
          const token = localStorage.getItem('token');
          const isImpersonating = this.isImpersonating; // Get from Vuex getters
          const impersonatedUserId = this.impersonatedUserId; // Get from Vuex getters
          let INFLUENCER_ORDERS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/view_influencer_orders_api/`;
        
          if (isImpersonating && impersonatedUserId) {
            INFLUENCER_ORDERS_API += `?impersonate=true&user_id=${impersonatedUserId}`;
          }
        
          try {
            const response = await fetch(INFLUENCER_ORDERS_API, {
              method: 'GET',
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
              },
            });


          if (!response.ok) throw new Error('Failed to fetch Orders');
          const data = await response.json();
          this.orders = data.data; // Assign the fetched data to orders

          this.$nextTick(() => {
            this.initializeDataTable();
          });
        } catch (error) {
          console.error('Error fetching Orders:', error);
        }finally {
          this.loading = false; // Hide the loader
        }
      },
      async fetchSingleOrder(orderId) {

        const token = localStorage.getItem('token');
        const API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/view_customer_order_details_api/${orderId}/`;
      
        try {
          const response = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
      
          if (!response.ok) throw new Error('Failed to fetch order details');
          const data = await response.json();
          this.selectedOrder = data.data; // Set the selected order data
          this.showOrderDetails = true; // Show the modal
      
        } catch (error) {
          console.error('Error fetching order:', error);
          this.error = error.message || 'Failed to load order details.';
        }
      },
      viewOrderDetails(orderId) {
        this.fetchSingleOrder(orderId).then(() => {
          this.showOrderDetails = true;
        });
      }
,      
    
      closeOrderDetails() {
        this.showOrderDetails = false;
      },
    },
    
    created() {
      this.getInfluencerOrders();
    },
    
    mounted() {
      this.getInfluencerOrders();
    
    },
    
    computed: {
      ...mapGetters('auth', ['isImpersonating', 'impersonatedUserId']),
    }
  }