<div class="home-navbar">
  <Influencer_sidebar />
</div>
<div class="app-content delivery-category">
  <Influencer_navbar />

  <div class="card table-card">
    <div class="card-body">
      <div v-if="loading" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <table id="content_table" class="table align-item table-flush-item content-table " data-sort="true">
        <thead class="thead-light">
          <tr>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Item</th>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Name</th>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Code</th>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Price</th>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Quantity</th>
            <th class="text-uppercase text-xxs fw-bold opacity-9">Action</th>

          </tr>
        </thead>
        <tbody v-if="product_list.length > 0">
          <tr v-for="item in product_list" :key="item.id">
            <td class="text-start">
              <img :src="item.image_url" alt="Product Image" width="40" height="40">
            </td>
            <td class="align-item text-start">
              <div>
                <small class="text-xxs font-weight-light mb-0">{{ item.product_name }}</small>
              </div>
            </td>
            <td class="text-start">
              <span class="text-xxs font-weight-light mb-0">{{ item.code }}</span>
            </td>
            <td class="text-start">
              <span class="text-xxs font-weight-light mb-0">{{ item.price || item.product_details[0]?.price}}</span>
            </td>
            <td class="text-start">
              <span class="text-xxs font-weight-light mb-0">{{ item.total_quantity }}</span>
            </td>
            <td class="text-start">
              <button class="btn-sm action-btn font-weight-light text-xxs manage-btn">View
              </button>
              <button class="btn-sm action-btn font-weight-light text-xxs manage-btn" @click="openEditModal(item)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="modal fade" id="updateModalImage" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">Update Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="selectedProduct && selectedProduct.product_details">
          <div class="form-section">
            <input type="hidden" id="update_video_image_id">
            <div class="row">
              <div class="col-md-6" v-if="selectedProduct">
                <div class="image-container">
                  <img :src="selectedProduct.image_url" alt="Current Image" class="img-preview">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="productName" class="text-label">Product Name:</label>
                  <input type="text" v-model="selectedProduct.product_name" id="product_name" class="form-control form-control-sm" required>
                </div>

                <div class="form-group mt-2">
                  <label for="update_description" class="text-label">Description:</label>
                  <input type="text" v-model="selectedProduct.description" id="update_description" class="form-control form-control-sm" required>
                </div>

                <div class="form-group mt-2">
                  <label for="update_stock" class="text-label">Price:</label>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R</span>
                    </div>
                    <input type="text" v-model="selectedProduct.product_details[0].price" id="price" class="form-control form-control-sm">
                  </div>
                </div>

                <div class="form-group mt-2">
                  <label for="color" class="text-label">Color:</label>
                  <input type="text" v-model="selectedProduct.product_details[0].color" id="color" class="form-control form-control-sm">
                </div>

                <div class="form-group mt-2">
                  <label for="department" class="text-label">Department:</label>
                  <select id="department" class="form-control form-control-sm" v-model="selectedDepartment" @change="updateCategories">
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                      {{ department.department_name }}
                    </option>
                  </select>

                  <input v-if="selectedDepartment === 'Other'" type="text" id="department_input" class="form-control form-control-sm mt-2"
                         v-model="newDepartment" @keyup.enter="addDepartment" placeholder="Type a new department">
                </div>

                <div class="form-group mt-2">
                  <label for="categories" class="text-label">Category:</label>
                  <select id="categories" class="form-control form-control-sm" v-model="selectedCategory" @change="updateSubcategories">
                    <option v-for="category in categories" :key="category.id" :value="category.id">
                      {{ category.category_name }}
                    </option>
                  </select>

                  <input v-if="selectedCategory === 'Other'" type="text" id="category-input" class="form-control form-control-sm mt-2"
                         v-model="newCategory" @keyup.enter="addCategory" placeholder="Type a new category">
                </div>

                <div class="form-group mt-2">
                  <label for="subcategories" class="text-label">Sub-Category:</label>
                  <select id="sub_category" class="form-control form-control-sm" v-model="selectedSubcategories">
                    <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                      {{ subcategory.subcategory_name }}
                    </option>
                  </select>
                </div>

                <!-- Size and Color Options Section -->
                <div class="form-group mt-2">
                  <div class="table-container" style="overflow-x: auto;">
                    <!-- Flexbox to align the plus icon with the table header -->
                    <table class="table table-sm table-bordered text-center" style="min-width: 100%;">
                      <thead>
                        <tr>
                          <th>Size</th>
                          <th>Size Quantity</th>
                        </tr>
                        <div class="d-flex align-items-center mb-2">
                          <i id="addMore" class="add-size-btn fa-solid fa-plus fs-5 me-2" @click="addSizeRow"></i>
                        </div>
                      </thead>
                      <tbody id="size_table">
                        <tr class="size-color-row" v-for="(detail, index) in selectedProduct.product_details" :key="index">
                          <td class="size-input">
                            <!-- Bind each size to v-model to ensure it reflects the selected size -->
                            <select v-model="detail.size"
                                    @change="onSizeChange($event, index)"
                                    class="form-select form-select-sm size-dropdown"
                                    required>
                              <!-- Loop through available sizes in the sizes array -->
                              <option v-for="size in sizes" :key="size.size" :value="size.size.trim()">
                                {{ size.size }}
                              </option>
                            </select>
                          </td>
                          
                          <td class="size-quantity">
                            <input type="number" class="form-control form-control-sm" v-model="detail.quantity" id="size_quantity" name="size_quantity" required>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="modal-footer">
          <button type="button" class="action-btn" @click="saveProduct">Update</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>